import { API } from 'aws-amplify'

import { listDeviceTypes } from '../../graphql/queries'

// constants
const SET_DEVICE_TYPES = 'SET_DEVICE_TYPES'

const filter = {
  status: {
    eq: true // filter priority = 1
  }
}

// actions

// thunks
export const fetchDeviceTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listDeviceTypes: { items }
        }
      } = await API.graphql({
        query: listDeviceTypes,
        variables: { filter: filter }
      })

      const deviceTypes = items.map(({ id, title }) => {
        return { id: id, name: title, value: id }
      })

      dispatch({
        type: SET_DEVICE_TYPES,
        payload: deviceTypes
      })
    } catch (err) {
      throw err
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_DEVICE_TYPES:
      return payload

    default:
      return state
  }
}

export default reducer
