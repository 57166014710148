import React, { useEffect, useState } from 'react'
import { makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import { PrimaryColor } from '../../styles/colors'
import { API } from 'aws-amplify'
import { getVideo } from '../../graphql/queries'
import { MovieForm } from './components/movieForm'

export const EditMovie = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [movie, setMovie] = useState(null)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    fetchMovie()
  }, [id])

  const fetchMovie = async () => {
    try {
      const {
        data: { getVideo: movieData }
      } = await API.graphql({
        query: getVideo,
        variables: { id }
      })

      setMovie(movieData)
      setLoading(false)
    } catch (err) {
      console.log('Error in getting video is: ', err)
      history.push('/')
    }
  }

  const updateLocally = async () => await fetchMovie()

  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.title}>Update Movie</Typography>
      {loading ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress size={30} className={classes.spinner} />
        </div>
      ) : (
        <div className={classes.formContainer}>
          <MovieForm movie={movie} updateLocally={updateLocally} />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  title: {
    color: 'black',
    fontSize: 20,
    fontWeight: '600',
    padding: 20,
    textAlign: 'center'
  },
  formContainer: {
    flex: 1,
    padding: '10px 30px',
    display: 'flex',
    overflow: 'hidden'
  },
  spinnerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  spinner: {
    color: PrimaryColor,
    fontSize: 13
  },
  mainContainer: {
    flex: 1,
    paddingTop: '65px',
    padding: '80px 20px 10px 20px',
    display: 'flex',
    flexDirection: 'column'
  }
}))
