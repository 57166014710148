import { makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import { UPDATE_MOVIE_TABS } from '../../../lib/constants'
import { PrimaryColor } from '../../../styles/colors'
import { CastAndCrew } from './castAndCrew'
import { MetaInformation } from './metaInformation'
import { Captions } from './captions'
import { Thumbnails } from './thumbnails'
import { Trailers } from './trailers'
import { Audios } from './audios'
import { ContentPreview } from './contentPreview'

export const MovieForm = ({ movie, updateLocally }) => {
  const classes = useStyles()
  const [tab, setTab] = useState('meta-information')
  return (
    <div className={classes.movieFormContainer}>
      <div className={classes.tabsContainer}>
        {UPDATE_MOVIE_TABS.map((item) => {
          return (
            <div
              role="presentation"
              onClick={() => setTab(item.id)}
              className={tab === item.id ? classes.selectedTab : classes.tab}
              key={item.id}>
              <Typography
                className={
                  tab === item.id ? classes.selectedTabText : classes.tabText
                }>
                {item.name}
              </Typography>
            </div>
          )
        })}
      </div>

      <div className={classes.tabContent}>
        {tab === 'meta-information' && (
          <MetaInformation {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'thumbnails' && (
          <Thumbnails {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'cast-and-crew' && (
          <CastAndCrew {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'captions' && (
          <Captions {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'audios' && (
          <Audios {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'trailers' && (
          <Trailers {...movie} updateLocally={updateLocally} />
        )}
        {tab === 'content-preview' && (
          <ContentPreview {...movie} updateLocally={updateLocally} />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  tab: {
    padding: '3px 10px',
    cursor: 'pointer'
  },
  selectedTab: {
    backgroundColor: PrimaryColor,
    padding: '3px 10px',
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  tabText: {
    color: 'gray',
    fontSize: 18,
    fontWeight: '500'
  },
  selectedTabText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '500'
  },
  movieFormContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tabsContainer: {
    borderBottom: '1px solid gray',
    display: 'flex'
  },
  tabContent: {
    flex: 1,
    display: 'flex',
    padding: 10,
    overflow: 'auto'
  }
}))
