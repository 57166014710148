import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import { useParams } from 'react-router'

import { CircularProgress, FormLabel } from '@material-ui/core'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import {
  getUserActivityLogsByUser,
  listUserActivityActions
} from '../../graphql/queries'
import { UsersActivityList } from './usersActivityList'

import { SelectInput, FormInput } from '../../components'

export const UserActivityLog = () => {
  const [usersActivityList, setUsersActivityList] = useState([])
  const [typeFilter, setTypeFilter] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loading, setLoading] = useState(true)
  const [activiyActionTypes, setActiviyActionTypes] = useState([])

  const { userId } = useParams()

  useEffect(() => {
    if (activiyActionTypes.length === 0) {
      ;(async () => {
        try {
          const {
            data: {
              listUserActivityActions: { items }
            }
          } = await API.graphql({
            query: listUserActivityActions
          })

          const actionTypes = items.map((actionType) => {
            return {
              id: actionType.id,
              name: actionType.description,
              value: actionType.id
            }
          })
          setActiviyActionTypes(actionTypes)
        } catch (err) {
          throw err
        }
      })()
    }
  }, [])

  const handleTypeFilter = ({ target: { value } }) => {
    setTypeFilter(value)
  }

  const handleStartDate = ({ target: { value } }) => {
    setStartDate(value)
  }
  const handleEndDate = ({ target: { value } }) => {
    setEndDate(value)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      if (startDate === '') {
        var yesterday = new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000
        ).toISOString()
        setStartDate(yesterday)
      } else {
        var start_date = new Date(startDate).toISOString().slice(0, 10)
        setStartDate(start_date)
      }
      if (endDate === '') {
        var today = new Date().toISOString()
        setEndDate(today)
      } else {
        setEndDate(new Date(endDate).toISOString().slice(0, 10))
      }

      if (typeFilter !== '') {
        try {
          const {
            data: {
              getUserActivityLogsByUser: { items }
            }
          } = await API.graphql({
            query: getUserActivityLogsByUser,
            variables: {
              user_id: userId,
              filter: {
                action_id: { eq: typeFilter },
                createdAt: { between: [startDate, endDate] }
              }
            }
          })
          const _usersActivityList = items.sort(
            (first, second) =>
              new Date(second.createdAt) - new Date(first.createdAt)
          )

          setUsersActivityList(_usersActivityList)
          setLoading(false)
        } catch (err) {
          console.log('Error in getting users activity is: ', err)
          setLoading(false)
        }
      }
    })()
  }, [
    userId,
    typeFilter,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setLoading,
    setUsersActivityList
  ])

  return (
    <div className="dashboard-page">
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
        <Tabs>
          <Tab label="< Back" to={`/user-management`} component={Link} />
          <Tab
            label="Personal Information"
            to={`/user-detail/${userId}`}
            component={Link}
          />
          <Tab
            label="Order Management"
            to={`/order-management/${userId}`}
            component={Link}
          />
          <Tab
            label="Activity Log"
            to={`/user-activity-log/${userId}`}
            component={Link}
          />
        </Tabs>
      </Box>

      <div className="plan-content">
        <div className="filter-header">
          <div className="dropdown-filter">
            <SelectInput
              data={activiyActionTypes}
              value={typeFilter}
              changeHandler={handleTypeFilter}
            />
          </div>
          <div className="filter-label">
            <FormLabel>Start Date:</FormLabel>
          </div>
          <div className="dropdown-filter">
            <FormInput
              placeholder="Start Date"
              id="start_date"
              name="start_date"
              type="date"
              onChangeHandler={handleStartDate}
              value={startDate}
            />
          </div>
          <div className="filter-label">
            <FormLabel>End Date:</FormLabel>
          </div>
          <div className="dropdown-filter">
            <FormInput
              placeholder="End Date"
              id="end_date"
              name="end_date"
              type="date"
              onChangeHandler={handleEndDate}
              value={endDate}
            />
          </div>
        </div>

        {loading && (
          <div className="loading-container">
            <CircularProgress size={30} className="countries-loader" />
          </div>
        )}

        {!loading && usersActivityList.length > 0 && (
          <UsersActivityList usersActivityList={usersActivityList} />
        )}
        {!loading && usersActivityList.length == 0 && (
          <div className="loading-container">No User Activities available</div>
        )}
      </div>
    </div>
  )
}
