// constants
const ADD_SIGNED_URL = 'ADD_SIGNED_URL'

// actions
export const addSignedUrl = (payload) => ({
  payload,
  type: ADD_SIGNED_URL
})

// thunks

// reducer
const initialState = {}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_SIGNED_URL:
      return { ...state, ...payload }

    default:
      return state
  }
}

export default reducer
