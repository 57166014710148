import React from 'react'

import { TextConfiguratinForm } from '../textConfiguration'
import { useStyles } from './styles'

export const TitleForm = ({ section }) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <TextConfiguratinForm header={'Desktop'} section={section} />
      <TextConfiguratinForm header={'Tablet'} section={section} />
      <TextConfiguratinForm header={'Mobile'} section={section} />
    </div>
  )
}
