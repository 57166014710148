import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { v4 as uuid4 } from 'uuid'
import { useDispatch } from 'react-redux'

import { PrimaryColor, SecondaryButton } from '../../../styles/colors'
import { ButtonComponent } from '../../../components'
import { TrailerUpdateForm } from './trailerUpdateForm'
import { showAlert } from '../../../redux/modules/alertHandler'
import { ALERT_TYPES, LanguagesCode } from '../../../lib/constants'
import { updateTrailersAndCaptions } from '../../../backend/movies'

export const Trailers = ({
  trailers,
  thumbnail_lg: movieThumbnailLg,
  thumbnail_md: movieThumbnailMd,
  thumbnail_sm: movieThumbnailSm,
  updateLocally,
  id: videoId
}) => {
  const classes = useStyles()
  const [trailersList, setTrailersList] = useState([])
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const data = trailers.items
      .filter((item) => item.type !== 'CONTENT_PREVIEW')
      .map((item) => {
        return {
          ...item,
          supportedCaptions: item.supported_captions.items,
          captionsUpdated: false,
          audio_tracks: item.audio_tracks.items.map((audioItem) => ({
            ...audioItem,
            language: audioItem.language,
            srcAudio: audioItem.url,
            isOriginal: audioItem.is_orignal,
            languageCode: LanguagesCode[audioItem.language].toUpperCase(),
            video_id: item.id
          }))
        }
      })
    setTrailersList(data)
  }, [trailers, setTrailersList])

  useEffect(() => {
    setValidated(true)

    trailersList.forEach((trailer) => {
      if (trailer.title === '') setValidated(false)
      const filteredCaptions = trailer.supportedCaptions.filter(
        (item) => !item.url
      )

      const filteredAudios = trailer.audio_tracks.filter(
        (item) => !item.srcAudio
      )
      if (filteredCaptions.length > 0 || filteredAudios.length > 0)
        setValidated(false)
    })
  }, [trailersList, setValidated])

  const addTrailer = () => {
    setTrailersList([
      ...trailersList,
      {
        title: '',
        thumbnail_sm: '',
        thumbnail_md: '',
        thumbnail_lg: '',
        key: '',
        hls_url: '',
        id: uuid4(),
        isMain: false,
        supportedCaptions: [],
        audio_tracks: [],
        new: true,
        video_id: videoId,
        videoFromBucket: null,
        duration: {
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        type: 'TRAILER'
      }
    ])
  }

  const removeTrailer = (id) => {
    const updatedTrailers = trailersList.filter((item) => item.id !== id)
    setTrailersList(updatedTrailers)
  }

  const updateTrailer = (id, data) => {
    const updatedTrailers = trailersList.map((trailer) => {
      if (trailer.id === id) return { ...trailer, ...data, updated: true }
      return trailer
    })
    setTrailersList(updatedTrailers)
    setUpdated(true)
  }

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const handleUpdate = async () => {
    try {
      setLoading(true)
      await updateTrailersAndCaptions(
        trailersList,
        movieThumbnailLg,
        movieThumbnailMd,
        movieThumbnailSm
      )
      handleAlert('Data updated successfuly', ALERT_TYPES.SUCCESS)
      setUpdated(false)
      setLoading(false)
      updateLocally()
    } catch (err) {
      console.log('Error in updating trailer is: ', err)
      handleAlert('Something went wrong. Please try again.', ALERT_TYPES.ERROR)
      setLoading(false)
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <div>
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add trailer"
            height={35}
            onClickHandler={addTrailer}
            width={140}
          />
        </div>
        <div>
          {trailersList.map((trailer) => {
            return (
              <TrailerUpdateForm
                videoId={videoId}
                updateTrailer={updateTrailer}
                removeTrailer={removeTrailer}
                trailer={trailer}
              />
            )
          })}
        </div>
      </div>
      <div className={classes.footer}>
        <ButtonComponent
          title="UPDATE"
          disabled={!updated || !validated}
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          loading={loading}
          onClickHandler={handleUpdate}
          width={150}
          height={40}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1
  }
}))
