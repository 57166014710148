import { Box, CircularProgress } from '@material-ui/core'

export function LoadingSpinner() {
  return (
    <Box
      m="auto"
      display="flex"
      width={500}
      height={80}
      alignItems="center"
      justifyContent="center">
      <CircularProgress size={60} />
    </Box>
  )
}
