import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchCastCrewTypes } from '../redux/modules/castCrewTypes'

export const useCastCrewTypes = () => {
  const castCrewTypes = useSelector((state) => state.castCrewTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    if (castCrewTypes.length === 0) dispatch(fetchCastCrewTypes())
  }, [castCrewTypes, dispatch])

  return { castCrewTypes }
}
