import React, { useReducer, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import {
  ButtonComponent,
  FormInput,
  Header,
  SelectInput
} from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { addQNA, updateQNA } from '../../redux/modules/helpQNA'
import { fetchCategories } from '../../backend/helpCategories'
import { validateHelpQNA } from '../../lib/util'

export const CreateQNA = () => {
  const history = useHistory()
  const [helpCategories, sethelpCategories] = useState([])
  const [error, setError] = useState(null)

  const helpQNAList = useSelector((state) => state.helpQNA.list)
  const helpQNA = useSelector((state) => state.helpQNA)
  const qnaCount = helpQNA.length

  const { qnaId } = useParams()
  const { categoryId } = useParams()

  const [
    { question, answer, precedence, category_id },
    sethelpQNA
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    question: '',
    answer: '',
    precedence: '',
    category_id: '-1'
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (qnaId) {
      const filteredlist = helpQNAList.filter(
        (categoryItem) => categoryItem.id === categoryId
      )

      if (filteredlist.length > 0) {
        const filteredQNA = filteredlist[0].Knowledge_base.items.filter(
          (qnaItem) => qnaItem.id === qnaId
        )
        const { question, answer, precedence, category_id } = filteredQNA[0]

        sethelpQNA({
          question: question,
          answer: answer,
          precedence: precedence,
          category_id: category_id
        })

        return
      }
    }
    history.replace('/create-qna')
  }, [qnaId, categoryId, qnaCount, history, sethelpQNA])

  const onChangeHandler = ({ target: { name, value } }) =>
    sethelpQNA({ [name]: value })

  const handleChange = (key, value) => {
    sethelpQNA({ [key]: value })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetchCategories(true)
        sethelpCategories(response)
      } catch (err) {
        console.log('Error in fetching help categories is: ', err)
        alert(err.message || 'Error in fetching help categories')
      }
    })()
  }, [])

  const addNewQNA = async () => {
    var precedenceValue = precedence
    if (precedence != '') precedenceValue = parseInt(precedence)

    const payload = {
      question: question,
      answer: answer,
      precedence: precedenceValue,
      category_id: category_id,
      status: true,
      created_by: 'Admin'
    }

    const { status, message } = validateHelpQNA(payload)
    if (!status) {
      setError(message)
      return
    }

    try {
      await dispatch(addQNA(payload))
      history.push('/help-qna')
    } catch (err) {
      console.log('Error in creating q&a is: ', err)
    }
  }

  const UpdateQNA = async () => {
    var precedenceValue = precedence
    if (precedence != '') precedenceValue = parseInt(precedence)

    const payload = {
      question: question,
      answer: answer,
      precedence: precedenceValue,
      category_id: category_id,
      status: true,
      created_by: 'Admin',
      id: qnaId
    }
    try {
      await dispatch(updateQNA(payload))
      history.push('/help-qna')
    } catch (err) {
      console.log('Error in creating q&a is: ', err)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title={qnaId ? 'Update QnA' : 'Create QnA'} />

      <div className="create-plan-container">
        <div className="create-plan-input-container">
          <div className="create-plan-input">
            <div className="promo-code-form-label">Select Category *</div>
            <SelectInput
              data={[
                { id: '-1', name: 'Select Category', value: '-1' },
                ...helpCategories
              ]}
              changeHandler={({ target: { value } }) =>
                handleChange('category_id', value)
              }
              value={category_id}
            />
          </div>
          <div className="create-plan-input">
            <div className="promo-code-form-label">Question *</div>
            <FormInput
              placeholder="Question"
              id="question"
              name="question"
              onChangeHandler={onChangeHandler}
              value={question}
            />
          </div>
          <div className="create-plan-input">
            <div className="promo-code-form-label">Answer *</div>
            <FormInput
              placeholder="Answer"
              id="answer"
              name="answer"
              onChangeHandler={onChangeHandler}
              value={answer}
            />
          </div>
          <div className="create-plan-input">
            <div className="promo-code-form-label">Precedence</div>
            <FormInput
              placeholder="Precedence"
              id="precedence"
              name="precedence"
              onChangeHandler={({ target: { value } }) =>
                handleChange('precedence', value)
              }
              value={precedence}
            />
          </div>
        </div>
      </div>
      <div className="create-plan-footer">
        <div className="form-error">{error}</div>
        <div className="create-plan-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={() => history.replace('/help-qna')}
            width={130}
          />
        </div>
        <div className="create-plan-footer-button">
          <ButtonComponent
            title={qnaId ? 'UPDATE' : 'SAVE'}
            onClickHandler={qnaId ? UpdateQNA : addNewQNA}
            width={130}
          />
        </div>
      </div>
    </div>
  )
}
