import React, { useState } from 'react'
import { Modal } from '@material-ui/core'

import { ButtonComponent } from '../'
import { PrimaryColor } from '../../styles/colors'
import webvtt from 'node-webvtt'
import { useStyles } from './styles'

export const CaptionsValidation = ({ isOpen, close }) => {
  const classes = useStyles()
  const [validationStatus, setValidationStatus] = useState(null)

  const showFile = (e) => {
    e.preventDefault()
    setValidationStatus(null)
    const reader = new FileReader()
    reader.onload = (e) => {
      const text = e.target.result
      const result = webvtt.parse(text)
      setValidationStatus(result.errors)
    }
    reader.readAsText(e.target.files[0])
  }

  const closeModal = () => {
    setValidationStatus(null)
    close()
  }

  return (
    <Modal
      open={isOpen}
      closeModal={closeModal}
      BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.5)' } }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="file-upload-modal">
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <div className={classes.contentRoot}>
            <input type="file" onChange={showFile} />
            {validationStatus && validationStatus.length === 0 && (
              <div className={classes.successContainer}>
                {'Validation Successfull'}
              </div>
            )}
            {validationStatus && (
              <div className={classes.errorList}>
                {validationStatus.map((item, index) => {
                  return (
                    <div className={classes.errorItem}>
                      <bold>{index + 1}. </bold>
                      {item.message}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={classes.closeButtonContainer}>
            <ButtonComponent
              borderColor={PrimaryColor}
              backgroundColor={PrimaryColor}
              titleColor={'#ffffff'}
              title="Close"
              width={140}
              height={25}
              onClickHandler={closeModal}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
