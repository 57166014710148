import { dashedStringToCamelCase } from '../../lib/util'
import { API } from 'aws-amplify'
import { batchUpdateLandingPageData } from '../../graphql/mutations'
import { listLandingPageData } from '../../graphql/queries'
// constants
const SET_BACKGROUND = 'SET_BACKGROUND'
const SET_TITLE = 'SET_TITLE'
const SET_SOCIAL_ICON = 'SET_SOCIAL_ICON'
const UNSET_UPDATED = 'UNSET_UPDATED'
const SET_DATA = 'SET_DATA'

export const fetchLandingPageData = () => async (dispatch) => {
  try {
    const {
      data: {
        listLandingPageData: { items }
      }
    } = await API.graphql({
      query: listLandingPageData
    })

    const landingPage = {
      background: {
        desktop: {},
        tablet: {},
        mobile: {}
      },
      title: {
        desktop: {},
        tablet: {},
        mobile: {}
      },
      subTitle: {
        desktop: {},
        tablet: {},
        mobile: {}
      },
      banner: {
        desktop: {},
        tablet: {},
        mobile: {}
      },
      socialIcons: {
        desktop: {},
        tablet: {},
        mobile: {}
      }
    }

    items.forEach((item) => {
      const device = item.device_name
      delete item['device_name']
      Object.keys(item).forEach((key) => {
        const keyName = dashedStringToCamelCase(key)
        Object.keys(item[key]).forEach((attribute) => {
          const attributeName = dashedStringToCamelCase(attribute)
          console.log('attribute', keyName, device, attribute)
          landingPage[keyName][device][attributeName] =
            attribute === 'background_color' &&
            item[key][attribute] === 'transparent'
              ? ''
              : item[key][attribute]
        })
      })
    })

    dispatch(setData(landingPage))
  } catch (err) {
    return err
  }
}

export const saveChanges = () => async (dispatch, getState) => {
  try {
    let { landingPage } = getState()

    delete landingPage['updated']
    let payload = []
    Object.keys(landingPage).forEach((key) => {
      Object.keys(landingPage[key]).forEach((device) => {
        Object.keys(landingPage[key][device]).forEach((attribute) => {
          let name = attribute.replace(
            /[A-Z]/g,
            (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase()
          )
          let keyName = key.replace(
            /[A-Z]/g,
            (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase()
          )

          const index = payload.findIndex(
            (item) => item.device_name && item.device_name === device
          )
          if (index > -1) {
            payload[index] = {
              ...payload[index],
              [keyName]: payload[index][keyName]
                ? {
                    ...payload[index][keyName],
                    [name]: landingPage[key][device][attribute]
                  }
                : { [name]: landingPage[key][device][attribute] }
            }
          } else {
            payload = [
              ...payload,
              {
                device_name: device,
                [keyName]: {
                  [name]: landingPage[key][device][attribute]
                }
              }
            ]
          }
        })
      })
    })

    await API.graphql({
      query: batchUpdateLandingPageData,
      variables: { devices: payload }
    })

    dispatch(unSetUpdated())
  } catch (err) {
    return err
  }
}

export const setData = (payload) => {
  return {
    type: SET_DATA,
    payload
  }
}

export const unSetUpdated = () => {
  return {
    type: UNSET_UPDATED
  }
}

export const setBackground = (payload) => {
  return {
    type: SET_BACKGROUND,
    payload
  }
}

export const setTitle = (payload) => {
  return {
    type: SET_TITLE,
    payload
  }
}

export const setSocialIcons = (payload) => {
  return {
    type: SET_SOCIAL_ICON,
    payload
  }
}

// reducer
const initialState = {
  background: {
    desktop: {
      type: 'image',
      url:
        'https://thumbnails-and-captions220932-devschema.s3.us-west-2.amazonaws.com/public/c94c2b98-f788-477a-8197-9ffbfbba0759/thumbnails/e17c29c5-100b-49d8-b7de-ff5d8cc68bcc.jpg'
    },
    tablet: {
      type: 'image',
      url:
        'https://thumbnails-and-captions220932-devschema.s3.us-west-2.amazonaws.com/public/c94c2b98-f788-477a-8197-9ffbfbba0759/thumbnails/e17c29c5-100b-49d8-b7de-ff5d8cc68bcc.jpg'
    },
    mobile: {
      type: 'image',
      url:
        'https://thumbnails-and-captions220932-devschema.s3.us-west-2.amazonaws.com/public/c94c2b98-f788-477a-8197-9ffbfbba0759/thumbnails/e17c29c5-100b-49d8-b7de-ff5d8cc68bcc.jpg'
    }
  },
  title: {
    desktop: {
      text: 'All Mintrio Originals.',
      fontSize: '5.9375',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    tablet: {
      text: 'All Mintrio Originals.',
      fontSize: '2.8',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    mobile: {
      text: 'All Mintrio Originals.',
      fontSize: '2.8',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    }
  },
  subTitle: {
    desktop: {
      text: 'Your own digital theater.',
      fontSize: 2.7,
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    tablet: {
      text: 'Your own digital theater.',
      fontSize: '1.4',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    mobile: {
      text: 'Your own digital theater.',
      fontSize: '1.4',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    }
  },
  banner: {
    desktop: {
      text: 'Checkout our content library now.',
      fontSize: '1.875',
      color: '#c2c2c2',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    tablet: {
      text: 'Checkout our content library now.',
      fontSize: '1.3',
      color: '#c2c2c2',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    mobile: {
      text: 'Checkout our content library now.',
      fontSize: 1.3,
      color: '#c2c2c2',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    }
  },
  socialIcons: {
    desktop: {
      // fontSize: 15,
      bottomOffset: '90',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    tablet: {
      // fontSize: 15,
      bottomOffset: '90',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    },
    mobile: {
      // fontSize: 15,
      bottomOffset: '90',
      color: '#ffffff',
      backgroundColor: '',
      backgroundBlurriness: '0',
      backgroundOpacity: '0.5'
    }
  },
  updated: false
}

export const landingPage = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_BACKGROUND: {
      return {
        ...state,
        updated: true,
        background: {
          ...state.background,
          [payload.name]: { ...state.background[payload.name], ...payload.data }
        }
      }
    }

    case SET_TITLE: {
      return {
        ...state,
        updated: true,
        [payload.section]: {
          ...state[payload.section],
          [payload.name]: {
            ...state[payload.section][payload.name],
            ...payload.data
          }
        }
      }
    }

    case SET_SOCIAL_ICON: {
      return {
        ...state,
        updated: true,
        socialIcons: {
          ...state.socialIcons,
          [payload.name]: {
            ...state.socialIcons[payload.name],
            ...payload.data
          }
        }
      }
    }

    case UNSET_UPDATED: {
      return {
        ...state,
        updated: false
      }
    }

    case SET_DATA: {
      return {
        ...payload,
        updated: false
      }
    }

    default:
      return state
  }
}
