import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ButtonComponent, Header } from '../../../../components'
import { TrailerUploadForm } from '../trailersAndClips/components/trailerUploadForm'
import { addTrailer } from '../../../../redux/modules/newMovie'
import { PrimaryColor } from '../../../../styles/colors'

export const ContentPreview = () => {
  const { trailers } = useSelector((state) => state.newMovie)

  const dispatch = useDispatch()

  const add = () => {
    if (trailers.findIndex((item) => item.type === 'CONTENT_PREVIEW') === -1) {
      dispatch(addTrailer('CONTENT_PREVIEW'))
    }
  }
  const trailer = trailers.find((item) => item.type === 'CONTENT_PREVIEW')

  return (
    <div>
      <Header title="Upload Content Preview" />
      <div className="create-movie-step">
        <div className="cast-crew-add-button">
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add Video"
            height={35}
            onClickHandler={add}
            width={140}
          />
        </div>
        {trailer && (
          <TrailerUploadForm
            key={trailer.id}
            trailer={trailer}
            index={0}
            contentPreview={true}
          />
        )}
      </div>
    </div>
  )
}
