import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { SOCIAL_ICONS } from '../../../../../../lib/constants'
import { hexToRGBA } from '../../../../../../lib/util'

export const SocialIcons = ({ name }) => {
  const {
    socialIcons: {
      [name]: {
        color,
        bottomOffset,
        backgroundColor: socialIconsBackgroundColor,
        backgroundOpacity: socialIconsBackgroundOpacity,
        backgroundBlurriness: socialIconsBackgroundBlurriness
      }
    }
  } = useSelector((state) => state.landingPage)
  const classes = useStyles({
    socialIconsBackgroundColor: Boolean(socialIconsBackgroundColor)
      ? hexToRGBA(socialIconsBackgroundColor, socialIconsBackgroundOpacity)
      : 'transparent',
    socialIconsBackgroundBlurriness,
    color,
    bottomOffset
  })
  return (
    <div className={classes.mainContainer}>
      <div className={classes.iconsContainer}>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        {SOCIAL_ICONS.map((item) => {
          return (
            <div className={classes.icon}>
              <a
                key={item.id}
                href={item.navigateTo}
                target="_blank"
                className={item.icon}
                rel="noreferrer"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: ({ bottomOffset }) => `${bottomOffset}px`,
    position: 'absolute'
  },
  iconsContainer: {
    backgroundColor: ({ socialIconsBackgroundColor }) =>
      socialIconsBackgroundColor,
    marginTop: 20,
    borderRadius: 5,
    display: 'flex',
    padding: '0px 10px',
    backdropFilter: ({ background_blurriness }) =>
      ` blur(${background_blurriness}px)`
  },
  icon: {
    height: '100%',
    width: `25px`,
    color: ({ color }) => color,
    fontSize: `15px`
  }
}))
