import React, { useReducer, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import { Copyright, useStyles } from './_Login'
import { validateEmail } from '../../lib/util'
import { loginUser } from '../../redux/modules/user'
import './login.css'

export function Login() {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [{ email, password }, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: '', password: '' }
  )

  const onChange = (event) => {
    const { name, value } = event.target

    setError(null)
    setInputValues({ [name]: value })
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    if (email.trim() === '') {
      setError('Please enter a email')
      return
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email')
      return
    }

    if (password.trim() === '') {
      setError('Please enter a password')
      return
    }

    if (password.length < 8) {
      setError('Password must not be less than 8')
      return
    }

    try {
      await dispatch(loginUser(email, password))
      setLoading(false)
    } catch (err) {
      setLoading(false)

      switch (err.code) {
        case 'UserNotFoundException':
          setError('User not found')
          break
        case 'InvalidUser':
          setError('Invalid User')
          break
        case 'UserNotConfirmedException':
          setError('Account not confirmed')
          break
        case 'NotAuthorizedException':
          setError('Incorrect email or password')
          break
        default:
          setError('Something went wrong.')
          break
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            onChange={onChange}
            value={email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={onChange}
            value={password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {error && <div className={'errorContainer'}>{error}</div>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            {loading ? (
              <CircularProgress
                size={24}
                color="white"
                style={{ color: 'white' }}
              />
            ) : (
              'Sign In'
            )}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
