/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchCreateCountries = /* GraphQL */ `
  mutation BatchCreateCountries($countries: [CreateCountryInput]) {
    batchCreateCountries(countries: $countries) {
      id
      country_code
      country_calling_code
      country_name
      currency
      status
      createdAt
      updatedAt
      payment_gateways {
        items {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`

export const batchDeleteVideoGenres = /* GraphQL */ `
  mutation BatchDeleteVideoGenres($ids: [ID]) {
    batchDeleteVideoGenres(ids: $ids) {
      id
    }
  }
`

export const batchDeleteVideoContentRatings = /* GraphQL */ `
  mutation BatchDeleteVideoContentRatings($ids: [ID]) {
    batchDeleteVideoContentRatings(ids: $ids) {
      id
    }
  }
`

export const batchCreateDeviceTypes = /* GraphQL */ `
  mutation BatchCreateDeviceTypes($deviceTypes: [CreateSupportedDeviceInput]) {
    batchCreateDeviceTypes(deviceTypes: $deviceTypes) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`

export const batchCreateVideoContentRatings = /* GraphQL */ `
  mutation BatchCreateVideoContentRatings(
    $ratings: [CreateVideoContentRatingInput]
  ) {
    batchCreateVideoContentRatings(ratings: $ratings) {
      id
    }
  }
`

export const batchCreateVideoQuality = /* GraphQL */ `
  mutation BatchCreateVideoQuality($videoQualities: [CreateVideoQuality]) {
    batchCreateVideoQuality(videoQualities: $videoQualities) {
      id
      resolution
      status
      createdAt
      updatedAt
      vertical_resolution {
        title
        resolution
        createdAt
        updatedAt
      }
    }
  }
`
export const batchCreatePlanDeviceTypes = /* GraphQL */ `
  mutation BatchCreatePlanDeviceTypes(
    $planDevices: [CreatePlanDeviceTypeInput]
  ) {
    batchCreatePlanDeviceTypes(planDevices: $planDevices) {
      id
      plan_id
      plans {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      device_type_id
      device_types {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchDeletePlanDeviceTypes = /* GraphQL */ `
  mutation BatchDeletePlanDeviceTypes($planDevices: [ID]) {
    batchDeletePlanDeviceTypes(planDevices: $planDevices) {
      id
    }
  }
`
export const batchCreatePaymentGateways = /* GraphQL */ `
  mutation BatchCreatePaymentGateways($paymentTypes: [CreatePaymentTypeInput]) {
    batchCreatePaymentGateways(paymentTypes: $paymentTypes) {
      id
      name
      disabled
      country_code
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchVideoByQualities = /* GraphQL */ `
  mutation BatchVideoByQualities($videoByQualites: [CreateVideoByQuality]) {
    batchVideoByQualities(videoByQualites: $videoByQualites) {
      id
    }
  }
`
export const batchCreateVideoPlans = /* GraphQL */ `
  mutation BatchCreateVideoPlans($videoPlans: [CreateVideoPlanInput]) {
    batchCreateVideoPlans(videoPlans: $videoPlans) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      video_by_quality_id
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const batchCreatePricings = /* GraphQL */ `
  mutation BatchCreatePricings($pricings: [CreatePricingInput]) {
    batchCreatePricings(pricings: $pricings) {
      id
      country_code
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const batchDeletePricings = /* GraphQL */ `
  mutation BatchDeletePricings($pricingIds: [ID]) {
    batchDeletePricings(pricingIds: $pricingIds) {
      id
    }
  }
`
export const batchDeleteVideoPlans = /* GraphQL */ `
  mutation BatchDeleteVideoPlans($videoPlanIds: [ID]) {
    batchDeleteVideoPlans(videoPlanIds: $videoPlanIds) {
      id
    }
  }
`
export const batchCreateGenres = /* GraphQL */ `
  mutation BatchCreateGenres($genres: [CreateGenre]) {
    batchCreateGenres(genres: $genres) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const batchCreateCastCrew = /* GraphQL */ `
  mutation BatchCreateCastCrew($casts: [CreateCastCrew]) {
    batchCreateCastCrew(casts: $casts) {
      id
      first_name
      last_name
      nick_name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchCastCrewTypes = /* GraphQL */ `
  mutation BatchCastCrewTypes($castTypes: [CreateCastCrewType]) {
    batchCastCrewTypes(castTypes: $castTypes) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchCreateVideoGenre = /* GraphQL */ `
  mutation BatchCreateVideoGenre($videoGenres: [CreateVideoGenre]) {
    batchCreateVideoGenre(videoGenres: $videoGenres) {
      id
    }
  }
`
export const batchCreateCaptions = /* GraphQL */ `
  mutation BatchCreateCaptions($captions: [CreateCaption]) {
    batchCreateCaptions(captions: $captions) {
      id
      language
      url
      status
      video_id
      is_orignal
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchCreateVideoCastCrew = /* GraphQL */ `
  mutation BatchCreateVideoCastCrew($videoCast: [CreateVideoCastCrew]) {
    batchCreateVideoCastCrew(videoCast: $videoCast) {
      id
      video_id
      cast_crew_id
      cast_crew {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const batchCreateVerticalResolutions = /* GraphQL */ `
  mutation BatchCreateVerticalResolutions($data: [CreateVerticalResolution]) {
    batchCreateVerticalResolutions(data: $data) {
      title
      resolution
      createdAt
      updatedAt
    }
  }
`
export const batchCreateOrders = /* GraphQL */ `
  mutation BatchCreateOrders($orders: [createOrderInput]) {
    batchCreateOrders(orders: $orders) {
      id
    }
  }
`
export const batchCreateTrailers = /* GraphQL */ `
  mutation BatchCreateTrailers($trailers: [CreateTrailer]) {
    batchCreateTrailers(trailers: $trailers) {
      id
      hls_url

      status
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      title
      is_main
      video_id
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createAdConfiguration = /* GraphQL */ `
  mutation CreateAdConfiguration(
    $input: CreateAdConfigurationInput!
    $condition: ModelAdConfigurationConditionInput
  ) {
    createAdConfiguration(input: $input, condition: $condition) {
      id
      name
      status
      ad_decision_server_url
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateAdConfiguration = /* GraphQL */ `
  mutation UpdateAdConfiguration(
    $input: UpdateAdConfigurationInput!
    $condition: ModelAdConfigurationConditionInput
  ) {
    updateAdConfiguration(input: $input, condition: $condition) {
      id
      name
      status
      ad_decision_server_url
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteAdConfiguration = /* GraphQL */ `
  mutation DeleteAdConfiguration(
    $input: DeleteAdConfigurationInput!
    $condition: ModelAdConfigurationConditionInput
  ) {
    deleteAdConfiguration(input: $input, condition: $condition) {
      id
      name
      status
      ad_decision_server_url
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createAdCampaign = /* GraphQL */ `
  mutation CreateAdCampaign(
    $input: CreateAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    createAdCampaign(input: $input, condition: $condition) {
      id
      name
      video_url
      video_by_quality_id
      country_code
      ad_configuration_id
      ad_configuration {
        id
        name
        status
        ad_decision_server_url
        additional_attributes
        createdAt
        updatedAt
      }
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const updateAdCampaign = /* GraphQL */ `
  mutation UpdateAdCampaign(
    $input: UpdateAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    updateAdCampaign(input: $input, condition: $condition) {
      id
      name
      video_url
      video_by_quality_id
      country_code
      ad_configuration_id
      ad_configuration {
        id
        name
        status
        ad_decision_server_url
        additional_attributes
        createdAt
        updatedAt
      }
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const deleteAdCampaign = /* GraphQL */ `
  mutation DeleteAdCampaign(
    $input: DeleteAdCampaignInput!
    $condition: ModelAdCampaignConditionInput
  ) {
    deleteAdCampaign(input: $input, condition: $condition) {
      id
      name
      video_url
      video_by_quality_id
      country_code
      ad_configuration_id
      ad_configuration {
        id
        name
        status
        ad_decision_server_url
        additional_attributes
        createdAt
        updatedAt
      }
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const createCaption = /* GraphQL */ `
  mutation CreateCaption(
    $input: CreateCaptionInput!
    $condition: ModelCaptionConditionInput
  ) {
    createCaption(input: $input, condition: $condition) {
      id
      language
      url
      status
      video_id
      is_orignal
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateCaption = /* GraphQL */ `
  mutation UpdateCaption(
    $input: UpdateCaptionInput!
    $condition: ModelCaptionConditionInput
  ) {
    updateCaption(input: $input, condition: $condition) {
      id
      language
      url
      status
      video_id
      is_orignal
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteCaption = /* GraphQL */ `
  mutation DeleteCaption(
    $input: DeleteCaptionInput!
    $condition: ModelCaptionConditionInput
  ) {
    deleteCaption(input: $input, condition: $condition) {
      id
      language
      url
      status
      video_id
      is_orignal
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createAudioTrack = /* GraphQL */ `
  mutation CreateAudioTrack(
    $input: CreateAudioTrackInput!
    $condition: ModelAudioTrackConditionInput
  ) {
    createAudioTrack(input: $input, condition: $condition) {
      id
      language
      url
      status
      is_orignal
      video_id
      createdAt
      updatedAt
    }
  }
`
export const updateAudioTrack = /* GraphQL */ `
  mutation UpdateAudioTrack(
    $input: UpdateAudioTrackInput!
    $condition: ModelAudioTrackConditionInput
  ) {
    updateAudioTrack(input: $input, condition: $condition) {
      id
      language
      url
      status
      is_orignal
      video_id
      createdAt
      updatedAt
    }
  }
`
export const deleteAudioTrack = /* GraphQL */ `
  mutation DeleteAudioTrack(
    $input: DeleteAudioTrackInput!
    $condition: ModelAudioTrackConditionInput
  ) {
    deleteAudioTrack(input: $input, condition: $condition) {
      id
      language
      url
      status
      is_orignal
      video_id
      createdAt
      updatedAt
    }
  }
`
export const createCastCrewType = /* GraphQL */ `
  mutation CreateCastCrewType(
    $input: CreateCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    createCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateCastCrewType = /* GraphQL */ `
  mutation UpdateCastCrewType(
    $input: UpdateCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    updateCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteCastCrewType = /* GraphQL */ `
  mutation DeleteCastCrewType(
    $input: DeleteCastCrewTypeInput!
    $condition: ModelCastCrewTypeConditionInput
  ) {
    deleteCastCrewType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`

export const batchUpdateTrailers = /* GraphQL */ `
  mutation BatchUpdateTrailers($trailers: [BatchUpdateTrailer]) {
    batchUpdateTrailers(trailers: $trailers) {
      id
    }
  }
`

export const batchDeleteCaptions = /* GraphQL */ `
  mutation BatchDeleteCaptions($ids: [ID]) {
    batchDeleteCaptions(ids: $ids) {
      id
    }
  }
`

export const batchDeleteVideoCastCrew = /* GraphQL */ `
  mutation BatchDeleteVideoCastCrew($ids: [ID]) {
    batchDeleteVideoCastCrew(ids: $ids) {
      id
    }
  }
`

export const createCastCrew = /* GraphQL */ `
  mutation CreateCastCrew(
    $input: CreateCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    createCastCrew(input: $input, condition: $condition) {
      id
      first_name
      last_name
      nick_name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateCastCrew = /* GraphQL */ `
  mutation UpdateCastCrew(
    $input: UpdateCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    updateCastCrew(input: $input, condition: $condition) {
      id
      first_name
      last_name
      nick_name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteCastCrew = /* GraphQL */ `
  mutation DeleteCastCrew(
    $input: DeleteCastCrewInput!
    $condition: ModelCastCrewConditionInput
  ) {
    deleteCastCrew(input: $input, condition: $condition) {
      id
    }
  }
`
export const createVideoCastCrew = /* GraphQL */ `
  mutation CreateVideoCastCrew(
    $input: CreateVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    createVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      cast_crew {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateVideoCastCrew = /* GraphQL */ `
  mutation UpdateVideoCastCrew(
    $input: UpdateVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    updateVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      cast_crew {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoCastCrew = /* GraphQL */ `
  mutation DeleteVideoCastCrew(
    $input: DeleteVideoCastCrewInput!
    $condition: ModelVideoCastCrewConditionInput
  ) {
    deleteVideoCastCrew(input: $input, condition: $condition) {
      id
      video_id
      cast_crew_id
      cast_crew {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createContentRating = /* GraphQL */ `
  mutation CreateContentRating(
    $input: CreateContentRatingInput!
    $condition: ModelContentRatingConditionInput
  ) {
    createContentRating(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      description
      videos {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateContentRating = /* GraphQL */ `
  mutation UpdateContentRating(
    $input: UpdateContentRatingInput!
    $condition: ModelContentRatingConditionInput
  ) {
    updateContentRating(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      description
      videos {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description

            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteContentRating = /* GraphQL */ `
  mutation DeleteContentRating(
    $input: DeleteContentRatingInput!
    $condition: ModelContentRatingConditionInput
  ) {
    deleteContentRating(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      description
      videos {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description

            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoContentRating = /* GraphQL */ `
  mutation CreateVideoContentRating(
    $input: CreateVideoContentRatingInput!
    $condition: ModelVideoContentRatingConditionInput
  ) {
    createVideoContentRating(input: $input, condition: $condition) {
      id
      video_id
      content_rating_id
      content_rating {
        id
        title
        status
        additional_attributes
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updateVideoContentRating = /* GraphQL */ `
  mutation UpdateVideoContentRating(
    $input: UpdateVideoContentRatingInput!
    $condition: ModelVideoContentRatingConditionInput
  ) {
    updateVideoContentRating(input: $input, condition: $condition) {
      id
      video_id
      content_rating_id
      content_rating {
        id
        title
        status
        additional_attributes
        description
        videos {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deleteVideoContentRating = /* GraphQL */ `
  mutation DeleteVideoContentRating(
    $input: DeleteVideoContentRatingInput!
    $condition: ModelVideoContentRatingConditionInput
  ) {
    deleteVideoContentRating(input: $input, condition: $condition) {
      id
      video_id
      content_rating_id
      content_rating {
        id
        title
        status
        additional_attributes
        description
        videos {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createKnowledgeBase = /* GraphQL */ `
  mutation CreateKnowledgeBase(
    $input: CreateKnowledgeBaseInput!
    $condition: ModelKnowledgeBaseConditionInput
  ) {
    createKnowledgeBase(input: $input, condition: $condition) {
      id
      question
      answer
      precedence
      category_id
      status
      created_by
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateKnowledgeBase = /* GraphQL */ `
  mutation UpdateKnowledgeBase(
    $input: UpdateKnowledgeBaseInput!
    $condition: ModelKnowledgeBaseConditionInput
  ) {
    updateKnowledgeBase(input: $input, condition: $condition) {
      id
      question
      answer
      precedence
      category_id
      status
      created_by
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteKnowledgeBase = /* GraphQL */ `
  mutation DeleteKnowledgeBase(
    $input: DeleteKnowledgeBaseInput!
    $condition: ModelKnowledgeBaseConditionInput
  ) {
    deleteKnowledgeBase(input: $input, condition: $condition) {
      id
      question
      answer
      precedence
      category_id
      status
      created_by
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createKnowledgeBaseCategory = /* GraphQL */ `
  mutation CreateKnowledgeBaseCategory(
    $input: CreateKnowledgeBaseCategoryInput!
    $condition: ModelKnowledgeBaseCategoryConditionInput
  ) {
    createKnowledgeBaseCategory(input: $input, condition: $condition) {
      id
      Knowledge_base {
        items {
          id
          question
          answer
          precedence
          category_id
          status
          created_by
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      precedence
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateKnowledgeBaseCategory = /* GraphQL */ `
  mutation UpdateKnowledgeBaseCategory(
    $input: UpdateKnowledgeBaseCategoryInput!
    $condition: ModelKnowledgeBaseCategoryConditionInput
  ) {
    updateKnowledgeBaseCategory(input: $input, condition: $condition) {
      id
      Knowledge_base {
        items {
          id
          question
          answer
          precedence
          category_id
          status
          created_by
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      precedence
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteKnowledgeBaseCategory = /* GraphQL */ `
  mutation DeleteKnowledgeBaseCategory(
    $input: DeleteKnowledgeBaseCategoryInput!
    $condition: ModelKnowledgeBaseCategoryConditionInput
  ) {
    deleteKnowledgeBaseCategory(input: $input, condition: $condition) {
      id
      Knowledge_base {
        items {
          id
          question
          answer
          precedence
          category_id
          status
          created_by
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      precedence
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createSiteNotification = /* GraphQL */ `
  mutation CreateSiteNotification(
    $input: CreateSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    createSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_code
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const updateSiteNotification = /* GraphQL */ `
  mutation UpdateSiteNotification(
    $input: UpdateSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    updateSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_code
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const deleteSiteNotification = /* GraphQL */ `
  mutation DeleteSiteNotification(
    $input: DeleteSiteNotificationInput!
    $condition: ModelSiteNotificationConditionInput
  ) {
    deleteSiteNotification(input: $input, condition: $condition) {
      id
      message
      status
      generated_by
      country_code
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const createOrganizationType = /* GraphQL */ `
  mutation CreateOrganizationType(
    $input: CreateOrganizationTypeInput!
    $condition: ModelOrganizationTypeConditionInput
  ) {
    createOrganizationType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateOrganizationType = /* GraphQL */ `
  mutation UpdateOrganizationType(
    $input: UpdateOrganizationTypeInput!
    $condition: ModelOrganizationTypeConditionInput
  ) {
    updateOrganizationType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteOrganizationType = /* GraphQL */ `
  mutation DeleteOrganizationType(
    $input: DeleteOrganizationTypeInput!
    $condition: ModelOrganizationTypeConditionInput
  ) {
    deleteOrganizationType(input: $input, condition: $condition) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createVideoOrganization = /* GraphQL */ `
  mutation CreateVideoOrganization(
    $input: CreateVideoOrganizationInput!
    $condition: ModelVideoOrganizationConditionInput
  ) {
    createVideoOrganization(input: $input, condition: $condition) {
      id
      video_id
      organization_id
      organization {
        id
        name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      organization_type_id
      organization_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateVideoOrganization = /* GraphQL */ `
  mutation UpdateVideoOrganization(
    $input: UpdateVideoOrganizationInput!
    $condition: ModelVideoOrganizationConditionInput
  ) {
    updateVideoOrganization(input: $input, condition: $condition) {
      id
      video_id
      organization_id
      organization {
        id
        name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      organization_type_id
      organization_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoOrganization = /* GraphQL */ `
  mutation DeleteVideoOrganization(
    $input: DeleteVideoOrganizationInput!
    $condition: ModelVideoOrganizationConditionInput
  ) {
    deleteVideoOrganization(input: $input, condition: $condition) {
      id
      video_id
      organization_id
      organization {
        id
        name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      organization_type_id
      organization_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createDeviceType = /* GraphQL */ `
  mutation CreateDeviceType(
    $input: CreateDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    createDeviceType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateDeviceType = /* GraphQL */ `
  mutation UpdateDeviceType(
    $input: UpdateDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    updateDeviceType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteDeviceType = /* GraphQL */ `
  mutation DeleteDeviceType(
    $input: DeleteDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    deleteDeviceType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createPlanDeviceType = /* GraphQL */ `
  mutation CreatePlanDeviceType(
    $input: CreatePlanDeviceTypeInput!
    $condition: ModelPlanDeviceTypeConditionInput
  ) {
    createPlanDeviceType(input: $input, condition: $condition) {
      id
      plan_id
      plans {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      device_type_id
      device_types {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updatePlanDeviceType = /* GraphQL */ `
  mutation UpdatePlanDeviceType(
    $input: UpdatePlanDeviceTypeInput!
    $condition: ModelPlanDeviceTypeConditionInput
  ) {
    updatePlanDeviceType(input: $input, condition: $condition) {
      id
      plan_id
      plans {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      device_type_id
      device_types {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deletePlanDeviceType = /* GraphQL */ `
  mutation DeletePlanDeviceType(
    $input: DeletePlanDeviceTypeInput!
    $condition: ModelPlanDeviceTypeConditionInput
  ) {
    deletePlanDeviceType(input: $input, condition: $condition) {
      id
      plan_id
      plans {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      device_type_id
      device_types {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      title
      status
      video_quality_id
      additional_attributes
      is_ad_supported
      n_watches
      is_downloadable
      n_days_download_to_expire
      expire_max_mins_after_purchase
      expire_max_min_after_start
      createdAt
      updatedAt
      device_types {
        items {
          id
          plan_id
          device_type_id
          additional_attributes
          createdAt
          updatedAt
          device_types {
            id
            title
            status
            createdAt
            updatedAt
          }
          plans {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      title
      status
      video_quality_id
      additional_attributes
      is_ad_supported
      n_watches
      n_days_download_to_expire
      is_downloadable
      expire_max_mins_after_purchase
      expire_max_min_after_start
      createdAt
      updatedAt
      device_types {
        items {
          id
          plan_id
          device_type_id
          additional_attributes
          createdAt
          updatedAt
          device_types {
            id
            title
            status
            createdAt
            updatedAt
          }
          plans {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
    }
  }
`
export const createVideoPlan = /* GraphQL */ `
  mutation CreateVideoPlan(
    $input: CreateVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    createVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      video_by_quality_id
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const updateVideoPlan = /* GraphQL */ `
  mutation UpdateVideoPlan(
    $input: UpdateVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    updateVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      video_by_quality_id
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const deleteVideoPlan = /* GraphQL */ `
  mutation DeleteVideoPlan(
    $input: DeleteVideoPlanInput!
    $condition: ModelVideoPlanConditionInput
  ) {
    deleteVideoPlan(input: $input, condition: $condition) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      video_by_quality_id
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      country_code
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      country_code
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      country_code
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createPromoType = /* GraphQL */ `
  mutation CreatePromoType(
    $input: CreatePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    createPromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const updatePromoType = /* GraphQL */ `
  mutation UpdatePromoType(
    $input: UpdatePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    updatePromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const deletePromoType = /* GraphQL */ `
  mutation DeletePromoType(
    $input: DeletePromoTypeInput!
    $condition: ModelPromoTypeConditionInput
  ) {
    deletePromoType(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      code
      unlimited
      max_use_limit_by_user
      video_id
      start_date
      user_id
      count
      max_use_limit
      status
      plan_id
      percentage_off
      country_code
      end_date
      promo_type_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      plan {
        id
        title
        status
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        device_types {
          items {
            id
            plan_id
            device_type_id
            additional_attributes
            createdAt
            updatedAt
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      code
      unlimited
      max_use_limit_by_user
      video_id
      start_date
      user_id
      count
      max_use_limit
      status
      plan_id
      percentage_off
      country_code
      end_date
      promo_type_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      plan {
        id
        title
        status
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        device_types {
          items {
            id
            plan_id
            device_type_id
            additional_attributes
            createdAt
            updatedAt
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      code
      reuse
      count
      status
      max_use_limit_by_user
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      percentage_off
      country_code
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const createTrailer = /* GraphQL */ `
  mutation CreateTrailer(
    $input: CreateTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    createTrailer(input: $input, condition: $condition) {
      id
      hls_url

      status
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      title
      is_main
      video_id
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateTrailer = /* GraphQL */ `
  mutation UpdateTrailer(
    $input: UpdateTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    updateTrailer(input: $input, condition: $condition) {
      id
      hls_url

      status
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      title
      is_main
      video_id
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteTrailer = /* GraphQL */ `
  mutation DeleteTrailer(
    $input: DeleteTrailerInput!
    $condition: ModelTrailerConditionInput
  ) {
    deleteTrailer(input: $input, condition: $condition) {
      id
      hls_url

      status
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      title
      is_main
      video_id
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createTrailerViewLog = /* GraphQL */ `
  mutation CreateTrailerViewLog(
    $input: CreateTrailerViewLogInput!
    $condition: ModelTrailerViewLogConditionInput
  ) {
    createTrailerViewLog(input: $input, condition: $condition) {
      id
      trailer_id
      trailer {
        id
        hls_url

        status
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        title
        is_main
        video_id
        additional_attributes
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      watched_at
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      country_code
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const updateTrailerViewLog = /* GraphQL */ `
  mutation UpdateTrailerViewLog(
    $input: UpdateTrailerViewLogInput!
    $condition: ModelTrailerViewLogConditionInput
  ) {
    updateTrailerViewLog(input: $input, condition: $condition) {
      id
      trailer_id
      trailer {
        id
        hls_url

        status
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        title
        is_main
        video_id
        additional_attributes
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      watched_at
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      country_code
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const deleteTrailerViewLog = /* GraphQL */ `
  mutation DeleteTrailerViewLog(
    $input: DeleteTrailerViewLogInput!
    $condition: ModelTrailerViewLogConditionInput
  ) {
    deleteTrailerViewLog(input: $input, condition: $condition) {
      id
      trailer_id
      trailer {
        id
        hls_url

        status
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        title
        is_main
        video_id
        additional_attributes
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      watched_at
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      country_code
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email_address
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email_address
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email_address
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createUserActivityLog = /* GraphQL */ `
  mutation CreateUserActivityLog(
    $input: CreateUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    createUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updateUserActivityLog = /* GraphQL */ `
  mutation UpdateUserActivityLog(
    $input: UpdateUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    updateUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deleteUserActivityLog = /* GraphQL */ `
  mutation DeleteUserActivityLog(
    $input: DeleteUserActivityLogInput!
    $condition: ModelUserActivityLogConditionInput
  ) {
    deleteUserActivityLog(input: $input, condition: $condition) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createUserActivityAction = /* GraphQL */ `
  mutation CreateUserActivityAction(
    $input: CreateUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    createUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const updateUserActivityAction = /* GraphQL */ `
  mutation UpdateUserActivityAction(
    $input: UpdateUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    updateUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteUserActivityAction = /* GraphQL */ `
  mutation DeleteUserActivityAction(
    $input: DeleteUserActivityActionInput!
    $condition: ModelUserActivityActionConditionInput
  ) {
    deleteUserActivityAction(input: $input, condition: $condition) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const createAvatar = /* GraphQL */ `
  mutation CreateAvatar(
    $input: CreateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    createAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const updateAvatar = /* GraphQL */ `
  mutation UpdateAvatar(
    $input: UpdateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    updateAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteAvatar = /* GraphQL */ `
  mutation DeleteAvatar(
    $input: DeleteAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    deleteAvatar(input: $input, condition: $condition) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const createUserRating = /* GraphQL */ `
  mutation CreateUserRating(
    $input: CreateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    createUserRating(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      rating_on_timestamp
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updateUserRating = /* GraphQL */ `
  mutation UpdateUserRating(
    $input: UpdateUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    updateUserRating(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      rating_on_timestamp
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deleteUserRating = /* GraphQL */ `
  mutation DeleteUserRating(
    $input: DeleteUserRatingInput!
    $condition: ModelUserRatingConditionInput
  ) {
    deleteUserRating(input: $input, condition: $condition) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      rating_on_timestamp
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createVideoTag = /* GraphQL */ `
  mutation CreateVideoTag(
    $input: CreateVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    createVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updateVideoTag = /* GraphQL */ `
  mutation UpdateVideoTag(
    $input: UpdateVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    updateVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deleteVideoTag = /* GraphQL */ `
  mutation DeleteVideoTag(
    $input: DeleteVideoTagInput!
    $condition: ModelVideoTagConditionInput
  ) {
    deleteVideoTag(input: $input, condition: $condition) {
      id
      tag_id
      video_id
      tag {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoGenre = /* GraphQL */ `
  mutation CreateVideoGenre(
    $input: CreateVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    createVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updateVideoGenre = /* GraphQL */ `
  mutation UpdateVideoGenre(
    $input: UpdateVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    updateVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deleteVideoGenre = /* GraphQL */ `
  mutation DeleteVideoGenre(
    $input: DeleteVideoGenreInput!
    $condition: ModelVideoGenreConditionInput
  ) {
    deleteVideoGenre(input: $input, condition: $condition) {
      id
      genre_id
      video_id
      genre {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createGenre = /* GraphQL */ `
  mutation CreateGenre(
    $input: CreateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    createGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateGenre = /* GraphQL */ `
  mutation UpdateGenre(
    $input: UpdateGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    updateGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteGenre = /* GraphQL */ `
  mutation DeleteGenre(
    $input: DeleteGenreInput!
    $condition: ModelGenreConditionInput
  ) {
    deleteGenre(input: $input, condition: $condition) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVideoType = /* GraphQL */ `
  mutation CreateVideoType(
    $input: CreateVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    createVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const updateVideoType = /* GraphQL */ `
  mutation UpdateVideoType(
    $input: UpdateVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    updateVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteVideoType = /* GraphQL */ `
  mutation DeleteVideoType(
    $input: DeleteVideoTypeInput!
    $condition: ModelVideoTypeConditionInput
  ) {
    deleteVideoType(input: $input, condition: $condition) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      country_code
      country_calling_code
      country_name
      currency
      status
      createdAt
      updatedAt
      payment_gateways {
        items {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      country_code
      country_calling_code
      country_name
      currency
      preferredCDN
      status
      createdAt
      updatedAt
      payment_gateways {
        items {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      country_code
      country_calling_code
      country_name
      currency
      status
      createdAt
      updatedAt
      payment_gateways {
        items {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_gateway_id
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        max_use_limit_by_user
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_code
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      is_gift
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      country_code
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      view_logs {
        items {
          id
          order_id
          status
          last_timestamp
          minutes_streamed
          started_at
          user_id
          user {
            id
            email_address
            status
            additional_attributes
            createdAt
            updatedAt
          }
          video_by_quality_id
          device_type_id
          device_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          ip_address
          bandwidth_used
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      payment_gateway {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_gateway_id
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        max_use_limit_by_user
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_code
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      is_gift
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      country_code
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      view_logs {
        items {
          id
          order_id
          status
          last_timestamp
          minutes_streamed
          started_at
          user_id
          user {
            id
            email_address
            status
            additional_attributes
            createdAt
            updatedAt
          }
          video_by_quality_id
          device_type_id
          device_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          ip_address
          bandwidth_used
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      payment_gateway {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_gateway_id
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        max_use_limit_by_user
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_code
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      is_gift
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      country_code
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      view_logs {
        items {
          id
          order_id
          status
          last_timestamp
          minutes_streamed
          started_at
          user_id
          user {
            id
            email_address
            status
            additional_attributes
            createdAt
            updatedAt
          }
          video_by_quality_id
          device_type_id
          device_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          ip_address
          bandwidth_used
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      payment_gateway {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const createViewLog = /* GraphQL */ `
  mutation CreateViewLog(
    $input: CreateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    createViewLog(input: $input, condition: $condition) {
      id
      order_id
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      video_by_quality_id
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      bandwidth_used
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const updateViewLog = /* GraphQL */ `
  mutation UpdateViewLog(
    $input: UpdateViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    updateViewLog(input: $input, condition: $condition) {
      id
      order_id
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      video_by_quality_id
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      bandwidth_used
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const deleteViewLog = /* GraphQL */ `
  mutation DeleteViewLog(
    $input: DeleteViewLogInput!
    $condition: ModelViewLogConditionInput
  ) {
    deleteViewLog(input: $input, condition: $condition) {
      id
      order_id
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      video_by_quality_id
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      bandwidth_used
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const createPaymentProcessingStatus = /* GraphQL */ `
  mutation CreatePaymentProcessingStatus(
    $input: CreatePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    createPaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const updatePaymentProcessingStatus = /* GraphQL */ `
  mutation UpdatePaymentProcessingStatus(
    $input: UpdatePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    updatePaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const deletePaymentProcessingStatus = /* GraphQL */ `
  mutation DeletePaymentProcessingStatus(
    $input: DeletePaymentProcessingStatusInput!
    $condition: ModelPaymentProcessingStatusConditionInput
  ) {
    deletePaymentProcessingStatus(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const createPaymentGateway = /* GraphQL */ `
  mutation CreatePaymentGateway(
    $input: CreatePaymentGatewayInput!
    $condition: ModelPaymentGatewayConditionInput
  ) {
    createPaymentGateway(input: $input, condition: $condition) {
      id
      name
      disabled
      country_code
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const updatePaymentGateway = /* GraphQL */ `
  mutation UpdatePaymentGateway(
    $input: UpdatePaymentGatewayInput!
    $condition: ModelPaymentGatewayConditionInput
  ) {
    updatePaymentGateway(input: $input, condition: $condition) {
      id
      name
      disabled
      country_code
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const deletePaymentGateway = /* GraphQL */ `
  mutation DeletePaymentGateway(
    $input: DeletePaymentGatewayInput!
    $condition: ModelPaymentGatewayConditionInput
  ) {
    deletePaymentGateway(input: $input, condition: $condition) {
      id
      name
      disabled
      country_code
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      non_mp4_group_outputs_sync_status
      mp4_group_outputs_sync_status
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      duration
      intro_start_time
      intro_end_time
      short_description
      long_description
      banner_message

      status
      release_date
      credits_start_timestamp
      is_orignal
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      audio_tracks {
        items {
          id
          language
          url
          status
          is_orignal
          video_id
          createdAt
          updatedAt
        }
        nextToken
      }
      trailers {
        items {
          id
          hls_url

          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
          video_id
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      cast_crew {
        items {
          id
          video_id
          cast_crew_id
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          cast_crew_type_id
          cast_crew_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      organizations {
        items {
          id
          video_id
          organization_id
          organization {
            id
            name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          organization_type_id
          organization_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      supported_captions {
        items {
          id
          language
          url
          status
          video_id
          is_orignal
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      content_rating {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      video_by_quality {
        items {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      non_mp4_group_outputs_sync_status
      mp4_group_outputs_sync_status
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      duration
      intro_start_time
      intro_end_time
      short_description
      long_description
      banner_message

      status
      release_date
      credits_start_timestamp
      is_orignal
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      audio_tracks {
        items {
          id
          language
          url
          status
          is_orignal
          video_id
          createdAt
          updatedAt
        }
        nextToken
      }
      trailers {
        items {
          id
          hls_url

          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
          video_id
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      cast_crew {
        items {
          id
          video_id
          cast_crew_id
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          cast_crew_type_id
          cast_crew_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      organizations {
        items {
          id
          video_id
          organization_id
          organization {
            id
            name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          organization_type_id
          organization_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      supported_captions {
        items {
          id
          language
          url
          status
          video_id
          is_orignal
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      content_rating {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      video_by_quality {
        items {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      duration
      intro_start_time
      intro_end_time
      short_description
      long_description
      banner_message

      status
      release_date
      credits_start_timestamp
      is_orignal
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      audio_tracks {
        items {
          id
          language
          url
          status
          is_orignal
          video_id
          createdAt
          updatedAt
        }
        nextToken
      }
      trailers {
        items {
          id
          hls_url

          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
          video_id
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      cast_crew {
        items {
          id
          video_id
          cast_crew_id
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          cast_crew_type_id
          cast_crew_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      organizations {
        items {
          id
          video_id
          organization_id
          organization {
            id
            name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          organization_type_id
          organization_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      supported_captions {
        items {
          id
          language
          url
          status
          video_id
          is_orignal
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      content_rating {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      video_by_quality {
        items {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`
export const createVideoQuality = /* GraphQL */ `
  mutation CreateVideoQuality(
    $input: CreateVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    createVideoQuality(input: $input, condition: $condition) {
      id
      resolution
      status
      createdAt
      updatedAt
      vertical_resolution {
        title
        resolution
        createdAt
        updatedAt
      }
    }
  }
`
export const updateVideoQuality = /* GraphQL */ `
  mutation UpdateVideoQuality(
    $input: UpdateVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    updateVideoQuality(input: $input, condition: $condition) {
      id
      resolution
      status
      createdAt
      updatedAt
      vertical_resolution {
        title
        resolution
        createdAt
        updatedAt
      }
    }
  }
`
export const deleteVideoQuality = /* GraphQL */ `
  mutation DeleteVideoQuality(
    $input: DeleteVideoQualityInput!
    $condition: ModelVideoQualityConditionInput
  ) {
    deleteVideoQuality(input: $input, condition: $condition) {
      id
      resolution
      status
      createdAt
      updatedAt
      vertical_resolution {
        title
        resolution
        createdAt
        updatedAt
      }
    }
  }
`
export const createVerticalResolution = /* GraphQL */ `
  mutation CreateVerticalResolution(
    $input: CreateVerticalResolutionInput!
    $condition: ModelVerticalResolutionConditionInput
  ) {
    createVerticalResolution(input: $input, condition: $condition) {
      title
      resolution
      createdAt
      updatedAt
    }
  }
`
export const updateVerticalResolution = /* GraphQL */ `
  mutation UpdateVerticalResolution(
    $input: UpdateVerticalResolutionInput!
    $condition: ModelVerticalResolutionConditionInput
  ) {
    updateVerticalResolution(input: $input, condition: $condition) {
      title
      resolution
      createdAt
      updatedAt
    }
  }
`
export const deleteVerticalResolution = /* GraphQL */ `
  mutation DeleteVerticalResolution(
    $input: DeleteVerticalResolutionInput!
    $condition: ModelVerticalResolutionConditionInput
  ) {
    deleteVerticalResolution(input: $input, condition: $condition) {
      title
      resolution
      createdAt
      updatedAt
    }
  }
`
export const createVideoByQuality = /* GraphQL */ `
  mutation CreateVideoByQuality(
    $input: CreateVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    createVideoByQuality(input: $input, condition: $condition) {
      id
      status
      video_id
      video_quality_id
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const updateVideoByQuality = /* GraphQL */ `
  mutation UpdateVideoByQuality(
    $input: UpdateVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    updateVideoByQuality(input: $input, condition: $condition) {
      id
      status
      video_id
      video_quality_id
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const deleteVideoByQuality = /* GraphQL */ `
  mutation DeleteVideoByQuality(
    $input: DeleteVideoByQualityInput!
    $condition: ModelVideoByQualityConditionInput
  ) {
    deleteVideoByQuality(input: $input, condition: $condition) {
      id
      status
      video_id
      video_quality_id
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const batchUpdateLandingPageData = /* GraphQL */ `
  mutation BatchUpdateLandingPageData($devices: [BatchUpdateLandingPageData]) {
    batchUpdateLandingPageData(devices: $devices) {
      device_name
      title {
        text
        font_size
        color
        background_color
        background_blurriness
      }
      sub_title {
        text
        font_size
        color
        background_color
        background_blurriness
      }
      banner {
        text
        font_size
        color
        background_color
        background_blurriness
      }
      social_icons {
        bottom_offset
        color
        background_color
        background_blurriness
        disabled
      }
      background {
        url
        type
      }
    }
  }
`

export const batchUpdateVideoByQualities = /* GraphQL */ `
  mutation BatchUpdateVideoByQualities(
    $videoByQualites: [CreateVideoByQuality]
  ) {
    batchUpdateVideoByQualities(videoByQualites: $videoByQualites) {
      id
    }
  }
`

export const batchCreateAudioTracks = /* GraphQL */ `
  mutation BatchCreateAudioTracks($tracks: [CreateAudioTrack]) {
    batchCreateAudioTracks(tracks: $tracks) {
      id
    }
  }
`

export const batchDeleteAudioTracks = /* GraphQL */ `
  mutation BatchDeleteAudioTracks($ids: [ID]) {
    batchDeleteAudioTracks(ids: $ids) {
      id
    }
  }
`
