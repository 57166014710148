import React, { useState } from 'react'
import {
  TablePagination,
  Switch,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { OrderListHeader } from '../../lib/constants'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const OrdersList = ({ ordersList, handleActivation }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ordersList.length - page * rowsPerPage)

  const classes = useStyles()
  const handleChangePage = (_, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {OrderListHeader.map((item) => (
                <TableCell
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? ordersList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : ordersList
            ).map((orderList) => {
              const {
                ref_no,
                payment_total,
                promocode,
                video_plan,
                country_code,
                purchased_at,
                video,
                is_gift,
                status,
                id
              } = orderList

              return (
                <TableRow key={id}>
                  <TableCell align="left">{ref_no}</TableCell>
                  <TableCell align="left">{video ? video.title : ''}</TableCell>
                  <TableCell align="left">
                    {video_plan ? video_plan.plan.title : ''}
                  </TableCell>
                  <TableCell align="left">{country_code}</TableCell>
                  <TableCell align="left">
                    {moment(purchased_at).format('MM-DD-YYYY')}
                  </TableCell>
                  <TableCell align="left">
                    {promocode ? promocode.code : ''}
                  </TableCell>
                  <TableCell align="left">{is_gift}</TableCell>
                  <TableCell align="left">{payment_total}</TableCell>
                  <TableCell align="center">
                    <Switch
                      name="checkedA"
                      checked={status}
                      onChange={(_, checked) => {
                        handleActivation(orderList, checked)
                      }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={ordersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </div>
  )
}

export { OrdersList }
