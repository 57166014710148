import { React, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { API } from 'aws-amplify'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { getUser } from '../../graphql/queries'
import { FormInput, ButtonComponent } from '../../components'
import { handleInvitePromoCodeUpdate } from '../../redux/modules/promoCode'

export const UserDetail = () => {
  const { userId } = useParams()
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const [
    {
      birthdate,
      gender,
      createdAt,
      email_address,
      first_name,
      id,
      last_name,
      invite_promo_code_id,
      invite_promo_code,
      provider,
      updatedAt
    },
    setPersonalInfo
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    birthdate: '',
    gender: '',
    createdAt: '',
    email_address: '',
    first_name: '',
    id: '',
    last_name: '',
    invite_promo_code_id: '',
    invite_promo_code: '',
    provider: '',
    updatedAt: ''
  })

  const useStyles = makeStyles({
    table: {
      minWidth: 650
    }
  })

  const classes = useStyles()

  useEffect(() => {
    const getPersonalInfo = async (userId) => {
      try {
        const {
          data: { getUser: personal_info }
        } = await API.graphql({
          query: getUser,
          variables: { id: userId }
        })

        var additional_attr = JSON.parse(personal_info.additional_attributes)

        setPersonalInfo({
          birthdate: additional_attr.birthdate,
          gender: additional_attr.gender,
          createdAt: personal_info.createdAt,
          email_address: personal_info.email_address,
          first_name: personal_info.first_name,
          id: personal_info.id,
          last_name: personal_info.last_name,
          invite_promo_code_id: personal_info.invite_promo_code_id,
          invite_promo_code: personal_info.promo_code.code,
          provider: personal_info.provider,
          status: personal_info.status,
          updatedAt: personal_info.updatedAt
        })
      } catch (err) {
        console.log('Error in getting user is: ', err)
        throw new Error('Something went wrong in getting user')
      }
    }
    getPersonalInfo(userId)
  }, [userId, setPersonalInfo])

  const onChangeHandler = ({ target: { value, name } }) =>
    setPersonalInfo({ [name]: value })

  const handleNewInvitePromoCode = async () => {
    const payload = {
      id: invite_promo_code_id,
      code: invite_promo_code
    }
    try {
      setLoading(true)
      await dispatch(handleInvitePromoCodeUpdate(payload))
      setLoading(false)
    } catch (err) {
      console.log('Error in creating promo code is: ', err)
      setLoading(false)
    }
  }

  return (
    <div className="dashboard-page">
      <div className="plan-content">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs>
            <Tab label="< Back" to={`/user-management`} component={Link} />
            <Tab label="Personal Information" />
            <Tab
              label="Order Management"
              to={`/order-management/${userId}`}
              component={Link}
            />
            <Tab
              label="Activity Log"
              to={`/user-activity-log/${userId}`}
              component={Link}
            />
          </Tabs>
        </Box>
        <div>
          <TableContainer component={Paper}>
            <Table
              size="small"
              className={classes.table}
              aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>{id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email Address</TableCell>
                  <TableCell>{email_address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>{first_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Name</TableCell>
                  <TableCell>{last_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Birth Date</TableCell>
                  <TableCell>{birthdate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Gender</TableCell>
                  <TableCell>{gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Provider</TableCell>
                  <TableCell>{provider}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>
                    {moment(createdAt).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Updated At</TableCell>
                  <TableCell>
                    {moment(updatedAt).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Invite Promo Code</TableCell>
                  <TableCell>
                    <FormInput
                      style={{ width: '40%', marginRight: '10px' }}
                      placeholder="Invite Promo Code"
                      id="invite_promo_code"
                      name="invite_promo_code"
                      onChangeHandler={onChangeHandler}
                      value={invite_promo_code}
                    />
                    <ButtonComponent
                      loading={loading}
                      title="UPDATE"
                      onClickHandler={handleNewInvitePromoCode}
                      width={200}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )
}
