import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FormInput } from '../../../../components'
import { setBackground } from '../../../../redux/modules/landingPage'
import { useStyles } from './styles'
import { ExpandMore } from '@material-ui/icons'

export const BackgroundForm = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <BackgroundConfiguratinForm header="Desktop" />
      <BackgroundConfiguratinForm header="Tablet" />
      <BackgroundConfiguratinForm header="Mobile" />
    </div>
  )
}

const BackgroundConfiguratinForm = ({ header }) => {
  const classes = useStyles()
  const name = header.toLowerCase()
  const {
    background: {
      [name]: { type, url }
    }
  } = useSelector((state) => state.landingPage)
  const dispatch = useDispatch()

  const handleChange = ({ target: { value, name: key } }) => {
    dispatch(
      setBackground(
        key === 'type'
          ? {
              name,
              data: {
                url: key === 'type' ? '' : url,
                type: value
              }
            }
          : {
              name,
              data: {
                [key]: value
              }
            }
      )
    )
  }

  return (
    <Accordion className={classes.accordian}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="desktop-content"
        id={`${header}-header`}>
        <Typography className={classes.sectionTitle}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        <div className={classes.title}>
          URL:
          <FormInput
            placeholder={`Background ${type} url `}
            id={`url-${name}`}
            value={url}
            onChangeHandler={handleChange}
            name="url"
          />
        </div>

        <div className={classes.typeSelectionContainer}>
          <RadioGroup
            row
            value={type}
            onChange={handleChange}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="type">
            <FormControlLabel
              value="image"
              control={<Radio color="primary" />}
              label="Image"
            />
            <FormControlLabel
              value="video"
              control={<Radio color="primary" />}
              label="Video"
            />
          </RadioGroup>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
