import {
  TabletAndroid,
  Smartphone,
  Cast,
  LaptopWindows,
  DesktopMac
} from '@material-ui/icons'

export const DevicesIcons = {
  Tablet: <TabletAndroid style={{ fontSize: 14 }} />,
  Mobile: <Smartphone style={{ fontSize: 14 }} />,
  TV: <Cast style={{ fontSize: 14 }} />,
  Laptop: <LaptopWindows style={{ fontSize: 14 }} />,
  Desktop: <DesktopMac style={{ fontSize: 14 }} />
}

export const DrawerMenus = [
  {
    id: '0',
    name: 'Movie List',
    url: ''
  },
  {
    id: '1',
    name: 'Plan List',
    url: 'plan-list'
  },
  {
    id: '2',
    name: 'Countries',
    url: 'countries'
  },
  {
    id: '3',
    name: 'Pricing',
    url: 'pricing'
  },
  {
    id: '4',
    name: 'Users Analytics',
    url: 'users-analytics'
  },
  {
    id: '5',
    name: 'Notification',
    url: 'notifications'
  },
  {
    id: '6',
    name: 'Notification Template',
    url: 'notification-template'
  },
  {
    id: '7',
    name: 'Cast List',
    url: 'cast-list'
  },
  {
    id: '07',
    name: 'System Notification',
    url: 'system-notification'
  },
  {
    id: '8',
    name: 'Promo Code',
    url: 'promo-code'
  },
  {
    id: '9',
    name: 'Help Q&A',
    url: 'help-qna'
  },
  {
    id: '10',
    name: 'User Management',
    url: 'user-management'
  },
  {
    id: '11',
    name: 'Landing Page',
    url: 'landing-page'
  },
  {
    id: '12',
    name: 'Log Out',
    url: 'log-out'
  }
]

export const PlanListFilters = [
  {
    id: '0',
    value: 'Filter By',
    name: 'Filter By'
  },
  {
    id: '1',
    value: 'all',
    name: 'All'
  },
  {
    id: '2',
    value: 'week',
    name: 'Week'
  },
  {
    id: '3',
    value: 'month',
    name: 'Month'
  }
]

export const CountriesFilters = [
  {
    id: '0',
    value: 'All',
    name: 'All'
  },
  {
    id: '1',
    value: 'enabled',
    name: 'Enabled'
  },
  {
    id: '2',
    value: 'disabled',
    name: 'Disabled'
  }
]

export const HelpQNAFilters = [
  {
    id: '0',
    value: 'All',
    name: 'All'
  },
  {
    id: '1',
    value: 'enabled',
    name: 'Enabled'
  },
  {
    id: '2',
    value: 'disabled',
    name: 'Disabled'
  }
]

export const CountriesHeader = [
  'Country Name',
  'Country Code',
  'Currency',
  'Preferred CDN',
  'Payment Gateways'
]

export const HelpQNAHeader = [
  'Question',
  'Answer',
  'Precedence',
  'Status',
  'Actions'
]

export const UserManagementHeader = ['User ID', 'User Name', 'Provider']

export const OrderListHeader = [
  'Reference#',
  'Video',
  'Plan',
  'Country',
  'Date',
  'PromoCode',
  'Gift?',
  'Total',
  'Status'
]

export const UserActivityHeader = ['Activity Type', 'Date']

export const HelpCategoryHeader = [
  'Category',
  'Precedence',
  'No.of QnAs',
  'Status',
  'Actions'
]

export const QualityList = [
  {
    id: '-1',
    name: 'Select Quality',
    value: 'Select Quality'
  },
  {
    id: '0',
    value: '480p',
    name: '480p'
  },
  {
    id: '1',
    value: '720p',
    name: '720p'
  },
  {
    id: '2',
    value: '1080p',
    name: '1080p'
  },
  {
    id: '3',
    value: '4K + HDR',
    name: '4K + HDR'
  }
]

export const DevicesList = [
  {
    id: '-1',
    value: 'Select Device',
    name: 'Select Device'
  },
  {
    id: '0',
    value: 'mobile',
    name: 'Mobile'
  },
  {
    id: '1',
    value: 'tablet',
    name: 'Tablet'
  },
  {
    id: '2',
    value: 'tv',
    name: 'TV'
  },
  {
    id: '3',
    value: 'laptop',
    name: 'Laptop'
  },
  {
    id: '4',
    value: 'desktop',
    name: 'Desktop'
  }
]

export const CountriesList = [
  {
    id: '-1',
    value: 'Select Country',
    name: 'Select Country'
  },
  {
    id: '0',
    value: 'Pakistan',
    name: 'Pakistan'
  },
  {
    id: '1',
    value: 'India',
    name: 'India'
  }
]

export const Languages = [
  {
    id: '-1',
    value: 'Select Language',
    name: 'Select Language'
  },
  {
    id: '0',
    value: 'URDU',
    name: 'URDU'
  },
  {
    id: '1',
    value: 'HINDI',
    name: 'HINDI'
  }
]

export const Currencies = [
  {
    id: '-1',
    value: 'Select Currency',
    name: 'Select Currency'
  },
  {
    id: '0',
    value: 'PKR',
    name: 'PKR'
  },
  {
    id: '1',
    value: 'INR',
    name: 'INR'
  }
]

export const LanguageList = [
  { id: '0', value: 'Arabic', name: 'Arabic' },
  { id: '1', value: 'Azerbaijani', name: 'Azerbaijani' },
  { id: '2', value: 'Bengali', name: 'Bengali' },
  { id: '3', value: 'Bosnian', name: 'Bosnian' },
  { id: '4', value: 'Buriat', name: 'Buriat' },
  { id: '5', value: 'German', name: 'German' },
  { id: '6', value: 'English', name: 'English' },
  { id: '7', value: 'Persian', name: 'Persian' },
  { id: '9', value: 'French', name: 'French' },
  { id: '10', value: 'Gujarati', name: 'Gujarati' },
  { id: '11', value: 'Hindi', name: 'Hindi' },
  { id: '12', value: 'Indonesian', name: 'Indonesian' },
  { id: '13', value: 'Italian', name: 'Italian' },
  { id: '14', value: 'Japanese', name: 'Japanese' },
  { id: '15', value: 'Kashmiri', name: 'Kashmiri' },
  { id: '16', value: 'Uncoded', name: 'Uncoded' },
  { id: '17', value: 'Malay', name: 'Malay' },
  { id: '18', value: 'Norwegian', name: 'Norwegian' },
  { id: '19', value: 'Panjabi', name: 'Panjabi' },
  { id: '20', value: 'Pushto', name: 'Pushto' },
  { id: '21', value: 'Thai', name: 'Thai' },
  { id: '22', value: 'Turkish', name: 'Turkish' },
  { id: '23', value: 'Urdu', name: 'Urdu' },
  { id: '24', value: 'Vietnamese', name: 'Vietnamese' },
  { id: '25', value: 'Zhuang', name: 'Zhuang' },
  { id: '26', value: 'Chinese', name: 'Chinese' },
  { id: '27', value: 'Spanish', name: 'Spanish' },
  { id: '28', value: 'Portuguese', name: 'Portuguese' },
  { id: '29', value: 'Tamil', name: 'Tamil' }
]

export const LanguagesCode = {
  Arabic: 'ara',
  Azerbaijani: 'aze',
  Bengali: 'ben',
  Bosnian: 'bos',
  Buriat: 'bua',
  German: 'deu',
  English: 'eng',
  Persian: 'fas',
  French: 'fra',
  Gujarati: 'guj',
  Hindi: 'hin',
  Indonesian: 'ind',
  Italian: 'ita',
  Japanese: 'jpn',
  Kashmiri: 'kas',
  Uncoded: 'mis',
  Malay: 'msa',
  Norwegian: 'nor',
  Panjabi: 'pan',
  Pushto: 'pus',
  Thai: 'tha',
  Turkish: 'tur',
  Urdu: 'urd',
  Vietnamese: 'vie',
  Zhuang: 'zha',
  Chinese: 'zho',
  Spanish: 'spa',
  Portuguese: 'por',
  Tamil: 'tam'
}

export const PROMO_CODES_FILTERS = [
  {
    id: '0',
    value: 'all',
    name: 'All'
  },
  {
    id: '1',
    value: 'enabled',
    name: 'Enabled'
  },
  {
    id: '2',
    value: 'disabled',
    name: 'Disabled'
  }
]

export const PROMO_CODE_TABLE_HEADER = [
  'Code',
  '%',
  'User ID',
  'Type',
  'Video',
  'Plan',
  'Country',
  'Start Date',
  'End Date',
  'Max. Use',
  'User Max.',
  'Count',
  'Justification'
]

export const CDN_OPTIONS = [
  {
    id: 'Cloudflare',
    value: 'Cloudflare',
    name: 'Cloudflare'
  },
  {
    id: 'Cloudfront',
    value: 'Cloudfront',
    name: 'Cloudfront'
  }
]
export const PAYMENT_GATEWAYS = [
  'CRYPTO',
  'EASY_PAISA',
  'JAZZ_CASH',
  'PAYPAL',
  'STRIPE'
]
export const UPDATE_MOVIE_TABS = [
  {
    name: 'Meta Information',
    id: 'meta-information'
  },
  {
    name: 'Thumbnails',
    id: 'thumbnails'
  },
  {
    name: 'Cast and Crew',
    id: 'cast-and-crew'
  },
  {
    name: 'Captions',
    id: 'captions'
  },
  {
    name: 'Audios',
    id: 'audios'
  },
  {
    name: 'Trailers',
    id: 'trailers'
  },
  {
    name: 'Content Preview',
    id: 'content-preview'
  }
]

export const LANDING_PAGE_TABS = [
  {
    name: 'Background',
    id: 'background'
  },
  {
    name: 'Title',
    id: 'title'
  },
  {
    name: 'Sub Title',
    id: 'sub-title'
  },
  {
    name: 'Banner',
    id: 'banner'
  },
  {
    name: 'Social Icons',
    id: 'social-icons'
  }
]

export const ALERT_TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

export const SOCIAL_ICONS = [
  {
    id: 'facebook',
    icon: 'fa fa-facebook footer-icon',
    navigateTo: 'https://www.facebook.com/mintriodigital'
  },
  {
    id: 'twitter',
    icon: 'fa fa-twitter footer-icon',
    navigateTo: 'https://twitter.com/mintriodigital'
  },
  {
    id: 'instagram',
    icon: 'fa fa-instagram footer-icon',
    navigateTo: 'https://www.instagram.com/mintriodigital'
  },
  {
    id: 'youtube',
    icon: 'fa fa-youtube-play footer-icon',
    navigateTo: 'https://www.youtube.com/channel/UCvpDDjtbWRVw_-Y2psoQ4Xg'
  }
]
