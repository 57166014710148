import React, { useRef, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { GridContainer } from './gridContainer'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import videojs from 'video.js'
import { SocialIcons } from './socialIcons'
import { KeyboardArrowDown } from '@material-ui/icons'
import { hexToRGBA } from '../../../../../../lib/util'

export const LandingPagePreview = ({ selectedDevice }) => {
  const name = selectedDevice.toLowerCase()
  const videoRef = useRef(null)
  const {
    background: {
      [name]: { type, url }
    },
    title: {
      [name]: {
        text: titleText,
        fontSize: titleFontSize,
        color: titleColor,
        backgroundColor: titleBackgroundColor,
        backgroundOpacity: titleBackgroundOpacity,
        backgroundBlurriness: titleBackgroundBlurriness
      }
    },
    subTitle: {
      [name]: {
        text: subTitleText,
        fontSize: subTitleFontSize,
        color: subTitleColor,
        backgroundColor: subTitleBackgroundColor,
        backgroundBlurriness: subTitleBackgroundBlurriness,
        backgroundOpacity: subTitleBackgroundOpacity
      }
    },
    banner: {
      [name]: {
        text: bannerText,
        fontSize: bannerFontSize,
        color: bannerColor,
        backgroundColor: bannerBackgroundColor,
        backgroundBlurriness: bannerBackgroundBlurriness,
        backgroundOpacity: bannerBackgroundOpacity
      }
    }
  } = useSelector((state) => state.landingPage)

  useEffect(() => {
    if (videoRef && videoRef.current && type === 'video')
      videojs(
        videoRef.current,
        {
          autoplay: true,
          controls: false,
          muted: true,
          preload: 'none',
          loop: true,
          sources: [
            {
              src: url,
              type: 'application/x-mpegURL',
              overrideNative: true
            }
          ]
        },
        function onPlayerReady(player) {}
      )
  }, [videoRef, type, url])

  const classes = useStyles({
    titleFontSize,
    titleColor,
    subTitleFontSize,
    subTitleColor,
    bannerFontSize,
    bannerColor,
    url,
    bannerBackgroundBlurriness,
    bannerBackgroundColor: Boolean(bannerBackgroundColor)
      ? hexToRGBA(bannerBackgroundColor, bannerBackgroundOpacity)
      : 'transparent',
    titleBackgroundBlurriness,
    titleBackgroundColor: Boolean(titleBackgroundColor)
      ? hexToRGBA(titleBackgroundColor, titleBackgroundOpacity)
      : 'transparent',
    subTitleBackgroundBlurriness,
    subTitleBackgroundColor: Boolean(subTitleBackgroundColor)
      ? hexToRGBA(subTitleBackgroundColor, subTitleBackgroundOpacity)
      : 'transparent'
  })

  if (type === 'image')
    return (
      <div
        className={classes.withImageContainer}
        id="image-background-container">
        <LandingPageContent
          classes={classes}
          bannerText={bannerText}
          titleText={titleText}
          subTitleText={subTitleText}
          selectedDevice={selectedDevice}
          name={name}
        />
      </div>
    )
  else
    return (
      <>
        <div style={{ width: '100%', height: '100%' }} data-vjs-player>
          <video ref={videoRef} className="video-js background" />
        </div>
        <div className={classes.videoOverContainer}>
          <LandingPageContent
            classes={classes}
            bannerText={bannerText}
            titleText={titleText}
            subTitleText={subTitleText}
            selectedDevice={selectedDevice}
            name={name}
          />
        </div>
      </>
    )
}

const LandingPageContent = ({
  classes,
  titleText,
  subTitleText,
  bannerText,
  selectedDevice,
  name
}) => {
  return (
    <>
      <GridContainer selectedDevice={selectedDevice}>
        <Grid
          container
          item
          direction="column"
          xs={12}
          style={{ margin: '0 auto' }}>
          <Grid
            item
            xs={12}
            className={classes.container}
            alignItems="center"
            justify="center"
            direction="column">
            <Typography color="secondary" className={classes.h1}>
              {titleText}
            </Typography>
            <Typography
              color="secondary"
              className={classes.h2}
              style={{ paddingTop: '20px' }}>
              {subTitleText}
            </Typography>
            <Typography className={classes.contentLibraryText}>
              {bannerText}
            </Typography>
          </Grid>
        </Grid>
      </GridContainer>
      <SocialIcons name={name} />
      <MoveToNextBanner classes={classes} />
    </>
  )
}

const MoveToNextBanner = ({ classes }) => {
  return (
    <div className={classes.downArrowContainer}>
      <KeyboardArrowDown style={{ fontSize: '5em' }} />
    </div>
  )
}
