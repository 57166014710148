import AWS from 'aws-sdk'
import Lambda from 'aws-sdk/clients/lambda' // npm install aws-sdk

AWS.config.update({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
})
const lambda = new Lambda()

export const invokeUploadMetaFunction = (payload) => {
  const params = {
    FunctionName: `uploadVideoMetaData-${process.env.REACT_APP_ENV}`,
    Payload: JSON.stringify(payload),
    InvocationType: 'RequestResponse'
  }
  return invokeLambda(params)
}

export const invokeUpdateCaptionsAudiosFunction = (payload) => {
  const params = {
    FunctionName: `updateCaptionsAndAudios-${process.env.REACT_APP_ENV}`,
    Payload: JSON.stringify(payload),
    InvocationType: 'RequestResponse'
  }
  return invokeLambda(params)
}

export const invokeLambda = (params) => {
  return new Promise((resolve, reject) => {
    lambda.invoke(params, (err, result) => {
      if (err) {
        return reject(err)
      } else {
        let { Payload } = result
        Payload = JSON.parse(Payload)

        if (Payload.statusCode === 200) {
          const body = JSON.parse(Payload.body)
          resolve({ fileName: body.fileName })
        } else {
          reject(Payload.body)
        }
      }
    })
  })
}
