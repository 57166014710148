import React, { useEffect, useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { v4 as uuid4 } from 'uuid'

import { Audioform, ButtonComponent } from '../../../components'
import { SecondaryButton } from '../../../styles/colors'
import { ALERT_TYPES, LanguagesCode } from '../../../lib/constants'
import { showAlert } from '../../../redux/modules/alertHandler'
import { updateAudioTracks } from '../../../backend/movies'

export const Audios = ({ audio_tracks, id, updateLocally }) => {
  const classes = useStyles()
  const [audios, setAudios] = useState([])
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setAudios([])
    if (audio_tracks && audio_tracks.items) {
      const data = audio_tracks.items.map((item) => {
        if (!item.additional_attributes) delete item['additional_attributes']
        delete item['createdAt']
        delete item['updatedAt']
        return {
          ...item,
          language: item.language,
          srcAudio: item.url,
          isOriginal: item.is_orignal,
          languageCode: LanguagesCode[item.language].toUpperCase()
        }
      })
      setAudios(data)
    }
  }, [audio_tracks, setAudios])

  useEffect(() => {
    const filteredAudios = audios.filter((item) => !item.srcAudio)
    setValidated(filteredAudios.length > 0 ? false : true)
  }, [audios, setValidated])

  const addAudio = () => {
    const newAudio = {
      id: uuid4(),
      isOriginal: true,
      language: 'English',
      languageCode: LanguagesCode['English'].toUpperCase(),
      status: true,
      srcAudio: null,
      video_id: id
    }

    setAudios([...audios, { ...newAudio }])
    setUpdated(true)
  }
  const remove = (id) => {
    const updatedAudios = audios.filter((item) => item.id !== id)
    setAudios(updatedAudios)
    setUpdated(true)
  }

  const update = (audioId, payload) => {
    console.log('payload', payload, audioId)
    const updatedAudios = audios.map((item) => {
      if (item.id === audioId) return { ...item, ...payload }
      return item
    })
    setAudios(updatedAudios)
    setUpdated(true)
  }

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const handleUpdate = async () => {
    if (loading) return
    setLoading(true)
    try {
      await updateAudioTracks(audio_tracks.items, audios, id)
      handleAlert('Data updated successfuly', ALERT_TYPES.SUCCESS)
      updateLocally()
      setLoading(false)
      setUpdated(false)
    } catch (err) {
      console.log('Error is: ', err)
      handleAlert('Something went wrong. Please try again.', ALERT_TYPES.ERROR)
      setLoading(false)
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <div style={{ margin: '10px 0px' }}>
          <ButtonComponent
            title="Add Audios"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={addAudio}
            width={150}
            height={30}
          />
        </div>
        <div className={classes.audiosCardContainer}>
          {audios.length === 0 && (
            <div style={{ marginTop: 20 }}>
              <Typography>Click on add audio button to add audios</Typography>
            </div>
          )}
          {audios.map((audio) => (
            <Audioform
              videoId={id}
              key={audio.id}
              update={update}
              audio={audio}
              remove={remove}
            />
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <ButtonComponent
          title="UPDATE"
          disabled={!updated || !validated}
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          loading={loading}
          onClickHandler={handleUpdate}
          width={150}
          height={40}
        />
      </div>
      {loading && <div>This will take few minutes.</div>}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%'
  },
  audiosCardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))
