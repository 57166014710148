import React, { useState } from 'react'
import { API } from 'aws-amplify'
import { Switch, Typography } from '@material-ui/core'

import { ButtonComponent, SelectInput } from '../../../components'
import { PrimaryColor } from '../../../styles/colors'
import {
  updateCountry,
  updatePaymentGateway,
  createPaymentGateway
} from '../../../graphql/mutations'
import { CDN_OPTIONS, PAYMENT_GATEWAYS } from '../../../lib/constants'

export const PaymentGatewayModal = ({
  payment_gateways,
  handleClose,
  preferredCDN,
  country_code,
  id
}) => {
  const [processing, setProcessing] = useState(false)
  const [listItems, setListItems] = useState(payment_gateways.items)
  const [cdn, setCdn] = useState(preferredCDN)
  const [tab, setTab] = useState(0)
  const [updated, setUpdated] = useState(false)

  const applyChanges = async () => {
    setProcessing(true)
    await Promise.all(
      listItems.map(async (item) => {
        if (item.id !== '') {
          await API.graphql({
            query: updatePaymentGateway,
            variables: { input: { id: item.id, disabled: item.disabled } }
          })
        } else {
          var payload = {
            name: item.name,
            disabled: item.disabled,
            country_code: country_code
          }
          await API.graphql({
            query: createPaymentGateway,
            variables: { input: payload }
          })
        }
      })
    )

    await API.graphql({
      query: updateCountry,
      variables: { input: { preferredCDN: cdn, country_code, id } }
    })
    setProcessing(false)
    handleClose(updated)
    setUpdated(false)
  }

  const handleChange = async (checked, payment_gateway) => {
    const itemExists = listItems.filter(
      (listItem) => listItem.name === payment_gateway
    )

    try {
      setUpdated(true)
      if (itemExists.length > 0) {
        const updatedStatus = listItems.map((item) => {
          if (item.name === payment_gateway)
            return { ...item, disabled: !checked }

          return { ...item }
        })
        setListItems(updatedStatus)
      } else {
        var newListItem = {
          name: payment_gateway,
          id: '',
          disabled: !checked
        }
        setListItems([...listItems, newListItem])
      }
    } catch (err) {
      const updatedStatus = listItems.map((item) => {
        if (item.id === id) return { ...item, disabled: checked }
        return { ...item }
      })
      setListItems(updatedStatus)
      throw err
    }
  }

  const handleCdn = ({ target: { value } }) => {
    setUpdated(true)
    setCdn(value)
  }

  return (
    <div className="caption-modal-container">
      <div className="tab-container">
        <div
          role="presentation"
          onClick={() => setTab(0)}
          className={tab === 0 ? 'selected-tab' : 'tab'}>
          <Typography className={'tab-text'}>CDN</Typography>
        </div>
        <div
          className={tab === 1 ? 'selected-tab' : 'tab'}
          role="presentation"
          onClick={() => setTab(1)}>
          <Typography className={'tab-text'}>Payment Gateways</Typography>
        </div>
      </div>
      {tab === 0 && (
        <div className="caption-languages">
          <div style={{ padding: 10 }}>
            <SelectInput
              data={CDN_OPTIONS}
              value={cdn}
              changeHandler={handleCdn}
            />
          </div>
        </div>
      )}
      {tab === 1 && (
        <div className="caption-languages">
          {PAYMENT_GATEWAYS.map((item) => {
            const itemExists = listItems.filter(
              (listItem) => listItem.name === item
            )

            return (
              <div
                key={
                  itemExists[0] && itemExists[0].name === item
                    ? itemExists[0].name
                    : item
                }>
                <Switch
                  name={item}
                  checked={
                    itemExists[0] && itemExists[0].name === item
                      ? !itemExists[0].disabled
                      : false
                  }
                  onChange={(_, checked) => handleChange(checked, item)}
                  color="primary"
                />
                {item}
              </div>
            )
          })}
        </div>
      )}
      <div className="caption-modal-footer">
        <div className="caption-modal-footer-button">
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Cancel"
            width={90}
            height={25}
            disabled={processing}
            onClickHandler={handleClose}
          />
        </div>
        <div>
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Apply"
            disabled={!updated}
            width={90}
            height={25}
            onClickHandler={applyChanges}
            loading={processing}
          />
        </div>
      </div>
    </div>
  )
}
