import _, { isEmpty } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import ReactSelect from 'react-select'
import { useSelector } from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import { API, graphqlOperation } from 'aws-amplify'
import 'react-datepicker/dist/react-datepicker.css'
import { useForm, Controller } from 'react-hook-form'
import {
  Typography,
  TextareaAutosize,
  makeStyles,
  Box
} from '@material-ui/core'

import { ButtonComponent } from '../../components'
import { useFetchCountriesList } from '../../hooks/useFetchCountriesList'
import {
  createSiteNotification,
  updateSiteNotification
} from '../../graphql/mutations'
import { LoadingSpinner } from '../../components/LoadingSpinner'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '300px',
    padding: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    borderWidth: '1px'
  },
  error: {
    color: 'red'
  },
  buttonsContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row'
  }
})

function DateInput({
  defaultValue,
  placeholderText,
  name,
  control,
  register,
  classes,
  error
}) {
  return (
    <Box component="div">
      <Controller
        control={control}
        name={name}
        {...register(name, { required: true })}
        defaultValue={defaultValue}
        render={({ field }) => (
          <ReactDatePicker
            className={classes.input}
            placeholderText={placeholderText}
            onChange={(e) => field.onChange(e)}
            selected={field.value}
            minDate={moment().toDate()}
            dateFormat="dd/MM/yyyy"
          />
        )}
      />
      {error && <div className={classes.error}>This field is required.</div>}
    </Box>
  )
}

export function CreateNewNotification({
  onGoBack,
  onAddNewNotification,
  notification,
  type
}) {
  const [loading, setLoading] = useState(false)

  const [country, setCountry] = useState(null)

  const { countries, loading: loadingCountries } = useFetchCountriesList({
    isSelect: true
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors
  } = useForm()

  const {
    attributes: { given_name }
  } = useSelector((state) => state.user)

  async function addNewNotification({
    content,
    country_id,
    startDate,
    endDate
  }) {
    try {
      setLoading(true)

      await API.graphql(
        graphqlOperation(createSiteNotification, {
          input: {
            status: true,
            message: content,
            generated_by: given_name,
            country_id,
            start_date: moment(startDate).format('YYYY-MM-DD'), // This is AWSDate format else mutation will fail.
            end_date: moment(endDate).format('YYYY-MM-DD')
          }
        })
      )

      // This is callback, which infact fetching notifications
      await onAddNewNotification()

      onGoBack()
    } catch (error) {
      console.error('error on adding notification', error)
      setLoading(false)
    }
  }

  async function editNotification({ content, country_id, startDate, endDate }) {
    try {
      setLoading(true)

      await API.graphql(
        graphqlOperation(updateSiteNotification, {
          input: {
            id: notification.id,
            status: true,
            message: content,
            generated_by: given_name,
            country_id,
            start_date: moment(startDate).format('YYYY-MM-DD'), // This is AWSDate format else mutation will fail.
            end_date: moment(endDate).format('YYYY-MM-DD')
          }
        })
      )

      setLoading(false)

      // This is callback, which infact fetching notifications
      await onAddNewNotification()

      onGoBack()
    } catch (error) {
      console.warn(error)

      setLoading(false)
    }
  }

  const onSubmit = (data) => {
    if (!country) {
      setError('country', { message: 'Please select country.' })

      return
    }

    const { startDate, endDate, content } = data

    if (moment(endDate, 'dd/MM/yyyy') <= moment(startDate, 'dd/MM/yyyy')) {
      return alert(
        'End Date should be greater than (at least 01 day) from Start Date'
      )
    }

    // True if we are adding new notification
    if (_.isEmpty(notification)) {
      return addNewNotification({
        content: `type:${type}:${content}`,
        country_id: country.value,
        startDate,
        endDate
      })
    }

    editNotification({
      content: `type:${type}:${content}`,
      country_id: country.value,
      startDate,
      endDate
    })
  }

  function getContent(message) {
    if (isEmpty(notification)) return null

    const text = message.split(':')

    if (_.isArray(text)) {
      return text[2]
    }
  }

  const classes = useStyles()

  if (loadingCountries) {
    return <LoadingSpinner />
  }

  const defaultCountry = !_.isEmpty(notification)
    ? countries.find((country) => notification.country_id === country.value)
    : null

  return (
    <Box component="div" className={classes.container}>
      <Typography variant="h4">
        {!_.isEmpty(notification) ? 'Edit' : 'Create New'} Notification ({type})
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DateInput
          control={control}
          register={register}
          name="startDate"
          placeholderText="Select Start Date"
          classes={classes}
          defaultValue={
            !_.isEmpty(notification) ? new Date(notification.start_date) : null
          }
          error={errors.startDate}
        />
        <DateInput
          control={control}
          register={register}
          name="endDate"
          placeholderText="Select End Date"
          classes={classes}
          defaultValue={
            !_.isEmpty(notification) ? new Date(notification.end_date) : null
          }
          error={errors.endDate}
        />
        <Box component="div">
          <Controller
            control={control}
            name="country"
            defaultValue={defaultCountry}
            render={({ field }) => (
              <ReactSelect
                value={country || defaultCountry}
                className={classes.input}
                placeholder="Select Country"
                options={countries}
                isClearable
                onChange={(e) => {
                  clearErrors('country')
                  setCountry(e)
                }}
              />
            )}
          />
          {errors.country && (
            <div className={classes.error}>{errors.country.message}</div>
          )}
        </Box>
        <Box component="div">
          <Controller
            control={control}
            name="content"
            {...register('content', { required: true })}
            defaultValue={
              !_.isEmpty(notification) ? getContent(notification.message) : null
            }
            render={({ field }) => (
              <TextareaAutosize
                className={classes.input}
                ref={field.ref}
                defaultValue={field.value}
                rowsMax={4}
                aria-label="maximum height"
                placeholder="Content"
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
          {errors.content && (
            <div className={classes.error}>Content is required.</div>
          )}
        </Box>
        <Box component="div" className={classes.buttonsContainer}>
          <ButtonComponent
            loading={loading}
            title="ADD NOW"
            onClickHandler={handleSubmit(onSubmit)}
            width={130}
          />
          <ButtonComponent
            disabled={loading}
            title="CANCEL"
            onClickHandler={onGoBack}
            width={130}
          />
        </Box>
      </form>
    </Box>
  )
}

export function CreateNewNotificationMobileEmail({
  onGoBack,
  onAddNewNotification,
  notification,
  type
}) {
  const [loading, setLoading] = useState(false)

  const [country, setCountry] = useState(null)

  const { countries, loading: loadingCountries } = useFetchCountriesList({
    isSelect: true
  })

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors
  } = useForm()

  const {
    attributes: { given_name }
  } = useSelector((state) => state.user)

  async function addNewNotification({
    content,
    country_id,
    startDate,
    endDate
  }) {
    try {
      setLoading(true)

      await API.graphql(
        graphqlOperation(createSiteNotification, {
          input: {
            status: true,
            message: content,
            generated_by: given_name,
            country_id,
            start_date: moment(startDate).format('YYYY-MM-DD'), // This is AWSDate format else mutation will fail.
            end_date: moment(endDate).format('YYYY-MM-DD')
          }
        })
      )

      // This is callback, which infact fetching notifications
      await onAddNewNotification()

      onGoBack()
    } catch (error) {
      console.error('error on adding notification', error)
      setLoading(false)
    }
  }

  async function editNotification({ content, country_id, startDate, endDate }) {
    try {
      setLoading(true)

      await API.graphql(
        graphqlOperation(updateSiteNotification, {
          input: {
            id: notification.id,
            status: true,
            message: content,
            generated_by: given_name,
            country_id,
            start_date: moment(startDate).format('YYYY-MM-DD'), // This is AWSDate format else mutation will fail.
            end_date: moment(endDate).format('YYYY-MM-DD')
          }
        })
      )

      setLoading(false)

      // This is callback, which infact fetching notifications
      await onAddNewNotification()

      onGoBack()
    } catch (error) {
      console.warn(error)

      setLoading(false)
    }
  }

  const onSubmit = (data) => {
    if (!country) {
      setError('country', { message: 'Please select country' })

      return
    }

    const { startDate, content } = data

    // True if we are adding new notification
    if (_.isEmpty(notification)) {
      return addNewNotification({
        content: `type:${type}:${content}`,
        country_id: country.value,
        startDate
      })
    }

    editNotification({
      content: `type:${type}:${content}`,
      country_id: country.value,
      startDate
    })
  }

  function getContent(message) {
    if (isEmpty(notification)) return null

    const text = message.split(':')

    if (_.isArray(text)) {
      return text[2]
    }
  }

  const classes = useStyles()

  if (loadingCountries) {
    return <LoadingSpinner />
  }

  const defaultCountry = !_.isEmpty(notification)
    ? countries.find((country) => notification.country_id === country.value)
    : null

  return (
    <Box component="div" className={classes.container}>
      <Typography variant="h4">
        {!_.isEmpty(notification) ? 'Edit' : 'Create New'} Notification ({type})
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DateInput
          control={control}
          register={register}
          name="startDate"
          placeholderText="Select Start Date"
          classes={classes}
          defaultValue={
            !_.isEmpty(notification) ? new Date(notification.start_date) : null
          }
          error={errors.startDate}
        />
        <Box component="div">
          <Controller
            control={control}
            name="country"
            defaultValue={defaultCountry}
            render={({ field }) => (
              <ReactSelect
                value={country || defaultCountry}
                className={classes.input}
                placeholder="Select Country"
                options={countries}
                isClearable
                onChange={(e) => {
                  clearErrors('country')
                  setCountry(e)
                }}
              />
            )}
          />
          {errors.country && (
            <div className={classes.error}>{errors.country.message}</div>
          )}
        </Box>
        <Box component="div">
          <Controller
            control={control}
            name="content"
            {...register('content', { required: true })}
            defaultValue={
              !_.isEmpty(notification) ? getContent(notification.message) : null
            }
            render={({ field }) => (
              <TextareaAutosize
                className={classes.input}
                ref={field.ref}
                defaultValue={field.value}
                rowsMax={4}
                aria-label="maximum height"
                placeholder="Content"
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
          {errors.content && (
            <div className={classes.error}>Content is required.</div>
          )}
        </Box>
        <Box component="div" className={classes.buttonsContainer}>
          <ButtonComponent
            loading={loading}
            title="ADD NOW"
            onClickHandler={handleSubmit(onSubmit)}
            width={130}
          />
          <ButtonComponent
            disabled={loading}
            title="CANCEL"
            onClickHandler={onGoBack}
            width={130}
          />
        </Box>
      </form>
    </Box>
  )
}
