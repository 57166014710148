import React, { useState } from 'react'
import {
  TablePagination,
  Switch,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Modal
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'

import { CountriesHeader } from '../../../lib/constants'
import { PaymentGatewayModal } from '.'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  CountryAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
})

export const List = ({ countries, handleActivation, fetchCountries }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedCountry, setSelectedCountry] = useState(null)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, countries.length - page * rowsPerPage)

  const classes = useStyles()

  const handleClose = (updated = false) => {
    setSelectedCountry(null)
    if (updated) fetchCountries()
  }

  const handleChangePage = (_, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {CountriesHeader.map((item) => (
                <TableCell
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
              <TableCell align="center" className="countries-table-header">
                Action
              </TableCell>
              <TableCell align="center" className="countries-table-header">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? countries.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : countries
            ).map((country) => {
              const {
                payment_gateways,
                country_name,
                country_code,
                currency,
                status,
                preferredCDN,
                id
              } = country

              const paymentGateways = payment_gateways.items
                .filter((item) => !item.disabled)
                .map((item) => item.name.replaceAll('_', ' '))
                .join(', ')

              return (
                <TableRow key={id}>
                  <TableCell align="left">{country_name}</TableCell>
                  <TableCell align="left">{country_code}</TableCell>
                  <TableCell align="left">{currency}</TableCell>
                  <TableCell align="left">{preferredCDN}</TableCell>
                  <TableCell align="left">
                    {payment_gateways.items.length > 0 ? paymentGateways : ''}
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon
                      className={classes.CountryAction}
                      role="presentation"
                      onClick={() => setSelectedCountry(country)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      name="checkedA"
                      checked={status}
                      onChange={(_, checked) => {
                        handleActivation(country, checked)
                      }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={countries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
      <Modal
        open={selectedCountry}
        BackdropProps={{ style: { backgroundColor: 'transperant' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="file-upload-modal">
        <PaymentGatewayModal {...selectedCountry} handleClose={handleClose} />
      </Modal>
    </div>
  )
}
