export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

export const validatePricingData = (movie, country, selectedPlans) => {
  if (movie === 'Select Movie') return 'Please select movie'

  if (country === 'Select Country') return 'Please select country'

  if (selectedPlans.length === 0) return 'Please select at least one plan'

  const withoutPricePlans = selectedPlans.filter(
    (item) => item.price === ''
  )

  if (withoutPricePlans.length > 0)
    return `Please add price for ${withoutPricePlans[0].title.toUpperCase()} plan`

  return null
}

export const validateHelpCategory = (helpCategory) => {
  const { title } = helpCategory

  if (!title) return { status: false, message: 'Please enter Title' }

  return { status: true }
}

export const validateHelpQNA = (helpQNA) => {
  const { category_id, question, answer } = helpQNA

  if (category_id === '-1')
    return { status: false, message: 'Please select Category' }
  if (!question) return { status: false, message: 'Please enter Question' }
  if (!answer) return { status: false, message: 'Please enter Answer' }

  return { status: true }
}

export const validatePromoCodeForm = (promoCode, user) => {
  const isAdmin =
    user.accessToken.payload['cognito:groups'].indexOf('admin') > -1
  const {
    country_code,
    end_date,
    percentage_off,
    max_use_limit,
    unlimited,
    justification,
    user_id
  } = promoCode
  if (country_code === '-1')
    return { status: false, message: 'Please select country' }
  if (!end_date) return { status: false, message: 'Please select end date' }
  if (!percentage_off)
    return { status: false, message: 'Please enter percentage off value' }
  if (parseInt(percentage_off) <= 0 || parseInt(percentage_off) > 100)
    return {
      status: false,
      message: 'Percentage off must be less than 0 and greater than 100'
    }
  if (
    !unlimited &&
    (parseInt(max_use_limit) <= 0 ||
      max_use_limit === null ||
      max_use_limit === '')
  )
    return { status: false, message: 'Max. use limit must be greater than 0' }
  if (!justification && !isAdmin)
    return {
      status: false,
      message: 'Please enter Justification for creating this Promo Code'
    }
  if (!user_id && !isAdmin)
    return { status: false, message: 'Please enter Valid User ID' }

  return { status: true }
}

// hash color and opacity to rgba
export const hexToRGBA = (color, opacity) => {
  var value = color.match(/[A-Za-z0-9]{2}/g)
  value = value.map(function (v) {
    return parseInt(v, 16)
  })
  value = [...value, opacity]
  return 'rgba(' + value.join(',') + ')'
}

export const dashedStringToCamelCase = (str) => {
  return str
    .split('_')
    .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1))
}
