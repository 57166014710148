import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  PrimaryColor as PrimaryColorHover,
  ButtonText,
  PrimaryButton
} from '../styles/colors'

export const ButtonComponent = ({
  disabled,
  onClickHandler,
  title,
  width,
  hoverBackgroundColor = PrimaryColorHover,
  backgroundColor = PrimaryButton,
  titleColor = ButtonText,
  hoverTitleColor = 'white',
  borderColor = '#000000',
  borderTopRightRadius = 4,
  borderTopLeftRadius = 4,
  borderBottomLeftRadius = 4,
  borderBottomRightRadius = 4,
  height = 40,
  icon = null,
  loading = false
}) => {
  const useStyles = makeStyles((theme) => ({
    buttonBackground: {
      width: width,
      color: titleColor,
      backgroundColor: loading ? hoverBackgroundColor : backgroundColor,
      border: `1px solid ${loading ? hoverBackgroundColor : borderColor}`,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      height: height,
      '&:hover': {
        backgroundColor: hoverBackgroundColor,
        border: `1px solid ${hoverBackgroundColor}`,
        color: hoverTitleColor
      }
    },
    circularProgress: {
      color: 'white'
    },
    contentStyle: {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center'
    }
  }))

  const classes = useStyles()

  return (
    <Button
      disabled={disabled}
      className={classes.buttonBackground}
      onClick={() => !loading && onClickHandler()}
      variant="outlined">
      {loading ? (
        <CircularProgress size={20} className={classes.circularProgress} />
      ) : (
        <span className={classes.contentStyle}>
          {icon}
          {title}
        </span>
      )}
    </Button>
  )
}
