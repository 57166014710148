import React, { useEffect, useState } from 'react'
import { Checkbox } from '@material-ui/core'

import { Header, ButtonComponent } from '.'
import { SecondaryButton } from '../styles/colors'

export const PlansTable = ({
  allPlans,
  closePlansModal,
  selectedPlans,
  addPlans
}) => {
  const [selectedPlanIds, setSelectedPlanIds] = useState([])

  const [plans, setPlans] = useState([...selectedPlans])

  useEffect(() => {
    const ids = plans.map((plan) => plan.id)

    setSelectedPlanIds(ids)
  }, [plans])

  const addPlan = (id) => {
    const plan = allPlans.filter((item) => item.id === id)
    setPlans([...plans, { ...plan[0], price: 0, status: true }])
  }

  const removePlan = (id) => {
    const index = plans.findIndex((item) => item.id === id)

    let newPlans = [...plans]
    newPlans.splice(index, 1)

    setPlans(newPlans)
  }

  const handleAddition = () => {
    addPlans(plans)
    closePlansModal()
  }

  return (
    <div className="plans-table-container">
      <Header title="Plans" />
      <div className="plans-table">
        {allPlans.map((plan) => {
          const {
            id,
            title,
            video_quality: {
              vertical_resolution: { title: qualityTitle }
            },
            device_types: { items: deviceTypes }
          } = plan
          return (
            <div className="plan-table-row" key={id}>
              <div className="plan-row-check">
                <Checkbox
                  onChange={(_, checked) =>
                    checked ? addPlan(id) : removePlan(id)
                  }
                  checked={selectedPlanIds.indexOf(id) > -1 ? true : false}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              <div className="plan-row-title">{title}</div>
              <div className="plan-row-quality">{qualityTitle}</div>
              <div className="plan-row-devices">
                {deviceTypes.map(
                  ({ device_types: { title: deviceTitle, id: deviceId } }) => (
                    <span className="device-tag" key={deviceId}>
                      {deviceTitle}
                    </span>
                  )
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="plans-table-footer">
        <div className="create-pricing-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={closePlansModal}
            width={130}
            height={30}
          />
        </div>
        <div className="create-pricing-footer-button">
          <ButtonComponent
            title="ADD"
            onClickHandler={handleAddition}
            width={130}
            height={30}
          />
        </div>
      </div>
    </div>
  )
}
