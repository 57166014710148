import React, { useEffect, useState } from 'react'
import { fetchCountries } from '../../backend/countries'

import { Header } from '../../components'
import { PromoForm } from './components'
import './promoCode.css'

export const PromoCode = () => {
  const [countries, setCountries] = useState([])
  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetchCountries(true)
        setCountries(response)
      } catch (err) {
        console.log('Error in fetching countries is: ', err)
        alert(err.message || 'Error in fetching countries')
      }
    })()
  }, [])
  return (
    <div className="dashboard-page">
      <Header title="Create Promo Code" />
      <PromoForm countries={countries} />
    </div>
  )
}
