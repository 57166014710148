import React, { useState, useEffect } from 'react'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Storage } from 'aws-amplify'

import { removeCast } from '../../redux/modules/cast'
import { ConfirmationDialog } from '../../components'

const useStyles = makeStyles(() => ({
  castAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
}))

export const CreateCastButton = ({ onClickHandler }) => {
  return (
    <div
      role="presentation"
      onClick={onClickHandler}
      className="create-cast-button">
      <AddIcon className="add-icon" /> Create New
    </div>
  )
}

export const CastCard = ({ castItem }) => {
  const classes = useStyles()

  const [isOpenDeleteDialogue, setIsOpenDeleteDialogue] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)

  const dispatch = useDispatch()

  const history = useHistory()

  const { id, description, name, nick_name, image_sm } = castItem

  useEffect(() => {
    ;(async () => {
      const response = await Storage.get(image_sm, { expires: 86400 })

      setImageUrl(response)
    })()
  }, [image_sm])

  const deleteCastItem = async () => {
    setProcessing(true)

    try {
      await dispatch(removeCast(id))

      setProcessing(false)
    } catch (err) {
      alert('Something went wrong please try again')

      setProcessing(false)
    }
  }

  const handleClose = () => setIsOpenDeleteDialogue(false)

  return (
    <div className={'cast-card'} key={id}>
      <div className="cast-card-header">
        <div className="cast-card-title">
          <div className="cast-card-nickname">{nick_name}</div>
          <div className="cast-card-fullname">{name} </div>
        </div>
        <div>
          <img src={imageUrl} className="cast-image" alt="Cast" />
        </div>
      </div>
      <div className="cast-card-description">
        {description.length > 150
          ? `${description.substring(0, 160)}...`
          : description}
      </div>

      <div className="movie-card-footer">
        <div className="movie-action-container">
          <EditIcon
            className={classes.castAction}
            role="presentation"
            onClick={() => history.push(`/edit-cast/${id}`)}
          />
        </div>
        <div className="movie-action-container">
          <DeleteIcon
            className={classes.castAction}
            role="presentation"
            onClick={() => setIsOpenDeleteDialogue(true)}
          />
        </div>
      </div>

      <ConfirmationDialog
        open={isOpenDeleteDialogue}
        handleClose={handleClose}
        processRequest={deleteCastItem}
        title={'Confirmation'}
        description={
          <p>
            Are you sure you want to delete{' '}
            <span className="country-name"> {name}</span>.
          </p>
        }
        loading={processing}
      />
    </div>
  )
}
