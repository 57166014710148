import { Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonComponent, Header } from '../../../components'
import { setData } from '../../../redux/modules/newMovie'
import { PrimaryColor } from '../../../styles/colors'
import { AddCast, CastCard, CastTypeHeader } from './_index'

export const StepThree = () => {
  const cast = useSelector((state) => state.cast)
  const { castCrew } = useSelector((state) => state.newMovie)

  const [idToCastMapping, setIdToCastMapping] = useState({})
  const [addCastModal, setAddCastModal] = useState(false)

  const castCrewCount = castCrew.length
  const castCount = cast.length

  useEffect(() => {
    const mapping = {}
    cast.forEach((item) => {
      mapping[item.id] = item
    })

    setIdToCastMapping(mapping)
  }, [castCrewCount, castCount])

  const dispatch = useDispatch()

  const handleAddition = (value, id) => {
    if (value.length === 0) value = ['-1']
    else if (value.length > 0 && value.includes('-1'))
      value.splice(value.indexOf('-1'), 1)

    const newCast = value.map((castId) => {
      return {
        id: castId,
        name: idToCastMapping[castId].name,
        display: true
      }
    })

    dispatch(
      setData({
        castCrew: { ...castCrew, [id]: { ...castCrew[id], cast: newCast } }
      })
    )

    closeModal()
  }

  const closeModal = () => setAddCastModal(false)

  return (
    <div>
      <Header title="Cast and Crew" />
      <div className="cast-crew-add-button">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title="Add Cast"
          onClickHandler={() => setAddCastModal(true)}
          width={200}
        />
      </div>

      <div>
        {Object.keys(castCrew).map((id) => {
          const item = castCrew[id]
          const notSelected =
            item.cast.filter((item) => item.id === '-1').length === 0
              ? false
              : true
          if (notSelected) return null
          else
            return (
              <div key={id} className="create-cast-stepthree-list">
                <CastTypeHeader title={item.title} />
                <div>
                  {item.cast.map((castItem) => {
                    return (
                      <CastCard
                        castTypeId={id}
                        castItemId={castItem.id}
                        name={castItem.name}
                        display={castItem.display}
                      />
                    )
                  })}
                </div>
              </div>
            )
        })}
      </div>

      <Modal
        open={addCastModal}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={'cast-modal'}>
        <AddCast close={closeModal} handleAddition={handleAddition} />
      </Modal>
    </div>
  )
}
