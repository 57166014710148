import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { listPricings } from '../../graphql/queries'
import { ConfirmationDialog } from '../../components'
import { deletePricingsData } from '../../redux/modules/pricings'

const useStyles = makeStyles(() => ({
  planAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
}))

export const fetchPricings = async (videoId) => {
  try {
    const {
      data: {
        listPricings: { items }
      }
    } = await API.graphql({
      query: listPricings,
      variables: { filter: { video_id: { eq: videoId } } }
    })

    const pricingsByCountry = {}

    items.map((item) => {
      const {
        country: { country_name }
      } = item

      if (pricingsByCountry[country_name])
        pricingsByCountry[country_name] = [
          ...pricingsByCountry[country_name],
          item
        ]
      else pricingsByCountry[country_name] = [item]
    })

    return pricingsByCountry
  } catch (err) {
    return err
  }
}

export const PricingCard = ({ title, item }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState('')

  const itemCount = item.length

  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    if (itemCount > 0) {
      const {
        country: {
          payment_gateways: { items: methods }
        }
      } = item[0]

      const names = methods.map((item) => {
        return item.name
      })

      setSupportedPaymentMethods(names.join(' | '))
    }
  }, [itemCount])

  const closeDeleteModal = () => {
    setProcessing(false)
    setIsDeleteModalVisible(false)
  }

  const handleDeletion = async () => {
    setProcessing(true)

    try {
      await dispatch(deletePricingsData(item))

      closeDeleteModal()
    } catch (err) {
      console.log('Error in deleting pricing is: ', err)
      setProcessing(false)
    }
  }

  const plans = item.map((pricingItem) => {
    const {
      price,
      status,
      id,
      country: { currency },
      video_plan: { plan }
    } = pricingItem

    return { title: plan.title, price, status, id, currency }
  })

  const videoId = item.length > 0 ? item[0].video.id : null
  const countryCode = item.length > 0 ? item[0].country.country_code : null
  const countryStatus = item.length > 0 ? item[0].country.status : null

  return (
    <div className="pricing-plan" style={{backgroundColor: !countryStatus ? 'gray': ''}}>
      <div className="plan-header">
        {title? title : ''}
        <div className="plan-actions">
          <div className="plan-action-container">
            {countryStatus && <EditIcon
              className={classes.planAction}
              role="presentation"
              onClick={() =>
                videoId &&
                countryCode &&
                history.push(
                  `/edit-pricing/${videoId}/${countryCode.toLowerCase()}`
                )
              }
            />}
          </div>
          <div className="plan-action-container">
            <DeleteIcon
              className={classes.planAction}
              role="presentation"
              onClick={() => setIsDeleteModalVisible(true)}
            />
          </div>
        </div>
      </div>
      <div className="pricing-type-container">
        {plans.map((plan) => {
          return (
            <div className="pricing-detail" key={plan.id}>
              <div className="pricing-detail-key">{plan.title}</div>
              <div className="pricing-detail-value">{`${plan.price} ${plan.currency}`}</div>
            </div>
          )
        })}
      </div>

      <div className="pricing-gateway-container">
        <div className="pricing-detail-key">STANDARD</div>
        <div className="pricing-detail-value">{supportedPaymentMethods}</div>
      </div>

      <ConfirmationDialog
        open={isDeleteModalVisible}
        handleClose={closeDeleteModal}
        processRequest={handleDeletion}
        title={`Delete`}
        description={` Are you sure you want to delete ${item[0].video.title} for ${title}? `}
        loading={processing}
      />
    </div>
  )
}
