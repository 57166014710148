import {
  Backdrop,
  LinearProgress,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  TrailersAndClips,
  AddAudio
} from './steps'
import { ContentPreview } from './steps/contentPreview'

const useStyles = makeStyles({
  backdrop: {
    color: '#ffffff',
    zIndex: 2000,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  progressContainer: {
    width: 600,
    padding: 10
  },
  linearProgress: {
    height: 15,
    borderRadius: 10
  },
  progressDetail: {
    fontSize: 16
  }
})

export const Steps = ({ step }) => {
  return (
    <div className="create-movie-main-container">
      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
      {step === 3 && <StepThree />}
      {step === 4 && <StepFour />}
      {step === 5 && <AddAudio />}
      {step === 6 && <TrailersAndClips />}
      {step === 7 && <ContentPreview />}
      {step === 8 && <StepFive />}
    </div>
  )
}

export const Loader = () => {
  return (
    <div className="loading-container">
      <CircularProgress size={30} className="countries-loader" />
    </div>
  )
}

export const Uploader = ({ saving, savingProgress, progressDetail }) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={saving}>
      <div className={classes.progressContainer}>
        <LinearProgress
          variant="determinate"
          value={savingProgress}
          className={classes.linearProgress}
        />
      </div>
      <p className={classes.progressDetail}>{progressDetail}</p>
    </Backdrop>
  )
}

export const enableDisableNextButton = (step, newMovie, toggleNextButton) => {
  if (step === 1) validateStepOne(newMovie, toggleNextButton)
  else if (step === 2) validateStepTwo(newMovie, toggleNextButton)
  else if (step === 3) validateStepThree(newMovie, toggleNextButton)
  else if (step === 4) validateStepFour(newMovie, toggleNextButton)
  else if (step === 5) validateStepFive(newMovie, toggleNextButton)
  else if (step === 6 || step === 7)
    validateStepSix(newMovie, toggleNextButton, step)
  else if (step === 8) validateStepSeven(newMovie, toggleNextButton)
}

const validateStepFive = (newMovie, toggleNextButton) => {
  const { audios } = newMovie

  if (audios.length > 0) {
    const empty = audios.filter(({ srcAudio }) => isEmpty(srcAudio))
    if (empty.length === 0) toggleNextButton(false)
  } else toggleNextButton(false)
}

const validateStepSix = (newMovie, toggleNextButton, step) => {
  let { trailers } = newMovie
  trailers = trailers.filter(
    (item) =>
      (step === 7 && item.type === 'CONTENT_PREVIEW') ||
      (step === 6 && item.type !== 'CONTENT_PREVIEW')
  )
  const empty = trailers.filter(({ title, key }) => !title || !key)
  let unHandledCaptions = false
  trailers.map((item) => {
    item.captions.map((caption) => {
      if (!caption.url) {
        unHandledCaptions = true
        return
      }
    })
  })
  if (trailers.length > 0 && empty.length === 0 && !unHandledCaptions)
    toggleNextButton(false)
}

const validateStepSeven = (newMovie, toggleNextButton) => {
  const { movie } = newMovie
  if (movie) toggleNextButton(false)
}

const validateStepFour = (newMovie, toggleNextButton) => {
  const { captions } = newMovie
  if (captions.length > 0) {
    const withoutFiles = captions.filter((item) => item.file === null)
    if (withoutFiles.length === 0) toggleNextButton(false)
  } else {
    toggleNextButton(false)
  }
}

const validateStepTwo = (newMovie, toggleNextButton) => {
  const {
    releaseDate,
    creditHours,
    creditMinutes,
    durationHours,
    durationMinutes
  } = newMovie
  if (
    releaseDate &&
    creditHours !== '' &&
    creditMinutes !== '' &&
    durationHours !== '' &&
    durationMinutes !== ''
  )
    toggleNextButton(false)
}

const validateStepThree = (newMovie, toggleNextButton) => {
  const { castCrew } = newMovie
  const isEmpty = Object.keys(castCrew).filter(
    (item) =>
      castCrew[item].cast.length === 1 && castCrew[item].cast.includes('-1')
  )
  if (isEmpty.length === 0) toggleNextButton(false)
}

const validateStepOne = (newMovie, toggleNextButton) => {
  const {
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    genre,
    mainTitle
  } = newMovie
  if (
    mainTitle !== '' &&
    genre.length > 0 &&
    !genre.includes('-1') &&
    thumbnail_sm &&
    thumbnail_md &&
    thumbnail_lg
  )
    toggleNextButton(false)
}
