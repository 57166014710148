import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchGenres } from '../redux/modules/genres'

export const useGenres = () => {
  const genres = useSelector((state) => state.genres)
  const dispatch = useDispatch()

  useEffect(() => {
    if (genres.length === 0) dispatch(fetchGenres())
  }, [genres, dispatch])

  return { genres }
}
