import React, { Fragment, useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Switch
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import { API, graphqlOperation } from 'aws-amplify'
import _, { isEmpty } from 'lodash'

import { color } from '../../styles/colors'
import {
  CreateNewNotification,
  CreateNewNotificationMobileEmail
} from './_index'
import { listSiteNotifications } from '../../graphql/queries'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { updateSiteNotification as UpdateSiteNotification } from '../../graphql/mutations'
import { ButtonComponent, Header, YesNoAlert } from '../../components'

const useStyles = makeStyles({
  root: {
    marginTop: 100,
    margin: '0 auto',
    width: '95%'
  },
  paper: {
    backgroundColor: color.cardGreen,
    height: 230
  },
  creaeteNew: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    height: 230,
    border: '2px solid',
    borderColor: color.cardGreen,
    borderStyle: 'dashed'
  },
  startDateEndDateContainer: {
    backgroundColor: color.cardDatesGreen,
    paddingLeft: 10,
    height: 50,
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  cardContent: { padding: 0, margin: 0, flex: 1 }
})

export function Notifications() {
  const classes = useStyles()

  const [
    {
      showCreateNewWeb,
      showEditNotificationWeb,
      showEditNotificationMobileEmail,
      showCreateNewMobileEmail,
      notifications,
      type,
      loading,
      open
    },
    setState
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    showCreateNewWeb: false,
    showEditNotificationWeb: null,
    notifications: [],
    loading: true,
    showCreateNewMobileEmail: false,
    showEditNotificationMobileEmail: false,
    type: null,
    open: false
  })

  async function fetchNotifications() {
    try {
      const {
        data: {
          listSiteNotifications: { items }
        }
      } = await API.graphql(graphqlOperation(listSiteNotifications))

      if (items.length > 0) {
        setState({ notifications: items, loading: false })
        return
      }

      setState({ loading: false })
    } catch (error) {
      console.error('error on fetching notifications', error)

      setState({ loading: false })
    }
  }

  async function changeNotificationStatus(id, status) {
    try {
      await API.graphql(
        graphqlOperation(UpdateSiteNotification, {
          input: {
            id,
            status
          }
        })
      )

      const notificationsCloned = _.cloneDeep(notifications)

      const notificationIndex = notificationsCloned.findIndex(
        (notification) => notification.id === id
      )

      notificationsCloned[notificationIndex].status = status

      setState({ notifications: notificationsCloned })
    } catch (error) {
      console.warn(error)
    }
  }

  function getTypeContent(message) {
    const text = message.split(':')

    if (_.isArray(text)) {
      return { type: text[1], content: text[2] }
    }
  }

  function onShowEditNotification(type, notification) {
    if (type === 'Web') {
      setState({ showEditNotificationWeb: notification, type })

      return
    }

    setState({ showEditNotificationMobileEmail: notification, type })
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  return (
    <Fragment>
      <Box component="div" className={classes.root}>
        {!showCreateNewWeb &&
          !showCreateNewMobileEmail &&
          isEmpty(showEditNotificationWeb) &&
          isEmpty(showEditNotificationMobileEmail) && (
            <Header title="Notifications" />
          )}
        {loading && <LoadingSpinner />}
        {!showCreateNewWeb &&
          !showCreateNewMobileEmail &&
          isEmpty(showEditNotificationWeb) &&
          isEmpty(showEditNotificationMobileEmail) && (
            <Fragment>
              <Box
                variant="div"
                style={{ display: 'flex', flexDirection: 'row' }}></Box>
              <Grid container spacing={3}>
                {!loading && (
                  <Grid item xs={3} sm={3} zeroMinWidth>
                    <Paper className={classes.creaeteNew}>
                      <ButtonComponent
                        disabled={loading}
                        title="Web"
                        onClickHandler={() =>
                          setState({ showCreateNewWeb: true, type: 'Web' })
                        }
                        width={130}
                      />
                      <ButtonComponent
                        disabled={loading}
                        title="Mobile"
                        onClickHandler={() =>
                          setState({
                            showCreateNewMobileEmail: true,
                            type: 'Mobile'
                          })
                        }
                        width={130}
                      />
                      <ButtonComponent
                        disabled={loading}
                        title="Email"
                        onClickHandler={() =>
                          setState({
                            showCreateNewMobileEmail: true,
                            type: 'Email'
                          })
                        }
                        width={130}
                      />
                    </Paper>
                  </Grid>
                )}
                {notifications.length > 0 &&
                  notifications.map((notification, index) => {
                    const {
                      id,
                      start_date,
                      end_date,
                      message,
                      status
                    } = notification

                    const notificationExpired =
                      new Date().getTime() > new Date(end_date).getTime()

                    const { type, content } = getTypeContent(message)

                    return (
                      <Grid item xs={6} sm={3} key={id}>
                        <Card className={classes.paper}>
                          <CardContent className={classes.cardContent}>
                            <Box
                              style={{ padding: 5 }}
                              component="h1"
                              color={color.white}>
                              {type}
                            </Box>
                            <Box
                              component="div"
                              className={classes.startDateEndDateContainer}
                              style={{
                                backgroundColor: notificationExpired
                                  ? color.red
                                  : color.cardDatesGreen
                              }}>
                              <Box component="div" flex="1">
                                <Typography
                                  variant="subtitle2"
                                  component="p"
                                  style={{ color: color.white }}>
                                  {type === 'Web'
                                    ? 'Start Date'
                                    : 'Scheduled At'}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  component="p"
                                  style={{ color: color.white }}>
                                  {start_date}
                                </Typography>
                              </Box>
                              {type === 'Web' && (
                                <Box component="div" flex="1">
                                  <Typography
                                    variant="subtitle2"
                                    component="p"
                                    style={{ color: color.white }}>
                                    End Date
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    style={{ color: color.white }}>
                                    {end_date}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Typography
                              variant="subtitle1"
                              component="p"
                              style={{ color: color.white, padding: 10 }}>
                              {content}
                            </Typography>
                          </CardContent>
                        </Card>
                        <CardActions
                          disableSpacing
                          style={{ justifyContent: 'center' }}>
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              onShowEditNotification(type, notification)
                            }>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Delete"
                            onClick={() => setState({ open: true })}>
                            <DeleteIcon />
                          </IconButton>
                          <Switch
                            checked={status}
                            onChange={() =>
                              changeNotificationStatus(id, !status)
                            }
                            name="checkedB"
                            color="primary"
                          />
                        </CardActions>
                      </Grid>
                    )
                  })}
              </Grid>
            </Fragment>
          )}
        {showCreateNewWeb && (
          <CreateNewNotification
            notification={{}}
            onAddNewNotification={fetchNotifications}
            onGoBack={() => setState({ showCreateNewWeb: false })}
            type={type}
          />
        )}
        {showCreateNewMobileEmail && (
          <CreateNewNotificationMobileEmail
            notification={{}}
            onAddNewNotification={fetchNotifications}
            onGoBack={() => setState({ showCreateNewMobileEmail: false })}
            type={type}
          />
        )}
        {!_.isEmpty(showEditNotificationWeb) && (
          <CreateNewNotification
            notification={showEditNotificationWeb}
            onAddNewNotification={fetchNotifications}
            onGoBack={() => setState({ showEditNotificationWeb: false })}
            type={type}
          />
        )}
        {!_.isEmpty(showEditNotificationMobileEmail) && (
          <CreateNewNotificationMobileEmail
            notification={showEditNotificationMobileEmail}
            onAddNewNotification={fetchNotifications}
            onGoBack={() =>
              setState({ showEditNotificationMobileEmail: false })
            }
            type={type}
          />
        )}
      </Box>
      <YesNoAlert
        open={open}
        handleClose={() => setState({ open: false })}
        title="Notifications"
        message="Are you sure you want to delete notifications?"
        positiveAction={() => {}}
        negativeAction={() => {}}
      />
    </Fragment>
  )
}
