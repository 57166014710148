import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonComponent } from '../../../../components'
import { validatePromoCodeForm } from '../../../../lib/util'
import { addNewPromoCode } from '../../../../redux/modules/promoCode'
import { PromoFormDetail } from '../promoFormDetail'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'

export const PromoForm = ({ countries }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)

  const User = useSelector((state) => state.user)

  const { newPromoCode } = useSelector((state) => state.promoCode)
  const dispatch = useDispatch()

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePromoCodeCreation()
      event.preventDefault()
    }
  }

  const handlePromoCodeCreation = async () => {
    setError(null)

    const { status, message } = validatePromoCodeForm(newPromoCode, User)
    if (!status) {
      setError(message)
      return
    }
    try {
      setLoading(true)
      await dispatch(addNewPromoCode())
      setLoading(false)
      setOpenSuccess(true)
    } catch (err) {
      setError(err.error_message)
      console.log('Error in creating promo code is: ', err)
      setLoading(false)
    }
  }

  return (
    <form onKeyPress={handleKeyPress} className="promo-form">
      <Collapse in={openSuccess}>
        <Alert sx={{ mb: 2 }}>
          {newPromoCode.createdCode} PromoCode Created!
        </Alert>
      </Collapse>
      <div className="promo-code-main-container">
        <PromoFormDetail countries={countries} />
      </div>
      <div className="promo-code-footer">
        <div className="form-error">{error}</div>
        <ButtonComponent
          loading={loading}
          title="Create"
          width={200}
          onClickHandler={handlePromoCodeCreation}
        />
      </div>
    </form>
  )
}
