import { API } from 'aws-amplify'
import { listCastCrewTypes } from '../../graphql/queries'
import { v4 as uuid4 } from 'uuid'
// constants
const SET_DATA = 'SET_DATA'
const SET_CAST_CREW = 'SET_CAST_CREW'
const ADD_TRAILER = 'ADD_TRAILER'
const REMOVE_TRAILER = 'REMOVE_TRAILER'
const ADD_TRAILER_TITLE = 'ADD_TRAILER_TITLE'
const ADD_TRAILER_THUMBNAIL = 'ADD_TRAILER_THUMBNAIL'
const SET_TRAILER = 'SET_TRAILER'
const ADD_TRAILER_CAPTIONS = 'ADD_TRAILER_CAPTIONS'
const ADD_TRAILER_AUDIO = 'ADD_TRAILER_AUDIO'
const ADD_TRAILER_VIDEO_FROM_BUCKET = 'ADD_TRAILER_VIDEO_FROM_BUCKET'
const REMOVE_TRAILER_CAPTIONS = 'REMOVE_TRAILER_CAPTIONS'
const REMOVE_TRAILER_AUDIO = 'REMOVE_TRAILER_AUDIO'
const UPDATE_TRAILER_AUDIO = 'UPDATE_TRAILER_AUDIO'
const SET_TRAILER_CAPTION_LANGUAGE = 'SET_TRAILER_CAPTION_LANGUAGE'
const SET_TRAILER_CAPTION_URL = 'SET_TRAILER_CAPTION_URL'
const SET_MAIN_TRAILER = 'SET_MAIN_TRAILER'
const SET_VIDEO_ID = 'SET_VIDEO_ID'
const SET_TRAILER_TYPE = 'SET_TRAILER_TYPE'
const SET_TRAILER_DURATION = 'SET_TRAILER_DURATION'

/** Movie Audio files actions */
const ADD_AUDIO = 'ADD_AUDIO'
const REMOVE_AUDIO = 'REMOVE_AUDIO'
const UPDATE_AUDIO = 'UPDATE_AUDIO'

// actions
export const setData = (payload) => ({
  type: SET_DATA,
  payload
})

export const addAudio = () => async (dispatch, getState) => {
  let { audios } = getState().newMovie
  dispatch({
    type: ADD_AUDIO,
    payload: {
      id: uuid4(),
      language: 'English',
      languageCode: 'ENG',
      isOriginal: audios.length ? false : true,
      srcAudio: ''
    }
  })
}

export const removeAudio = (id) => ({
  type: REMOVE_AUDIO,
  payload: {
    id
  }
})

export const updateAudio = (id, audio) => ({
  type: UPDATE_AUDIO,
  payload: {
    id,
    audio
  }
})

export const addTrailer = (type = 'TRAILER') => ({
  type: ADD_TRAILER,
  payload: {
    title: '',
    thumbnail_sm: null,
    thumbnail_md: null,
    thumbnail_lg: null,
    key: null,
    id: uuid4(),
    isMain: false,
    captions: [],
    audios: [],
    videoFromBucket: null,
    duration: {
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    type
  }
})

export const addTrailerCaptions = (language, trailerId) => ({
  type: ADD_TRAILER_CAPTIONS,
  payload: {
    caption: { id: uuid4(), language, url: null },
    trailerId
  }
})

export const addTrailerAudio = (trailerId) => async (dispatch, getState) => {
  const { audios } = getState().newMovie
  dispatch({
    type: ADD_TRAILER_AUDIO,
    payload: {
      audio: {
        id: uuid4(),
        language: 'English',
        languageCode: 'ENG',
        isOriginal: audios.length ? false : true,
        srcAudio: ''
      },
      trailerId
    }
  })
}

export const updateTrailerAudio = (trailerId, audioId, data) => ({
  type: UPDATE_TRAILER_AUDIO,
  payload: {
    trailerId,
    audioId,
    data
  }
})

export const addTrailerVideoFromBucket = (videoFromBucket, trailerId) => ({
  type: ADD_TRAILER_VIDEO_FROM_BUCKET,
  payload: {
    videoFromBucket,
    trailerId
  }
})

export const setTrailerCaptionLanguage = (trailerId, captionId, language) => ({
  type: SET_TRAILER_CAPTION_LANGUAGE,
  payload: {
    trailerId,
    captionId,
    language
  }
})

export const setTrailerCaptionUrl = (trailerId, captionId, url) => ({
  type: SET_TRAILER_CAPTION_URL,
  payload: {
    trailerId,
    captionId,
    url
  }
})

export const removeTrailerCaptions = (trailerId, captionId) => ({
  type: REMOVE_TRAILER_CAPTIONS,
  payload: {
    trailerId,
    captionId
  }
})

export const removeTrailerAudio = (trailerId, audioId) => ({
  type: REMOVE_TRAILER_AUDIO,
  payload: {
    trailerId,
    audioId
  }
})

export const removeTrailer = (id) => ({
  type: REMOVE_TRAILER,
  payload: { id }
})

export const addTrailerTitle = (title, id) => ({
  type: ADD_TRAILER_TITLE,
  payload: { title, id }
})

export const setTrailerThumbNail = (key, thumbnailId, id) => ({
  type: ADD_TRAILER_THUMBNAIL,
  payload: { key, thumbnailId, id }
})

export const setTrailerDuration = (key, value, id) => ({
  type: SET_TRAILER_DURATION,
  payload: { key, value, id }
})

export const setTrailer = (key, id) => ({
  type: SET_TRAILER,
  payload: { key, id }
})

export const setMainTrailer = (id) => ({
  type: SET_MAIN_TRAILER,
  payload: { id }
})

export const setTrailerType = (id, type) => ({
  type: SET_TRAILER_TYPE,
  payload: { id, type }
})

export const setVideoID = (videoId) => ({
  type: SET_VIDEO_ID,
  payload: { videoId }
})

export const clearData = () => {
  const payload = {
    videoId: '',
    thumbnail: null,
    thumbnail_sm: null,
    thumbnail_md: null,
    thumbnail_lg: null,
    isOrignal: true,
    banner_message: '',
    language: 'Urdu',
    subTitle: '',
    mainTitle: '',
    genre: ['-1'],
    releaseDate: null,
    creditHours: '',
    creditMinutes: '',
    creditSeconds: '',
    durationHours: '',
    durationMinutes: '',
    durationSeconds: '',
    castCrew: null,
    captions: [],
    audios: [],
    movie: null,
    movieFromBucket: null,
    trailer: null,
    trailers: [],
    short_description: '',
    long_description: '',
    duration: 0
  }
  return {
    type: SET_DATA,
    payload
  }
}

// thunks

// fetch cast crew types
export const fetchCastCrewTypes = () => async (dispatch) => {
  try {
    const {
      data: {
        listCastCrewTypes: { items }
      }
    } = await API.graphql({ query: listCastCrewTypes })

    const castCrew = {}
    items.forEach((item) => {
      castCrew[item.id] = {
        title: item.title,
        cast: [{ id: '-1', name: '-1', display: true }]
      }
    })
    dispatch({
      type: SET_CAST_CREW,
      payload: { castCrew }
    })
  } catch (err) {}
}

// reducer
const initialState = {
  // step one
  videoId: '',
  thumbnail: null,
  subTitle: '',
  mainTitle: '',
  genre: ['-1'],
  short_description: '',
  long_description: '',
  banner_message: '',
  isOrignal: true,
  language: 'Urdu',
  ratings: [],

  // step two
  releaseDate: null,

  creditHours: '',
  creditMinutes: '',
  durationHours: '',
  durationMinutes: '',

  //step three
  castCrew: null,

  // step four
  captions: [],

  // audio files
  audios: [],

  // step five
  movie: null,
  movieFromBucket: null,
  trailer: null,
  trailers: [],
  duration: 0
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_DATA:
      return { ...state, ...payload }
    case SET_CAST_CREW:
      return { ...state, ...payload }
    case ADD_TRAILER:
      return { ...state, trailers: [...state.trailers, { ...payload }] }
    case SET_VIDEO_ID:
      return { ...state, videoId: payload.videoId }
    case REMOVE_TRAILER:
      return {
        ...state,
        trailers: state.trailers.filter(({ id }) => id !== payload.id)
      }
    case ADD_TRAILER_TITLE:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id)
            return { ...trailer, title: payload.title }
          else return { ...trailer }
        })
      }

    case ADD_TRAILER_CAPTIONS:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              captions: [...trailer.captions, payload.caption]
            }
          else return { ...trailer }
        })
      }
    case ADD_TRAILER_AUDIO:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              audios: [...trailer.audios, payload.audio]
            }
          else return { ...trailer }
        })
      }
    case UPDATE_TRAILER_AUDIO:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              audios: trailer.audios.map((audio) => {
                if (audio.id === payload.audioId)
                  return { ...audio, ...payload.data }
                return { ...audio }
              })
            }
          else return { ...trailer }
        })
      }

    case ADD_TRAILER_VIDEO_FROM_BUCKET:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              videoFromBucket: payload.videoFromBucket,
              key: payload.videoFromBucket ? payload.videoFromBucket.Key : null
            }
          else return { ...trailer }
        })
      }

    case SET_TRAILER_CAPTION_LANGUAGE:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              captions: trailer.captions.map((caption) => {
                if (caption.id === payload.captionId)
                  return { ...caption, language: payload.language }
                return { ...caption }
              })
            }
          else return { ...trailer }
        })
      }

    case SET_TRAILER_CAPTION_URL:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              captions: trailer.captions.map((caption) => {
                if (caption.id === payload.captionId)
                  return { ...caption, url: payload.url }
                return { ...caption }
              })
            }
          else return { ...trailer }
        })
      }

    case REMOVE_TRAILER_CAPTIONS:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              captions: trailer.captions.filter(
                (caption) => caption.id !== payload.captionId
              )
            }
          else return { ...trailer }
        })
      }
    case REMOVE_TRAILER_AUDIO:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.trailerId)
            return {
              ...trailer,
              audios: trailer.audios.filter(
                (audio) => audio.id !== payload.audioId
              )
            }
          else return { ...trailer }
        })
      }

    case SET_TRAILER_DURATION:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id)
            return {
              ...trailer,
              duration: { ...trailer.duration, [payload.key]: payload.value }
            }
          else return { ...trailer }
        })
      }
    case ADD_TRAILER_THUMBNAIL:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id)
            return { ...trailer, [payload.thumbnailId]: payload.key }
          else return { ...trailer }
        })
      }

    case SET_TRAILER_TYPE:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id)
            return {
              ...trailer,
              type: payload.type,
              isMain: payload.type === 'TRAILER' ? trailer.isMain : false
            }
          else return { ...trailer }
        })
      }

    case SET_TRAILER:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id)
            return { ...trailer, key: payload.key, videoFromBucket: null }
          else return { ...trailer }
        })
      }
    case SET_MAIN_TRAILER:
      return {
        ...state,
        trailers: state.trailers.map((trailer) => {
          if (trailer.id === payload.id) return { ...trailer, isMain: true }
          else return { ...trailer, isMain: false }
        })
      }

    case ADD_AUDIO:
      return {
        ...state,
        audios: [...state.audios, payload]
      }

    case REMOVE_AUDIO:
      return {
        ...state,
        audios: state.audios.filter((audio) => {
          if (audio.id !== payload.id) return audio
        })
      }
    case UPDATE_AUDIO:
      return {
        ...state,
        audios: state.audios.map((audio) => {
          if (audio.id === payload.id) return { ...audio, ...payload.audio }
          return audio
        })
      }
    default:
      return state
  }
}

export default reducer
