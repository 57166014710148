import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import { ButtonComponent } from '../../components'
import { PrimaryColor } from '../../styles/colors'
import { enableDisableNextButton } from './_createMovie'

export const MovieFooter = ({ handleSteps, step, openLoader }) => {
  const history = useHistory()

  const [isNextDisabled, setIsNextDisabled] = useState(true)

  const toggleNextButton = (value) => setIsNextDisabled(value)

  const newMovie = useSelector((state) => state.newMovie)

  useEffect(() => {
    setIsNextDisabled(true)
    enableDisableNextButton(step, newMovie, toggleNextButton)
  }, [step, newMovie])

  const handleNext = () => {
    if (step === 8) openLoader()
    else handleSteps(step + 1)
  }

  return (
    <div className="create-movie-footer">
      <div className="create-movie-steps-button">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title="PREVIOUS"
          onClickHandler={() => handleSteps(step - 1)}
          disabled={step === 1 ? true : false}
          width={130}
        />
      </div>
      <div className="create-movie-steps-button">
        <ButtonComponent
          title="CANCEL"
          width={130}
          onClickHandler={() => history.push('/')}
        />
      </div>
      <div className="create-movie-steps-button">
        <ButtonComponent
          disabled={isNextDisabled}
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title={step === 8 ? 'SAVE' : 'NEXT'}
          onClickHandler={handleNext}
          width={130}
        />
      </div>
    </div>
  )
}
