import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Header, Plan, CreateButton, FormInput } from '../../components'
import './planList.css'
import { fetchPlans } from '../../redux/modules/plans'

export const PlanList = () => {
  const [loading, setLoading] = useState(true)
  const [searchPlan, setSearchPlan] = useState("")

  const plans = useSelector((state) => state.plans)

  const history = useHistory()

  const dispatch = useDispatch()

  const navigateToCreatePlan = () => history.push('/create-plan')

  useEffect(() => {
    ; (async () => {
      try {
        await dispatch(fetchPlans())

        setLoading(false)
      } catch (err) {
        console.log('Error in getting plans is: ', err)
      }
    })()
  }, [setLoading])

  console.log('plans', plans)

  return (
    <div className="dashboard-page">
      <Header title="Plan List" />
      <div className="plan-filter-container centered">
        <div className="search-container">
          <FormInput
            placeholder="Search"
            id="plan-search"
            name="search"
            value={searchPlan}
            onChangeHandler={(e) => { setSearchPlan(e.target.value.toLowerCase()); }}
          />
        </div>
      </div>
      <div className="plan-content">
        {loading ? (
          <div className="centered-loader-container">
            <CircularProgress size={30} color="black" />
          </div>
        ) : (
          <div className="plans-list-container">
            <CreateButton onClickHandler={navigateToCreatePlan} />
            {plans.filter((plan) => {
              if (searchPlan === "") {
                return plan;
              }
              const regex = new RegExp(`${searchPlan}`, 'gi');
              return plan.title.match(regex);
            }).map((plan) => (
              <Plan data={plan} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
