import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

import { InputBorder } from '../styles/colors'

const useStyles = makeStyles(() => ({
  textField: {
    border: `1px solid ${InputBorder}`,
    width: '100%',
    // paddingTop:1,
    paddingBottom: 1,
    borderRadius: 4,
    paddingLeft: 12,
    paddingRight: 12
  }
}))

export const FormInput = (props) => {
  const {
    placeholder,
    id,
    name,
    value = null,
    disabled = false,
    onChangeHandler = () => {},
    type = 'text',
    rows = 1,
    multiline = false
  } = props
  const classes = useStyles()
  return (
    <InputBase
      style={{ color: 'black' }}
      placeholder={placeholder}
      variant="outlined"
      id={id}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      value={value || ''}
      name={name}
      type={type}
      {...props}
      onChange={onChangeHandler}
      className={classes.textField}
    />
  )
}

export default FormInput
