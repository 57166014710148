import { makeStyles } from '@material-ui/core'

export const OrSeparator = () => {
  const classes = useStyles()
  return <div className={classes.container}>--OR--</div>
}

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gray',
    fontSize: 17
  }
}))
