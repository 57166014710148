import React, { useState } from 'react'
import {
  TablePagination,
  Switch,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { PROMO_CODE_TABLE_HEADER } from '../../../lib/constants'
import { useDispatch } from 'react-redux'
import { handleStatusUpdate } from '../../../redux/modules/promoCode'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

export const PromoCodeTable = ({ promoCodes }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const dispatch = useDispatch()

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, promoCodes.length - page * rowsPerPage)

  const classes = useStyles()

  const handleChangePage = (_, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleStatus = async (id, status) => {
    try {
      await dispatch(handleStatusUpdate(id, status))
    } catch (err) {
      console.log('Error in status update is: ', err)
      alert(
        err.message || 'Something went wrong in status update. Please try again'
      )
    }
  }

  return (
    <div className="promo-code-table">
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {PROMO_CODE_TABLE_HEADER.map((item) => (
                <TableCell
                  style={{ fontWeight: 'bold' }}
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
              <TableCell
                style={{ fontWeight: 'bold' }}
                align="center"
                className="countries-table-header">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? promoCodes.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : promoCodes
            ).map((promoCode) => {
              const {
                additional_attributes,
                user_id,
                code,
                promo_type: { name },
                video,
                plan,
                start_date,
                end_date,
                status,
                count,
                max_use_limit,
                max_use_limit_by_user,
                unlimited,
                country,
                percentage_off,
                id
              } = promoCode

              var additional_attributes_object = ''

              if (additional_attributes !== null) {
                additional_attributes_object = JSON.parse(additional_attributes)
              }

              return (
                <TableRow key={id}>
                  <TableCell align="left">{code}</TableCell>
                  <TableCell align="left">{percentage_off}</TableCell>
                  <TableCell align="left">{user_id || '-'}</TableCell>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="left">
                    {video ? video.title : '-'}
                  </TableCell>
                  <TableCell align="left">{plan ? plan.title : '-'}</TableCell>
                  <TableCell align="left">
                    {country ? country.country_code : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {start_date ? moment(start_date).format('MM-DD-YYYY') : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {end_date ? moment(end_date).format('MM-DD-YYYY') : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {unlimited ? 'unlimited' : max_use_limit}
                  </TableCell>
                  <TableCell align="left">{max_use_limit_by_user}</TableCell>
                  <TableCell align="left">{count || 0}</TableCell>
                  <TableCell align="left">
                    {additional_attributes !== null &&
                      additional_attributes_object['justification']}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      name="checkedA"
                      checked={status}
                      onChange={(_, checked) => {
                        handleStatus(id, checked)
                      }}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={promoCodes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </div>
  )
}
