import { makeStyles } from '@material-ui/core'
import { PrimaryColor } from '../../../../styles/colors'

export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: window.innerWidth - 250
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 2,
    border: '1px solid lightgray',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  closeIcon: {
    color: 'lightgray',
    fontSize: 20
  },
  platformsContainer: {
    height: 80,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceButton: {
    borderRadius: 4,
    margin: '0px 10px'
  },
  selectedDeviceButton: {
    borderRadius: 4,
    margin: '0px 10px',
    backgroundColor: PrimaryColor,
    '&:hover': {
      backgroundColor: PrimaryColor
    }
  },
  deviceIcon: {
    color: 'black',
    fontSize: 30
  },
  selectedDeviceIcon: {
    color: 'white',
    fontSize: 30
  },
  desktop: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  tablet: {
    height: 500,
    width: 738,
    position: 'relative'
  },
  mobile: {
    height: 680,
    width: 343,
    position: 'relative'
  },
  landingPagePreviewMainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }
}))
