import { withStyles } from '@material-ui/core/styles'
import { MenuItem, Select, InputBase } from '@material-ui/core'

const Input = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    position: 'relative',
    width: '100%',
    border: `1px solid #3C3C63`,
    fontSize: 16,
    borderRadius: 5,
    borderColor: '#3C3C63',
    padding: '7px 26px 7px 12px',
    color: 'black',

    '&:focus': {
      borderRadius: 5,
      borderColor: '#3C3C63',
      color: 'black',
      backgroundColor: 'transparent'
    }
  }
}))(InputBase)

export const SelectInput = ({
  data,
  value,
  changeHandler,
  multiple = false,
  name = '',
  disabled = false
}) => {
  return (
    <Select
      className="plan-list-filter"
      labelId="plan-list-filter"
      id="plan-list-filter"
      value={value}
      name={name}
      placeholder="check"
      multiple={multiple}
      onChange={changeHandler}
      disabled={disabled}
      input={<Input />}>
      {data.map((item) => (
        <MenuItem key={item.id} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  )
}
