import { API } from 'aws-amplify'

import { listKnowledgeBaseCategories } from '../graphql/queries'

export const fetchCategories = async (status) => {
  try {
    const {
      data: {
        listKnowledgeBaseCategories: { items }
      }
    } = await API.graphql({
      query: listKnowledgeBaseCategories,
      variables: { filter: { status: { eq: status } }, limit: 500 }
    })

    const helpCategories = items.map((helpCategory) => {
      return {
        id: helpCategory.id,
        name: helpCategory.title,
        value: helpCategory.id
      }
    })

    return helpCategories
  } catch (err) {
    throw err
  }
}
