import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {PricingCreateButton } from '../Pricing/_pricing'

import { ButtonComponent, Header } from '../../components'
import { PricingCard } from './_moviePricing'
import './moviePricing.css'

export const MoviePricing = () => {
  const allPricings = useSelector((state) => state.pricingsData)

  const [pricingsData, setPricingsData] = useState({})
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const navigateToCreatePricing = () => history.push(`/create-pricing/${videoId}`)

  const { videoId } = useParams()

  const pricingsCount = allPricings.length
  useEffect(() => {
    if (videoId && pricingsCount > 0) fetchData()
  }, [videoId, pricingsCount])

  const fetchData = async () => {
    setLoading(true)

    const pricingsByCountry = {}

    const filteredPricings = allPricings.filter(
      (item) => item.video_id === videoId
    )

    filteredPricings.map((item) => {
      const {
        country: { country_name }
      } = item

      pricingsByCountry[country_name] = pricingsByCountry[country_name]
        ? [...pricingsByCountry[country_name], item]
        : [item]
    })

    setPricingsData(pricingsByCountry)
    setLoading(false)
  }

  return (
    <div className="dashboard-page">
      {loading ? (
        <div className="centered-loader-container">
          <CircularProgress size={30} color="black" />
        </div>
      ) : (
        <div className="movie-pricing-page">
          <Header title={'Movie in countries'} />
          <div className="plan-content">
            <div className="plans-list-container">
            <PricingCreateButton onClickHandler={navigateToCreatePricing} />
              {Object.keys(pricingsData).map((title) => {
                if(title){
                  return <PricingCard title={title} item={pricingsData[title]} />
                }
              })}
              {Object.keys(pricingsData).length === 0 && (
                <div className="empty-container">
                  <p>Pricing is not available for this movie</p>
                  <ButtonComponent
                    title="CREATE PRICING"
                    onClickHandler={() => history.push(`/create-pricing/${videoId}`)}
                    width={200}
                    height={35}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
