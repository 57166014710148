import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { SourceBucket } from '../../classes/SourceBucket'

export const VideoAutoSelector = ({
  isProtected = false,
  handleChange,
  value
}) => {
  const sourceBucket = new SourceBucket()
  const [list, setList] = useState([])

  useEffect(() => {
    ;(async () => {
      setList([])
      try {
        let data = []
        if (isProtected) data = await sourceBucket.listProtectedContent()
        else data = await sourceBucket.listPublicContent()
        setList(data)
      } catch (err) {
        alert('Something went wrong please try again')
      }
    })()
  }, [isProtected, setList])

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      disablePortal
      id="video-Selector"
      options={list}
      renderInput={(params) => (
        <TextField {...params} label="Select from bucket" />
      )}
    />
  )
}
