import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Brightness1Rounded } from '@material-ui/icons'
import {
  makeStyles,
  Switch,
  Modal,
  CircularProgress,
  Tooltip,
  IconButton
} from '@material-ui/core'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import { updateMovie } from '../../backend/movies'
import { getSignedUrl } from '../../backend/video'
import { VideoPlayer } from '../../components'
import { LanguagesCode } from '../../lib/constants'

const useStyles = makeStyles(() => ({
  planAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  },
  progress: {
    color: 'white'
  }
}))

export const MovieCard = ({ movie }) => {
  const {
    id,
    title,
    thumbnail_lg,
    genres: { items },
    release_date,
    supported_captions: { items: captionItems },
    statusValue,
    trailers: { items: trailersList },
    video_by_quality: { items: videoByQualities },
    mp4_group_outputs_sync_status,
    non_mp4_group_outputs_sync_status
  } = movie

  const classes = useStyles()
  const history = useHistory()

  const [movieState, setMovieState] = useState(statusValue)
  const [captions, setCaptions] = useState([])
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)
  const [istrailerVisible, setIsTrailerVisible] = useState(false)
  const [selectedHlsUrl, setSelectedHlsUrl] = useState(null)
  const [selectedTrailer, setSelectedTrailer] = useState(null)

  useEffect(() => {
    ;(async () => {
      var processedCaptions = await Promise.all(
        captionItems.map(async ({ url, language }) => {
          const signedUrl = await Storage.get(url, {
            expires: 86400
          })
          return {
            kind: 'captions',
            src: signedUrl,
            label: language,
            srclang: LanguagesCode[language]
          }
        })
      )
      setCaptions(processedCaptions)
    })()
  }, [captionItems])

  const handleClose = () => setIsPreviewVisible(false)
  const closeTrailer = () => setIsTrailerVisible(false)

  const handleStatusChange = async (_, checked) => {
    const value = checked ? 'ENABLED' : 'DISABLED'
    setMovieState(value)
    try {
      await updateMovie({ status: checked, id, statusValue: value })
    } catch (err) {
      alert(err)
      setMovieState(checked ? 'DISABLED' : 'ENABLED')
    }
  }

  return (
    <div className="movie-card" key={id}>
      <div className="movie-card-header">
        {title}

        <div className="movie-card-actions">
          <Switch
            checked={movieState === 'ENABLED'}
            onChange={handleStatusChange}
            size="small"
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>

      <div className="move-card-thumbnail-container">
        <img src={thumbnail_lg} className="movie-card-thumbnail" alt={title} />
        <div className="release-date-container">
          <p className="release-date-title">Release Date</p>
          <p className="release-date-text">
            {moment(release_date).format('MM / DD / YYYY')}
          </p>
          <div className="flex">
            {non_mp4_group_outputs_sync_status !== 'COMPLETE' && (
              <Tooltip
                title={`HLS/Dash syncing ${
                  non_mp4_group_outputs_sync_status === 'ERROR'
                    ? 'failed'
                    : 'in progress'
                }`}>
                <IconButton size="small">
                  <Brightness1Rounded
                    style={{
                      fontSize: 14,
                      color:
                        non_mp4_group_outputs_sync_status === 'ERROR'
                          ? '#E60000'
                          : 'orange'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {mp4_group_outputs_sync_status !== 'COMPLETE' && (
              <Tooltip
                title={`MP4 syncing ${
                  mp4_group_outputs_sync_status === 'ERROR'
                    ? 'failed'
                    : 'in progress'
                }`}>
                <IconButton size="small">
                  <Brightness1Rounded
                    style={{
                      fontSize: 14,
                      color:
                        mp4_group_outputs_sync_status === 'ERROR'
                          ? '#E60000'
                          : 'orange'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="movie-genre-container">
        <p className="genre-title">Genre</p>
        <div className="flex-container">
          {items.map((item) => {
            return (
              <p key={item.genre.id} className="genre-text">
                {item.genre.title}
              </p>
            )
          })}
        </div>
      </div>
      <div className="divider">
        <p className="section-title">Video Qualities</p>
        <div className="movie-card-footer">
          {videoByQualities.map(
            ({ video_quality, cloudflare: { hls_url } }) => {
              return (
                <div className="movie-action-container">
                  <p
                    className={'trailer'}
                    role="presentation"
                    onClick={() => {
                      setIsPreviewVisible(true)
                      setSelectedHlsUrl(hls_url)
                    }}>
                    {video_quality.resolution}
                  </p>
                </div>
              )
            }
          )}
        </div>
      </div>

      <div className="divider">
        <p className="section-title">Video Trailers</p>
        <div className="movie-card-footer">
          {trailersList.map((trailer) => {
            return (
              <div className="movie-action-container">
                <p
                  className={'trailer'}
                  role="presentation"
                  onClick={() => {
                    setIsTrailerVisible(true)
                    setSelectedTrailer(trailer)
                  }}>
                  {trailer.title ? trailer.title : 'Trailer'}
                </p>
              </div>
            )
          })}
        </div>
      </div>

      <div className="movie-card-footer">
        <div className="movie-action-container">
          <EditIcon
            className={classes.planAction}
            role="presentation"
            onClick={() => history.push(`/edit-movie/${id}`)}
          />
        </div>
        <div className="movie-action-container">
          <DeleteIcon
            className={classes.planAction}
            role="presentation"
            onClick={() => {}}
          />
        </div>
      </div>

      <Modal
        open={isPreviewVisible && selectedHlsUrl}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={'video-modal'}>
        <PreviewVideo
          hls_url={selectedHlsUrl}
          captions={captions}
          close={handleClose}
          id={id}
          thumbnail_lg={thumbnail_lg}
        />
      </Modal>

      <Modal
        open={istrailerVisible && selectedTrailer}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="trailer-video-modal"
        aria-describedby="trailer-video-modal"
        className={'video-modal'}>
        <PreViewTrailer
          trailer={selectedTrailer}
          close={closeTrailer}
          id={id}
        />
      </Modal>
    </div>
  )
}

const PreviewVideo = ({ close, thumbnail_lg, captions, hls_url }) => {
  const {
    attributes: { sub: userId }
  } = useSelector((state) => state.user)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (userId) {
      ;(async () => {
        try {
          const { url } = await getSignedUrl(userId, hls_url)
          setUrl(url)
        } catch (err) {
          alert('Something went wrong please try again')
          close()
        }
      })()
    }
  }, [userId, hls_url, setUrl])

  return (
    <div className={'video-container'}>
      <Loader isLoading={!url} />
      {url && (
        <VideoPlayer
          autoplay={false}
          controls={true}
          preload={'none'}
          poster={thumbnail_lg}
          showCloseButton={true}
          close={close}
          tracks={captions}
          sources={[
            {
              src: url,
              type: 'application/x-mpegURL',
              overrideNative: true
            }
          ]}
        />
      )}
    </div>
  )
}

const PreViewTrailer = ({ close, id, trailer }) => {
  const [videoOptions, setVideoOptions] = useState(null)
  const { hls_url, thumbnail_lg } = trailer

  useEffect(() => {
    setVideoOptions({
      autoplay: false,
      controls: true,
      preload: 'none',
      poster: thumbnail_lg,
      sources: [
        {
          src: hls_url,
          type: 'application/x-mpegURL',
          overrideNative: true
        }
      ]
    })
  }, [id, setVideoOptions, hls_url, thumbnail_lg])

  return (
    <div className={'video-container'}>
      <Loader isLoading={!videoOptions} />
      {videoOptions && thumbnail_lg && (
        <VideoPlayer {...videoOptions} showCloseButton={true} close={close} />
      )}
    </div>
  )
}

const Loader = ({ isLoading }) => {
  const classes = useStyles()
  if (isLoading)
    return (
      <div className={'video-modal-loader'}>
        <CircularProgress className={classes.progress} />
      </div>
    )
  else return null
}
