import { API } from 'aws-amplify'

import { listCastCrews } from '../../graphql/queries'
import {
  createCastCrew,
  deleteCastCrew,
  updateCastCrew
} from '../../graphql/mutations'

// constants
const SET_CAST = 'SET_CAST'

// actions

// thunks

// update cast
export const updateCast = (payload) => async (dispatch, getState) => {
  try {
    const { data } = await API.graphql({
      query: updateCastCrew,
      variables: { input: payload }
    })

    const updatedCast = {
      name: `${data.updateCastCrew.first_name} ${data.updateCastCrew.last_name}`,
      value: data.updateCastCrew.id,
      ...data.updateCastCrew
    }

    let cast = [...getState().cast]
    const index = cast.findIndex((castItem) => castItem.id === payload.id)
    if (index > -1) cast.splice(index, 1, { ...updatedCast })

    dispatch({
      type: SET_CAST,
      payload: [...cast]
    })
  } catch (err) {
    throw err
  }
}

// remove cast
export const removeCast = (id) => async (dispatch, getState) => {
  try {
    await API.graphql({
      query: deleteCastCrew,
      variables: { input: { id } }
    })

    let cast = [...getState().cast]
    const index = cast.findIndex((castItem) => castItem.id === id)
    if (index > -1) cast.splice(index, 1)

    dispatch({
      type: SET_CAST,
      payload: [...cast]
    })
  } catch (err) {
    throw err
  }
}

// add cast
export const addCast = (payload) => async (dispatch, getState) => {
  try {
    const { data } = await API.graphql({
      query: createCastCrew,
      variables: { input: payload }
    })

    const newCast = {
      name: `${data.createCastCrew.first_name} ${data.createCastCrew.last_name}`,
      value: data.createCastCrew.id,
      ...data.createCastCrew
    }

    dispatch({
      type: SET_CAST,
      payload: [{ ...newCast }, ...getState().cast]
    })
  } catch (err) {
    throw err
  }
}

// fetch cast crew list
export const fetchCast = () => async (dispatch) => {
  try {
    const {
      data: {
        listCastCrews: { items }
      }
    } = await API.graphql({ query: listCastCrews })

    const cast = items.map((item) => {
      return {
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        value: item.id,
        ...item
      }
    })

    dispatch({
      type: SET_CAST,
      payload: cast
    })
  } catch (err) {
    throw err
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CAST:
      return payload

    default:
      return state
  }
}

export default reducer
