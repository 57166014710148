import React, { useEffect, useState, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Modal from '@material-ui/core/Modal'
import { CircularProgress } from '@material-ui/core'

import {
  ButtonComponent,
  Header,
  SelectInput,
  PlansTable,
  PlanItem
} from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { fetchCountries } from '../../backend/countries'
import { createPricing, fetchPlans } from '../../backend/pricings'
import { validatePricingData } from '../../lib/util'
import './createPricing.css'

export const CreatePricing = () => {
  // new pricing data
  const allPricings = useSelector((state) => state.pricingsData)
  const [
    { movie, country, selectedPlans, countryCode },
    setPricing
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    movie: 'Select Movie',
    country: 'Select Country',
    selectedPlans: [],
    countryCode: ''
  })


  // list of data fetched from backend
  const [{ countriesList, videosList, allPlans }, setStaticData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      countriesList: [
        { id: '-1', name: 'Select Country', value: 'Select Country' }
      ],
      videosList: [{ id: '-1', name: 'Select Movie', value: 'Select Movie' }],
      allPlans: []
    }
  )

  const [isPlansModalVisible, setIsPlanModalVisible] = useState(false)
  const [fetchingPlans, setFetchingPlans] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)

  const history = useHistory()
  const { videoId } = useParams()

  const handleCountry = (event, child) => {
    const code = child.props.children.split('-')

    setPricing({
      countryCode: code[code.length - 1].trim(),
      country: event.target.value
    })
  }

  const closePlansModal = () => setIsPlanModalVisible(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (movie !== 'Select Movie') fetchPlansData()
  }, [movie])

  const fetchPlansData = () => {
    setFetchingPlans(true)

    fetchPlans(movie).then((res) => {
      let combinePlans = []

      res.map((item) => {
        const { plansByQuality, videoByQualityId } = item
        const plansWithVideoByQuality = plansByQuality.map((plan) => {
          return { ...plan, videoByQualityId }
        })
        combinePlans = [...combinePlans, ...plansWithVideoByQuality]
      })

      setStaticData({ allPlans: combinePlans })
      setPricing({
        selectedPlans: [{ ...combinePlans[0], price: 0, status: true }]
      })
      setFetchingPlans(false)
    })
  }

  const fetchData = async () => {
    try {
      const _countries = await fetchCountries(true)
      const _exists = await fetchingDemo();

      const video = _exists[Object.keys(_exists)[0]][0]?.video;
      
      await setStaticData({ videosList: [{id: video.id, name: video.title, value: video.id}] })
      setPricing({ movie: video.id })

      let filter = await _countries.filter(val => !Object.keys(_exists).includes(val.value));
      await setStaticData({ countriesList: [...countriesList, ...filter] })
    } catch (err) {
      console.log('Error in getting data is: ', err)
    }
  }

  const fetchingDemo = async () => {
    const pricingsByCountry = {}

    const filteredPricings = await allPricings.filter(
      (item) => {
        return item.video_id === videoId
      }
    )

    await filteredPricings.map((item) => {
      const {
        country: { country_code }
      } = item

      pricingsByCountry[country_code] = pricingsByCountry[country_code]
        ? [...pricingsByCountry[country_code], item]
        : [item]
    })
    return pricingsByCountry;
  }

  const addPlans = (plans) => {
    setPricing({
      selectedPlans: plans
    })
  }

  const removePlan = (id) => {
    const selectedPlanIndex = selectedPlans.findIndex((item) => item.id === id)

    let plans = [...selectedPlans]
    plans.splice(selectedPlanIndex, 1)

    setPricing({ selectedPlans: plans })
  }

  const handlePlanInput = (index, key, value) => {
    let plans = [...selectedPlans]
    plans[index][key] = value

    setPricing({ selectedPlans: plans })
  }

  const handlePriceAddition = async () => {
    setError(null)

    const validation = validatePricingData(movie, country, selectedPlans)

    if (validation) {
      setError(validation)
      return
    }

    setProcessing(true)

    try {
      await createPricing(movie, country, selectedPlans)

      history.push('/pricing')
    } catch (err) {
      console.log('Error in pricing addition is: ', err)

      setError('Something went wrong please try again')
      setProcessing(false)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title="Add Pricings" />
      <div className="create-pricing-container">
        <div className="create-pricing-form">
          <div className="create-pricing-input-container">
            <div className="create-pricing-input">
              <SelectInput
                data={videosList}
                value={movie}
                changeHandler={(event) =>
                  setPricing({ movie: event.target.value })
                }
              />
            </div>
            <div className="create-pricing-input">
              <SelectInput
                data={countriesList}
                value={country}
                changeHandler={handleCountry}
              />
            </div>
          </div>
          {fetchingPlans ? (
            <div className="create-pricing-fetching-plans">
              <CircularProgress size={20} color="gray" />
              Fetching plans
            </div>
          ) : (
            <div>
              {allPlans.length === 0 && (
                <div className="create-pricing-fetching-plans">
                  No plans are associated with this movie
                </div>
              )}

              {selectedPlans.map((item, index) => {
                return (
                  <PlanItem
                    removePlan={removePlan}
                    item={item}
                    countryCode={countryCode}
                    index={index}
                    handlePlanInput={handlePlanInput}
                  />
                )
              })}

              {allPlans.length > 0 && (
                <div className="add-plan-container">
                  <ButtonComponent
                    title="ADD PLAN"
                    backgroundColor={'white'}
                    borderColor={'black'}
                    onClickHandler={() => setIsPlanModalVisible(true)}
                    width={130}
                    height={30}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {error && <p className="create-pricing-error">{error}</p>}
      <div className="create-pricing-footer">
        <div className="create-pricing-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={() => history.replace('/pricing')}
            width={130}
          />
        </div>
        <div className="create-pricing-footer-button">
          <ButtonComponent
            loading={processing}
            title="SAVE"
            onClickHandler={handlePriceAddition}
            width={130}
          />
        </div>
      </div>

      <Modal
        open={isPlansModalVisible}
        onClose={closePlansModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centered-container">
        <PlansTable
          addPlans={addPlans}
          allPlans={allPlans}
          selectedPlans={selectedPlans}
          closePlansModal={closePlansModal}
        />
      </Modal>
    </div>
  )
}
