import { combineReducers } from 'redux'

import user from './modules/user'
import plans from './modules/plans'
import newMovie from './modules/newMovie'
import genres from './modules/genres'
import castCrewTypes from './modules/castCrewTypes'
import cast from './modules/cast'
import pricingsData from './modules/pricings'
import videoQualities from './modules/videoQualities'
import deviceTypes from './modules/deviceTypes'
import signedUrls from './modules/signedUrls'
import helpCategory from './modules/helpCategory'
import helpQNA from './modules/helpQNA'
import promoCode from './modules/promoCode'
import contentRatings from './modules/contentRating'
import alertHandler from './modules/alertHandler'
import { landingPage } from './modules/landingPage'

const reducers = combineReducers({
  user,
  plans,
  newMovie,
  genres,
  cast,
  pricingsData,
  videoQualities,
  deviceTypes,
  signedUrls,
  promoCode,
  contentRatings,
  alertHandler,
  castCrewTypes,
  helpCategory,
  helpQNA,
  landingPage
})

export const rootReducer = (state, action) => {
  // Clear persistant storage on logout
  // Dispatch RESET_APP on logout
  if (action.type === 'RESET_APP') {
    state = {}
  }

  return reducers(state, action)
}
