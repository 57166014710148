import { API, Auth } from 'aws-amplify'

export const createOrder = async (
  userId,
  countryCode,
  videoId,
  videoPlanId,
  justification
) => {
  const authToken = await (await Auth.currentSession())
    .getIdToken()
    .getJwtToken()

  const params = {
    body: {
      userId,
      countryCode,
      videoId,
      videoPlanId,
      justification
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('admin', '/create-order', params)
}
