import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Header, ButtonComponent } from '../../../../components'
import { PrimaryColor } from '../../../../styles/colors'
import { TrailerUploadForm } from './components/trailerUploadForm'
import { addTrailer } from '../../../../redux/modules/newMovie'

export const TrailersAndClips = () => {
  const { trailers } = useSelector((state) => state.newMovie)

  const dispatch = useDispatch()

  const handleTrailerAddition = () => {
    dispatch(addTrailer())
  }
  return (
    <div>
      <Header title="Upload Trailers/Clips/Songs" />
      <div className="create-movie-step">
        <div className="cast-crew-add-button">
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add trailer"
            height={35}
            onClickHandler={handleTrailerAddition}
            width={140}
          />
        </div>

        {trailers
          .filter((item) => item.type !== 'CONTENT_PREVIEW')
          .map((trailer, index) => (
            <TrailerUploadForm
              key={trailer.id}
              trailer={trailer}
              index={index}
            />
          ))}
      </div>
    </div>
  )
}
