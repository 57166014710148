import React, { useReducer } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  IconButton,
  makeStyles,
  Chip,
  LinearProgress,
  Switch
} from '@material-ui/core'
import ReactDropzone from 'react-dropzone'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import { v4 as uuid4 } from 'uuid'
import AWS from 'aws-sdk'

import { LanguageList, LanguagesCode } from '../../lib/constants'
import { ButtonComponent, SelectInput } from '../../components'
import { PrimaryColor } from '../../styles/colors'
import { VideoAutoSelector } from '../videoAutoSelector'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
})

export const Audioform = ({
  audio,
  remove,
  isTrailer = false,
  videoId,
  update
}) => {
  const classes = useStyles()
  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )

  const handleRemoveAudio = () => remove(audio.id)

  const handleLanguage = ({ target: { value: language } }) => {
    update(audio.id, {
      language,
      languageCode: LanguagesCode[language].toUpperCase()
    })
  }

  const promisifyUpload = async (file, fileName) => {
    const bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: process.env.REACT_APP_REGION
    })
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName
    }

    await new Promise((resolve, reject) => {
      bucket
        .putObject(params)
        .on('httpUploadProgress', (progress) => {
          const { loaded, total } = progress

          setUploadData({
            uploadProgress: Math.floor((loaded * 100) / total)
          })
        })
        .send((err) => {
          if (err) reject(err)
          resolve()
        })
    })
  }

  const handleUpload = async (acceptedFiles) => {
    if (uploading) return
    setUploadData({ uploading: true })

    const file = acceptedFiles[0]
    let fileName = uuid4()
    let fileType = file.name.split('.').pop()
    const key = `${
      isTrailer ? 'public' : 'protected'
    }/${videoId}/${fileName}.${fileType}`

    try {
      await promisifyUpload(file, key)
      update(audio.id, { srcAudio: key })

      setUploadData({ uploading: false, uploadProgress: 0 })
    } catch (error) {
      setUploadData({ uploading: false, uploadProgress: 0 })
    }
  }

  const handleOrignal = ({ target: { checked } }) => {
    update(audio.id, { isOriginal: checked })
  }

  const handleFromBucket = (value) => {
    update(audio.id, { srcAudio: value ? value.Key : '' })
  }

  return (
    <div key={audio.id} className={classes.mainAudioContainer}>
      <div className={classes.detail}>
        <div className={classes.selector}>
          <SelectInput
            data={LanguageList}
            multiple={false}
            value={audio.language}
            changeHandler={handleLanguage}
          />
        </div>
        <div style={{ paddingLeft: 10 }} className="cast-card-toggle-container">
          Is Orignal
          <Switch
            checked={audio.isOriginal}
            onChange={handleOrignal}
            size="small"
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <div style={{ flex: 1, padding: '0px 20px' }}>
          <div className="caption-upload-button">
            {uploading && (
              <div className={'caption-upload-progress'}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </div>
            )}
            {audio.srcAudio && (
              <div className={'chip-container'}>
                {' '}
                <Chip label="Uploaded" />{' '}
              </div>
            )}
            <ReactDropzone onDrop={handleUpload} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} accept="audio/*, .mov" />
                    <ButtonComponent
                      borderColor={PrimaryColor}
                      titleColor={PrimaryColor}
                      title="ADD"
                      onClickHandler={() => {}}
                      width={90}
                      height={30}
                      icon={
                        <BackupOutlinedIcon
                          style={{ fontSize: 24, paddingRight: 10 }}
                        />
                      }
                    />
                  </div>
                </section>
              )}
            </ReactDropzone>

            {/* audio selector from drop down */}
            <div style={{ paddingLeft: 10, width: 250 }}>
              <VideoAutoSelector
                isProtected={!isTrailer}
                value={audio.srcAudio}
                handleChange={handleFromBucket}
              />
            </div>
          </div>
        </div>
      </div>
      <IconButton onClick={handleRemoveAudio} size="small">
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainAudioContainer: {
    border: '1px solid gray',
    borderRadius: 3,
    padding: 10,
    margin: 10,
    display: 'flex',
    width: '100%'
  },
  detail: {
    flex: 1,
    display: 'flex'
  },
  selector: {
    width: 200
  }
}))
