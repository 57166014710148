import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import {
  ButtonComponent,
  FormInput,
  Header,
  SelectInput
} from '../../components'
import { PROMO_CODES_FILTERS } from '../../lib/constants'
import { fetchPromoCodes } from '../../redux/modules/promoCode'
import { fetchPromoTypes } from '../../backend/promoCodeTypes'
import { PromoCodeTable } from './components'
import './promoCodeList.css'
import { PrimaryColor } from '../../styles/colors'

export const PromoCodeList = () => {
  const [filter, setFilter] = useState('all')
  const [typeFilter, setTypeFilter] = useState(
    '5a566523-94922-4de0-98c6-b61e5652e32c'
  )
  const [promoCodes, setPromoCodes] = useState([])
  const [promoCodesClone, setPromoCodesClone] = useState([])

  // list of data fetched from backend
  const [{ promoCodeTypes }, setStaticData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      promoCodeTypes: [{ id: '-1', name: '-1', value: '-1' }]
    }
  )
  const fetchData = async () => {
    try {
      const _promoCodeTypes = await fetchPromoTypes()

      setStaticData({ promoCodeTypes: [..._promoCodeTypes] })
    } catch (err) {
      console.log('Error in getting data is: ', err)
    }
  }

  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')

  const { list } = useSelector((state) => state.promoCode)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await dispatch(fetchPromoCodes(typeFilter))
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.log('Error in fetching promo codes is: ', err)
        alert(err.message || 'something went wrong in fetching promo codes.')
      }
    })()
  }, [dispatch, typeFilter])

  useEffect(() => {
    setPromoCodes(list)
    setPromoCodesClone(list)
    fetchData()
  }, [list, setPromoCodes, setPromoCodesClone])

  const handleFilter = ({ target: { value } }) => {
    setFilter(value)

    if (value === 'all') setPromoCodes(promoCodesClone)
    else if (value === 'enabled') {
      const filteredPromoCodes = promoCodesClone.filter((item) => item.status)
      setPromoCodes(filteredPromoCodes)
    } else {
      const filteredPromoCodes = promoCodesClone.filter((item) => !item.status)
      setPromoCodes(filteredPromoCodes)
    }
  }

  const handleTypeFilter = ({ target: { value } }) => {
    setTypeFilter(value)
    setPromoCodes(promoCodesClone)
  }

  const handleSearch = ({ target: { value } }) => {
    setSearchText(value)

    if (value.length < 2) setPromoCodes(promoCodesClone)
    else {
      let filteredPromoCodes = promoCodesClone.filter(
        (item) =>
          item.code.toLowerCase().includes(value.toLowerCase()) ||
          (item.country_code &&
            item.country_code.toLowerCase().includes(value.toLowerCase())) ||
          item.promo_type.name.toLowerCase().includes(value.toLowerCase()) ||
          (item.video &&
            item.video.title.toLowerCase().includes(value.toLowerCase())) ||
          (item.plan &&
            item.plan.title.toLowerCase().includes(value.toLowerCase()))
      )

      setPromoCodes(filteredPromoCodes)
    }
  }

  const navigateToCreatePromoCode = () => history.push('/create-promo-code')

  return (
    <div className="dashboard-page">
      <Header title="Promo Codes" />
      <div className="promo-code-list-container">
        <div className="list-header">
          <div className="promo-code-search-container">
            <FormInput
              value={searchText}
              placeholder="Search"
              id="plan-search"
              name="search"
              onChangeHandler={handleSearch}
            />
          </div>
          <div className="promo-code-dropdown-filter">
            <SelectInput
              data={PROMO_CODES_FILTERS}
              value={filter}
              changeHandler={handleFilter}
            />
          </div>
          <div className="promo-code-dropdown-filter">
            <SelectInput
              data={promoCodeTypes}
              value={typeFilter}
              changeHandler={handleTypeFilter}
            />
          </div>
          <div className="add-promo-code-button">
            <ButtonComponent
              title="Create Promo Code"
              onClickHandler={navigateToCreatePromoCode}
              backgroundColor={PrimaryColor}
              titleColor={'white'}
              borderColor={PrimaryColor}
              width={200}
              height={34}
            />
          </div>
        </div>

        {loading && (
          <div className="loading-container">
            <CircularProgress size={30} className="countries-loader" />
          </div>
        )}

        {!loading && promoCodes.length > 0 && (
          <PromoCodeTable promoCodes={promoCodes} />
        )}
        {!loading && promoCodes.length === 0 && (
          <div className="loading-container">No Promo Code available</div>
        )}
      </div>
    </div>
  )
}
