import React, { useEffect, useReducer, useState } from 'react'
import ReactDropzone from 'react-dropzone'
import { v4 as uuid4 } from 'uuid'
import { IconButton, LinearProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Storage } from 'aws-amplify'

import './imageDropZone.css'
import config from '../../config'

export const ImageDropZone = ({
  onChange,
  imageUrl,
  title,
  videoId = null,
  castId = null
}) => {
  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (imageUrl) {
      if (!videoId) {
        ;(async () => {
          const response = await Storage.get(imageUrl)
          setUrl(response)
        })()
      } else setUrl(imageUrl)
    }
  }, [imageUrl, setUrl])

  const uploadThumbnail = async (acceptedFiles) => {
    if (uploading) return

    setUploadData({ uploading: true })

    const file = acceptedFiles[0]
    const fileParts = file.name.split('.')
    const fileName = uuid4()
    let fileType = fileParts[fileParts.length - 1]

    if (!['png', 'gif', 'jpeg', 'jpg'].includes(fileType)) fileType = ''

    try {
      const { key } = await Storage.put(
        videoId
          ? `${videoId}/${config.thumbnailsFolder}/${fileName}.${fileType}`
          : `${config.castAndCrewFolder}/${castId}/${fileName}.${fileType}`,
        file,
        {
          progressCallback(progress) {
            const { loaded, total } = progress

            setUploadData({
              uploadProgress: Math.floor((loaded * 100) / total)
            })
          }
        }
      )

      const signedUrl = await Storage.get(key)
      const url = signedUrl.split('?')[0]
      setUrl(url)
      setUploadData({ uploadProgress: 0, uploading: false })
      onChange(url)
    } catch (error) {
      setUploadData({ uploadProgress: 0, uploading: false })

      alert('Something went wrong please try again')
    }
  }

  const deleteImage = () => {
    setUrl(null)
    onChange(null)
  }

  return (
    <div className="dropzone-container">
      {url ? (
        <div className="image-container-dropzone">
          <img src={url} className="dropzone-image" alt={'cast image'} />
          <div className="dropzone-action-container">
            <IconButton size="small" onClick={deleteImage}>
              <DeleteIcon style={{ fontSize: 20, color: '#969696' }} />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="image-dropzone">
          <ReactDropzone onDrop={uploadThumbnail} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input
                    {...getInputProps()}
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  />
                  <div className="dropzone-title-container">
                    <h5>{title}</h5>
                  </div>
                </div>
              </section>
            )}
          </ReactDropzone>
        </div>
      )}
      {uploading && (
        <div className="dropzone-progressbar">
          <LinearProgress variant="determinate" value={uploadProgress} />
        </div>
      )}
    </div>
  )
}
