import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ButtonComponent,
  FormInput,
  Header,
  SelectInput
} from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { CountriesList, Languages, Currencies } from '../../lib/constants'

export const CreateCountry = () => {
  const [country, setCountry] = useState('Select Country')
  const [language, setLanguage] = useState('Select Language')
  const [currency, setCurrency] = useState('Select Currency')

  const history = useHistory()

  const handleCountry = (event) => setCountry(event.target.value)

  const handleLanguage = (event) => setLanguage(event.target.value)

  const handleCurrency = (event) => setCurrency(event.target.value)

  return (
    <div className="dashboard-page">
      <Header title="Create Country" />

      <div className="create-plan-container">
        <div className="create-plan-input-container">
          <div className="create-plan-input">
            <SelectInput
              data={CountriesList}
              value={country}
              changeHandler={handleCountry}
            />
          </div>
          <div className="create-plan-input">
            <FormInput placeholder="Country Code" id="plan-id" name="planId" />
          </div>
          <div className="create-plan-input">
            <SelectInput
              data={Languages}
              value={language}
              changeHandler={handleLanguage}
            />
          </div>
          <div className="create-plan-input">
            <SelectInput
              data={Currencies}
              value={currency}
              changeHandler={handleCurrency}
            />
          </div>
        </div>
      </div>
      <div className="create-plan-footer">
        <div className="create-plan-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={() => history.replace('/countries')}
            width={130}
          />
        </div>
        <div className="create-plan-footer-button">
          <ButtonComponent title="SAVE" onClickHandler={() => {}} width={130} />
        </div>
      </div>
    </div>
  )
}
