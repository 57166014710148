import React, { useEffect, useState } from 'react'
import { Divider, makeStyles, Modal, Switch } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { showAlert } from '../../../redux/modules/alertHandler'
import { updateMovieCastCrew } from '../../../backend/movies'
import { ALERT_TYPES } from '../../../lib/constants'
import { PrimaryColor, SecondaryButton } from '../../../styles/colors'
import {
  AutoFillField,
  ButtonComponent,
  SelectInput
} from '../../../components'
import { useCastCrewTypes } from '../../../hooks/useCastCrewTypes'
import { useCast } from '../../../hooks/useCast'

export const CastAndCrew = ({ cast_crew, id, updateLocally }) => {
  const classes = useStyles()
  const [castCrew, setCastCrew] = useState({})
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [addCastModal, setAddCastModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const types = {}
    cast_crew.items.forEach((item) => {
      if (item.cast_crew) {
        if (types[item.cast_crew_type.id])
          types[item.cast_crew_type.id].push({
            ...item,
            cast_crew: {
              ...item.cast_crew,
              name: `${item.cast_crew.first_name} ${item.cast_crew.last_name}`,
              value: item.cast_crew.id
            }
          })
        else
          types[item.cast_crew_type.id] = [
            {
              ...item,
              cast_crew: {
                ...item.cast_crew,
                name: `${item.cast_crew.first_name} ${item.cast_crew.last_name}`,
                value: item.cast_crew.id
              }
            }
          ]
      }
    })

    setCastCrew(types)
  }, [cast_crew, setCastCrew])

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const toggleDisplay = async (typeId, castCrewId, value) => {
    let updatedCastCrew = { ...castCrew }

    updatedCastCrew[typeId] = updatedCastCrew[typeId].map((item) => {
      if (item.cast_crew.id === castCrewId) {
        return { ...item, display: value }
      }
      return { ...item }
    })
    setCastCrew(updatedCastCrew)
    setUpdated(true)
  }

  const handleUpdate = async () => {
    try {
      setLoading(true)
      await updateMovieCastCrew(cast_crew, castCrew, id)
      handleAlert('Updated data successfuly', ALERT_TYPES.SUCCESS)
      updateLocally()
      setLoading(false)
      setUpdated(false)
    } catch (err) {
      handleAlert('Something went wrong please try again.', ALERT_TYPES.ERROR)
      setLoading(false)
    }
  }

  const closeModal = () => setAddCastModal(false)

  const handleAddition = (casts, cast_crew_type) => {
    const payload = casts.map((item) => {
      return {
        cast_crew: item,
        status: true,
        display: true,
        id: cast_crew_type.id,
        cast_crew_type: { ...cast_crew_type, title: cast_crew_type.name }
      }
    })
    if (payload.length === 0 && castCrew[cast_crew_type.id]) {
      let updatedCastCrew = { ...castCrew }
      delete updatedCastCrew[cast_crew_type.id]
      setCastCrew(updatedCastCrew)
    } else if (payload.length > 0) {
      setCastCrew({ ...castCrew, [cast_crew_type.id]: [...payload] })
    }

    setUpdated(true)
    closeModal()
  }

  return (
    <div className={classes.mainContainer}>
      <div className="cast-crew-add-button">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title="Add Cast"
          onClickHandler={() => setAddCastModal(true)}
          width={200}
        />
      </div>
      <div className={classes.content}>
        {Object.keys(castCrew).map((id) => {
          const item = castCrew[id]
          return (
            <div className={classes.section}>
              <div className={classes.titleContainer}>
                <p className={classes.title}>{item[0].cast_crew_type.title}</p>
                <div className={classes.dividerContainer}>
                  <Divider className={classes.divider} />
                </div>
              </div>

              {item.map((castCrewItem) => {
                if (!castCrewItem.cast_crew) return
                else {
                  const name = `${castCrewItem.cast_crew.first_name} ${castCrewItem.cast_crew.last_name}`
                  return (
                    <div key={cast_crew.id} className="cast-card-container">
                      <div className="cast-card-name">{name}</div>
                      <div className="cast-card-toggle-container">
                        Display
                        <Switch
                          checked={castCrewItem.display}
                          onChange={(event) =>
                            toggleDisplay(
                              castCrewItem.cast_crew_type.id,
                              castCrewItem.cast_crew.id,
                              event.target.checked
                            )
                          }
                          size="small"
                          color="primary"
                          name="checkedB"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          )
        })}
      </div>

      <div className={classes.footer}>
        <ButtonComponent
          title="UPDATE"
          disabled={!updated}
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          loading={loading}
          onClickHandler={handleUpdate}
          width={150}
          height={40}
        />
      </div>

      <Modal
        open={addCastModal}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={'cast-modal'}>
        <AddCastCrewModal
          castCrew={castCrew}
          close={closeModal}
          handleAddition={handleAddition}
        />
      </Modal>
    </div>
  )
}

const AddCastCrewModal = ({ close, handleAddition, castCrew }) => {
  const { castCrewTypes } = useCastCrewTypes()
  const [selectedType, setSelectedType] = useState('')
  const [selectedCastCrew, setSelectedCastCrew] = useState([])
  const { cast } = useCast()

  useEffect(() => {
    if (!castCrew[selectedType]) {
      setSelectedCastCrew(null)

      setTimeout(() => {
        setSelectedCastCrew([])
      }, 100)
    } else if (selectedType !== '') {
      const items = castCrew[selectedType]
      const ids = items.map((item) => item.cast_crew)
      setSelectedCastCrew(null)
      setTimeout(() => {
        setSelectedCastCrew(ids)
      }, 100)
    }
  }, [selectedType, castCrew, setSelectedCastCrew])

  const onChangeHandler = ({ target: { value } }) => setSelectedType(value)
  const handleCastSelection = (value) => setSelectedCastCrew(value)

  const addCastCrew = () => {
    const type = castCrewTypes.filter((item) => item.id === selectedType)[0]
    handleAddition(selectedCastCrew, type)
  }

  return (
    <div className="add-cast-conatiner">
      <h2 className="add-cast-modal-title">Add Cast</h2>
      <div className="add-cast-item-container">
        <p style={{ paddingBottom: 5, margin: 0 }}>Select Type</p>
        <SelectInput
          name={'castTypes'}
          data={castCrewTypes}
          value={selectedType}
          changeHandler={onChangeHandler}
        />
      </div>
      <div className="add-cast-item-container">
        <div style={{ width: 350, minHeight: 50 }}>
          {selectedCastCrew && (
            <AutoFillField
              check={2}
              label={'Select Cast'}
              data={cast}
              defaultValue={selectedCastCrew}
              handleCastSelection={handleCastSelection}
            />
          )}
        </div>
      </div>

      <div className="add-cast-item-container">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={'white'}
          backgroundColor={PrimaryColor}
          title="Add"
          onClickHandler={addCastCrew}
          width={'100%'}
          height={36}
          disabled={!selectedType}
        />
      </div>

      <div className="add-cast-item-container">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title="Cancel"
          onClickHandler={close}
          width={'100%'}
          height={36}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    width: '100%'
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  section: {
    flex: 1,
    width: '100%',
    float: 'left'
  },
  titleContainer: {
    display: 'flex',
    flex: 1
  },
  dividerContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px'
  },
  divider: {
    width: '100%'
  }
}))
