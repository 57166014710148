import { API } from 'aws-amplify'

import { listCountries } from '../graphql/queries'

export const fetchCountries = async (status) => {
  try {
    const {
      data: {
        listCountries: { items }
      }
    } = await API.graphql({
      query: listCountries,
      variables: { filter: { status: { eq: status } }, limit: 500 }
    })

    const countries = items.map((country) => {
      return {
        id: country.id,
        name: `${country.country_name} - ${country.currency}`,
        value: country.country_code
      }
    })

    return countries
  } catch (err) {
    throw err
  }
}
