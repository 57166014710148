import { API } from 'aws-amplify'
import { listCastCrewTypes } from '../../graphql/queries'

// constants
const SET_CAST_CREW_TYPES = 'SET_CAST_CREW_TYPES'

// actions

// thunks
export const fetchCastCrewTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listCastCrewTypes: { items }
        }
      } = await API.graphql({ query: listCastCrewTypes })

      const types = items
        .map((item) => {
          return { id: item.id, name: item.title, value: item.id }
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      dispatch({
        type: SET_CAST_CREW_TYPES,
        payload: types
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CAST_CREW_TYPES:
      return payload

    default:
      return state
  }
}

export default reducer
