export const validatePlan = (
  title,
  selectedQuality,
  selectedDevices = null
) => {
  if (title.trim() === '') return 'Please enter title of plan'

  if (selectedQuality === 'Select Quality') return 'Please select the quality'

  if (selectedDevices)
    if (selectedDevices.length === 1 && selectedDevices[0] === 'Select Device')
      return 'Please select the device'

  return null
}
