import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from '@material-ui/core'

import {
  FormInput,
  Header,
  SelectInput,
  ImageDropZone
} from '../../../components'
import { setData } from '../../../redux/modules/newMovie'
import { LanguageList } from '../../../lib/constants'
import { useContentRatings } from '../../../hooks/useContentRatings'
import { AutoFillField } from '../../../components/autoFillField'
import { useGenres } from '../../../hooks/useGenres'

export const StepOne = () => {
  const newMovie = useSelector((state) => state.newMovie)
  const { contentRatings } = useContentRatings()

  const {
    subTitle,
    mainTitle,
    genre,
    short_description,
    long_description,
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    isOrignal,
    language,
    banner_message,
    ratings,
    videoId
  } = newMovie

  const thumbnails = [
    {
      id: 'thumbnail_lg',
      title: 'Upload Thumbnail (lg)',
      imageUrl: thumbnail_lg
    },
    {
      id: 'thumbnail_md',
      title: 'Upload Thumbnail (md)',
      imageUrl: thumbnail_md
    },
    {
      id: 'thumbnail_sm',
      title: 'Upload Thumbnail (sm)',
      imageUrl: thumbnail_sm
    }
  ]

  const { genres } = useGenres()

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) =>
    dispatch(setData({ [name]: value }))

  const handleGenre = (event) => {
    let genre = event.target.value

    if (genre.length === 0) genre = ['-1']
    else if (genre.includes('-1')) genre.splice(genre.indexOf('-1'), 1)

    dispatch(setData({ genre: event.target.value }))
  }

  const handleLanguage = ({ target: { value } }) =>
    dispatch(setData({ language: value }))

  const handleRatings = (value) => dispatch(setData({ ratings: value }))

  return (
    <div>
      <Header title="New Movie" />
      <div className="create-movie-step">
        <div className="create-movie-thumbnail-item">
          {thumbnails.map(({ id, title, imageUrl }) => {
            return (
              <ImageDropZone
                videoId={videoId}
                key={id}
                imageUrl={imageUrl}
                title={title}
                onChange={(key) => dispatch(setData({ [id]: key }))}
              />
            )
          })}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="create-movie-form-item">
            <FormInput
              placeholder="Main Title"
              id="mainTitle"
              name="mainTitle"
              onChangeHandler={handleChange}
              value={mainTitle}
            />
          </div>
          <div className="create-movie-form-item">
            <FormInput
              placeholder="Sub Title"
              id="subTitle"
              name="subTitle"
              onChangeHandler={handleChange}
              value={subTitle}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="create-movie-form-item">
            <SelectInput
              data={[
                { id: '-1', name: 'Select Genre', value: '-1' },
                ...genres
              ]}
              multiple={true}
              value={genre}
              changeHandler={handleGenre}
            />
          </div>
          <div className="create-movie-form-item">
            <SelectInput
              data={[
                { id: '-1', name: 'Select Language', value: '-1' },
                ...LanguageList
              ]}
              multiple={false}
              value={language}
              changeHandler={handleLanguage}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
          <div className="create-movie-form-item">
            <AutoFillField
              check={1}
              label={'Content Ratings'}
              data={contentRatings}
              defaultValue={ratings}
              handleCastSelection={handleRatings}
            />
          </div>
          <div className="create-movie-form-item"></div>
        </div>

        <div className="create-movie-description-item">
          <FormInput
            placeholder="Short Description"
            id="short_description"
            multiline={true}
            rows={2}
            name="short_description"
            onChangeHandler={handleChange}
            value={short_description}
          />
        </div>
        <div className="create-movie-description-item">
          <FormInput
            placeholder="Banner Message"
            id="banner_message"
            multiline={true}
            rows={2}
            name="banner_message"
            onChangeHandler={handleChange}
            value={banner_message}
          />
        </div>
        <div className="create-movie-description-item">
          <FormInput
            placeholder="Long Description"
            multiline={true}
            rows={5}
            id="long_description"
            name="long_description"
            onChangeHandler={handleChange}
            value={long_description}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flex: 1,
            width: 540
          }}>
          <p>Is Orignal? </p>
          <Checkbox
            onChange={(_, checked) => dispatch(setData({ isOrignal: checked }))}
            checked={isOrignal}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
      </div>
    </div>
  )
}
