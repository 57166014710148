import React, { useReducer, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { Header, ButtonComponent, ImageDropZone } from '../../components'
import './createCast.css'
import { addCast, updateCast } from '../../redux/modules/cast'
import { CastForm } from './_createCast'
import { v4 as uuidv4 } from 'uuid'

export function CreateCast() {
  const [savingData, setSavingData] = useState(false)
  const [newCastId, setNewCastId] = useState(null)
  const cast = useSelector((state) => state.cast)
  const castCount = cast.length

  const { castId } = useParams()
  const [
    {
      firstName,
      lastName,
      nickName,
      description,
      image_sm,
      image_md,
      image_lg,
      id
    },
    setCast
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    firstName: '',
    lastName: '',
    nickName: '',
    description: '',
    image_sm: null,
    image_md: null,
    image_lg: null,
    id: null
  })

  const dispatch = useDispatch()

  const history = useHistory()

  const images = [
    {
      id: 'image_lg',
      title: 'Upload Image (lg)',
      imageUrl: image_lg
    },
    {
      id: 'image_md',
      title: 'Upload Image (md)',
      imageUrl: image_md
    },
    {
      id: 'image_sm',
      title: 'Upload Image (sm)',
      imageUrl: image_sm
    }
  ]

  useEffect(() => {
    if (!castId) setNewCastId(uuidv4())
  }, [castId, setNewCastId])

  useEffect(() => {
    if (castId) {
      const filteredCast = cast.filter((castItem) => castItem.id === castId)

      if (filteredCast.length > 0) {
        const {
          first_name,
          last_name,
          nick_name,
          image_sm,
          image_md,
          image_lg,
          description,
          id
        } = filteredCast[0]

        setCast({
          firstName: first_name,
          lastName: last_name,
          nickName: nick_name,
          description: description,
          image_sm,
          image_md,
          image_lg,
          id
        })

        return
      }
    }

    history.replace('/create-cast')
  }, [castId, castCount, history, setCast])

  const onChangeHandler = ({ target: { value, name } }) =>
    setCast({ [name]: value })

  const isDataValid = () => {
    if (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      nickName.trim() !== '' &&
      image_sm &&
      image_md &&
      image_lg
    )
      return false

    return true
  }

  const castAction = async (payload, handler, key) => {
    setSavingData(true)

    try {
      await dispatch(handler(payload))

      setSavingData(false)

      history.replace('/cast-list')
    } catch (err) {
      alert('Something went wrong please try again')

      console.log(`Error in ${key} is: `, err)

      setSavingData(false)
    }
  }

  const addNewCast = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      nick_name: nickName,
      description,
      image_sm,
      image_md,
      image_lg,
      status: true,
      id: newCastId
    }

    castAction(payload, addCast, 'CreateCast')
  }

  const updateCastItem = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      nick_name: nickName,
      description,
      image_sm,
      image_md,
      image_lg,
      id
    }

    castAction(payload, updateCast, 'UpdateCast')
  }

  return (
    <div className="dashboard-page">
      <Header title="Create New Cast" />
      <div className="cast-list-content">
        <div className="images-upload-form">
          {images.map(({ id: imageId, title, imageUrl }) => {
            return (
              <ImageDropZone
                key={imageId}
                castId={id || newCastId}
                imageUrl={imageUrl}
                title={title}
                onChange={(key) => setCast({ [imageId]: key })}
              />
            )
          })}
        </div>
        <CastForm
          onChangeHandler={onChangeHandler}
          firstName={firstName}
          lastName={lastName}
          nickName={nickName}
          description={description}
        />
      </div>
      <div className="create-cast-footer">
        <ButtonComponent
          disabled={isDataValid()}
          loading={savingData}
          title={castId ? 'UPDATE' : 'SAVE'}
          onClickHandler={castId ? updateCastItem : addNewCast}
          width={200}
        />
      </div>
    </div>
  )
}
