import React from 'react'
import Switch from '@material-ui/core/Switch'
import { IconButton } from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

import { FormInput } from '.'

export const PlanItem = ({
  item,
  countryCode,
  removePlan,
  index,
  handlePlanInput
}) => {
  const { title, status, id, price } = item

  const handlePrice = (event) => {
    const { value } = event.target

    if (value === '') handlePlanInput(index, 'price', 0)
    else if (value >= 0) handlePlanInput(index, 'price', parseFloat(value))
  }

  return (
    <div className="create-pricing-payment-container" key={id}>
      <div className="remove-plan-button-container">
        <IconButton
          aria-label="minus"
          style={{ padding: 5 }}
          onClick={() => removePlan(id)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>
      <div className="plan-title-input-container">
        <FormInput
          placeholder="Title"
          id="title-id"
          disabled={true}
          value={title.toUpperCase()}
          name="titleID"
        />
      </div>
      <div className="plan-pricing-input-container">
        <FormInput
          placeholder="pricing"
          id="pricing-id"
          name="pricingId"
          value={price}
          type={'number'}
          min={0}
          onChangeHandler={handlePrice}
        />
      </div>
      <div className="plan-country-code-input">
        <FormInput
          placeholder="Currency"
          id="pricing-id"
          disabled={true}
          value={countryCode}
          name="pricingId"
        />
      </div>
      <div className="plan-status-container">
        <Switch
          color="primary"
          name="checkedB"
          checked={status}
          onChange={(event, checked) =>
            handlePlanInput(index, 'status', checked)
          }
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  )
}
