import { API } from 'aws-amplify'

import {
  listKnowledgeBaseCategories,
  getKnowledgeBaseCategory
} from '../../graphql/queries'
import {
  createKnowledgeBase,
  updateKnowledgeBase,
  updateKnowledgeBaseCategory
} from '../../graphql/mutations'

// constants
const FORM_CHANGES = 'FORM_CHANGES'
const SET_QNA_LIST = 'SET_QNA_LIST'
const UPDATE_QUESTION_STATUS = 'UPDATE_QUESTION_STATUS'
const ADD_QNA_TO_LIST = 'ADD_QNA_TO_LIST'
const UPDATE_CATEGORY_STATUS = 'UPDATE_CATEGORY_STATUS'

// actions

export const handleFormChanges = (payload) => ({
  type: FORM_CHANGES,
  payload
})

export const setList = (payload) => ({
  type: SET_QNA_LIST,
  payload
})

export const updateQuestionStatus = (payload) => ({
  type: UPDATE_QUESTION_STATUS,
  payload
})

export const addQNAToList = (payload) => ({
  type: ADD_QNA_TO_LIST,
  payload
})

export const updateStatus = (payload) => ({
  type: UPDATE_CATEGORY_STATUS,
  payload
})

export const handleCategoryStatusUpdate = (id, status) => async (dispatch) => {
  try {
    dispatch(updateStatus({ id, status }))
    const {
      data: {
        updateKnowledgeBaseCategory: { id: helpCategoryId }
      }
    } = await API.graphql({
      query: updateKnowledgeBaseCategory,
      variables: { input: { id, status } }
    })

    if (!helpCategoryId) dispatch(!updateStatus({ id, status: !status }))
  } catch (err) {
    dispatch(updateStatus({ id, status: !status }))
    throw err
  }
}

// thunks

export const updateQNA = (payload) => async (dispatch) => {
  const filter = {
    status: {
      eq: true
    }
  }
  try {
    if (payload.precedence === '') {
      const {
        data: { getKnowledgeBaseCategory: items }
      } = await API.graphql({
        query: getKnowledgeBaseCategory,
        variables: { id: payload.category_id, filter: filter }
      })

      if (items.Knowledge_base.items.length > 0) {
        const lastPrecedence = items.Knowledge_base.items.sort(
          (first, second) => {
            return second.precedence - first.precedence
          }
        )
        payload.precedence = lastPrecedence[0].precedence + 1
      } else payload.precedence = 1
    }
    await API.graphql({
      query: updateKnowledgeBase,
      variables: { input: payload }
    })
    dispatch(fetchQNAs())
  } catch (err) {
    throw err
  }
}

export const addQNA = (payload) => async (dispatch, getState) => {
  const filter = {
    status: {
      eq: true
    }
  }

  try {
    if (payload.precedence === '') {
      const {
        data: { getKnowledgeBaseCategory: items }
      } = await API.graphql({
        query: getKnowledgeBaseCategory,
        variables: { id: payload.category_id, filter: filter }
      })

      if (items.Knowledge_base.items.length > 0) {
        const lastPrecedence = items.Knowledge_base.items.sort(
          (first, second) => {
            return second.precedence - first.precedence
          }
        )

        payload.precedence = lastPrecedence[0].precedence + 1
      } else payload.precedence = 1
    }

    await API.graphql({
      query: createKnowledgeBase,
      variables: { input: payload }
    })

    dispatch(fetchQNAs())
  } catch (err) {
    throw err
  }
}

export const fetchQNAs = () => async (dispatch) => {
  try {
    const {
      data: {
        listKnowledgeBaseCategories: { items }
      }
    } = await API.graphql({ query: listKnowledgeBaseCategories })

    const list = items.sort((first, second) => {
      return first.precedence - second.precedence
    })

    dispatch(setList({ list }))
  } catch (err) {
    throw err
  }
}

export const handleQNAStatusUpdate = (id, status, categoryId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(updateQuestionStatus({ id, categoryId, status }))
    const {
      data: {
        updateKnowledgeBase: { id: helpQNAId }
      }
    } = await API.graphql({
      query: updateKnowledgeBase,
      variables: { input: { id, status } }
    })

    if (!helpQNAId)
      dispatch(!updateQuestionStatus({ id, categoryId, status: !status }))
  } catch (err) {
    dispatch(updateQuestionStatus({ id, categoryId, status: !status }))
    throw err
  }
}

// reducer
const initialState = {
  list: [],
  helpQNA: {
    question: '',
    answer: '',
    precedence: '',
    category_id: '-1',
    status: true
  }
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FORM_CHANGES:
      return {
        ...state,
        helpQNA: { ...state.helpQNA, ...payload }
      }

    case SET_QNA_LIST:
      return {
        ...state,
        list: payload.list
      }

    case ADD_QNA_TO_LIST:
      return {
        ...state,
        list: [payload, ...state.list]
      }
    case UPDATE_CATEGORY_STATUS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === payload.id) return { ...item, status: payload.status }

          return item
        })
      }
    case UPDATE_QUESTION_STATUS:
      return {
        ...state,
        list: state.list.map((listItems) => {
          if (listItems.id === payload.categoryId) {
            return {
              ...listItems,
              Knowledge_base: {
                items: listItems.Knowledge_base.items.map((item) => {
                  if (item.id === payload.id) {
                    return { ...item, status: payload.status }
                  }
                  return item
                })
              }
            }
          }

          return { ...listItems }
        })
      }

    default:
      return state
  }
}

export default reducer
