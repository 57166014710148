import { makeStyles } from '@material-ui/core'
import { PrimaryColor } from '../../styles/colors'

export const useStyles = makeStyles(() => ({
  tab: {
    padding: '3px 10px',
    cursor: 'pointer'
  },
  selectedTab: {
    backgroundColor: PrimaryColor,
    padding: '3px 10px',
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  tabText: {
    color: 'gray',
    fontSize: 18,
    fontWeight: '500'
  },
  selectedTabText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '500'
  },
  movieFormContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tabsContainer: {
    borderBottom: '1px solid gray',
    display: 'flex'
  },
  tabContent: {
    flex: 1,
    display: 'flex',
    padding: 10,
    overflow: 'auto'
  },
  previewButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  spinner: {
    color: 'black',
    marginBottom: 10
  },
  saveButtonContainer: {
    padding: '0px 10px'
  }
}))
