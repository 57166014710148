import React, { useEffect, useState, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Modal from '@material-ui/core/Modal'
import { CircularProgress } from '@material-ui/core'

import {
  ButtonComponent,
  Header,
  SelectInput,
  PlansTable,
  PlanItem
} from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { fetchCountries } from '../../backend/countries'
import {
  pricingsByCountryAndVideo,
  updatePricing,
  fetchPlans
} from '../../backend/pricings'
import { validatePricingData } from '../../lib/util'

export const EditPricing = () => {
  const allPricings = useSelector((state) => state.pricingsData)
  const [{ movie, country, selectedPlans, code }, setPricing] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      movie: 'Select Movie',
      country: 'Select Country',
      selectedPlans: [],
      code: ''
    }
  )

  // list of data fetched from backend
  const [{ countriesList, videosList, allPlans }, setStaticData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      countriesList: [
        { id: '-1', name: 'Select Country', value: 'Select Country' }
      ],
      videosList: [{ id: '-1', name: 'Select Movie', value: 'Select Movie' }],
      allPlans: []
    }
  )

  const [isPlansModalVisible, setIsPlanModalVisible] = useState(false)
  const [fetchingPlans, setFetchingPlans] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [selectedPricings, setSelectedPricings] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const { videoId, countryCode } = useParams()

  useEffect(() => {
    if (countriesList.length > 1) {
      const filteredCountries = countriesList.filter(
        ({ value }) => value === countryCode.toUpperCase()
      )
      if (filteredCountries.length > 0) {
        const filteredCode = filteredCountries[0].name.split('-')
        setPricing({ code: filteredCode[filteredCode.length - 1].trim() })
      }
    }
  }, [countriesList])

  const closePlansModal = () => setIsPlanModalVisible(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (videoId && countryCode) fetchFilteredPricings()
  }, [videoId, countryCode])

  useEffect(() => {
    if (movie !== 'Select Movie') fetchPlansData()
  }, [movie])

  const fetchFilteredPricings = async () => {
    setLoading(true)

    try {
      const items = await pricingsByCountryAndVideo(
        videoId,
        countryCode.toUpperCase()
      )

      setSelectedPricings(items)

      const plans = items.map((item) => {
        const {
          video_plan: { plan, video_by_quality_id },
          price,
          status
        } = item

        return {
          ...plan,
          price: price,
          status: status,
          videoByQualityId: video_by_quality_id
        }
      })

      setPricing({
        movie: videoId,
        country: countryCode.toUpperCase(),
        selectedPlans: plans
      })

      setLoading(false)
    } catch (err) {
      console.log('Error in fetching pricings')
    }
  }

  const fetchPlansData = () => {
    setFetchingPlans(true)

    fetchPlans(movie).then((res) => {
      let combinePlans = []

      res.map((item) => {
        const { plansByQuality, videoByQualityId } = item

        const plansWithVideoByQuality = plansByQuality.map((plan) => {
          return { ...plan, videoByQualityId }
        })

        combinePlans = [...combinePlans, ...plansWithVideoByQuality]
      })

      setStaticData({ allPlans: combinePlans })
      setFetchingPlans(false)
    })
  }

  const fetchData = async () => {
    try {
      const _countries = await fetchCountries(true)
      const _exists = await fetchingDemo();

      const video = _exists[Object.keys(_exists)[0]][0]?.video;
      await setStaticData({ videosList: [{id: video.id, name: video.title, value: video.id}] })

      setStaticData({
        countriesList: [...countriesList, ..._countries]
      })
    } catch (err) {
      console.log('Error in getting data is: ', err)
    }
  }

  const fetchingDemo = async () => {
    const pricingsByCountry = {}

    const filteredPricings = await allPricings.filter(
      (item) => {
        return item.video_id === videoId
      }
    )

    await filteredPricings.map((item) => {
      const {
        country: { country_code }
      } = item

      pricingsByCountry[country_code] = pricingsByCountry[country_code]
        ? [...pricingsByCountry[country_code], item]
        : [item]
    })
    return pricingsByCountry;
  }

  const addPlans = (plans) => {
    setPricing({
      selectedPlans: plans
    })
  }

  const removePlan = (id) => {
    const index = selectedPlans.findIndex((item) => item.id === id)

    let plans = [...selectedPlans]
    plans.splice(index, 1)

    setPricing({ selectedPlans: plans })
  }

  const handlePlanInput = (index, key, value) => {
    let plans = [...selectedPlans]
    plans[index][key] = value

    setPricing({ selectedPlans: plans })
  }

  const handlePriceUpdation = async () => {
    setError(null)

    const validation = validatePricingData(movie, country, selectedPlans)

    if (validation) {
      setError(validation)
      return
    }

    setProcessing(true)

    try {
      await updatePricing(movie, country, selectedPlans, selectedPricings)

      history.push('/pricing')
    } catch (err) {
      console.log('Error in pricing addition is: ', err)

      setError('Something went wrong please try again')
      setProcessing(false)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title={'Update Pricings'} />
      {loading ? (
        <div className="centered-loader-container">
          <CircularProgress size={30} color="black" />
        </div>
      ) : (
        <div className="create-pricing-container">
          <div className="create-pricing-form">
            <div className="create-pricing-input-container">
              <div className="create-pricing-input">
                <SelectInput data={videosList} value={movie} disabled={true} />
              </div>
              <div className="create-pricing-input">
                <SelectInput
                  data={countriesList}
                  value={country}
                  disabled={true}
                />
              </div>
            </div>
            {fetchingPlans ? (
              <div className="create-pricing-fetching-plans">
                <CircularProgress size={20} color="gray" />
                Fetching plans
              </div>
            ) : (
              <div>
                {allPlans.length === 0 && (
                  <div className="create-pricing-fetching-plans">
                    No plans are associated with this movie
                  </div>
                )}

                {selectedPlans.map((item, index) => {
                  return (
                    <PlanItem
                      removePlan={removePlan}
                      item={item}
                      countryCode={code}
                      index={index}
                      handlePlanInput={handlePlanInput}
                    />
                  )
                })}

                {allPlans.length > 0 && (
                  <div className="add-plan-container">
                    <ButtonComponent
                      title="ADD PLAN"
                      backgroundColor={'white'}
                      borderColor={'black'}
                      onClickHandler={() => setIsPlanModalVisible(true)}
                      width={130}
                      height={30}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {error && <p className="create-pricing-error">{error}</p>}
      <div className="create-pricing-footer">
        <div className="create-pricing-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={() => history.replace('/pricing')}
            width={130}
          />
        </div>
        <div className="create-pricing-footer-button">
          <ButtonComponent
            loading={processing}
            title={'UPDATE'}
            onClickHandler={handlePriceUpdation}
            width={130}
          />
        </div>
      </div>

      <Modal
        open={isPlansModalVisible}
        onClose={closePlansModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="centered-container">
        <PlansTable
          addPlans={addPlans}
          allPlans={allPlans}
          selectedPlans={selectedPlans}
          closePlansModal={closePlansModal}
        />
      </Modal>
    </div>
  )
}
