import React, { useState, useEffect } from 'react'
import { ButtonComponent, Header } from '../../components'
import { LANDING_PAGE_TABS } from '../../lib/constants'
import { useStyles } from './styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { PrimaryColor, SecondaryButton } from '../../styles/colors'
import {
  BackgroundForm,
  PreviewDrawer,
  SocialIconsForm,
  TitleForm
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLandingPageData,
  saveChanges
} from '../../redux/modules/landingPage'

export const LandingPage = () => {
  const [tab, setTab] = useState(LANDING_PAGE_TABS[0].id)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const { updated } = useSelector((state) => state.landingPage)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSaveChanges = async () => {
    try {
      setSaving(true)
      await dispatch(saveChanges())
      setSaving(false)
    } catch (err) {
      setSaving(false)
      alert('Something went wrong please try again')
      console.log('Error in saving landing page changes is: ', err)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await dispatch(fetchLandingPageData())
        setLoading(false)
      } catch (err) {
        console.log('Error in fetching landing page data is: ', err)
        alert('Something went wrong please try again')
      }
    })()
  }, [dispatch, setLoading])

  return (
    <div className="dashboard-page">
      <Header title="Landing Page" />
      <div className={classes.movieFormContainer}>
        <div className={classes.tabsContainer}>
          {LANDING_PAGE_TABS.map((item) => {
            return (
              <div
                role="presentation"
                onClick={() => setTab(item.id)}
                className={tab === item.id ? classes.selectedTab : classes.tab}
                key={item.id}>
                <Typography
                  className={
                    tab === item.id ? classes.selectedTabText : classes.tabText
                  }>
                  {item.name}
                </Typography>
              </div>
            )
          })}
        </div>
        {loading ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress size={20} className={classes.spinner} />
            Loading data...
          </div>
        ) : (
          <div className={classes.tabContent}>
            {tab === 'background' && <BackgroundForm />}
            {tab === 'title' && <TitleForm section={'title'} />}
            {tab === 'sub-title' && <TitleForm section={'subTitle'} />}
            {tab === 'banner' && <TitleForm section={'banner'} />}
            {tab === 'social-icons' && (
              <SocialIconsForm section={'socialIcons'} />
            )}
          </div>
        )}
      </div>

      <div className={classes.previewButton}>
        <div className={classes.saveButtonContainer}>
          <ButtonComponent
            title="Save"
            loading={saving}
            disabled={!updated}
            backgroundColor={!updated ? SecondaryButton : PrimaryColor}
            borderColor={PrimaryColor}
            onClickHandler={handleSaveChanges}
            width={150}
            height={40}
          />
        </div>

        <ButtonComponent
          title="Preview"
          backgroundColor={PrimaryColor}
          borderColor={PrimaryColor}
          onClickHandler={() => setOpenPreview(true)}
          width={150}
          height={40}
        />
      </div>
      <PreviewDrawer open={openPreview} close={() => setOpenPreview(false)} />
    </div>
  )
}
