import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { CircularProgress } from '@material-ui/core'

import {
  ConfirmationDialog,
  FormInput,
  Header,
  SelectInput
} from '../../components'
import { CountriesFilters } from '../../lib/constants'
import { listCountries } from '../../graphql/queries'
import { updateCountry } from '../../graphql/mutations'
import { List } from './components'
import './countriesList.css'

export const CountriesList = () => {
  const [filter, setFilter] = useState('All')
  const [searchCountry, setSearchCountry] = useState('')
  const [countries, setCountries] = useState([])
  const [countriesClone, setCountriesClone] = useState([])
  const [processing, setProcessing] = useState(false)
  const [open, setOpen] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCountries()
  }, [])

  useEffect(() => {
    handleSearch(searchCountry);
  }, [searchCountry])

  const handleClose = () => {
    setOpen(false)
    setNewStatus(null)
    setSelectedCountry(null)
    setProcessing(false)
  }

  const handleFilter = (event) => {
    let _filter = event.target.value
    setFilter(_filter)

    getCountriesByFilter(_filter, countriesClone)
  }

  const getCountriesByFilter = (_filter, _countriesList) => {
    if (_filter === 'All') setCountries(_countriesList)
    else if (_filter === 'enabled') {
      let _countries = _countriesList.filter((item) => item.status)
      setCountries(_countries)
    } else {
      let _countries = _countriesList.filter((item) => !item.status)
      setCountries(_countries)
    }
  }

  function compare(a, b) {
    if (a.country_name < b.country_name) return -1
    if (a.country_name > b.country_name) return 1
    return 0
  }

  const fetchCountries = async () => {
    setLoading(true)
    try {
      const response = await API.graphql({
        query: listCountries,
        variables: { limit: 500 }
      })
      let _countries = response.data.listCountries.items

      _countries = _countries.sort(compare)

      getCountriesByFilter(filter, _countries)
      setCountriesClone(_countries)
      setLoading(false)
    } catch (err) {
      console.log('Error in getting countries is: ', err)
      setLoading(false)
    }
  }

  const handleSearch = (searchText) => {
    if (searchText.length < 2) setCountries(countriesClone)
    else {
      let _countries = countriesClone.filter(
        (item) =>
          item.country_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.country_code.toLowerCase().includes(searchText.toLowerCase())
      )

      setCountries(_countries)
    }
  }

  const handleActivation = (country, status) => {
    setSelectedCountry(country)
    setNewStatus(status)
    setOpen(true)
  }

  const processRequest = async () => {
    setProcessing(true)
    try {
      const response = await API.graphql({
        query: updateCountry,
        variables: {
          input: {
            id: selectedCountry.id,
            country_code: selectedCountry.country_code,
            status: newStatus
          }
        }
      })

      countries.forEach((country, index) => {
        const { updateCountry } = response.data

        if (country.id == updateCountry.id) {
          let _countries = [...countries]
          _countries[index].status = updateCountry.status

          setCountries(_countries)
        }
      })

      countriesClone.forEach((country, index) => {
        const { updateCountry } = response.data

        if (country.id == updateCountry.id) {
          let _countries = [...countriesClone]
          _countries[index].status = updateCountry.status

          setCountriesClone(_countries)
        }
      })

      setProcessing(false)

      handleClose()
    } catch (err) {
      console.log('Error in updating country is: ', err)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title="Countries" />
      <div className="plan-content">
        <div className="plan-filter-container">
          <div className="search-container">
            <FormInput
              placeholder="Search"
              id="plan-search"
              name="search"
              value={searchCountry}
              onChangeHandler={(e) => { setSearchCountry(e.target.value.toLowerCase());}} 
            />
          </div>
          <div className="dropdown-filter">
            <SelectInput
              data={CountriesFilters}
              value={filter}
              changeHandler={handleFilter}
            />
          </div>
        </div>

        {loading && (
          <div className="loading-container">
            <CircularProgress size={30} className="countries-loader" />
          </div>
        )}

        {!loading && countries.length > 0 && (
          <List
            countries={countries}
            handleActivation={handleActivation}
            fetchCountries={fetchCountries}
          />
        )}
        {!loading && countries.length == 0 && (
          <div className="loading-container">No country available</div>
        )}
      </div>

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        processRequest={processRequest}
        title={'Confirmation'}
        description={
          <p>
            Are you sure you want to {newStatus ? 'enable' : 'disable'}{' '}
            <span className="country-name">
              {' '}
              {selectedCountry && selectedCountry.country_name}
            </span>
            .
          </p>
        }
        loading={processing}
      />
    </div>
  )
}
