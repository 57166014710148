import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  TablePagination,
  Switch,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { useDispatch } from 'react-redux'
import {
  handleQNAStatusUpdate,
  handleCategoryStatusUpdate
} from '../../../redux/modules/helpQNA'
import { HelpQNAHeader, HelpCategoryHeader } from '../../../lib/constants'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  helpAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
})
function Row(helpQNA) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()

  const handleCategoryStatus = async (id, status) => {
    try {
      await dispatch(handleCategoryStatusUpdate(id, status))
    } catch (err) {
      console.log('Error in status update is: ', err)
      alert(
        err.message || 'Something went wrong in status update. Please try again'
      )
    }
  }

  const handleQNAStatus = async (id, status, categoryId) => {
    try {
      await dispatch(handleQNAStatusUpdate(id, status, categoryId))
    } catch (err) {
      console.log('Error in status update is: ', err)
      alert(
        err.message || 'Something went wrong in status update. Please try again'
      )
    }
  }

  return (
    <React.Fragment>
      <TableRow key={helpQNA.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{helpQNA.row.title}</TableCell>
        <TableCell align="left">{helpQNA.row.precedence}</TableCell>
        <TableCell align="left">
          {helpQNA.row.Knowledge_base.items.length}
        </TableCell>
        <TableCell align="center">
          <Switch
            name="checkedA"
            checked={helpQNA.row.status}
            onChange={(_, checked) => {
              handleCategoryStatus(helpQNA.row.id, checked)
            }}
            color="primary"
          />
        </TableCell>
        <TableCell>
          <EditIcon
            className={classes.helpAction}
            role="presentation"
            onClick={() => history.push(`/edit-qna-category/${helpQNA.row.id}`)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {HelpQNAHeader.map((item) => (
                      <TableCell
                        style={{ fontWeight: 'bold' }}
                        key={item}
                        align="left"
                        className="countries-table-header">
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {helpQNA.row.Knowledge_base.items.length > 0 ? (
                    helpQNA.row.Knowledge_base.items
                      .sort(
                        (
                          { precedence: previousID },
                          { precedence: currentID }
                        ) => previousID - currentID
                      )
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.question}</TableCell>
                          <TableCell>{item.answer}</TableCell>
                          <TableCell>{item.precedence}</TableCell>
                          <TableCell>
                            <Switch
                              name="checkedB"
                              checked={item.status}
                              onChange={(_, checked) => {
                                handleQNAStatus(
                                  item.id,
                                  checked,
                                  helpQNA.row.id
                                )
                              }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell>
                            <EditIcon
                              className={classes.helpAction}
                              role="presentation"
                              onClick={() =>
                                history.push(
                                  `/edit-qna/${helpQNA.row.id}/${item.id}`
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align={'center'}>
                        No Q&As Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export const HelpQNATable = ({ helpQNAs }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, helpQNAs.length - page * rowsPerPage)

  const classes = useStyles()

  const handleChangePage = (_, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="promo-code-table">
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {HelpCategoryHeader.map((item) => (
                <TableCell
                  style={{ fontWeight: 'bold' }}
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? helpQNAs.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : helpQNAs
            )
              .sort(
                ({ precedence: previousID }, { precedence: currentID }) =>
                  previousID - currentID
              )
              .map((helpQNA) => (
                <Row key={helpQNA.id} row={helpQNA} />
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={6}
            count={helpQNAs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </div>
  )
}
