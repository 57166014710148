import { API } from 'aws-amplify'
import AddIcon from '@material-ui/icons/Add'

import { listPricings } from '../../graphql/queries'

export const fetchPricings = async () => {
  try {
    const {
      data: {
        listPricings: { items }
      }
    } = await API.graphql({
      query: listPricings
    })

    const pricingsByMovie = {}

    items.map((item) => {
      const {
        video: { title }
      } = item

      if (pricingsByMovie[title])
        pricingsByMovie[title] = [...pricingsByMovie[title], item]
      else pricingsByMovie[title] = [item]
    })

    return pricingsByMovie
  } catch (err) {
    return err
  }
}

export const PricingCreateButton = ({ onClickHandler }) => {
  return (
    <div
      role="presentation"
      onClick={onClickHandler}
      className="pricing-create-button">
      <AddIcon className="add-icon" /> Create New
    </div>
  )
}

export const PricingByMovieCard = ({
  title,
  item,
  navigateToPricingsByMovie
}) => {
  let countries = {}

  item.forEach((i) => (countries[i.country.id] = item))
  const {
    video_id,
    video: { thumbnail_sm },
    video_plan
  } = item[0]

  return (
    <div
      className="pricing-movie"
      role="presentation"
      onClick={() => navigateToPricingsByMovie(video_id)}>
      <img className="pricing-card-image" src={thumbnail_sm} alt={title} />
      <div className="pricing-card-detail">
        <div className="pricing-title">{title}</div>
        {Object.keys(video_plan).length !== 0 && <div className="pricing-countries">
          Countries - {Object.values(countries).length}
        </div>}
      </div>
    </div>
  )
}
