import React from 'react'
import { makeStyles } from '@material-ui/core'
import { VideoPlayer } from '../../../components'

export const TrailerPlayer = ({ url, close }) => {
  const classes = useStyles()
  const videoOptions = {
    autoplay: false,
    controls: true,
    preload: 'none',
    sources: [
      {
        src: url,
        type: 'application/x-mpegURL',
        overrideNative: true
      }
    ]
  }
  return (
    <div className={classes.mainContainer}>
      <VideoPlayer {...videoOptions} showCloseButton={true} close={close} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: 500,
    width: 800,
    border: '1px solid gray',
    backgroundColor: 'black'
  }
}))
