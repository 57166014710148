import { API } from 'aws-amplify'
import moment from 'moment'
import { createPromoCode, updatePromoCode } from '../../graphql/mutations'
import { listPromoCodes, promoCodeByCode } from '../../graphql/queries'
// constants
const FORM_CHANGES = 'FORM_CHANGES'
const RESET_DATA = 'RESET_DATA'
const SET_PROMO_CODE_LIST = 'SET_PROMO_CODE_LIST'
const ADD_PROMO_CODE_TO_LIST = 'ADD_PROMO_CODE_TO_LIST'
const UPDATE_STATUS = 'UPDATE_STATUS'
const SET_PROMO_CODE = 'SET_PROMO_CODE'
const SET_CREATED_PROMO_CODE = 'SET_CREATED_PROMO_CODE'

// actions

export const setPromoCode = (code) => ({
  type: SET_PROMO_CODE,
  payload: { code }
})

export const setCreatedPromoCode = (code) => ({
  type: SET_CREATED_PROMO_CODE,
  payload: { code }
})

export const handleFormChanges = (payload) => ({
  type: FORM_CHANGES,
  payload
})

export const updateStatus = (payload) => ({
  type: UPDATE_STATUS,
  payload
})

export const setList = (payload) => ({
  type: SET_PROMO_CODE_LIST,
  payload
})

export const addPromoCodeToList = (payload) => ({
  type: ADD_PROMO_CODE_TO_LIST,
  payload
})

export const clearData = () => ({
  type: RESET_DATA,
  payload: {
    video_id: '-1',
    start_date: null,
    max_use_limit: null,
    max_use_limit_by_user: 1,
    plan_id: '-1',
    percentage_off: null,
    country_code: '-1',
    end_date: null,
    unlimited: false,
    user_id: '',
    justification: ''
  }
})

export const addNewPromoCode = () => async (dispatch, getState) => {
  const {
    promoCode: {
      newPromoCode: {
        video_id,
        start_date,
        max_use_limit,
        max_use_limit_by_user,
        plan_id,
        percentage_off,
        country_code,
        end_date,
        unlimited,
        code,
        user_id,
        justification
      }
    }
  } = getState()

  fetchPromoCodes("code = ''")
  if (fetchPromoCodes()) {
  }

  let payload = {
    count: 0,
    percentage_off: parseInt(percentage_off),
    country_code,
    end_date: moment(end_date).format(),
    unlimited,
    status: true,
    max_use_limit_by_user: parseInt(max_use_limit_by_user),
    code: code,
    user_id: user_id,
    additional_attributes: JSON.stringify({ justification: justification }),
    // fixed for general promotion
    promo_type_id: '5a566523-94922-4de0-98c6-b61e5652e32c'
  }

  if (!unlimited && max_use_limit)
    payload['max_use_limit'] = parseInt(max_use_limit)
  if (video_id !== '-1') payload['video_id'] = video_id
  if (plan_id !== '-1') payload['plan_id'] = plan_id
  if (start_date !== null) payload['start_date'] = moment(start_date).format()
  else payload['start_date'] = moment().format()

  const filter = {
    status: {
      eq: true
    }
  }

  try {
    const {
      data: { promoCodeByCode: items }
    } = await API.graphql({
      query: promoCodeByCode,
      variables: { code: code, filter: filter }
    })

    if (items.items.length === 0) {
      const {
        data: { createPromoCode: promoCode }
      } = await API.graphql({
        query: createPromoCode,
        variables: { input: payload }
      })

      if (promoCode) {
        dispatch(clearData())
        dispatch(addPromoCodeToList({ promoCode }))
        dispatch(setCreatedPromoCode(payload.code))
        return Promise.resolve()
      } else
        return Promise.reject({ error_message: 'Unable to create promo code' })
    } else return Promise.reject({ error_message: 'Promo Code Already Exists' })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchPromoCodes = (promo_type_id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      data: {
        listPromoCodes: { items }
      }
    } = await API.graphql({
      query: listPromoCodes,
      variables: { filter: { promo_type_id: { eq: promo_type_id } } }
    })

    const list = items.sort((first, second) => {
      return new Date(second.createdAt) - new Date(first.createdAt)
    })
    dispatch(setList({ list }))
  } catch (err) {
    throw err
  }
}

export const handleStatusUpdate = (id, status) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(updateStatus({ id, status }))
    const {
      data: {
        updatePromoCode: { id: promoCodeId }
      }
    } = await API.graphql({
      query: updatePromoCode,
      variables: { input: { id, status } }
    })

    if (!promoCodeId) dispatch(!updateStatus({ id, status: !status }))
  } catch (err) {
    dispatch(updateStatus({ id, status: !status }))
    throw err
  }
}

export const handleInvitePromoCodeUpdate = (payload) => async () => {
  try {
    await API.graphql({
      query: updatePromoCode,
      variables: { input: payload }
    })
  } catch (err) {
    throw err
  }
}

// reducer
const initialState = {
  list: [],
  newPromoCode: {
    type: 'COUNTRY',
    video_id: '-1',
    start_date: null,
    max_use_limit: null,
    max_use_limit_by_user: 1,
    plan_id: '-1',
    percentage_off: null,
    country_code: '-1',
    end_date: null,
    unlimited: false,
    user_id: '',
    justification: ''
  }
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case FORM_CHANGES:
      return {
        ...state,
        newPromoCode: { ...state.newPromoCode, ...payload }
      }
    case RESET_DATA:
      return {
        ...state,
        newPromoCode: { type: state.newPromoCode.type, ...payload }
      }
    case SET_PROMO_CODE_LIST:
      return {
        ...state,
        list: payload.list
      }

    case ADD_PROMO_CODE_TO_LIST:
      return {
        ...state,
        list: [payload.promoCode, ...state.list]
      }

    case UPDATE_STATUS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === payload.id) return { ...item, status: payload.status }

          return item
        })
      }
    case SET_PROMO_CODE:
      return {
        ...state,
        newPromoCode: { ...state.newPromoCode, code: payload.code }
      }
    case SET_CREATED_PROMO_CODE:
      return {
        ...state,
        newPromoCode: { ...state.newPromoCode, createdCode: payload.code }
      }

    default:
      return state
  }
}

export default reducer
