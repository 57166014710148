import { API } from 'aws-amplify'

import { listKnowledgeBaseCategories } from '../../graphql/queries'
import {
  createKnowledgeBaseCategory,
  updateKnowledgeBaseCategory
} from '../../graphql/mutations'

// constants
const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'
const UPDATE_STATUS = 'UPDATE_STATUS'
const ADD_CATEGORY_TO_LIST = 'ADD_CATEGORY_TO_LIST'

export const setList = (payload) => ({
  type: SET_CATEGORY_LIST,
  payload
})

export const updateStatus = (payload) => ({
  type: UPDATE_STATUS,
  payload
})

export const addCategoryToList = (payload) => ({
  type: ADD_CATEGORY_TO_LIST,
  payload
})

// actions

// thunks

export const updateCategory = (payload) => async (dispatch) => {
  const filter = {
    status: {
      eq: true
    }
  }
  try {
    if (payload.precedence === '') {
      const {
        data: {
          listKnowledgeBaseCategories: { items }
        }
      } = await API.graphql({
        query: listKnowledgeBaseCategories,
        variables: { filter: filter }
      })
      if (items.length > 0) {
        const lastPrecedence = items.sort((first, second) => {
          return second.precedence - first.precedence
        })
        payload.precedence = lastPrecedence[0].precedence + 1
      } else payload.precedence = 1
    }

    await API.graphql({
      query: updateKnowledgeBaseCategory,
      variables: { input: payload }
    })

    dispatch(fetchCategories())
  } catch (err) {
    throw err
  }
}

// add category
export const addCategory = (payload) => async (dispatch, getState) => {
  const filter = {
    status: {
      eq: true
    }
  }
  try {
    if (payload.precedence === '') {
      const {
        data: {
          listKnowledgeBaseCategories: { items }
        }
      } = await API.graphql({
        query: listKnowledgeBaseCategories,
        variables: { filter: filter }
      })
      if (items.length > 0) {
        const lastPrecedence = items.sort((first, second) => {
          return second.precedence - first.precedence
        })
        payload.precedence = lastPrecedence[0].precedence + 1
      } else payload.precedence = 1
    }
    await API.graphql({
      query: createKnowledgeBaseCategory,
      variables: { input: payload }
    })

    dispatch(fetchCategories())
  } catch (err) {
    throw err
  }
}

// fetch categories
export const fetchCategories = () => async (dispatch) => {
  try {
    const {
      data: {
        listKnowledgeBaseCategories: { items }
      }
    } = await API.graphql({ query: listKnowledgeBaseCategories })

    const list = items.sort((first, second) => {
      return first.precedence - second.precedence
    })

    dispatch(setList({ list }))
  } catch (err) {
    throw err
  }
}

export const handleCategoryStatusUpdate = (id, status) => async (dispatch) => {
  try {
    dispatch(updateStatus({ id, status }))
    const {
      data: {
        updateKnowledgeBaseCategory: { id: helpCategoryId }
      }
    } = await API.graphql({
      query: updateKnowledgeBaseCategory,
      variables: { input: { id, status } }
    })

    if (!helpCategoryId) dispatch(!updateStatus({ id, status: !status }))
  } catch (err) {
    dispatch(updateStatus({ id, status: !status }))
    throw err
  }
}

// reducer
const initialState = {
  list: [],
  helpCategory: {
    title: '',
    precedence: ''
  }
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        list: payload.list
      }

    case ADD_CATEGORY_TO_LIST:
      return {
        ...state,
        list: [payload.helpCategory, ...state.list]
      }

    case UPDATE_STATUS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === payload.id) return { ...item, status: payload.status }

          return item
        })
      }

    default:
      return state
  }
}

export default reducer
