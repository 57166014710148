import React, { useState } from 'react'
import { API } from 'aws-amplify'

import { CircularProgress } from '@material-ui/core'

import { getUserByEmail } from '../../graphql/queries'
import { updateUser } from '../../graphql/mutations'
import { UsersList } from './usersList'
import { validateEmail } from '../../lib/util'
import './userManagement.css'

import { Header, ConfirmationDialog, FormInput } from '../../components'

export const UserManagement = () => {
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [open, setOpen] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [searchText, setSearchText] = useState('')

  const handleSearch = async ({ target: { value } }) => {
    setSearchText(value)

    if (value.length < 2) setLoading(false)
    else if (validateEmail(value)) {
      setLoading(true)
      try {
        const {
          data: {
            getUserByEmail: { items }
          }
        } = await API.graphql({
          query: getUserByEmail,
          variables: { email_address: value }
        })
        let _usersList = items

        setUsersList(_usersList)
        setLoading(false)
      } catch (err) {
        console.log('Error in getting users is: ', err)
        setLoading(false)
      }
    }
  }

  const handleActivation = (user, status) => {
    setSelectedUser(user)
    setNewStatus(status)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setNewStatus(null)
    setSelectedUser(null)
    setProcessing(false)
  }

  const processRequest = async () => {
    setProcessing(true)

    try {
      const response = await API.graphql({
        query: updateUser,
        variables: { input: { id: selectedUser.id, status: newStatus } }
      })

      usersList.forEach((user, index) => {
        const { updateUser } = response.data

        if (user.id == updateUser.id) {
          let _usersList = [...usersList]
          _usersList[index].status = updateUser.status

          setUsersList(_usersList)
        }
      })

      setProcessing(false)

      handleClose()
    } catch (err) {
      console.log('Error in updating country is: ', err)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title="User Management" />
      <div className="plan-content">
        <div className="plan-filter-container">
          <div className="search-container">
            <FormInput
              value={searchText}
              placeholder="Search"
              id="user-search"
              name="search"
              onChangeHandler={handleSearch}
            />
          </div>
        </div>
        {loading && (
          <div className="loading-container">
            <CircularProgress size={30} className="countries-loader" />
          </div>
        )}

        {!loading && usersList.length > 0 && (
          <UsersList
            usersList={usersList}
            handleActivation={handleActivation}
          />
        )}
        {!loading && usersList.length == 0 && (
          <div className="loading-container">
            Enter Existing User to Proceed.
          </div>
        )}
      </div>
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        processRequest={processRequest}
        title={'Confirmation'}
        description={
          <p>
            Are you sure you want to {newStatus ? 'enable' : 'disable'}{' '}
            <span className="country-name">
              {' '}
              {selectedUser && selectedUser.email_address}
            </span>
            .
          </p>
        }
        loading={processing}
      />
    </div>
  )
}
