import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    minHeight: 300,
    maxHeight: 600,
    overflow: 'auto',
    width: 400,
    backgroundColor: 'lightgray',
    borderRadius: 5,
    padding: 15,
    display: 'flex',
    flexDirection: 'column'
  },
  contentRoot: {
    flex: 1
  },
  errorList: {
    padding: '20px 0px',
    fontSize: 13
  },
  successContainer: {
    padding: '20px 0px'
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorItem: {
    paddingBottom: 15
  }
}))
