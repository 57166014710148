import { API } from 'aws-amplify'

import { listPromoTypes } from '../graphql/queries'

export const fetchPromoTypes = async () => {
  try {
    const {
      data: {
        listPromoTypes: { items }
      }
    } = await API.graphql({
      query: listPromoTypes
    })

    const promoTypes = items.map((promoType) => {
      return {
        id: promoType.id,
        name: promoType.name,
        value: promoType.id
      }
    })

    return promoTypes
  } catch (err) {
    throw err
  }
}
