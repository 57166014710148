import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { listCountries } from '../graphql/queries'

function compare(a, b) {
  if (a.country_name < b.country_name) return -1
  if (a.country_name > b.country_name) return 1

  return 0
}

export const useFetchCountriesList = ({ isSelect = false }) => {
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: {
            listCountries: { items }
          }
        } = await API.graphql(graphqlOperation(listCountries, {}))

        if (isSelect) {
          const countries = items.sort(compare).map((country) => {
            const { id, country_name } = country

            return {
              label: country_name,
              value: id
            }
          })

          setLoading(false)
          setCountries(countries)

          return
        }

        setLoading(false)
        setCountries(items.sort(compare))
      } catch (err) {
        console.log('Error in getting countries is: ', err)

        setLoading(false)
      }
    })()
  }, [isSelect])

  return { countries, loading }
}
