import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  TablePagination,
  Switch,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { UserManagementHeader } from '../../lib/constants'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  userAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
})

const UsersList = ({ usersList, handleActivation }) => {
  const history = useHistory()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, usersList.length - page * rowsPerPage)

  const classes = useStyles()
  const handleChangePage = (_, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {UserManagementHeader.map((item) => (
                <TableCell
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
              <TableCell align="center" className="countries-table-header">
                Status
              </TableCell>
              <TableCell align="center" className="countries-table-header">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? usersList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : usersList
            ).map((userList) => {
              const { email_address, status, id, provider } = userList

              return (
                <TableRow key={id}>
                  <TableCell align="left">{id}</TableCell>
                  <TableCell align="left">{email_address}</TableCell>
                  <TableCell align="left">{provider}</TableCell>
                  <TableCell align="center">
                    <Switch
                      name="checkedA"
                      checked={status}
                      onChange={(_, checked) => {
                        handleActivation(userList, checked)
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon
                      className={classes.userAction}
                      role="presentation"
                      onClick={() => history.push(`/user-detail/${id}`)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </div>
  )
}

export { UsersList }
