import React from 'react'
import { FormLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { FormInput, Header } from '../../../components'
import { setData } from '../../../redux/modules/newMovie'

export const StepTwo = () => {
  const {
    releaseDate,
    creditHours,
    creditMinutes,
    creditSeconds,
    durationHours,
    durationMinutes,
    durationSeconds
  } = useSelector((state) => state.newMovie)

  const dispatch = useDispatch()

  const onChangeHandler = (event) => {
    const { name, value } = event.target
    dispatch(setData({ [name]: value }))
  }

  const validateHour = (value) => {
    if (isNaN(value) || value.length > 2) return false
    if (parseInt(value) < 0 || parseInt(value) > 12) return false

    return true
  }

  const validateMinutes = (value) => {
    if (isNaN(value) || value.length > 2) return false
    if (parseInt(value) < 0 || parseInt(value) > 60) return false

    return true
  }

  const validateSeconds = (value) => {
    if (isNaN(value) || value.length > 2) return false
    if (parseInt(value) < 0 || parseInt(value) > 60) return false

    return true
  }

  const handleHours = (event) => {
    const { value } = event.target
    if (!validateHour(value)) return

    onChangeHandler(event)
  }

  const handleMinutes = (event) => {
    const { value } = event.target
    if (!validateMinutes(value)) return

    onChangeHandler(event)
  }

  const handleSeconds = (event) => {
    const { value } = event.target
    if (!validateSeconds(value)) return

    onChangeHandler(event)
  }

  return (
    <div>
      <Header title="New Movie" />
      <div className="create-movie-step">
        <div className="create-movie-form-item">
          <div className="create-movie-form-label">
            <FormLabel>Release Date</FormLabel>
          </div>
          <FormInput
            onChangeHandler={onChangeHandler}
            placeholder="Release Time"
            id="releaseDate"
            name="releaseDate"
            type="date"
            value={releaseDate}
          />
        </div>
        <div className="create-movie-form-item">
          <div className="create-movie-form-label">
            <FormLabel>Duration</FormLabel>
          </div>
          <div className="flex-container">
            <div>
              <FormInput
                onChangeHandler={handleHours}
                placeholder="hh"
                id="durationHours"
                name="durationHours"
                value={durationHours}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={handleMinutes}
                placeholder="mm"
                id="durationMinutes"
                name="durationMinutes"
                value={durationMinutes}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={handleSeconds}
                placeholder="ss"
                id="durationSeconds"
                name="durationSeconds"
                value={durationSeconds}
              />
            </div>
          </div>
        </div>
        <div className="create-movie-form-item">
          <div className="create-movie-form-label">
            <FormLabel>Credit Start Time</FormLabel>
          </div>

          <div className="flex-container">
            <div>
              <FormInput
                onChangeHandler={handleHours}
                placeholder="hh"
                id="creditHours"
                name="creditHours"
                value={creditHours}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={handleMinutes}
                placeholder="mm"
                id="creditMinutes"
                name="creditMinutes"
                value={creditMinutes}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={handleSeconds}
                placeholder="ss"
                id="creditSeconds"
                name="creditSeconds"
                value={creditSeconds}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
