/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlansByQualities = /* GraphQL */ `
  query GetPlansByQualities($qualityIds: [ID]) {
    getPlansByQualities(qualityIds: $qualityIds) {
      id
      title
      status
      device_types {
        items {
          id
          plan_id
          plans {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          device_type_id
          device_types {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      video_quality_id
      additional_attributes
      is_ad_supported
      expire_max_mins_after_purchase
      expire_max_min_after_start
      createdAt
      updatedAt
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const getAdConfiguration = /* GraphQL */ `
  query GetAdConfiguration($id: ID!) {
    getAdConfiguration(id: $id) {
      id
      name
      status
      ad_decision_server_url
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listAdConfigurations = /* GraphQL */ `
  query ListAdConfigurations(
    $filter: ModelAdConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        ad_decision_server_url
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAdCampaign = /* GraphQL */ `
  query GetAdCampaign($id: ID!) {
    getAdCampaign(id: $id) {
      id
      name
      video_url
      video_by_quality_id
      country_code
      ad_configuration_id
      ad_configuration {
        id
        name
        status
        ad_decision_server_url
        additional_attributes
        createdAt
        updatedAt
      }
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const listAdCampaigns = /* GraphQL */ `
  query ListAdCampaigns(
    $filter: ModelAdCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        video_url
        video_by_quality_id
        country_code
        ad_configuration_id
        ad_configuration {
          id
          name
          status
          ad_decision_server_url
          additional_attributes
          createdAt
          updatedAt
        }
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`
export const getCaption = /* GraphQL */ `
  query GetCaption($id: ID!) {
    getCaption(id: $id) {
      id
      language
      url
      status
      video_id
      is_orignal
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listCaptions = /* GraphQL */ `
  query ListCaptions(
    $filter: ModelCaptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        url
        status
        video_id
        is_orignal
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAudioTrack = /* GraphQL */ `
  query GetAudioTrack($id: ID!) {
    getAudioTrack(id: $id) {
      id
      language
      url
      status
      is_orignal
      video_id
      createdAt
      updatedAt
    }
  }
`
export const listAudioTracks = /* GraphQL */ `
  query ListAudioTracks(
    $filter: ModelAudioTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudioTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        url
        status
        is_orignal
        video_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCastCrewType = /* GraphQL */ `
  query GetCastCrewType($id: ID!) {
    getCastCrewType(id: $id) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listCastCrewTypes = /* GraphQL */ `
  query ListCastCrewTypes(
    $filter: ModelCastCrewTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCastCrewTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCastCrew = /* GraphQL */ `
  query GetCastCrew($id: ID!) {
    getCastCrew(id: $id) {
      id
      first_name
      last_name
      nick_name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listCastCrews = /* GraphQL */ `
  query ListCastCrews(
    $filter: ModelCastCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCastCrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoCastCrew = /* GraphQL */ `
  query GetVideoCastCrew($id: ID!) {
    getVideoCastCrew(id: $id) {
      id
      video_id
      cast_crew_id
      cast_crew {
        id
        first_name
        last_name
        nick_name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      cast_crew_type_id
      cast_crew_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listVideoCastCrews = /* GraphQL */ `
  query ListVideoCastCrews(
    $filter: ModelVideoCastCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoCastCrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video_id
        cast_crew_id
        cast_crew {
          id
          first_name
          last_name
          nick_name
          description
          image_sm
          image_md
          image_lg
          status
          additional_attributes
          createdAt
          updatedAt
        }
        status
        cast_crew_type_id
        cast_crew_type {
          id
          title
          status
          additional_attributes
          createdAt
          updatedAt
        }
        display
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getContentRating = /* GraphQL */ `
  query GetContentRating($id: ID!) {
    getContentRating(id: $id) {
      id
      title
      status
      additional_attributes
      description
      videos {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listContentRatings = /* GraphQL */ `
  query ListContentRatings(
    $filter: ModelContentRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        additional_attributes
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getKnowledgeBase = /* GraphQL */ `
  query GetKnowledgeBase($id: ID!) {
    getKnowledgeBase(id: $id) {
      id
      question
      answer
      precedence
      category_id
      status
      created_by
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listKnowledgeBases = /* GraphQL */ `
  query ListKnowledgeBases(
    $filter: ModelKnowledgeBaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        precedence
        category_id
        status
        created_by
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getKnowledgeBaseCategory = /* GraphQL */ `
  query GetKnowledgeBaseCategory($id: ID!) {
    getKnowledgeBaseCategory(id: $id) {
      id
      Knowledge_base {
        items {
          id
          question
          answer
          precedence
          category_id
          status
          created_by
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      precedence
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listKnowledgeBaseCategories = /* GraphQL */ `
  query ListKnowledgeBaseCategories(
    $filter: ModelKnowledgeBaseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeBaseCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Knowledge_base {
          items {
            id
            question
            answer
            precedence
            category_id
            status
            created_by
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        title
        precedence
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      message
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      read
      generated_by
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        status
        read
        generated_by
        start_date
        end_date
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getSiteNotification = /* GraphQL */ `
  query GetSiteNotification($id: ID!) {
    getSiteNotification(id: $id) {
      id
      message
      status
      generated_by
      country_code
      start_date
      end_date
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const listSiteNotifications = /* GraphQL */ `
  query ListSiteNotifications(
    $filter: ModelSiteNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        generated_by
        country_code
        start_date
        end_date
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`
export const getOrganizationType = /* GraphQL */ `
  query GetOrganizationType($id: ID!) {
    getOrganizationType(id: $id) {
      id
      title
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listOrganizationTypes = /* GraphQL */ `
  query ListOrganizationTypes(
    $filter: ModelOrganizationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      image_sm
      image_md
      image_lg
      status
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoOrganization = /* GraphQL */ `
  query GetVideoOrganization($id: ID!) {
    getVideoOrganization(id: $id) {
      id
      video_id
      organization_id
      organization {
        id
        name
        description
        image_sm
        image_md
        image_lg
        status
        additional_attributes
        createdAt
        updatedAt
      }
      status
      organization_type_id
      organization_type {
        id
        title
        status
        additional_attributes
        createdAt
        updatedAt
      }
      display
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listVideoOrganizations = /* GraphQL */ `
  query ListVideoOrganizations(
    $filter: ModelVideoOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        video_id
        organization_id
        organization {
          id
          name
          description
          image_sm
          image_md
          image_lg
          status
          additional_attributes
          createdAt
          updatedAt
        }
        status
        organization_type_id
        organization_type {
          id
          title
          status
          additional_attributes
          createdAt
          updatedAt
        }
        display
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDeviceType = /* GraphQL */ `
  query GetDeviceType($id: ID!) {
    getDeviceType(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listDeviceTypes = /* GraphQL */ `
  query ListDeviceTypes(
    $filter: ModelDeviceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      title
      status
      video_quality_id
      additional_attributes
      is_ad_supported
      n_watches
      n_days_download_to_expire
      is_downloadable
      expire_max_mins_after_purchase
      expire_max_min_after_start
      createdAt
      updatedAt
      device_types {
        items {
          id
          plan_id
          device_type_id
          additional_attributes
          createdAt
          updatedAt
          device_types {
            id
            title
            status
            createdAt
            updatedAt
          }
          plans {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        video_quality_id
        additional_attributes
        is_ad_supported
        n_watches
        n_days_download_to_expire
        is_downloadable
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        device_types {
          items {
            id
            plan_id
            device_type_id
            additional_attributes
            createdAt
            updatedAt
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              n_watches
              is_downloadable
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`
export const getVideoPlan = /* GraphQL */ `
  query GetVideoPlan($id: ID!) {
    getVideoPlan(id: $id) {
      id
      status
      plan_id
      plan {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      video_by_quality_id
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const listVideoPlans = /* GraphQL */ `
  query ListVideoPlans(
    $filter: ModelVideoPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`
export const getPricing = /* GraphQL */ `
  query GetPricing($id: ID!) {
    getPricing(id: $id) {
      id
      country_code
      price
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const listPricings = /* GraphQL */ `
  query ListPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country_code
        price
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        video_id
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          statusValue
          
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`
export const getPromoType = /* GraphQL */ `
  query GetPromoType($id: ID!) {
    getPromoType(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`
export const listPromoTypes = /* GraphQL */ `
  query ListPromoTypes(
    $filter: ModelPromoTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      code
      reuse
      count
      status
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      percentage_off
      country_code
      end_date
      promo_type_id
      promo_type {
        id
        name
        description
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        max_use_limit_by_user
        unlimited
        video_id
        start_date
        user_id
        count
        max_use_limit
        status
        plan_id
        percentage_off
        country_code
        end_date
        promo_type_id
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video {
          id
          title
        }
        plan {
          id
          title
          status
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const promoCodeByCode = /* GraphQL */ `
  query PromoCodeByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodeByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        unlimited
        video_id
        start_date
        user_id
        count
        max_use_limit
        status
        plan_id
        percentage_off
        country_code
        end_date
        promo_type_id
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        plan {
          id
          title
          status
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
              type
            }
            nextToken
          }
          users_rating {
            items {
              id
              video_id
              is_liked
              user_id
              rating_on_timestamp
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`

export const getTrailer = /* GraphQL */ `
  query GetTrailer($id: ID!) {
    getTrailer(id: $id) {
      id
      type
      hls_url

      status
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      title
      is_main
      video_id
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listTrailers = /* GraphQL */ `
  query ListTrailers(
    $filter: ModelTrailerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrailers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        hls_url

        status
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        title
        is_main
        video_id
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTrailerViewLog = /* GraphQL */ `
  query GetTrailerViewLog($id: ID!) {
    getTrailerViewLog(id: $id) {
      id
      trailer_id
      trailer {
        id
        hls_url

        status
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        title
        is_main
        video_id
        additional_attributes
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      watched_at
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      country_code
      additional_attributes
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const listTrailerViewLogs = /* GraphQL */ `
  query ListTrailerViewLogs(
    $filter: ModelTrailerViewLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrailerViewLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trailer_id
        trailer {
          id
          hls_url

          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
          video_id
          additional_attributes
          createdAt
          updatedAt
        }
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        watched_at
        device_type_id
        device_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        ip_address
        country_code
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email_address
      first_name
      last_name
      status
      provider
      additional_attributes
      invite_promo_code_id
      promo_code {
        id
        code
        unlimited
        video_id
        start_date
        user_id
        count
        max_use_limit
        max_use_limit_by_user
        status
        plan_id
        percentage_off
        country_code
        end_date
        promo_type_id
        additional_attributes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserActivityLog = /* GraphQL */ `
  query GetUserActivityLog($id: ID!) {
    getUserActivityLog(id: $id) {
      id
      action_id
      action {
        id
        description
        status
        createdAt
        updatedAt
      }
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listUserActivityLogs = /* GraphQL */ `
  query ListUserActivityLogs(
    $id: ID
    $filter: ModelUserActivityLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserActivityLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        action_id
        action {
          id
          description
          status
          createdAt
          updatedAt
        }
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserActivityAction = /* GraphQL */ `
  query GetUserActivityAction($id: ID!) {
    getUserActivityAction(id: $id) {
      id
      description
      status
      createdAt
      updatedAt
    }
  }
`
export const listUserActivityActions = /* GraphQL */ `
  query ListUserActivityActions(
    $filter: ModelUserActivityActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActivityActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAvatar = /* GraphQL */ `
  query GetAvatar($id: ID!) {
    getAvatar(id: $id) {
      id
      name
      url
      status
      createdAt
      updatedAt
    }
  }
`
export const listAvatars = /* GraphQL */ `
  query ListAvatars(
    $filter: ModelAvatarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvatars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserRating = /* GraphQL */ `
  query GetUserRating($id: ID!) {
    getUserRating(id: $id) {
      id
      video_id
      rating
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      rating_on_timestamp
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            type
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const listUserRatings = /* GraphQL */ `
  query ListUserRatings(
    $filter: ModelUserRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video_id
        rating
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        rating_on_timestamp
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              type
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      videos {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        videos {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getGenre = /* GraphQL */ `
  query GetGenre($id: ID!) {
    getGenre(id: $id) {
      id
      title
      status
      videos {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listGenres = /* GraphQL */ `
  query ListGenres(
    $filter: ModelGenreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        videos {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideoType = /* GraphQL */ `
  query GetVideoType($id: ID!) {
    getVideoType(id: $id) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`
export const listVideoTypes = /* GraphQL */ `
  query ListVideoTypes(
    $filter: ModelVideoTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const planByQuality = /* GraphQL */ `
  query PlanByQuality(
    $video_quality_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planByQuality(
      video_quality_id: $video_quality_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        device_types {
          items {
            id
            plan_id
            plans {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            device_type_id
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        video_quality_id
        additional_attributes
        is_ad_supported
        expire_max_mins_after_purchase
        expire_max_min_after_start
        createdAt
        updatedAt
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $country_code: String
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountries(
      country_code: $country_code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        country_code
        country_calling_code
        country_name
        currency_symbol
        currency
        status
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        preferredCDN
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCountry = /* GraphQL */ `
  query GetCountry($country_code: String!) {
    getCountry(country_code: $country_code) {
      id
      country_code
      country_calling_code
      country_name
      currency
      preferredCDN
      status
      createdAt
      updatedAt
      payment_gateways {
        items {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!, $purchased_at: AWSDateTime!) {
    getOrder(id: $id, purchased_at: $purchased_at) {
      id
      status
      ref_no
      payment_total
      disabled
      payment_gateway_id
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      promo_code_id
      promo_code {
        max_use_limit_by_user
        id
        code
        reuse
        count
        status
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        percentage_off
        country_code
        end_date
        promo_type_id
        promo_type {
          id
          name
          description
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      purchased_at
      started_at
      expired_at
      expired
      is_gift
      twenty_five_percent_watched
      fifty_percent_watched
      seventy_five_percent_watched
      hundred_percent_watched
      video_plan_id
      video_plan {
        id
        status
        plan_id
        plan {
          id
          title
          status
          device_types {
            items {
              id
              plan_id
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_quality_id
          additional_attributes
          is_ad_supported
          expire_max_mins_after_purchase
          expire_max_min_after_start
          createdAt
          updatedAt
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        video_by_quality_id
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      video_id
      additional_attributes
      country_code
      createdAt
      updatedAt
      country {
        id
        country_code
        country_calling_code
        country_name
        currency
        status
        createdAt
        updatedAt
        payment_gateways {
          items {
            id
            name
            disabled
            country_code
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      view_logs {
        items {
          id
          order_id
          status
          last_timestamp
          minutes_streamed
          started_at
          user_id
          user {
            id
            email_address
            status
            additional_attributes
            createdAt
            updatedAt
          }
          video_by_quality_id
          device_type_id
          device_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          ip_address
          bandwidth_used
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      payment_gateway {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            type
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $purchased_at: ModelStringKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      purchased_at: $purchased_at
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        ref_no
        payment_total
        disabled
        payment_gateway_id
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        promo_code_id
        promo_code {
          max_use_limit_by_user
          id
          code
          reuse
          count
          status
          video_plan_id
          video_plan {
            id
            status
            plan_id
            plan {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            video_by_quality_id
            additional_attributes
            createdAt
            updatedAt
            video_by_quality {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          percentage_off
          country_code
          end_date
          promo_type_id
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          additional_attributes
          createdAt
          updatedAt
          country {
            id
            country_code
            country_calling_code
            country_name
            currency
            status
            createdAt
            updatedAt
            payment_gateways {
              nextToken
            }
          }
        }
        purchased_at
        started_at
        expired_at
        expired
        is_gift
        twenty_five_percent_watched
        fifty_percent_watched
        seventy_five_percent_watched
        hundred_percent_watched
        video_plan_id
        video_plan {
          id
          status
          plan_id
          plan {
            id
            title
            status
            device_types {
              nextToken
            }
            video_quality_id
            additional_attributes
            is_ad_supported
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          video_by_quality {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        video_id
        additional_attributes
        country_code
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        view_logs {
          items {
            id
            order_id
            status
            last_timestamp
            minutes_streamed
            started_at
            user_id
            user {
              id
              email_address
              status
              additional_attributes
              createdAt
              updatedAt
            }
            video_by_quality_id
            device_type_id
            device_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            ip_address
            bandwidth_used
            additional_attributes
            createdAt
            updatedAt
            video_by_quality {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        payment_gateway {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              type
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`
export const getViewLog = /* GraphQL */ `
  query GetViewLog($id: ID!) {
    getViewLog(id: $id) {
      id
      order_id
      status
      last_timestamp
      minutes_streamed
      started_at
      user_id
      user {
        id
        email_address
        status
        additional_attributes
        createdAt
        updatedAt
      }
      video_by_quality_id
      device_type_id
      device_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      ip_address
      bandwidth_used
      additional_attributes
      createdAt
      updatedAt
      video_by_quality {
        id
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message

          status
          release_date
          credits_start_timestamp
          is_orignal
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              type
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
export const listViewLogs = /* GraphQL */ `
  query ListViewLogs(
    $filter: ModelViewLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order_id
        status
        last_timestamp
        minutes_streamed
        started_at
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        video_by_quality_id
        device_type_id
        device_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        ip_address
        bandwidth_used
        additional_attributes
        createdAt
        updatedAt
        video_by_quality {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
      }
      nextToken
    }
  }
`
export const getPaymentProcessingStatus = /* GraphQL */ `
  query GetPaymentProcessingStatus($id: ID!) {
    getPaymentProcessingStatus(id: $id) {
      id
      status
      createdAt
      updatedAt
    }
  }
`
export const listPaymentProcessingStatuss = /* GraphQL */ `
  query ListPaymentProcessingStatuss(
    $filter: ModelPaymentProcessingStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentProcessingStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listPaymentGateways = /* GraphQL */ `
  query ListPaymentGateways(
    $filter: ModelPaymentGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPaymentGateway = /* GraphQL */ `
  query GetPaymentGateway($id: ID!) {
    getPaymentGateway(id: $id) {
      id
      name
      disabled
      country_code
      additional_attributes
      createdAt
      updatedAt
    }
  }
`
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        statusValue
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        non_mp4_group_outputs_sync_status
        mp4_group_outputs_sync_status
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            type
            hls_url
            supported_captions {
              items {
                id
                language
                url
                status
                video_id
                is_orignal
                additional_attributes
                jsonUrl
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            jsonUrl
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            cloudfront {
              hls_url
            }
            cloudflare {
              hls_url
            }
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      non_mp4_group_outputs_sync_status
      mp4_group_outputs_sync_status
      thumbnail_lg
      genres {
        items {
          id
          genre_id
          video_id
          genre {
            id
            title
            status
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      duration
      intro_start_time
      intro_end_time
      short_description
      long_description
      banner_message

      status
      release_date
      credits_start_timestamp
      is_orignal
      video_tags {
        items {
          id
          tag_id
          video_id
          tag {
            id
            name
            videos {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      language
      video_type_id
      video_type {
        id
        title
        status
        createdAt
        updatedAt
      }
      additional_attributes
      audio_tracks {
        items {
          id
          language
          url
          status
          is_orignal
          video_id
          createdAt
          updatedAt
        }
        nextToken
      }
      trailers {
        items {
          id
          type
          hls_url

          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
          video_id
          additional_attributes
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cast_crew {
        items {
          id
          video_id
          cast_crew_id
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          cast_crew_type_id
          cast_crew_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      organizations {
        items {
          id
          video_id
          organization_id
          organization {
            id
            name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
          status
          organization_type_id
          organization_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          display
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      supported_captions {
        items {
          id
          language
          url
          status
          video_id
          is_orignal
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      content_rating {
        items {
          id
          video_id
          content_rating_id
          content_rating {
            id
            title
            status
            additional_attributes
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      video_by_quality {
        items {
          id
          status
          video_id
          video_quality_id
          additional_attributes
          createdAt
          updatedAt
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            genres {
              nextToken
            }
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message

            status
            release_date
            credits_start_timestamp
            is_orignal
            video_tags {
              nextToken
            }
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            audio_tracks {
              nextToken
            }
            trailers {
              nextToken
            }
            cast_crew {
              nextToken
            }
            organizations {
              nextToken
            }
            supported_captions {
              nextToken
            }
            content_rating {
              nextToken
            }
            createdAt
            updatedAt
            video_by_quality {
              nextToken
            }
          }
          video_quality {
            id
            resolution
            status
            createdAt
            updatedAt
            vertical_resolution {
              title
              resolution
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`
export const listVideosByReleaseDate = /* GraphQL */ `
  query ListVideosByReleaseDate(
    $id: ID
    $release_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByReleaseDate(
      id: $id
      release_date: $release_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const listVideoQualities = /* GraphQL */ `
  query ListVideoQualities(
    $filter: ModelVideoQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoQualities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resolution
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getVideoQuality = /* GraphQL */ `
  query GetVideoQuality($id: ID!) {
    getVideoQuality(id: $id) {
      id
      resolution
      status
      createdAt
      updatedAt
      vertical_resolution {
        title
        resolution
        createdAt
        updatedAt
      }
    }
  }
`
export const listVerticalResolutions = /* GraphQL */ `
  query ListVerticalResolutions(
    $resolution: String
    $filter: ModelVerticalResolutionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVerticalResolutions(
      resolution: $resolution
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        title
        resolution
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVerticalResolution = /* GraphQL */ `
  query GetVerticalResolution($resolution: String!) {
    getVerticalResolution(resolution: $resolution) {
      title
      resolution
      createdAt
      updatedAt
    }
  }
`
export const getVideoByQuality = /* GraphQL */ `
  query GetVideoByQuality($id: ID!) {
    getVideoByQuality(id: $id) {
      id
      status
      video_id
      video_quality_id
      additional_attributes
      createdAt
      updatedAt
      video {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        genres {
          items {
            id
            genre_id
            video_id
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message

        status
        release_date
        credits_start_timestamp
        is_orignal
        video_tags {
          items {
            id
            tag_id
            video_id
            tag {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        language
        video_type_id
        video_type {
          id
          title
          status
          createdAt
          updatedAt
        }
        additional_attributes
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            createdAt
            updatedAt
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url

            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            cast_crew_type_id
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        organizations {
          items {
            id
            video_id
            organization_id
            organization {
              id
              name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
            status
            organization_type_id
            organization_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            display
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        video_by_quality {
          items {
            id
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message

              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      video_quality {
        id
        resolution
        status
        createdAt
        updatedAt
        vertical_resolution {
          title
          resolution
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const listVideoByQualities = /* GraphQL */ `
  query ListVideoByQualities(
    $filter: ModelVideoByQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoByQualities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cloudfront {
          hls_url
        }
        cloudflare {
          hls_url
        }
        status
        video_id
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          download_url
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message
          status
          statusValue
          release_date
          credits_start_timestamp
          is_orignal
          non_mp4_group_outputs_sync_status
          mp4_group_outputs_sync_status
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url
              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              type
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          supported_captions {
            items {
              id
              language
              url
              jsonUrl
              status
              video_id
              time_delta
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          users_rating {
            items {
              id
              video_id
              is_liked
              user_id
              rating_on_timestamp
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        video_quality_id
        video_quality {
          id
          resolution
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
          status
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getUserActivityLogsByUser = /* GraphQL */ `
  query GetUserActivityLogsByUser(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivityLogsByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action_id
        user_id
        user {
          id
          email_address
          status
          additional_attributes
          createdAt
          updatedAt
        }
        additional_attributes
        createdAt
        updatedAt
        action {
          id
          description
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email_address: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email_address: $email_address
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email_address
        first_name
        last_name
        status
        provider
        additional_attributes
        invite_promo_code_id
        createdAt
        updatedAt
        promo_code {
          id
          code
          unlimited
          video_id
          start_date
          user_id
          count
          max_use_limit
          max_use_limit_by_user
          status
          plan_id
          percentage_off
          country_code
          end_date
          promo_type_id
          additional_attributes
          createdAt
          updatedAt
          country {
            id
            country_code
            country_calling_code
            country_name
            currency
            status
            preferredCDN
            createdAt
            updatedAt
            payment_gateways {
              nextToken
            }
          }
          plan {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            n_days_download_to_expire
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          promo_code_usage {
            items {
              id
              user_id
              promo_code_id
              order_id
              createdAt
              updatedAt
            }
            nextToken
          }
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            download_url
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message
            status
            statusValue
            release_date
            credits_start_timestamp
            is_orignal
            non_mp4_group_outputs_sync_status
            mp4_group_outputs_sync_status
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            organizations {
              nextToken
            }
            createdAt
            updatedAt
            supported_captions {
              nextToken
            }
            audio_tracks {
              nextToken
            }
            cast_crew {
              nextToken
            }
            content_rating {
              nextToken
            }
            trailers {
              nextToken
            }
            users_rating {
              nextToken
            }
            video_tags {
              nextToken
            }
            genres {
              nextToken
            }
            video_by_quality {
              nextToken
            }
          }
        }
        devices {
          items {
            id
            user_id
            device_token
            device_type_id
            additional_attributes
            createdAt
            updatedAt
            device_types {
              id
              title
              status
              createdAt
              updatedAt
            }
            user {
              id
              email_address
              first_name
              last_name
              status
              provider
              additional_attributes
              invite_promo_code_id
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getOrdersByUser = /* GraphQL */ `
  query GetOrdersByUser(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        ref_no
        payment_total
        disabled
        payment_gateway_id
        user_id
        promo_code_id
        purchased_at
        started_at
        expired_at
        expired
        is_gift
        first_check_point
        second_check_point
        third_check_point
        fourth_check_point
        fifth_check_point
        sixth_check_point
        seventh_check_point
        eighth_check_point
        ninth_check_point
        tenth_check_point
        n_downloads
        video_plan_id
        video_id
        additional_attributes
        country_code
        createdAt
        updatedAt
        country {
          id
          country_code
          country_calling_code
          country_name
          currency
          status
          preferredCDN
          createdAt
          updatedAt
          payment_gateways {
            items {
              id
              name
              disabled
              country_code
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        view_logs {
          items {
            id
            order_id
            status
            last_timestamp
            minutes_streamed
            started_at
            ended_at
            user_id
            video_by_quality_id
            device_type_id
            ip_address
            bandwidth_used
            additional_attributes
            createdAt
            updatedAt
            device_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            user {
              id
              email_address
              first_name
              last_name
              status
              additional_attributes
              invite_promo_code_id
              createdAt
              updatedAt
            }
            video_by_quality {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        video_plan {
          id
          status
          plan_id
          video_by_quality_id
          additional_attributes
          createdAt
          updatedAt
          plan {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            n_days_download_to_expire
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          video_by_quality {
            id
            cloudfront {
              hls_url
            }
            cloudflare {
              hls_url
            }
            status
            video_id
            video_quality_id
            additional_attributes
            createdAt
            updatedAt
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              download_url
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message
              status
              statusValue
              release_date
              credits_start_timestamp
              is_orignal
              non_mp4_group_outputs_sync_status
              mp4_group_outputs_sync_status
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
        }
        payment_gateway {
          id
          name
          disabled
          country_code
          additional_attributes
          createdAt
          updatedAt
        }
        promo_code {
          id
          code
          unlimited
          video_id
          start_date
          user_id
          count
          max_use_limit
          max_use_limit_by_user
          status
          plan_id
          percentage_off
          country_code
          end_date
          promo_type_id
          additional_attributes
          createdAt
          updatedAt
          country {
            id
            country_code
            country_calling_code
            country_name
            currency
            status
            preferredCDN
            createdAt
            updatedAt
            payment_gateways {
              nextToken
            }
          }
          plan {
            id
            title
            status
            video_quality_id
            additional_attributes
            is_ad_supported
            n_watches
            n_days_download_to_expire
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            createdAt
            updatedAt
            device_types {
              nextToken
            }
            video_quality {
              id
              resolution
              status
              createdAt
              updatedAt
            }
          }
          promo_type {
            id
            name
            description
            createdAt
            updatedAt
          }
          promo_code_usage {
            items {
              id
              user_id
              promo_code_id
              order_id
              createdAt
              updatedAt
            }
            nextToken
          }
          video {
            id
            title
            sub_title
            main_title
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            download_url
            duration
            intro_start_time
            intro_end_time
            short_description
            long_description
            banner_message
            status
            statusValue
            release_date
            credits_start_timestamp
            is_orignal
            non_mp4_group_outputs_sync_status
            mp4_group_outputs_sync_status
            language
            video_type_id
            video_type {
              id
              title
              status
              createdAt
              updatedAt
            }
            additional_attributes
            organizations {
              nextToken
            }
            createdAt
            updatedAt
            supported_captions {
              nextToken
            }
            audio_tracks {
              nextToken
            }
            cast_crew {
              nextToken
            }
            content_rating {
              nextToken
            }
            trailers {
              nextToken
            }
            users_rating {
              nextToken
            }
            video_tags {
              nextToken
            }
            genres {
              nextToken
            }
            video_by_quality {
              nextToken
            }
          }
        }
        user {
          id
          email_address
          first_name
          last_name
          status
          additional_attributes
          invite_promo_code_id
          createdAt
          updatedAt
          promo_code {
            id
            code
            unlimited
            video_id
            start_date
            user_id
            count
            max_use_limit
            max_use_limit_by_user
            status
            plan_id
            percentage_off
            country_code
            end_date
            promo_type_id
            additional_attributes
            createdAt
            updatedAt
            country {
              id
              country_code
              country_calling_code
              country_name
              currency
              status
              preferredCDN
              createdAt
              updatedAt
            }
            plan {
              id
              title
              status
              video_quality_id
              additional_attributes
              is_ad_supported
              n_watches
              n_days_download_to_expire
              is_downloadable
              expire_max_mins_after_purchase
              expire_max_min_after_start
              createdAt
              updatedAt
            }
            promo_type {
              id
              name
              description
              createdAt
              updatedAt
            }
            promo_code_usage {
              nextToken
            }
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              download_url
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message
              status
              statusValue
              release_date
              credits_start_timestamp
              is_orignal
              non_mp4_group_outputs_sync_status
              mp4_group_outputs_sync_status
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          devices {
            items {
              id
              user_id
              device_token
              device_type_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          download_url
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message
          status
          statusValue
          release_date
          credits_start_timestamp
          is_orignal
          non_mp4_group_outputs_sync_status
          mp4_group_outputs_sync_status
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          supported_captions {
            items {
              id
              language
              url
              jsonUrl
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url

              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              type
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          users_rating {
            items {
              id
              video_id
              is_liked
              user_id
              rating_on_timestamp
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`

export const listLandingPageData = /* GraphQL */ `
  query ListLandingPageData(
    $device_name: String
    $filter: ModelLandingPageDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLandingPageData(
      device_name: $device_name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        device_name
        title {
          text
          font_size
          color
          background_color
          background_opacity
          background_blurriness
        }
        sub_title {
          text
          font_size
          color
          background_color
          background_opacity
          background_blurriness
        }
        banner {
          text
          font_size
          color
          background_color
          background_opacity
          background_blurriness
        }
        social_icons {
          bottom_offset
          color
          background_color
          background_opacity
          background_blurriness
          disabled
        }
        background {
          url
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const videoByQuityByVideo = /* GraphQL */ `
  query VideoByQuityByVideo(
    $video_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoByQualityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoByQuityByVideo(
      video_id: $video_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cloudfront {
          hls_url
        }
        cloudflare {
          hls_url
        }
        status
        video_id
        video_quality_id
        additional_attributes
        createdAt
        updatedAt
        video {
          id
          title
          sub_title
          main_title
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          download_url
          duration
          intro_start_time
          intro_end_time
          short_description
          long_description
          banner_message
          status
          statusValue
          release_date
          credits_start_timestamp
          is_orignal
          non_mp4_group_outputs_sync_status
          mp4_group_outputs_sync_status
          language
          video_type_id
          video_type {
            id
            title
            status
            createdAt
            updatedAt
          }
          additional_attributes
          organizations {
            items {
              id
              video_id
              organization_id
              status
              organization_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          supported_captions {
            items {
              id
              language
              url
              jsonUrl
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          audio_tracks {
            items {
              id
              language
              url
              status
              is_orignal
              video_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          cast_crew {
            items {
              id
              video_id
              cast_crew_id
              status
              cast_crew_type_id
              display
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          content_rating {
            items {
              id
              video_id
              content_rating_id
              createdAt
              updatedAt
            }
            nextToken
          }
          trailers {
            items {
              id
              hls_url
              status
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              title
              is_main
              video_id
              type
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          users_rating {
            items {
              id
              video_id
              is_liked
              user_id
              rating_on_timestamp
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
          video_tags {
            items {
              id
              tag_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          genres {
            items {
              id
              genre_id
              video_id
              createdAt
              updatedAt
            }
            nextToken
          }
          video_by_quality {
            items {
              id
              status
              video_id
              video_quality_id
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        video_quality {
          id
          resolution
          status
          createdAt
          updatedAt
          vertical_resolution {
            title
            resolution
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`
