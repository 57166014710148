import { fetchPricings, deletePricings } from '../../backend/pricings'
import { fetchMovies } from '../../backend/movies'

// constants
const SET_PRICINGS = 'SET_PRICINGS'

// actions

// thunks
export const fetchPricingsData = () => {
  return async (dispatch) => {
    try {
      let dummy = []
      const pricings = await fetchPricings()
      let movies = await fetchMovies()
      
      await movies.map((movie) => {
        let contains = pricings.some( price => price?.video?.id === movie?.id)
        if(!contains){
          dummy.push({id: "", country_code: "", country: { country_name: ""}, video_plan: {}, video_id: movie.id, video: movie})
        }
      })

      dispatch({
        type: SET_PRICINGS,
        payload: pricings.concat(dummy)
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export const deletePricingsData = (item) => {
  return async (dispatch, getState) => {
    try {
      await deletePricings(item)

      const itemIds = item.map((i) => i.id)

      const pricings = getState().pricingsData

      const filteredPricings = pricings.filter((i) => itemIds.indexOf(i.id) < 0)

      dispatch({
        type: SET_PRICINGS,
        payload: filteredPricings
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PRICINGS:
      return payload

    default:
      return state
  }
}

export default reducer
