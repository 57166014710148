import _ from 'lodash'

export const validateMetaInformation = (
  mainTitle,
  releaseDate,
  videoGenres
) => {
  if (
    _.isEmpty(mainTitle) ||
    _.isEmpty(releaseDate) ||
    videoGenres.length === 0
  )
    return false

  return true
}
