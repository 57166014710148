import React, { useEffect, useState } from 'react'

import './moviesList.css'
import { CreateButton, FormInput, Header } from '../../components'
import { MovieCard } from './_moviesList'
import { useHistory } from 'react-router-dom'
import { fetchMovies } from '../../backend/movies'

export function MoviesList() {
  const history = useHistory()
  const [movies, setMovies] = useState([])
  const [searchMovie, setSearchMovie] = useState("")

  useEffect(() => {
    ; (async () => {
      try {
        const movies = await fetchMovies()
        setMovies(movies)
      } catch (err) {
        console.log('Error in fetching movies is: ', err)
      }
    })()

  }, [])

  return (
    <div className="dashboard-page">
      <Header title="Movie List" />
      <div className="plan-filter-container centered">
        <div className="search-container">
          <FormInput
            placeholder="Search"
            id="plan-search"
            name="search"
            value={searchMovie}
            onChangeHandler={(e) => { setSearchMovie(e.target.value.toLowerCase()); }}
          />
        </div>
      </div>

      <div className="movies-list-content">
        <CreateButton onClickHandler={() => history.push('/create-movie')} />
        {movies.filter((movie) => {
          if (searchMovie === "") {
            return movie;
          }
          const regex = new RegExp(`${searchMovie}`, 'gi');
          return movie.title.match(regex);
        }).map((movie) => {
          return <MovieCard movie={movie} key={movie.id} />
        })}
      </div>
    </div>
  )
}
