import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchCast } from '../redux/modules/cast'

export const useCast = () => {
  const cast = useSelector((state) => state.cast)
  const dispatch = useDispatch()

  useEffect(() => {
    if (cast.length === 0) dispatch(fetchCast())
  }, [cast, dispatch])

  return { cast }
}
