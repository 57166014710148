import React, { useReducer, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { ButtonComponent, FormInput, Header } from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { addCategory, updateCategory } from '../../redux/modules/helpCategory'
import { validateHelpCategory } from '../../lib/util'

export const CreateQNACategory = () => {
  const history = useHistory()
  const [error, setError] = useState(null)
  const helpCategoryList = useSelector((state) => state.helpQNA.list)
  const helpCategory = useSelector((state) => state.helpCategory)
  const categoryCount = helpCategory.length

  const dispatch = useDispatch()

  const { categoryId } = useParams()

  const [{ title, precedence }, sethelpCategory] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      title: '',
      precedence: ''
    }
  )

  useEffect(() => {
    if (categoryId) {
      const filteredlist = helpCategoryList.filter(
        (categoryItem) => categoryItem.id === categoryId
      )

      if (filteredlist.length > 0) {
        const { title, precedence } = filteredlist[0]

        sethelpCategory({
          title: title,
          precedence: precedence
        })

        return
      }
    }

    history.replace('/create-qna-category')
  }, [categoryId, categoryCount, history, sethelpCategory])

  const onChangeHandler = ({ target: { name, value } }) =>
    sethelpCategory({ [name]: value })

  const handleChange = (key, value) => {
    sethelpCategory({ [key]: value })
  }

  const addNewCategory = async () => {
    var precedenceValue = precedence
    if (precedence != '') precedenceValue = parseInt(precedence)

    const payload = {
      title: title,
      precedence: precedenceValue,
      status: true
    }

    const { status, message } = validateHelpCategory(payload)
    if (!status) {
      setError(message)
      return
    }

    try {
      await dispatch(addCategory(payload))
      history.replace('/help-qna')
    } catch (err) {
      console.log('Error in creating category is: ', err)
    }
  }

  const UpdateCategory = async () => {
    var precedenceValue = precedence
    if (precedence != '') precedenceValue = parseInt(precedence)

    const payload = {
      title: title,
      precedence: precedenceValue,
      status: true,
      id: categoryId
    }
    try {
      await dispatch(updateCategory(payload))
      history.replace('/help-qna')
    } catch (err) {
      console.log('Error in updating category is: ', err)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title={categoryId ? 'Update Category' : 'Create Category'} />

      <div className="create-plan-container">
        <div className="create-plan-input-container">
          <div className="create-plan-input">
            <div className="promo-code-form-label">Category *</div>
            <FormInput
              placeholder="Category"
              id="title"
              name="title"
              onChangeHandler={onChangeHandler}
              value={title}
            />
          </div>
          <div className="create-plan-input">
            <div className="promo-code-form-label">Precedence</div>
            <FormInput
              placeholder="Precedence"
              id="precedence"
              name="precedence"
              onChangeHandler={({ target: { value } }) =>
                handleChange('precedence', value)
              }
              value={precedence}
            />
          </div>
        </div>
      </div>
      <div className="create-plan-footer">
        <div className="form-error">{error}</div>
        <div className="create-plan-footer-button">
          <ButtonComponent
            title="CANCEL"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={() => history.replace('/help-qna')}
            width={130}
          />
        </div>
        <div className="create-plan-footer-button">
          <ButtonComponent
            title={categoryId ? 'UPDATE' : 'SAVE'}
            onClickHandler={categoryId ? UpdateCategory : addNewCategory}
            width={130}
          />
        </div>
      </div>
    </div>
  )
}
