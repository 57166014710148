import React, { useReducer, useState, useEffect } from 'react'
import {
  LinearProgress,
  Chip,
  Switch,
  Divider,
  IconButton,
  Checkbox
  // FormLabel
} from '@material-ui/core'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuid4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import ReactDropzone from 'react-dropzone'
import { Storage } from 'aws-amplify'
import CloseIcon from '@material-ui/icons/Close'
import config from '../../../config'

import {
  FormInput,
  ButtonComponent,
  SelectInput,
  ImageDropZone,
  VideoAutoSelector,
  OrSeparator
} from '../../../components'
import {
  addTrailerTitle,
  removeTrailer,
  setData,
  setTrailerThumbNail,
  setTrailer,
  setMainTrailer
} from '../../../redux/modules/newMovie'
import { LanguageList } from '../../../lib/constants'
import { PrimaryColor } from '../../../styles/colors'
import { AutoFillField } from '../../../components/autoFillField'
import { SourceBucket } from '../../../classes'

export const VideoUploadForm = ({ isTrailer = false }) => {
  const sourceBucket = new SourceBucket()
  const { movie, movieFromBucket, videoId } = useSelector(
    (state) => state.newMovie
  )

  const [name, setName] = useState(null)

  const dispatch = useDispatch()

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done' && name) {
      dispatch(
        setData({
          movie: name,
          duration: meta.duration ? meta.duration * 1000 : 0,
          movieFromBucket: null
        })
      )
      setName(null)
    }

    if (status === 'headers_received') {
      remove()
    } else if (status === 'aborted') {
      alert('Something went wrong please try again')
      setName(null)
    }
  }

  // protected video uploading
  const getUploadParams = async ({ file, meta: { name } }) => {
    const fileParts = file.name.split('.')
    const fileType = fileParts[1]
    const fileName = `protected/${videoId}/${uuid4()}.${fileType}`

    setName(fileName)

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName,
      Expires: 60,
      ContentType: fileType
    }

    const _url = await sourceBucket.getUploadURL(params)

    return {
      body: file,
      method: 'PUT',
      meta: {
        fileUrl: `${process.env.REACT_APP_BUCKET_URL}/${fileName}`
      },
      url: _url
    }
  }

  const handleBucketVideoChange = (value) => {
    dispatch(
      setData({
        movie: value ? value.Key : null,
        movieFromBucket: value
      })
    )
  }

  return (
    <div className="movie-form">
      <VideoAutoSelector
        isProtected={true}
        value={movieFromBucket}
        handleChange={handleBucketVideoChange}
      />
      <OrSeparator />
      <Dropzone
        autoUpload={true}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputContent={
          <div className="column-centered">
            <p className="zero-margin">{'UPLOAD MOVIE'}</p>
            {movie && <p className="trailer-name">({movie})</p>}
          </div>
        }
        styles={{
          dropzone: {
            width: '100%',
            borderWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed gray'
          },
          inputLabel: { fontSize: 14, fontWeight: 'normal', color: 'black' }
        }}
        accept="video/*"
      />
    </div>
  )
}

const dropZoneStyle = {
  dropzone: {
    minHeight: 35,
    borderWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed gray'
  },
  inputLabel: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    color: 'black'
  }
}

export const TrailerUploadForm = ({ trailer, index }) => {
  const sourceBucket = new SourceBucket()
  const [name, setName] = useState(null)
  const { trailers } = useSelector((state) => state.newMovie)
  const count = trailers.length
  const {
    title,
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    key,
    id,
    isMain
  } = trailer
  const dispatch = useDispatch()

  useEffect(() => {
    if (count === 1) dispatch(setMainTrailer(id))
  }, [count])

  const handleIsMain = () => dispatch(setMainTrailer(id))

  const handleTitle = ({ target: { value } }) =>
    dispatch(addTrailerTitle(value, id))

  const thumbnails = [
    {
      id: 'thumbnail_lg',
      title: 'Upload Thumbnail (lg)',
      imageUrl: thumbnail_lg
    },
    {
      id: 'thumbnail_md',
      title: 'Upload Thumbnail (md)',
      imageUrl: thumbnail_md
    },
    {
      id: 'thumbnail_sm',
      title: 'Upload Thumbnail (sm)',
      imageUrl: thumbnail_sm
    }
  ]

  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done' && name) {
      console.log(status, meta, file)
      dispatch(setTrailer(name, id))
      setUploadData({ uploading: false })
      setName(null)
    }

    if (status === 'headers_received') {
      remove()
    } else if (status === 'aborted') {
      alert('Something went wrong please try again')
      setName(null)
    }
  }
  // public video
  const getUploadParams = async ({ file, meta: { duration } }) => {
    const fileParts = file.name.split('.')
    const fileType = fileParts[1]
    const fileName = `public/${id}/${uuid4()}.${fileType}`

    setName(fileName)

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName,
      Expires: 60,
      ContentType: fileType
    }

    const _url = await sourceBucket.getUploadURL(params)

    return {
      body: file,
      method: 'PUT',
      meta: {
        fileUrl: `${process.env.REACT_APP_BUCKET_URL}/${fileName}`
      },
      url: _url
    }
  }

  const handleTrailerRemovel = () => {
    dispatch(removeTrailer(id))
  }

  return (
    <div className="trailer-form-container">
      <div className="trailer-form">
        <div className="caption-upload-button">
          {uploading && (
            <div className={'caption-upload-progress'}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </div>
          )}
          <div className="form-title-input">
            <FormInput
              value={title}
              placeholder="Title"
              id="title"
              name="title"
              wi
              onChangeHandler={handleTitle}
            />
          </div>

          <div className="trailer-drop-zone-container">
            <Dropzone
              autoUpload={true}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              inputContent={
                <div>
                  <p style={{ margin: 0 }}>{'UPLOAD TRAILER'}</p>
                  {key && <p className="trailer-name">({key})</p>}
                </div>
              }
              styles={dropZoneStyle}
              accept="video/*"
            />
          </div>
        </div>
      </div>
      <div className="create-movie-thumbnail-item">
        {thumbnails.map(({ id: thumbnailId, title, imageUrl }) => {
          return (
            <ImageDropZone
              key={thumbnailId}
              imageUrl={imageUrl}
              title={title}
              onChange={(value) =>
                dispatch(setTrailerThumbNail(value, thumbnailId, id))
              }
            />
          )
        })}
      </div>

      <div className="trailer-container-close-button">
        <IconButton size="small" onClick={handleTrailerRemovel}>
          <CloseIcon style={{ height: 15, width: 15, color: 'red' }} />
        </IconButton>
      </div>

      <div className="trailer-container-is-main">
        <p>Is Main</p>
        <Checkbox
          checked={isMain}
          onClick={handleIsMain}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    </div>
  )
}

export const CaptionForm = ({ item }) => {
  const { captions, videoId } = useSelector((state) => state.newMovie)

  const dispatch = useDispatch()

  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )

  const handleUpload = async (acceptedFiles) => {
    if (uploading) return

    setUploadData({ uploading: true })

    const file = acceptedFiles[0]
    let fileName = uuid4()
    let fileType = file.name.split('.').pop()

    try {
      const { key } = await Storage.put(
        `${videoId}/${config.captionsFolder}/${fileName}.${fileType}`,
        file,
        {
          progressCallback(progress) {
            const { loaded, total } = progress

            setUploadData({
              uploadProgress: Math.floor((loaded * 100) / total)
            })
          }
        }
      )

      const newCaptions = captions.map((caption) => {
        if (caption.language === item.language) {
          return { ...caption, file: key }
        }

        return { ...caption }
      })

      dispatch(setData({ captions: newCaptions }))

      setUploadData({ uploading: false, uploadProgress: 0 })
    } catch (error) {
      setUploadData({ uploading: false, uploadProgress: 0 })
    }
  }

  const handleOrignal = ({ target: { checked } }) => {
    const newCaptions = captions.map((caption) => {
      if (caption.language === item.language) {
        return { ...caption, isOrignal: checked }
      }

      return { ...caption }
    })

    dispatch(setData({ captions: newCaptions }))
  }

  return (
    <div className="caption-form">
      <div className="caption-form-input-container">
        <div className="caption-form-input">
          <FormInput
            value={item.language}
            placeholder="None"
            id="none"
            name="none"
          />
        </div>

        <div className="cast-card-toggle-container">
          Is Orignal
          <Switch
            checked={item.isOrignal}
            onChange={handleOrignal}
            size="small"
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      <div className="caption-upload-button">
        {uploading && (
          <div className={'caption-upload-progress'}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
        {item.file && (
          <div className={'chip-container'}>
            {' '}
            <Chip label="Uploaded" />{' '}
          </div>
        )}
        <ReactDropzone onDrop={handleUpload} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} accept=".vtt" />
                <ButtonComponent
                  borderColor={PrimaryColor}
                  titleColor={PrimaryColor}
                  title="ADD"
                  onClickHandler={() => {}}
                  width={90}
                  height={30}
                  icon={
                    <BackupOutlinedIcon
                      style={{ fontSize: 24, paddingRight: 10 }}
                    />
                  }
                />
              </div>
            </section>
          )}
        </ReactDropzone>
      </div>
    </div>
  )
}

export const AddCaptionModal = ({ closeModal }) => {
  const { captions } = useSelector((state) => state.newMovie)
  const captionsCount = captions.length

  const [selectedCaptions, setSelectedCaptions] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    let captionLanguages = captions.map((item) => item.language)

    setSelectedCaptions(captionLanguages)
  }, [captionsCount])

  const addCaptions = () => {
    const existedCaptions = captions.map((caption) => caption.language)

    const newLanguages = selectedCaptions.filter(
      (item) => !existedCaptions.includes(item)
    )

    const oldCaptions = captions.filter((caption) =>
      selectedCaptions.includes(caption.language)
    )

    const newCaptions = newLanguages.map((item) => {
      return { language: item, file: null, isOrignal: false }
    })

    dispatch(setData({ captions: [...oldCaptions, ...newCaptions] }))

    closeModal()
  }

  const handleAdd = (checked, name) => {
    if (checked) {
      setSelectedCaptions([...selectedCaptions, name])

      return
    }

    let temp = [...selectedCaptions]

    temp.splice(temp.indexOf(name), 1)

    setSelectedCaptions(temp)
  }

  return (
    <div className="caption-modal-container">
      <h3 className="caption-modal-title">Add Caption</h3>
      <div className="caption-languages">
        {LanguageList.map((item) => {
          return (
            <div key={item.id}>
              <Switch
                name={item.name}
                checked={
                  selectedCaptions.indexOf(item.name) >= 0 ? true : false
                }
                onChange={(event, checked) => handleAdd(checked, item.name)}
                color="primary"
              />
              {item.name}
            </div>
          )
        })}
      </div>
      <div className="caption-modal-footer">
        <div className="caption-modal-footer-button">
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Cancel"
            width={90}
            height={25}
            onClickHandler={closeModal}
          />
        </div>
        <div>
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Apply"
            width={90}
            height={25}
            onClickHandler={addCaptions}
          />
        </div>
      </div>
    </div>
  )
}

export const AddCast = ({ close, handleAddition }) => {
  const { castCrew } = useSelector((state) => state.newMovie)
  const castCrewKeys = castCrew ? Object.keys(castCrew).length : []

  const cast = useSelector((state) => state.cast)

  const [castCrewTypes, setCastCrewTypes] = useState([])
  const [selectedCastType, setSelectedCastType] = useState('-1')
  const [selectedCast, setSelectedCast] = useState()

  useEffect(() => {
    if (selectedCastType !== '-1') {
      setSelectedCast(null)
      const castIds = castCrew[selectedCastType].cast.map((item) => {
        return item.id
      })
      setTimeout(() => {
        setSelectedCast(castIds)
      }, 100)
    }
  }, [selectedCastType, castCrewKeys, setSelectedCast])

  useEffect(() => {
    if (castCrewKeys > 0) {
      const types = Object.keys(castCrew).map((id) => {
        return { id, value: id, name: castCrew[id].title }
      })

      setCastCrewTypes(types)
      if (types.length > 0) setSelectedCastType(types[0].id)
    }
  }, [castCrewKeys, setSelectedCastType, setCastCrewTypes])

  const handleCastSelection = (value) => {
    const ids = value.map((item) => item.id)
    setSelectedCast(ids)
  }

  const renderAutoComplete = () => {
    const defaultValue = selectedCast
      ? cast.filter((item) => selectedCast.indexOf(item.id) > -1)
      : []
    if (defaultValue.length > 0) {
      return (
        <AutoFillField
          check={1}
          label={'Select Cast'}
          data={cast}
          defaultValue={defaultValue}
          handleCastSelection={handleCastSelection}
        />
      )
    } else
      return (
        <AutoFillField
          check={2}
          label={'Select Cast'}
          data={cast}
          defaultValue={[]}
          handleCastSelection={handleCastSelection}
        />
      )
  }

  return (
    <div className="add-cast-conatiner">
      <h2 className="add-cast-modal-title">Add Cast</h2>
      <div className="add-cast-item-container">
        <p style={{ paddingBottom: 5, margin: 0 }}>Select Type</p>
        <SelectInput
          name={'castTypes'}
          data={[...castCrewTypes]}
          value={selectedCastType}
          changeHandler={(event) => setSelectedCastType(event.target.value)}
        />
      </div>
      <div className="add-cast-item-container">
        <div style={{ width: 350, minHeight: 50 }}>
          {selectedCast && selectedCastType && renderAutoComplete()}
        </div>
      </div>

      <div className="add-cast-item-container">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={'white'}
          backgroundColor={PrimaryColor}
          title="Add"
          onClickHandler={() => handleAddition(selectedCast, selectedCastType)}
          width={'100%'}
          height={36}
        />
      </div>

      <div className="add-cast-item-container">
        <ButtonComponent
          borderColor={PrimaryColor}
          titleColor={PrimaryColor}
          title="Cancel"
          onClickHandler={close}
          width={'100%'}
          height={36}
        />
      </div>
    </div>
  )
}

export const CastTypeHeader = ({ title }) => {
  return (
    <div className="step-three-cast-type-header">
      <p className="step-three-cast-type-header-title">{title}</p>
      <div className="step-three-cast-type-header-divider">
        <Divider style={{ width: '90%' }} />
      </div>
    </div>
  )
}

export const CastCard = ({ castTypeId, castItemId, name, display }) => {
  const dispatch = useDispatch()

  const { castCrew } = useSelector((state) => state.newMovie)

  const toggleDisplay = (castTypeId, castId, checked) => {
    let updatedCast = castCrew[castTypeId].cast
    const selectedCastIndex = updatedCast.findIndex(
      (cast) => cast.id === castId
    )

    if (selectedCastIndex > -1)
      updatedCast.splice(selectedCastIndex, 1, {
        ...updatedCast[selectedCastIndex],
        display: checked
      })

    dispatch(
      setData({
        castCrew: {
          ...castCrew,
          [castTypeId]: { ...castCrew[castTypeId], cast: updatedCast }
        }
      })
    )
  }

  return (
    <div key={castItemId} className="cast-card-container">
      <div className="cast-card-name">{name}</div>
      <div className="cast-card-toggle-container">
        Display
        <Switch
          checked={display}
          onChange={(event) =>
            toggleDisplay(castTypeId, castItemId, event.target.checked)
          }
          size="small"
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  )
}
