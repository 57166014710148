import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetContentRatings } from '../redux/modules/contentRating'

export const useContentRatings = () => {
  const contentRatings = useSelector((state) => state.contentRatings)
  const dispatch = useDispatch()

  useEffect(() => {
    if (contentRatings.length === 0) dispatch(fetContentRatings())
  }, [contentRatings, dispatch])

  return { contentRatings }
}
