import { API } from 'aws-amplify'

import {
  createPlan,
  batchCreatePlanDeviceTypes,
  updatePlan,
  batchDeletePlanDeviceTypes
} from '../graphql/mutations'

export const handlePlanCreation = async (payload, selectedDevices) => {
  try {
    const {
      data: {
        createPlan: { id }
      }
    } = await API.graphql({
      query: createPlan,
      variables: { input: payload }
    })

    addDevices(id, selectedDevices)

    return
  } catch (err) {
    throw err
  }
}

export const handleUpdatePlan = async (
  payload,
  selectedDevices,
  previousDevices
) => {
  try {
    await API.graphql({
      query: updatePlan,
      variables: { input: payload }
    })

    const toDeletePayload = previousDevices.map(({ id }) => {
      return id
    })

    await deleteDevices(toDeletePayload)
    await addDevices(payload.id, selectedDevices)
    return
  } catch (err) {
    throw err
  }
}

const addDevices = async (id, selectedDevices) => {
  const filteredSelectedDevices = selectedDevices.filter(
    (device) => device !== 'Select Device'
  )

  const planDevices = filteredSelectedDevices.map((device) => {
    return {
      device_type_id: device,
      plan_id: id
    }
  })

  await API.graphql({
    query: batchCreatePlanDeviceTypes,
    variables: { planDevices: planDevices }
  })
}

const deleteDevices = async (payload) => {
  await API.graphql({
    query: batchDeletePlanDeviceTypes,
    variables: { planDevices: payload }
  })
}
