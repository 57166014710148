import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ApppBar, useStyles } from '../../components/AppBar'
import { MoviesList } from '../MoviesList'
import { DrawerMenus } from '../../lib/constants'
import { PlanList } from '../PlanList'
import { CreateUpdatePlan } from '../CreateUpdatePlan'
import { CountriesList } from '../CountriesList'
import { CreateCountry } from '../CreateCountry'
import { Pricing } from '../Pricing'
import { CreatePricing } from '../CreatePricing'
import { logoutUser } from '../../redux/modules/user'
import { CreateMovie } from '../CreateMovie'
import { Notifications } from '../Notifications'
import { MoviePricing } from '../MoviePricing'
import { EditPricing } from '../EditPricing'
import { CastList } from '../CastList'
import { CreateCast } from '../CreateCast'
import { UserManagement } from '../UserManagement'
import { OrderManagement } from '../OrderManagement'
import { UserDetail } from '../UserManagement/userDetail'
import { UserActivityLog } from '../UserActivityLog'
import { HelpQNA } from '../HelpQNA'
import { CreateQNA } from '../CreateQNA'
import { CreateQNACategory } from '../CreateQNACategory'
import { PromoCode } from '../PromoCode'
import { PromoCodeList } from '../promoCodeList'
import { EditMovie } from '../EditMovie'
import { Alert } from '../../components/alert'
import { LandingPage } from '../LandingPage'

export function Dashboard() {
  const classes = useStyles()

  const [selectedPage, setSelectedPage] = useState('MoviesList')

  const location = useLocation()

  const history = useHistory()

  const dispatch = useDispatch()

  const User = useSelector((state) => state.user)

  const isAdmin =
    User.accessToken.payload['cognito:groups'].indexOf('admin') > -1

  useEffect(() => {
    let pageUrl = location.pathname.split('/')
    pageUrl = pageUrl[pageUrl.length - 1]

    if (
      pageUrl === 'dashboard' ||
      pageUrl === 'create-movie' ||
      location.pathname.includes('edit-movie')
    )
      setSelectedPage('')
    else if (
      pageUrl === 'plan-list' ||
      pageUrl === 'create-plan' ||
      location.pathname.includes('edit-plan')
    )
      setSelectedPage('plan-list')
    else if (pageUrl === 'promo-code' || pageUrl === 'create-promo-code')
      setSelectedPage('promo-code')
    else if (pageUrl === 'countries' || pageUrl === 'create-country')
      setSelectedPage('countries')
    else if (
      pageUrl === 'pricing' ||
      pageUrl === 'create-pricing' ||
      location.pathname.includes('pricing')
    )
      setSelectedPage('pricing')
    else if (
      pageUrl === 'cast-list' ||
      pageUrl === 'create-cast' ||
      location.pathname.includes('edit-cast')
    )
      setSelectedPage('cast-list')
    else if (pageUrl === 'user-management') setSelectedPage('user-management')
    else if (
      pageUrl === 'help-qna' ||
      pageUrl === 'create-qna' ||
      pageUrl === 'create-qna-category'
    )
      setSelectedPage('help-qna')
    else if (pageUrl === 'landing-page') setSelectedPage('landing-page')
    else setSelectedPage(pageUrl)
  }, [location.pathname])

  const handleMenuPress = (item) => {
    const { id, url } = item

    if (id === '12') {
      dispatch(logoutUser())
      history.push(`/`)
    } else history.push(`/${url}`)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApppBar classes={classes} open={true} />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper)
        }}
        open={true}>
        <div className={classes.toolbarIcon}>
          <h2>Admin Panel</h2>
        </div>
        <Divider />
        <div>
          <List>
            {DrawerMenus.map((item) => (
              <div key={item.id}>
                <ListItem
                  role="presentation"
                  onClick={() => handleMenuPress(item)}
                  button
                  key={item.name}
                  className={
                    selectedPage === item.url
                      ? classes.selectedDrawerMenu
                      : classes.drawerMenu
                  }>
                  <ListItemText
                    primary={item.name}
                    classes={{
                      primary:
                        selectedPage === item.url
                          ? classes.selectedDrawerMenuText
                          : classes.drawerMenuText
                    }}
                  />
                  <KeyboardArrowRightIcon
                    className={
                      selectedPage === item.url
                        ? classes.selectedRightIcon
                        : classes.rightIcon
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Switch>
          <Route exact path="/">
            <MoviesList />
          </Route>
          {isAdmin && (
            <Route exact path="/create-movie">
              <CreateMovie />
            </Route>
          )}
          {isAdmin && (
            <Route exact path="/edit-movie/:id">
              <EditMovie />
            </Route>
          )}
          <Route exact path="/plan-list">
            <PlanList />
          </Route>
          {isAdmin && (
            <Route exact path="/create-plan">
              <CreateUpdatePlan />
            </Route>
          )}
          {isAdmin && (
            <Route exact path="/edit-plan/:id">
              <CreateUpdatePlan />
            </Route>
          )}
          <Route exact path="/countries">
            <CountriesList />
          </Route>
          {isAdmin && (
            <Route exact path="/create-country">
              <CreateCountry />
            </Route>
          )}
          <Route exact path="/notifications">
            <Notifications />
          </Route>
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/pricing/:videoId">
            <MoviePricing />
          </Route>
          {isAdmin && (
            <Route exact path="/create-pricing/:videoId">
              <CreatePricing />
            </Route>
          )}
          {isAdmin && (
            <Route exact path="/edit-pricing/:videoId/:countryCode">
              <EditPricing />
            </Route>
          )}
          <Route exact path="/cast-list">
            <CastList />
          </Route>
          {isAdmin && (
            <Route exact path="/create-cast">
              <CreateCast />
            </Route>
          )}
          {isAdmin && (
            <Route exact path="/edit-cast/:castId">
              <CreateCast />
            </Route>
          )}
          <Route exact path="/user-management">
            <UserManagement />
          </Route>
          <Route exact path="/user-detail/:userId">
            <UserDetail />
          </Route>
          <Route exact path="/order-management/:userId">
            <OrderManagement />
          </Route>
          <Route exact path="/user-activity-log/:userId">
            <UserActivityLog />
          </Route>
          <Route exact path="/help-qna">
            <HelpQNA />
          </Route>
          <Route exact path="/create-qna">
            <CreateQNA />
          </Route>
          <Route exact path="/edit-qna/:categoryId/:qnaId">
            <CreateQNA />
          </Route>
          <Route exact path="/create-qna-category">
            <CreateQNACategory />
          </Route>
          <Route exact path="/edit-qna-category/:categoryId">
            <CreateQNACategory />
          </Route>
          <Route exact path="/promo-code">
            <PromoCodeList />
          </Route>
          {isAdmin && (
            <Route exact path="/create-promo-code">
              <PromoCode />
            </Route>
          )}
          {isAdmin && (
            <Route exact path="/landing-page">
              <LandingPage />
            </Route>
          )}
        </Switch>
      </main>
      <Alert />
    </div>
  )
}
