import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { Dashboard } from '../pages/Dashboard'
import { Login } from '../pages/Login'
import { useSelector } from 'react-redux'

export function Routes() {
  const User = useSelector((state) => state.user)
  return (
    <Router>
      <Switch>
        <Route path="/">{User.login ? <Dashboard /> : <Login />}</Route>
      </Switch>
    </Router>
  )
}
