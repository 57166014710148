import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { DevicesIcons } from '../lib/constants'
import { ConfirmationDialog } from '.'
import { handleDelete } from '../redux/modules/plans'
import { useHistory } from 'react-router'

const useStyles = makeStyles(() => ({
  planAction: {
    fontSize: 20,
    color: 'lightgray',
    cursor: 'pointer',
    '&:active': {
      color: '#ffffff'
    }
  }
}))

export const Plan = ({ data }) => {
  const {
    title,
    id,
    video_quality: {
      vertical_resolution: { title: videoQuality }
    },
    is_downloadable,
    n_watches,
    device_types,
    status,
    is_ad_supported,
    expire_max_mins_after_purchase,
    expire_max_min_after_start
  } = data

  const [processing, setProcessing] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()

  const closeDeleteModal = () => {
    setProcessing(false)
    setIsDeleteModalVisible(false)
  }

  const handleDeletion = async () => {
    setProcessing(true)
    try {
      await dispatch(handleDelete(id))
      closeDeleteModal()
    } catch (err) {
      console.log('Error in deleting plan is: ', err)
    }
  }

  const classes = useStyles()

  return (
    <div className="movie-plan" key={id}>
      <div className="plan-header">
        {title}
        <div className="plan-actions">
          <div className="plan-action-container">
            <EditIcon
              className={classes.planAction}
              role="presentation"
              onClick={() => history.push(`/edit-plan/${id}`)}
            />
          </div>
          <div className="plan-action-container">
            <DeleteIcon
              className={classes.planAction}
              role="presentation"
              onClick={() => setIsDeleteModalVisible(true)}
            />
          </div>
        </div>
      </div>
      <div className="plan-quality">{status ? 'Enabled' : 'Disabled'}</div>
      <div className="plan-devices-container">
        <p className="plan-type">
          Downloadable: {is_downloadable ? 'Yes' : 'No'}
        </p>
        <p className="plan-type">No. of watches: {n_watches}</p>
      </div>
      <div className="plan-type-container">
        <div className="plan-detail-item">
          <p className="plan-type">Quality</p>
          <p className="plan-type-name">{videoQuality}</p>
        </div>
        <div className="plan-detail-item">
          <p className="plan-type">Expiry (P)</p>
          <p className="plan-type-name">
            {expire_max_mins_after_purchase / 1440} days
          </p>
        </div>

        <div className="plan-detail-item">
          <p className="plan-type">Expiry (S)</p>
          <p className="plan-type-name">
            {expire_max_min_after_start / 60} hours
          </p>
        </div>

        <div className="plan-detail-item">
          <p className="plan-type">Ads</p>
          <p className="plan-type-name">{is_ad_supported ? 'Yes' : 'No'}</p>
        </div>
      </div>

      <div className="plan-devices-container">
        {device_types.length > 0 &&
          device_types.map((device) => {
            const { id, title } = device

            return (
              <div id={id} key={id} className="plan-device">
                <div>{DevicesIcons[title]}</div>
                {title}
              </div>
            )
          })}
      </div>

      <ConfirmationDialog
        open={isDeleteModalVisible}
        handleClose={closeDeleteModal}
        processRequest={handleDeletion}
        title={`Delete ${title}`}
        description={' Are you sure you want to delete this plan? '}
        loading={processing}
      />
    </div>
  )
}
