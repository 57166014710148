import React from 'react'

import { useStyles } from './styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'

import { FormInput } from '../../../../components'
import { setSocialIcons } from '../../../../redux/modules/landingPage'

export const SocialIconsForm = ({ section }) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <SocialIconConfiguration header={'Desktop'} section={section} />
      <SocialIconConfiguration header={'Tablet'} section={section} />
      <SocialIconConfiguration header={'Mobile'} section={section} />
    </div>
  )
}

const SocialIconConfiguration = ({ section, header }) => {
  const classes = useStyles()
  const name = header.toLowerCase()
  const {
    [section]: {
      [name]: {
        bottomOffset,
        backgroundOpacity,
        color,
        backgroundColor,
        backgroundBlurriness,
        disabled
      }
    }
  } = useSelector((state) => state.landingPage)
  const dispatch = useDispatch()

  const handleChange = ({ target: { value, name: key } }) => {
    dispatch(
      setSocialIcons({
        name,
        data: {
          [key]: value
        }
      })
    )
  }

  const backgroundForm = Boolean(backgroundColor)
  const disabledValue = Boolean(disabled)
  return (
    <Accordion className={classes.accordian}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="desktop-content"
        id={`${header}-header`}>
        <Typography className={classes.sectionTitle}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        <div className={classes.attributesContainer}>
          <div className={classes.input}>
            Bottom Offset:
            <FormInput
              type={'number'}
              placeholder={`Bottom Offset`}
              id={`bottom-offset-${name}`}
              value={bottomOffset}
              onChangeHandler={handleChange}
              name={`bottomOffset`}
            />
          </div>
          <div className={classes.input}>
            Color:
            <FormInput
              type="color"
              placeholder={`Color`}
              id={`color-${name}`}
              value={color}
              onChangeHandler={handleChange}
              name={`color`}
            />
          </div>
        </div>

        <div className={classes.attributesContainer}>
          <div
            className={classes.input}
            style={{ display: 'flex', alignItems: 'center' }}>
            Background:
            <Checkbox
              onChange={(_, checked) =>
                dispatch(
                  setSocialIcons({
                    name,
                    data: {
                      backgroundColor: checked ? '#000000' : '',
                      backgroundOpacity: checked ? '0.5' : '0'
                    }
                  })
                )
              }
              checked={backgroundForm}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <div
            className={classes.input}
            style={{ display: 'flex', alignItems: 'center' }}>
            Disabled:
            <Checkbox
              onChange={(_, checked) =>
                dispatch(
                  setSocialIcons({
                    name,
                    data: {
                      disabled: checked
                    }
                  })
                )
              }
              checked={disabledValue}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          {backgroundForm && (
            <>
              <div className={classes.input}>
                Background Color:
                <FormInput
                  type="color"
                  placeholder={`Color`}
                  id={`background-color-${name}`}
                  value={backgroundColor}
                  onChangeHandler={handleChange}
                  name={`backgroundColor`}
                />
              </div>
              <div className={classes.input}>
                Background Opacity:
                <FormInput
                  type="number"
                  placeholder={`Background Opacity`}
                  id={`background-opacity-${name}`}
                  value={backgroundOpacity}
                  onChangeHandler={handleChange}
                  name={`backgroundOpacity`}
                  max={1}
                  min={0}
                />
              </div>
              <div className={classes.input}>
                Background Blurrness:
                <FormInput
                  type={'number'}
                  placeholder={`Background Blurrness`}
                  id={`background-blurrness-${name}`}
                  value={backgroundBlurriness}
                  onChangeHandler={handleChange}
                  name={`backgroundBlurriness`}
                />
              </div>
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
