import React, { useReducer, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { ButtonComponent, FormInput } from '../../components'
import { createOrder } from '../../backend/order'
import { PrimaryColor } from '../../styles/colors'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { listVideos, listPricings } from '../../graphql/queries'

export const CreateOrderModal = ({
  handleCloseModal,
  userId,
  fetchOrdersList
}) => {
  const [orderProcessing, setOrderProcessing] = useState(false)
  const [videoList, setVideoList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [planList, setPlanList] = useState([])
  const [pricings, setPricings] = useState([])

  const [
    { countryCode, videoId, videoPlanId, justification, updated },
    setData
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    countryCode: '',
    videoId: '',
    videoPlanId: '',
    justification: '',
    updated: false
  })

  useEffect(() => {
    if (videoId) {
      ;(async () => {
        try {
          const {
            data: {
              listPricings: { items }
            }
          } = await API.graphql({
            query: listPricings,
            variables: {
              filter: {
                status: {
                  eq: true
                },
                video_id: {
                  eq: videoId
                }
              }
            }
          })
          setCountryList([])
          setPlanList([])
          setPricings(items)
          setData({
            countryCode: '',
            videoPlanId: ''
          })
        } catch (err) {
          alert('Something went wrong in fetching pricings')
        }
      })()
    }
  }, [videoId, setPricings, setCountryList, setPlanList, setData])

  useEffect(() => {
    if (videoId && pricings) {
      let countries = {}
      pricings.forEach(
        (item) =>
          (countries[item.country_code] = {
            id: item.country.country_code,
            label: item.country.country_name
          })
      )
      setCountryList(Object.values(countries))
      setPlanList([])
      setData({ videoPlanId: '', countryCode: '' })
    }
  }, [videoId, pricings, setCountryList, setData])

  useEffect(() => {
    if (videoId && pricings && countryCode) {
      const plans = pricings
        .filter((item) => item.country_code === countryCode)
        .map((item) => {
          return {
            id: item.video_plan_id,
            label: item.video_plan.plan.title
          }
        })
      setPlanList(plans)
      setData({ videoPlanId: '' })
    }
  }, [videoId, pricings, countryCode, setData, setPlanList])

  useEffect(() => {
    if (videoList.length === 0) {
      ;(async () => {
        try {
          const {
            data: {
              listVideos: { items }
            }
          } = await API.graphql({
            query: listVideos,
            variables: {
              filter: {
                status: {
                  eq: true
                }
              }
            }
          })
          const _videoList = items.map((videoItem) => {
            return {
              id: videoItem.id,
              label: videoItem.title
            }
          })
          setVideoList(_videoList)
        } catch (err) {
          throw err
        }
      })()
    }
  }, [videoList, setVideoList])

  const onChangeHandler = ({ target: { value, name } }) =>
    setData({ [name]: value, updated: true })

  const handleChange = (name, value) =>
    setData({ [name]: value ? value.id : '', updated: true })

  const handleCreateOrder = () => {
    if (!userId || !countryCode || !videoId || !videoPlanId || !justification) {
      alert('Please provide all data')
      return
    }
    setOrderProcessing(true)
    ;(async () => {
      try {
        await createOrder(
          userId,
          countryCode,
          videoId,
          videoPlanId,
          justification
        )

        setOrderProcessing(false)
        handleCloseModal()
        if (updated) fetchOrdersList()
      } catch (err) {
        alert('Something went wrong please try again')
        console.log(err)
        handleCloseModal()
      }
    })()
  }
  return (
    <div className="caption-modal-container">
      <h3 className="caption-modal-title">Create Order</h3>
      <div className="caption-languages">
        <div style={{ padding: 10 }}>
          <Autocomplete
            onChange={(event, newValue) => handleChange('videoId', newValue)}
            disablePortal
            id="videoId"
            name="videoId"
            options={videoList}
            renderInput={(params) => <TextField {...params} label="Video ID" />}
          />
        </div>
        <div style={{ padding: 10 }}>
          <Autocomplete
            onChange={(event, newValue) =>
              handleChange('countryCode', newValue)
            }
            disablePortal
            id="countryCode"
            name="countryCode"
            options={countryList}
            renderInput={(params) => (
              <TextField {...params} label="Country Code" />
            )}
          />
        </div>
        <div style={{ padding: 10 }}>
          <Autocomplete
            onChange={(event, newValue) =>
              handleChange('videoPlanId', newValue)
            }
            disablePortal
            id="videoPlanId"
            name="videoPlanId"
            options={planList}
            renderInput={(params) => <TextField {...params} label="Plan ID" />}
          />
        </div>
        <div style={{ padding: 10 }}>
          <FormInput
            placeholder="Justification"
            id="justification"
            name="justification"
            onChangeHandler={onChangeHandler}
            value={justification}
          />
        </div>
      </div>
      <div className="caption-modal-footer">
        <div className="caption-modal-footer-button">
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Cancel"
            width={90}
            height={25}
            onClickHandler={handleCloseModal}
            disabled={orderProcessing}
          />
        </div>
        <div>
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="Create"
            disabled={!updated}
            width={90}
            height={25}
            onClickHandler={handleCreateOrder}
            loading={orderProcessing}
          />
        </div>
      </div>
    </div>
  )
}
