import React, { useEffect, useReducer } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { ButtonComponent, FormInput, AutoFillField } from '../../../components'
import { SecondaryButton } from '../../../styles/colors'
import { showAlert } from '../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../lib/constants'
import { updateMetaInformation } from '../../../backend/movies'
import { validateMetaInformation } from './validation'
import { useGenres } from '../../../hooks/useGenres'
import { useContentRatings } from '../../../hooks/useContentRatings'

export const MetaInformation = ({
  main_title,
  id,
  sub_title,
  banner_message,
  short_description,
  long_description,
  release_date,
  updateLocally,
  genres: video_genres,
  content_rating: video_content_ratings
}) => {
  const classes = useStyles()
  const [
    {
      mainTitle,
      subTitle,
      bannerMessage,
      shortDescription,
      longDescription,
      releaseDate,
      videoGenres,
      videoContentRatings,
      updated,
      loading,
      validated,
      temp,
      genreUpdated,
      contentRatingUpdated
    },
    setData
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    mainTitle: main_title,
    subTitle: sub_title,
    bannerMessage: banner_message,
    shortDescription: short_description,
    longDescription: long_description,
    releaseDate: moment(release_date).format('YYYY-MM-DD'),
    updated: false,
    loading: false,
    validated: true,
    videoGenres: [],
    temp: true,
    genreUpdated: false,
    videoContentRatings: [],
    contentRatingUpdated: false
  })

  const { genres } = useGenres()
  const { contentRatings } = useContentRatings()
  const dispatch = useDispatch()

  useEffect(() => {
    const items = video_genres.items.map((item) => {
      return {
        id: item.genre.id,
        name: item.genre.title,
        value: item.genre.id
      }
    })
    const contentRatingItems = video_content_ratings.items.map((item) => {
      return {
        id: item.content_rating.id,
        name: item.content_rating.title,
        value: item.content_rating.id
      }
    })
    setData({
      videoGenres: items,
      temp: false,
      videoContentRatings: contentRatingItems
    })
    setTimeout(() => {
      setData({ temp: true })
    }, 100)
  }, [video_genres, video_content_ratings, setData])

  useEffect(() => {
    const response = validateMetaInformation(
      mainTitle,
      releaseDate,
      videoGenres
    )
    setData({ validated: response })
  }, [mainTitle, releaseDate, videoGenres, setData])

  const onChangeHandler = ({ target: { value, name } }) =>
    setData({ [name]: value, updated: true })

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const updateMeta = async () => {
    setData({ loading: true })
    const payload = {
      id,
      main_title: mainTitle,
      sub_title: subTitle,
      banner_message: bannerMessage,
      short_description: shortDescription,
      long_description: longDescription,
      release_date: moment(releaseDate).format()
    }

    try {
      // update movie meta data
      await updateMetaInformation(
        payload,
        genreUpdated,
        contentRatingUpdated,
        video_genres,
        videoGenres,
        video_content_ratings,
        videoContentRatings,
        id
      )
      setData({
        loading: false,
        genreUpdated: false,
        updated: false,
        contentRatingUpdated: false
      })
      handleAlert('Data updated successfuly', ALERT_TYPES.SUCCESS)
      updateLocally()
    } catch (err) {
      console.log('error is: ', err)
      handleAlert(
        'Unable to update meta information. Please try again',
        ALERT_TYPES.ERROR
      )
      setData({ loading: false })
    }
  }

  const handleGenreSelection = (value) =>
    setData({ videoGenres: value, genreUpdated: true, updated: true })

  const handleContentRatingSelection = (value) =>
    setData({
      videoContentRatings: value,
      contentRatingUpdated: true,
      updated: true
    })

  return (
    <div className={classes.mainContainer}>
      <div className={classes.form}>
        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Main Title*</Typography>
            <FormInput
              placeholder="Main Title"
              id="mainTitle"
              value={mainTitle}
              onChangeHandler={onChangeHandler}
              name="mainTitle"
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Sub Title</Typography>
            <FormInput
              placeholder="Sub Title"
              id="subTitle"
              value={subTitle}
              onChangeHandler={onChangeHandler}
              name="subTitle"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Release Date*</Typography>
            <FormInput
              placeholder="Release Date"
              id="releaseDate"
              type="date"
              value={releaseDate}
              name="releaseDate"
              onChangeHandler={onChangeHandler}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Banner Message</Typography>
            <FormInput
              placeholder="Banner Message"
              id="bannerMessage"
              value={bannerMessage}
              onChangeHandler={onChangeHandler}
              name="bannerMessage"
            />
          </div>
        </div>

        {temp && (
          <>
            <div className={classes.fullRow}>
              <AutoFillField
                label={'Genres*'}
                data={genres}
                defaultValue={videoGenres}
                handleCastSelection={handleGenreSelection}
              />
            </div>

            <div className={classes.fullRow}>
              {temp && (
                <AutoFillField
                  label={'Content Ratings*'}
                  data={contentRatings}
                  defaultValue={videoContentRatings}
                  handleCastSelection={handleContentRatingSelection}
                />
              )}
            </div>
          </>
        )}

        <div className={classes.fullRow}>
          <Typography className={classes.label}>Short Description</Typography>
          <FormInput
            placeholder="Short Description"
            multiline={true}
            rows={2}
            id="shortDescription"
            name="shortDescription"
            onChangeHandler={onChangeHandler}
            value={shortDescription}
          />
        </div>
        <div className={classes.fullRow}>
          <Typography className={classes.label}>Long Description</Typography>
          <FormInput
            placeholder="Long Description"
            multiline={true}
            rows={5}
            id="longDescription"
            name="longDescription"
            onChangeHandler={onChangeHandler}
            value={longDescription}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <ButtonComponent
          disabled={!updated || !validated}
          loading={loading}
          title="UPDATE"
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          onClickHandler={updateMeta}
          width={150}
          height={40}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    marginBottom: '20px'
  },
  inputContainer: {
    width: 300,
    padding: '0px 10px'
  },
  label: {
    color: 'gray'
  },
  fullRow: {
    marginBottom: '20px',
    padding: '0px 10px'
  },
  form: {
    flex: 1
  },
  footer: {}
}))
