import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

//import { CircularProgress } from '@material-ui/core'

import { Header, ButtonComponent, SelectInput } from '../../components'

import { PrimaryColor } from '../../styles/colors'
import { HelpQNAFilters } from '../../lib/constants'
import { fetchQNAs } from '../../redux/modules/helpQNA'
import { HelpQNATable } from './components'
import './helpQNA.css'

export const HelpQNA = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('All')
  const [helpQNAs, setHelpQNAs] = useState([])
  const [helpQNAsClone, setHelpQNAsClone] = useState([])
  const [loading, setLoading] = useState(false)

  const { list } = useSelector((state) => state.helpQNA)

  useEffect(() => {
    if (list.length === 0) {
      ;(async () => {
        try {
          setLoading(true)
          await dispatch(fetchQNAs())
          setLoading(false)
        } catch (err) {
          setLoading(false)
          console.log('Error in fetching q & a is: ', err)
          alert(err.message || 'something went wrong in fetching q & a.')
        }
      })()
    } else {
      setHelpQNAs(list)
      setHelpQNAsClone(list)
    }
  }, [list, setHelpQNAs, setHelpQNAsClone])

  const handleFilter = (event) => {
    let _filter = event.target.value
    setFilter(_filter)

    if (_filter === 'all') setHelpQNAs(helpQNAsClone)
    else if (_filter === 'enabled') {
      let _helpQNAs = helpQNAsClone.filter((item) => item.status)
      setHelpQNAs(_helpQNAs)
    } else {
      let _helpQNAs = helpQNAsClone.filter((item) => !item.status)
      setHelpQNAs(_helpQNAs)
    }
  }

  return (
    <div className="dashboard-page">
      <Header title="Q n A" />
      <div className="plan-list-container">
        <div className="dropdown-filter">
          <SelectInput
            data={HelpQNAFilters}
            value={filter}
            changeHandler={handleFilter}
          />
        </div>
      </div>
      <div className="add-promo-code-button">
        <div style={{ marginRight: '1em' }}>
          <ButtonComponent
            borderColor={PrimaryColor}
            titleColor={PrimaryColor}
            title="Add Category"
            onClickHandler={() => history.push('/create-qna-category')}
            width={200}
          />
        </div>
        <div>
          <ButtonComponent
            borderColor={PrimaryColor}
            titleColor={PrimaryColor}
            title="Add QNA"
            onClickHandler={() => history.push('/create-qna')}
            width={200}
          />
        </div>
      </div>
      <div className="plan-content">
        {!loading && helpQNAs.length > 0 && (
          <HelpQNATable helpQNAs={helpQNAs} />
        )}

        {!loading && helpQNAs.length === 0 && (
          <div className="loading-container">No Q&As available</div>
        )}
      </div>
    </div>
  )
}
