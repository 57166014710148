import { FormInput } from '../../components'

export const CastForm = ({
  onChangeHandler,
  firstName,
  lastName,
  nickName,
  description
}) => {
  return (
    <div className="creat-cast-form">
      <div className="create-cast-name-container">
        <div className="create-movie-form-item">
          <FormInput
            placeholder="Firs Name"
            id="firstName"
            name="firstName"
            onChangeHandler={onChangeHandler}
            value={firstName}
          />
        </div>
        <div className="create-movie-form-item">
          <FormInput
            placeholder="Last Name"
            id="lastName"
            name="lastName"
            onChangeHandler={onChangeHandler}
            value={lastName}
          />
        </div>
      </div>
      <div className="create-cast-nickname-container">
        <div className="create-cast-nickname-input">
          <FormInput
            placeholder="Nick Name"
            id="nickName"
            name="nickName"
            onChangeHandler={onChangeHandler}
            value={nickName}
          />
        </div>
      </div>
      <div className="create-movie-description-item">
        <FormInput
          placeholder="Description"
          multiline={true}
          rows={5}
          id="description"
          name="description"
          onChangeHandler={onChangeHandler}
          value={description}
        />
      </div>
    </div>
  )
}
