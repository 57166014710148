import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import { useParams } from 'react-router'

import { CircularProgress, Modal } from '@material-ui/core'

import { getOrdersByUser } from '../../graphql/queries'
import { updateOrder } from '../../graphql/mutations'
import { OrdersList } from './ordersList'

import { ConfirmationDialog, ButtonComponent } from '../../components'

import { CreateOrderModal } from './createOrderModal.js'

import { PrimaryColor } from '../../styles/colors'

export const OrderManagement = () => {
  const [ordersList, setOrdersList] = useState([])
  const [ordersLoading, setOrdersLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [open, setOpen] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const { userId } = useParams()

  useEffect(() => {
    fetchOrdersList()
  }, [])

  const fetchOrdersList = async () => {
    setOrdersLoading(true)

    try {
      const response = await API.graphql({
        query: getOrdersByUser,
        variables: { user_id: userId }
      })
      let _ordersList = response.data.getOrdersByUser.items

      setOrdersList(_ordersList)
      setOrdersLoading(false)
    } catch (err) {
      console.log('Error in getting orders is: ', err)
      setOrdersLoading(false)
    }
  }

  const handleActivation = (order, status) => {
    setSelectedOrder(order)
    setNewStatus(status)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setNewStatus(null)
    setSelectedOrder(null)
    setProcessing(false)
  }

  const handleCloseModal = () => {
    setIsVisible(false)
  }

  const processRequest = async () => {
    setProcessing(true)

    try {
      const response = await API.graphql({
        query: updateOrder,
        variables: { input: { id: selectedOrder.id, status: newStatus } }
      })

      ordersList.forEach((order, index) => {
        const { updateOrder } = response.data

        if (order.id == updateOrder.id) {
          let _ordersList = [...ordersList]
          _ordersList[index].status = updateOrder.status

          setOrdersList(_ordersList)
        }
      })

      setProcessing(false)

      handleClose()
    } catch (err) {
      console.log('Error in updating Order is: ', err)
    }
  }

  return (
    <div className="dashboard-page">
      <div className="plan-content">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: '20px'
          }}>
          <Tabs>
            <Tab label="< Back" to={`/user-management`} component={Link} />
            <Tab
              label="Personal Information"
              to={`/user-detail/${userId}`}
              component={Link}
            />
            <Tab
              label="Order Management"
              to={`/order-management/${userId}`}
              component={Link}
            />
            <Tab
              label="Activity Log"
              to={`/user-activity-log/${userId}`}
              component={Link}
            />
          </Tabs>
        </Box>
        <div className="plan-content">
          <div className="filter-header">
            <div className="add-order-button">
              <ButtonComponent
                title="Create Order"
                backgroundColor={PrimaryColor}
                titleColor={'white'}
                borderColor={PrimaryColor}
                width={200}
                height={34}
                onClickHandler={() => setIsVisible(true)}
              />
            </div>
          </div>
        </div>

        {ordersLoading && (
          <div className="loading-container">
            <CircularProgress size={30} className="countries-loader" />
          </div>
        )}

        {!ordersLoading && ordersList.length > 0 && (
          <OrdersList
            ordersList={ordersList}
            handleActivation={handleActivation}
          />
        )}
        {!ordersLoading && ordersList.length == 0 && (
          <div className="loading-container">No Orders available</div>
        )}
      </div>
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        processRequest={processRequest}
        title={'Confirmation'}
        description={
          <p>
            Are you sure you want to {newStatus ? 'enable' : 'disable'}{' '}
            <span className="country-name">
              {' '}
              {selectedOrder && selectedOrder.ref_no}
            </span>
            .
          </p>
        }
        loading={processing}
      />

      <Modal
        open={isVisible}
        closeModal={handleCloseModal}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="file-upload-modal">
        <CreateOrderModal
          handleCloseModal={handleCloseModal}
          userId={userId}
          fetchOrdersList={fetchOrdersList}
        />
      </Modal>
    </div>
  )
}
