export const PrimaryColor = '#0097A7'
export const DrawerBackground = '#F5F5F5'
export const InputBorder = '#737F8F'
export const PrimaryButton = '#ffffff'
export const SecondaryButton = '#DCDCDC'
export const ButtonText = '#000000'

export const color = {
  cardGreen: '#4295A5',
  cardDatesGreen: '#4B9FAD',
  red: '#FF0000',
  white: '#FFFFFF'
}
