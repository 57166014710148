import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  inputContainer: {
    width: '80%',
    margin: '10px 10px',
    fontSize: 16
  },
  typeSelectionContainer: {
    marginTop: 20
  },
  sectionTitle: {
    fontWeight: '600'
  },
  accordian: {
    marginBottom: 10,
    width: '80%'
  },
  accordianDetails: {
    width: '100%',
    borderTop: '1px solid lightgray',
    flexDirection: 'column',
    display: 'flex'
  },
  title: {
    width: '100%',
    fontSize: 16
  },
  attributesContainer: {
    width: '100%',
    fontSize: 16,
    display: 'flex',
    marginTop: 10
  },
  input: {
    flex: 1,
    padding: 5
  }
}))
