import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))
