import React, { useReducer } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, makeStyles, Chip, LinearProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import ReactDropzone from 'react-dropzone'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import { Storage } from 'aws-amplify'
import { v4 as uuid4 } from 'uuid'

import config from '../../../../../config'
import {
  removeTrailerCaptions,
  setTrailerCaptionLanguage,
  setTrailerCaptionUrl
} from '../../../../../redux/modules/newMovie'
import { LanguageList } from '../../../../../lib/constants'
import { ButtonComponent, SelectInput } from '../../../../../components'
import { PrimaryColor } from '../../../../../styles/colors'

export const CaptionForm = ({ trailerId, caption }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )

  const handleRemoveCaption = () =>
    dispatch(removeTrailerCaptions(trailerId, caption.id))

  const handleLanguage = ({ target: { value } }) =>
    dispatch(setTrailerCaptionLanguage(trailerId, caption.id, value))

  const handleUpload = async (acceptedFiles) => {
    if (uploading) return

    setUploadData({ uploading: true })

    const file = acceptedFiles[0]
    let fileName = uuid4()
    let fileType = file.name.split('.').pop()

    try {
      const { key } = await Storage.put(
        `${trailerId}/${config.captionsFolder}/${fileName}.${fileType}`,
        file,
        {
          progressCallback(progress) {
            const { loaded, total } = progress

            setUploadData({
              uploadProgress: Math.floor((loaded * 100) / total)
            })
          }
        }
      )

      dispatch(setTrailerCaptionUrl(trailerId, caption.id, key))

      setUploadData({ uploading: false, uploadProgress: 0 })
    } catch (error) {
      setUploadData({ uploading: false, uploadProgress: 0 })
    }
  }

  return (
    <div key={caption.id} className={classes.mainCaptionContainer}>
      <div className={classes.detail}>
        <div className={classes.selector}>
          <SelectInput
            data={LanguageList}
            multiple={false}
            value={caption.language}
            changeHandler={handleLanguage}
          />
        </div>
        <div style={{ flex: 1, padding: '0px 20px' }}>
          <div className="caption-upload-button">
            {uploading && (
              <div className={'caption-upload-progress'}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </div>
            )}
            {caption.url && (
              <div className={'chip-container'}>
                {' '}
                <Chip label="Uploaded" />{' '}
              </div>
            )}
            <ReactDropzone onDrop={handleUpload} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} accept=".vtt" />
                    <ButtonComponent
                      borderColor={PrimaryColor}
                      titleColor={PrimaryColor}
                      title="ADD"
                      onClickHandler={() => {}}
                      width={90}
                      height={30}
                      icon={
                        <BackupOutlinedIcon
                          style={{ fontSize: 24, paddingRight: 10 }}
                        />
                      }
                    />
                  </div>
                </section>
              )}
            </ReactDropzone>
          </div>
        </div>
      </div>
      <IconButton onClick={handleRemoveCaption} size="small">
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainCaptionContainer: {
    border: '1px solid gray',
    borderRadius: 3,
    padding: 10,
    margin: 10,
    display: 'flex'
  },
  detail: {
    flex: 1,
    display: 'flex'
  },
  selector: {
    width: 200
  }
}))
