import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Checkbox,
  Modal,
  makeStyles,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from '@material-ui/core'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuid4 } from 'uuid'
import CloseIcon from '@material-ui/icons/Close'

import {
  ButtonComponent,
  FormInput,
  ImageDropZone,
  OrSeparator,
  VideoAutoSelector,
  Audioform
} from '../../../components'
import { PrimaryColor } from '../../../styles/colors'
import { TrailerPlayer } from './trailerPlayer'
import { CaptionCard } from './captions'
import { SourceBucket } from '../../../classes'
import { LanguagesCode } from '../../../lib/constants'

const dropZoneStyle = {
  dropzone: {
    minHeight: 56,
    borderWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed gray'
  },
  inputLabel: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    color: 'black'
  }
}

export const TrailerUpdateForm = ({
  videoId,
  trailer,
  removeTrailer,
  updateTrailer,
  contentPreview = true
}) => {
  const [name, setName] = useState(null)
  const {
    title,
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    key,
    hls_url,
    id,
    is_main,
    status,
    supportedCaptions,
    videoFromBucket,
    type,
    audio_tracks,
    duration
  } = trailer

  const [trailerUrl, setTrailerUrl] = useState(null)
  const [captions, setCaptions] = useState([])
  const [audios, setAudios] = useState([])
  const classes = useStyles()
  const sourceBucket = new SourceBucket()

  useEffect(() => {
    const data = supportedCaptions.map((item) => {
      if (!item.additional_attributes) delete item['additional_attributes']
      if (item.createdAt) delete item['createdAt']
      if (item.updatedAt) delete item['updatedAt']

      return item
    })
    setCaptions(data)

    const audiosData = audio_tracks.map((item) => {
      if (!item.additional_attributes) delete item['additional_attributes']
      delete item['createdAt']
      delete item['updatedAt']
      return item
    })
    setAudios(audiosData)
  }, [supportedCaptions, audio_tracks, setAudios, setCaptions])

  const closeTrailerPlayer = () => setTrailerUrl(null)

  const removeCaption = (id) => {
    const updatedCaptions = captions.filter((item) => item.id !== id)
    setCaptions(updatedCaptions)
    updateTrailer(id, {
      supportedCaptions: updatedCaptions,
      captionsUpdated: true
    })
  }

  const updateCaption = (data) => {
    const updatedCaptions = captions.map((caption) => {
      if (caption.id === data.id) return { ...data, updated: true }
      return caption
    })
    setCaptions(updatedCaptions)
    updateTrailer(id, {
      supportedCaptions: updatedCaptions,
      captionsUpdated: true
    })
  }

  const removeAudio = (audioId) => {
    const updatedAudios = audios.filter((item) => item.id !== audioId)
    setAudios(updatedAudios)
    updateTrailer(id, {
      audio_tracks: updatedAudios,
      audiosUpdated: true
    })
  }

  const updateAudio = (audioId, payload) => {
    const updatedAudios = audios.map((audio) => {
      if (audio.id === audioId) return { ...audio, updated: true, ...payload }
      return audio
    })
    setAudios(updatedAudios)
    updateTrailer(id, {
      audio_tracks: updatedAudios,
      audiosUpdated: true
    })
  }

  const addAudio = () => {
    const updatedAudio = [
      ...audios,
      {
        id: uuid4(),
        isOriginal: true,
        language: 'English',
        languageCode: LanguagesCode['English'].toUpperCase(),
        status: true,
        srcAudio: null,
        video_id: id,
        new: true
      }
    ]
    setAudios(updatedAudio)
    updateTrailer(id, {
      audio_tracks: updatedAudio,
      audiosUpdated: true
    })
  }

  const addCaption = () => {
    const updatedCaptions = [
      ...captions,
      {
        id: uuid4(),
        language: 'English',
        url: null,
        status: true,
        video_id: id,
        is_orignal: false,
        new: true
      }
    ]
    setCaptions(updatedCaptions)
    updateTrailer(id, {
      supportedCaptions: updatedCaptions,
      captionsUpdated: true
    })
  }

  const handleIsMain = () => updateTrailer(id, { is_main: !is_main })
  const handleStatus = () => updateTrailer(id, { status: !status })

  const handleTitle = ({ target: { value } }) =>
    updateTrailer(id, { title: value })

  const thumbnails = [
    {
      id: 'thumbnail_lg',
      title: 'Upload Thumbnail (lg)',
      imageUrl: thumbnail_lg
    },
    {
      id: 'thumbnail_md',
      title: 'Upload Thumbnail (md)',
      imageUrl: thumbnail_md
    },
    {
      id: 'thumbnail_sm',
      title: 'Upload Thumbnail (sm)',
      imageUrl: thumbnail_sm
    }
  ]

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done' && name) {
      console.log(status, meta, file)
      updateTrailer(id, { key: name })

      setName(null)
    }

    if (status === 'headers_received') {
      remove()
    } else if (status === 'aborted') {
      alert('Something went wrong please try again')
      setName(null)
    }
  }

  useEffect(() => {
    if (!type) updateTrailer(id, { type: 'TRAILER' })
  }, [type])

  const handleTrailerType = ({ target: { value } }) =>
    updateTrailer(id, {
      type: value,
      is_main: value === 'TRAILER' ? is_main : false
    })

  // public video
  const getUploadParams = async ({ file, meta: { duration } }) => {
    const fileParts = file.name.split('.')
    const fileType = fileParts[1]
    const fileName = `public/${id}/${uuid4()}.${fileType}`

    setName(fileName)

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName,
      Expires: 60,
      ContentType: fileType
    }

    const _url = await sourceBucket.getUploadURL(params)

    return {
      body: file,
      method: 'PUT',
      meta: {
        fileUrl: `${process.env.REACT_APP_BUCKET_URL}/${fileName}`
      },
      url: _url
    }
  }

  const handleTrailerRemovel = () => removeTrailer(id)

  const handleVideoFromBucketChagne = (value) =>
    updateTrailer(id, {
      videoFromBucket: value,
      key: value ? value.Key : ''
    })

  const handleDuration = (event, key) => {
    const { value } = event.target
    updateTrailer(id, {
      duration: { ...duration, [key]: value }
    })
  }

  return (
    <div className={classes.trailerFormContainer}>
      <div>
        <div className={classes.formTitleInput}>
          <FormInput
            value={title}
            placeholder="Title"
            id="title"
            name="title"
            onChangeHandler={handleTitle}
          />
        </div>

        <div className={classes.trailerDropZoneContainer}>
          {hls_url ? (
            <div className={classes.playButtonContainer}>
              <ButtonComponent
                backgroundColor={PrimaryColor}
                titleColor={'white'}
                borderColor={PrimaryColor}
                title="Play"
                height={35}
                onClickHandler={() => {
                  setTrailerUrl(hls_url)
                }}
                width={160}
              />
            </div>
          ) : (
            <div className="one-flex flex-container">
              <div className="one-flex">
                <Dropzone
                  autoUpload={true}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  inputContent={
                    <div>
                      <Typography>{'UPLOAD TRAILER'}</Typography>
                      {key && <p className={classes.trailerName}>({key})</p>}
                    </div>
                  }
                  styles={dropZoneStyle}
                  accept="video/*"
                />
              </div>
              <OrSeparator />
              <div className="one-flex">
                <VideoAutoSelector
                  handleChange={handleVideoFromBucketChagne}
                  value={videoFromBucket}
                />
              </div>
            </div>
          )}
        </div>

        {contentPreview && duration && (
          <div className="create-movie-form-item">
            <div className="create-movie-form-label">
              <FormLabel>Duration</FormLabel>
            </div>
            <div className="flex-container">
              <div>
                <FormInput
                  onChangeHandler={(event) => handleDuration(event, 'hours')}
                  placeholder="hh"
                  id="durationHours"
                  name="durationHours"
                  type={'number'}
                  value={duration.hours}
                />
              </div>
              <div className="time-sapartor-container">:</div>
              <div>
                <FormInput
                  onChangeHandler={(event) => handleDuration(event, 'minutes')}
                  placeholder="mm"
                  id="durationMinutes"
                  name="durationMinutes"
                  type={'number'}
                  value={duration.minutes}
                />
              </div>
              <div className="time-sapartor-container">:</div>
              <div>
                <FormInput
                  onChangeHandler={(event) => handleDuration(event, 'seconds')}
                  placeholder="ss"
                  id="durationSeconds"
                  name="durationSeconds"
                  type={'number'}
                  value={duration.seconds}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.createMovieThumbnailItem}>
        {thumbnails.map(({ id: thumbnailId, title, imageUrl }) => {
          return (
            <ImageDropZone
              videoId={videoId}
              key={thumbnailId}
              imageUrl={imageUrl}
              title={title}
              onChange={(value) => updateTrailer(id, { [thumbnailId]: value })}
            />
          )
        })}
      </div>

      {!hls_url && (
        <div className={classes.trailerContainerCloseButton}>
          <IconButton size="small" onClick={handleTrailerRemovel}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      )}

      {/* Captions section */}
      <>
        <div className={classes.centeredContainer}>
          {captions.map((caption) => {
            return (
              <CaptionCard
                videoId={id}
                key={caption.id}
                updateCaption={updateCaption}
                caption={caption}
                removeCaption={removeCaption}
                showCloseIcon={Boolean(caption.new)}
              />
            )
          })}
        </div>

        <div className={classes.centeredContainer}>
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add Caption"
            height={35}
            onClickHandler={addCaption}
            width={150}
          />
        </div>
      </>

      {/* Audio section */}
      <div className={classes.audioSection}>
        <div className={classes.centeredContainer}>
          {audios.map((audio) => {
            return (
              <Audioform
                videoId={id}
                key={audio.id}
                audio={audio}
                update={updateAudio}
                remove={removeAudio}
                isTrailer={true}
              />
            )
          })}
        </div>

        <div className={classes.centeredContainer}>
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add Audio"
            height={35}
            onClickHandler={addAudio}
            width={150}
          />
        </div>
      </div>

      <div className={classes.flex}>
        <div className="flex-container one-flex">
          {type === 'TRAILER' && (
            <div className={classes.trailerContainerIsMain}>
              <p>Is Main</p>
              <Checkbox
                checked={is_main}
                defaultValue={is_main}
                onClick={handleIsMain}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          )}
          <div className={classes.trailerContainerIsMain}>
            <p>Active</p>
            <Checkbox
              checked={status}
              defaultValue={status}
              onClick={handleStatus}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </div>
        {!contentPreview && (
          <div className="flex-end">
            <RadioGroup
              row
              value={type || 'TRAILER'}
              onChange={handleTrailerType}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group">
              <FormControlLabel
                value="TRAILER"
                control={<Radio color="primary" />}
                label="Trailer"
              />
              <FormControlLabel
                value="SONG"
                control={<Radio color="primary" />}
                label="Song"
              />
              <FormControlLabel
                value="CLIP"
                control={<Radio color="primary" />}
                label="Clip"
              />
            </RadioGroup>
          </div>
        )}
      </div>

      <Modal
        open={Boolean(trailerUrl)}
        onClose={closeTrailerPlayer}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.playerModal}>
        <TrailerPlayer url={trailerUrl} close={closeTrailerPlayer} />
      </Modal>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  playerModal: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  trailerFormContainer: {
    border: '1px solid gray',
    padding: '20px',
    borderRadius: '5px',
    margin: '10px 0px',
    position: 'relative',
    width: '860px'
  },
  trailerForm: {
    flex: 1,
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    margin: '20px 10px 10px 10px'
  },
  captionUploadButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  captionUploadProgress: {
    flex: 1,
    paddingRight: '20px'
  },
  formTitleInput: {
    flex: 1,
    display: 'flex'
  },
  trailerDropZoneContainer: {
    display: 'flex',
    flex: 1,
    paddingTop: 10
  },
  playButtonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  trailerName: {
    color: 'gray',
    textAlign: 'center',
    margin: '0px',
    fontSize: '10px'
  },
  createMovieThumbnailItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  trailerContainerCloseButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 2
  },
  closeIcon: {
    height: 15,
    width: 15,
    color: 'red'
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  flex: {
    display: 'flex'
  },
  trailerContainerIsMain: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px'
  },
  audioSection: {
    marginTop: 10,
    paddingTop: 10,
    borderTop: '1px solid gray'
  }
}))
