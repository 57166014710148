import axios from 'axios'
import { API } from 'aws-amplify'
import moment from 'moment'

import {
  createVideo,
  batchVideoByQualities,
  batchCreateVideoGenre,
  batchCreateCaptions,
  batchCreateVideoCastCrew,
  updateVideo,
  batchCreateTrailers,
  batchCreateVideoContentRatings,
  batchDeleteVideoGenres,
  batchDeleteVideoContentRatings,
  updateVideoCastCrew,
  batchDeleteCaptions,
  updateTrailer,
  batchDeleteVideoCastCrew,
  batchUpdateVideoByQualities,
  batchCreateAudioTracks,
  batchDeleteAudioTracks
  // batchUpdateTrailers
} from '../graphql/mutations'
import {
  listVideos,
  listVideoQualities,
  videoByQuityByVideo
} from '../graphql/queries'
import { v4 } from 'uuid'
import { LanguagesCode } from '../lib/constants'
import {
  invokeUpdateCaptionsAudiosFunction,
  invokeUploadMetaFunction
} from './invokeLambda'

//This work will be shifted to lambda
// we do not want to handle transcodeing and uploading at UI side
export const addMovieAndTrailerToAWS = async (newMovie, handleProgress) => {
  let {
    videoId,
    subTitle,
    mainTitle,
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    genre,
    captions,
    castCrew,
    creditHours,
    creditMinutes,
    durationHours,
    durationMinutes,
    durationSeconds,
    creditSeconds,
    releaseDate,
    short_description,
    long_description,
    isOrignal,
    language,
    banner_message,
    duration: movieDruation,
    ratings,
    audios
  } = newMovie
  try {
    const { trailers, movieFileName } = await uploadVideoAndTrailersMeta(
      newMovie
    )

    const transcodedMovie = await fetchDetail(movieFileName, false, {})

    handleProgress(40, 'Updating DB Tables')

    let duration = movieDruation
    if (duration === 0)
      duration =
        (parseInt(durationHours) * 60 * 60 +
          parseInt(durationMinutes) * 60 +
          parseInt(durationSeconds !== '' ? durationSeconds : 0)) *
        1000
    const credits_start_timestamp =
      (parseInt(creditHours) * 60 * 60 +
        parseInt(creditMinutes) * 60 +
        parseInt(creditSeconds !== '' ? creditSeconds : 0)) *
      1000
    const release_date = moment(releaseDate).format()

    const uploadedVideo = await uploadVideo(
      videoId,
      subTitle,
      mainTitle,
      thumbnail_sm,
      thumbnail_md,
      thumbnail_lg,
      duration,
      credits_start_timestamp,
      release_date,
      short_description,
      long_description,
      isOrignal,
      language,
      banner_message,
      transcodedMovie.detail.mp4UrlsCloudflare.length
        ? transcodedMovie.detail.mp4UrlsCloudflare[0]
        : '',
      transcodedMovie.detail.Mp4GroupOutputsSyncStatus,
      transcodedMovie.detail.nonMp4GroupOutputsSyncStatus
    )

    handleProgress(48, 'Updating DB Tables')

    await addVideoByQualities(
      uploadedVideo.id,
      transcodedMovie.detail.hlsUrls,
      transcodedMovie.detail.hlsUrlsCloudflare
    )

    handleProgress(50, 'Updating DB Tables')

    await addVideoContentRatings(uploadedVideo.id, ratings)

    handleProgress(55, 'Updating DB Tables')

    await addVideoGenre(uploadedVideo.id, genre)

    handleProgress(63, 'Updating DB Tables')

    if (captions.length > 0) await addCaptions(uploadedVideo.id, captions)

    if (audios.length > 0) await addAudioTracks(uploadedVideo.id, audios)

    handleProgress(73, 'Updating DB Tables')

    await addCastCrew(uploadedVideo.id, castCrew)

    handleProgress(80, 'Updating DB Tables')

    const transcodedTrailers = await Promise.all(
      trailers.map(async (trailer) => {
        const result = await fetchDetail(trailer.key, true, trailer)
        const hlsUrls = {}
        await Promise.all(
          result.detail.hlsUrlsCloudflare.map((url) => {
            const resolution = parseVideoQuality(url)
            hlsUrls[parseInt(resolution)] = url
            return
          })
        )
        const hlsUrl = hlsUrls[Math.max(...Object.keys(hlsUrls))]

        return {
          ...result,
          hlsCloudflareUrl: hlsUrl
        }
      })
    )

    handleProgress(90, 'Updating DB Tables')

    await addTrailerCaptions(trailers)
    await addTrailerAudioTracks(trailers)

    console.log('transcodedTrailers', transcodedTrailers)

    handleProgress(95, 'Updating DB Tables')

    const processedTrailers = transcodedTrailers.map((trailer) => {
      const durationObj = trailer.duration || {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      return {
        id: trailer.id,
        hls_url: trailer.hlsCloudflareUrl,
        status: true,
        thumbnail_sm: trailer.thumbnail_sm
          ? trailer.thumbnail_sm
          : thumbnail_sm,
        thumbnail_md: trailer.thumbnail_md
          ? trailer.thumbnail_sm
          : thumbnail_md,
        thumbnail_lg: trailer.thumbnail_lg
          ? trailer.thumbnail_sm
          : thumbnail_lg,
        title: trailer.title,
        is_main: trailer.isMain,
        video_id: uploadedVideo.id,
        type: trailer.type,
        additional_attributes: JSON.stringify({
          duration: durationObj
        })
      }
    })

    // await batchCreateTrailers()
    await addTrailer(processedTrailers)

    handleProgress(100, 'Updating DB Tables')

    return
  } catch (err) {
    throw err
  }
}

const uploadVideoAndTrailersMeta = async (newMovie) => {
  let { videoId, movie, trailers, captions, audios } = newMovie
  const caption = captions.map((item) => {
    return {
      language: item.language,
      srcCaption: item.file,
      // type: item.file.includes('.srt') ? 'SRT' : 'WEBVTT',
      type: 'WEBVTT',
      languageCode: LanguagesCode[item.language].toUpperCase(),
      timeDelta: 0
    }
  })
  const payload = {
    data: {
      srcVideo: movie,
      audio: audios,
      caption
    },
    videoId,
    isMovie: true
  }

  const { fileName: movieFileName } = await invokeUploadMetaFunction(payload)

  /** upload trailers meta files to public folder inside video source bucket */
  trailers = await Promise.all(
    trailers.map(async (trailer) => {
      const trailerCaptions = trailer.captions.map((item) => {
        return {
          language: item.language,
          srcCaption: item.url,
          // type: item.url.includes('.srt') ? 'SRT' : 'WEBVTT',
          type: 'WEBVTT',
          languageCode: LanguagesCode[item.language].toUpperCase(),
          timeDelta: 0
        }
      })
      const trailerPayload = {
        data: {
          srcVideo: trailer.key,
          audio: trailer.audios,
          caption: trailerCaptions
        },
        videoId: trailer.id,
        isMovie: false
      }

      const { fileName: trailerFileName } = await invokeUploadMetaFunction(
        trailerPayload
      )
      return {
        ...trailer,
        key: trailerFileName
      }
    })
  )

  return { trailers, movieFileName }
}

const parseVideoQuality = (url) => {
  const splittedUrl = url.split('/')
  const fileName = splittedUrl[splittedUrl.length - 1].split('.')
  const splittedFileName = fileName[0].split('_')
  const quality = splittedFileName[splittedFileName.length - 1]
  return quality
}

const addVideoByQualities = async (videoId, hls_urls, cloudflare_hls_urls) => {
  const hls_resolutions = {}
  // parsing and fetching resolution from url
  hls_urls.forEach((url) => {
    const resolution = parseVideoQuality(url)
    hls_resolutions[resolution] = url
  })

  const cloudflare_hls_resolutions = {}
  // parsing and fetching resolution from url
  cloudflare_hls_urls.forEach((url) => {
    const resolution = parseVideoQuality(url)
    cloudflare_hls_resolutions[resolution] = url
  })

  const resolutions = Object.keys(hls_resolutions)
  // creating condition for getting resolution ids
  const orConditions = resolutions.map((resolution) => {
    return {
      resolution: { eq: resolution }
    }
  })

  try {
    // fetching video qualites for id
    const {
      data: {
        listVideoQualities: { items: qualityItems }
      }
    } = await API.graphql({
      query: listVideoQualities,
      variables: {
        filter: {
          or: orConditions
        }
      }
    })

    const payload = qualityItems.map(({ id, resolution }) => {
      return {
        // will remove these later because currently our
        // other functions platforms (mobile, tv etc) may use these values
        cloudflare: {
          hls_url: cloudflare_hls_resolutions[resolution]
        },
        cloudfront: {
          hls_url: hls_resolutions[resolution]
        },
        status: true,
        video_id: videoId,
        video_quality_id: id,
        id: v4()
      }
    })

    await API.graphql({
      query: batchVideoByQualities,
      variables: { videoByQualites: payload }
    })
  } catch (err) {
    console.log('error in adding VideoByQuality  ', err)
    throw new Error('Something went wrong in uploading video')
  }
}

const addVideoContentRatings = async (video_id, ratings) => {
  const payload = ratings.map((item) => {
    return {
      id: v4(),
      video_id,
      content_rating_id: item.id
    }
  })

  try {
    await API.graphql({
      query: batchCreateVideoContentRatings,
      variables: { ratings: payload }
    })
  } catch (err) {
    console.log('error in adding video ratings  ', err)
    throw new Error('Something went wrong in adding video ratings')
  }
}

const addTrailer = async (payload) => {
  try {
    await API.graphql({
      query: batchCreateTrailers,
      variables: { trailers: payload }
    })
  } catch (err) {
    console.log('Error in uploading trailers is: ', err)
    throw new Error('uploading tailer failed')
  }
}

const addVideoGenre = async (video_id, genre) => {
  const payload = genre.map((genre_id) => {
    return {
      genre_id,
      video_id
    }
  })

  try {
    await API.graphql({
      query: batchCreateVideoGenre,
      variables: { videoGenres: payload }
    })
  } catch (err) {
    console.log('Error in addVideoGenre is: ', err)
    throw new Error('uploading video genre failed')
  }
}

const addAudioTracks = async (video_id, audios) => {
  const tracks = audios.map(({ id, language, srcAudio, isOriginal }) => {
    return {
      id,
      language,
      url: srcAudio,
      status: true,
      is_orignal: isOriginal,
      video_id
    }
  })

  try {
    if (tracks.length > 0)
      await API.graphql({
        query: batchCreateAudioTracks,
        variables: { tracks }
      })
  } catch (err) {
    console.log('Error in uploading audio tracks is: ', err)
    throw new Error('uploading audio tracks failed')
  }
}

const addTrailerAudioTracks = async (trailers) => {
  let tracks = []
  trailers.forEach((trailer) => {
    trailer.audios.forEach(({ id, language, srcAudio, isOriginal }) => {
      tracks.push({
        id,
        language,
        url: srcAudio,
        status: true,
        is_orignal: isOriginal,
        video_id: trailer.id
      })
    })
  })

  try {
    if (tracks.length > 0)
      await API.graphql({
        query: batchCreateAudioTracks,
        variables: { tracks }
      })
  } catch (err) {
    console.log('Error in uploading trailer audio tracks is: ', err)
    throw new Error('uploading trailer audio tracks failed')
  }
}

const addCaptions = async (video_id, captions) => {
  const payload = captions.map(({ language, file, isOrignal }) => {
    return {
      language: language,
      url: file,
      status: true,
      video_id,
      is_orignal: isOrignal
    }
  })

  try {
    if (payload.length > 0)
      await API.graphql({
        query: batchCreateCaptions,
        variables: { captions: payload }
      })
  } catch (err) {
    console.log('Error in uploading supported captions is: ', err)
    throw new Error('uploading supported captions failed')
  }
}

const addTrailerCaptions = async (trailers) => {
  let captions = []
  trailers.forEach((trailer) => {
    trailer.captions.forEach((caption) => {
      captions.push({
        language: caption.language,
        url: caption.url,
        status: true,
        video_id: trailer.id,
        is_orignal: false
      })
    })
  })

  try {
    if (captions.length > 0)
      await API.graphql({
        query: batchCreateCaptions,
        variables: { captions }
      })
  } catch (err) {
    console.log('Error in uploading trailer supported captions is: ', err)
    throw new Error('uploading supported trailer captions failed')
  }
}

const addCastCrew = async (video_id, castCrew) => {
  let payload = []
  Object.keys(castCrew).forEach((cast_crew_type_id) => {
    const cast = castCrew[cast_crew_type_id].cast.map((castItem) => {
      return {
        cast_crew_id: castItem.id,
        status: true,
        display: castItem.display,
        cast_crew_type_id,
        video_id
      }
    })

    payload = [...payload, ...cast]
  })

  try {
    await API.graphql({
      query: batchCreateVideoCastCrew,
      variables: { videoCast: payload }
    })
  } catch (err) {
    console.log('Error in uploading Video Cast Crew is: ', err)
    throw new Error('Uploading Cast and crew fails')
  }
}

const uploadVideo = async (
  videoId,
  subTitle,
  mainTitle,
  thumbnail_sm,
  thumbnail_md,
  thumbnail_lg,
  duration,
  credits_start_timestamp,
  release_date,
  short_description,
  long_description,
  is_orignal,
  language,
  banner_message,
  download_url,
  Mp4GroupOutputsSyncStatus,
  nonMp4GroupOutputsSyncStatus
) => {
  const payload = {
    id: videoId,
    title: `${mainTitle} ${subTitle}`,
    sub_title: subTitle,
    main_title: mainTitle,
    thumbnail_sm: thumbnail_sm,
    thumbnail_md: thumbnail_md,
    thumbnail_lg: thumbnail_lg,
    duration,
    short_description,
    long_description,
    status: false,
    statusValue: 'DISABLED',
    release_date,
    credits_start_timestamp,
    // will handle these values later
    intro_start_time: credits_start_timestamp,
    intro_end_time: credits_start_timestamp,
    language,
    banner_message,
    is_orignal,
    download_url,
    non_mp4_group_outputs_sync_status: nonMp4GroupOutputsSyncStatus,
    mp4_group_outputs_sync_status: Mp4GroupOutputsSyncStatus
  }

  try {
    const { data } = await API.graphql({
      query: createVideo,
      variables: { input: payload }
    })

    return data.createVideo
  } catch (err) {
    console.log('error in uploading video ', err)
    throw new Error('Something went wrong in uploading video')
  }
}

// fetch the status and detail of transcoding
const fetchDetail = async (movie, isTrailer, payload) => {
  try {
    const {
      data: { body }
    } = await axios.get(
      `${
        process.env.REACT_APP_TRANSCODING_STATUS_URL
      }?filename=${movie}&videotype=movie&isTrailer=${
        isTrailer ? 'yes' : 'no'
      }`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    )
    if (!body || !body.status) {
      return await fetchDetail(movie, isTrailer, payload)
    }
    // when transcoding starts its status becomes ingest
    // The status will changes only if the transcoding fails or complete
    if (body.status === 'Ingest') {
      console.log('ingest callled')
      // If the status is ingest ite means it still in processing
      // need to check it again till the status change
      return await fetchDetail(movie, isTrailer, payload)
    }
    // when transcoding of video is completed its status becomes Complete
    else if (body.status === 'Complete') {
      console.log('complete called ', body)
      return { ...body, ...payload }
    }
    // If something went wrong the status becomes Error
    else if (body.status === 'Error') {
      console.log('error called ', body)
      throw new Error('Transcoding movie fails')
    }
  } catch (err) {
    console.log('error in transcoding ', err)
    throw new Error('Something went wrong in transcoding movie')
  }
}

// fetch all videos
export const fetchMovies = async () => {
  try {
    const {
      data: {
        listVideos: { items }
      }
    } = await API.graphql({
      query: listVideos
    })
    const sortedItems = items.sort((first, second) => {
      return new Date(second.createdAt) - new Date(first.createdAt)
    })

    return sortedItems
  } catch (err) {
    console.log('error in fetching movies ', err)
    throw new Error('Something went wrong in uploading video')
  }
}

export const updateMovie = async (payload) => {
  try {
    await API.graphql({
      query: updateVideo,
      variables: { input: payload }
    })
  } catch (err) {
    console.log('error in updating video ', err)
    throw new Error('Something went wrong in updating video')
  }
}

export const updateGenre = async (oldGenre, newGenre, video_id) => {
  const newGenreIds = newGenre.map((item) => item.id)
  const oldGenreIds = oldGenre.items.map((item) => item.genre.id)
  const toDelete = oldGenre.items.filter(
    (item) => newGenreIds.indexOf(item.genre.id) < 0
  )
  const toCreate = newGenreIds.filter((item) => oldGenreIds.indexOf(item) < 0)

  const videoGenres = toCreate.map((genre_id) => {
    return {
      genre_id,
      video_id
    }
  })
  const ids = toDelete.map((item) => item.id)

  try {
    if (videoGenres.length > 0)
      await API.graphql({
        query: batchCreateVideoGenre,
        variables: { videoGenres }
      })

    if (toDelete.length > 0)
      await API.graphql({
        query: batchDeleteVideoGenres,
        variables: { ids }
      })
  } catch (err) {
    console.log('Error in updating genre is: ', err)
    throw new Error('Error in updating genre')
  }
}

export const updateContentRatings = async (
  oldContentRatings,
  newContentRatings,
  video_id
) => {
  const newContentRatingIds = newContentRatings.map((item) => item.id)
  const oldContentRatingIds = oldContentRatings.items.map(
    (item) => item.content_rating.id
  )
  const toDelete = oldContentRatings.items.filter(
    (item) => newContentRatingIds.indexOf(item.content_rating.id) < 0
  )
  const toCreate = newContentRatingIds.filter(
    (item) => oldContentRatingIds.indexOf(item) < 0
  )

  const ratings = toCreate.map((content_rating_id) => {
    return {
      id: v4(),
      video_id,
      content_rating_id
    }
  })

  const ids = toDelete.map((item) => item.id)

  try {
    if (ratings.length > 0)
      await API.graphql({
        query: batchCreateVideoContentRatings,
        variables: { ratings }
      })

    if (toDelete.length > 0)
      await API.graphql({
        query: batchDeleteVideoContentRatings,
        variables: { ids }
      })
  } catch (err) {
    console.log('Error in updating content rating is: ', err)
    throw new Error('Error in updating content ratings is')
  }
}

export const updateMetaInformation = async (
  payload,
  genreUpdated,
  contentRatingUpdated,
  video_genres,
  videoGenres,
  video_content_ratings,
  videoContentRatings,
  video_id
) => {
  await updateMovie(payload)
  // update genre
  if (genreUpdated) await updateGenre(video_genres, videoGenres, video_id)
  // update content ratings
  if (contentRatingUpdated)
    await updateContentRatings(
      video_content_ratings,
      videoContentRatings,
      video_id
    )
}

export const updateCastCrewVisibility = async (payload) => {
  try {
    await API.graphql({
      query: updateVideoCastCrew,
      variables: { input: payload }
    })
  } catch (err) {
    console.log('error in updating castCrew ', err)
    throw new Error('Something went wrong in updating castCrew')
  }
}

export const updateMovieCastCrew = async (oldData, newData, video_id) => {
  try {
    let newArray = []
    const oldArray = oldData.items.filter((item) => item.cast_crew)
    Object.keys(newData).forEach(
      (item) => (newArray = [...newArray, ...newData[item]])
    )
    const oldIds = {}
    const newIds = {}
    oldArray.forEach((item) => {
      oldIds[`${item.cast_crew.id}_${item.cast_crew_type.id}`] = item
    })
    newArray.forEach((item) => {
      newIds[`${item.cast_crew.id}_${item.cast_crew_type.id}`] = item
    })
    let toCreate = []
    Object.keys(newIds).forEach((item) => {
      if (!oldIds[item]) toCreate.push(newIds[item])
    })

    let toDelete = []
    Object.keys(oldIds).forEach((item) => {
      if (!newIds[item]) toDelete.push(oldIds[item])
    })

    let toUpdate = []
    Object.keys(oldIds).forEach((item) => {
      if (newIds[item])
        toUpdate.push({ ...oldIds[item], display: newIds[item].display })
    })

    if (toDelete.length > 0 || toUpdate.length > 0) {
      const ids = [...toDelete, ...toUpdate].map((item) => item.id)
      await API.graphql({
        query: batchDeleteVideoCastCrew,
        variables: { ids }
      })
    }
    // create new entries
    if (toCreate.length > 0 || toUpdate.length > 0) {
      const payload = [...toCreate, ...toUpdate].map((item) => {
        return {
          cast_crew_id: item.cast_crew.id,
          status: true,
          display: item.display,
          cast_crew_type_id: item.cast_crew_type.id,
          video_id
        }
      })
      await API.graphql({
        query: batchCreateVideoCastCrew,
        variables: { videoCast: payload }
      })
    }
    // const toUpdate = []

    // const toDelete = []
    //   return {
    //     cast_crew_id: castItem.id,
    //     status: true,
    //     display: castItem.display,
    //     cast_crew_type_id,
    //     video_id
    //   }
    // })

    // payload = [...payload, ...cast]
    // })

    // try {
    // await API.graphql({
    //   query: batchCreateVideoCastCrew,
    //   variables: { videoCast: payload }
    // })
    // } catch (err) {

    // }
  } catch (err) {
    console.log('Error in uploading Video Cast Crew is: ', err)
    throw new Error('Uploading Cast and crew fails')
  }
}

const updateVideoByQualities = async (
  video_id,
  cloudfront_hls_urls,
  cloudflare_hls_urls
) => {
  const cloudfront_hls_resolutions = {}
  // parsing and fetching resolution from url
  cloudfront_hls_urls.forEach((url) => {
    const resolution = parseVideoQuality(url)
    cloudfront_hls_resolutions[resolution] = url
  })

  const cloudflare_hls_resolutions = {}
  // parsing and fetching resolution from url
  cloudflare_hls_urls.forEach((url) => {
    const resolution = parseVideoQuality(url)
    cloudflare_hls_resolutions[resolution] = url
  })

  const {
    data: {
      videoByQuityByVideo: { items: videoQualityItems }
    }
  } = await API.graphql({
    query: videoByQuityByVideo,
    variables: { video_id }
  })

  const updatedQualities = videoQualityItems.map((item) => {
    return {
      id: item.id,
      cloudfront: {
        hls_url: cloudfront_hls_resolutions[item.video_quality.resolution]
      },
      cloudflare: {
        hls_url: cloudflare_hls_resolutions[item.video_quality.resolution]
      },
      status: item.status,
      video_id: item.video_id,
      video_quality_id: item.video_quality_id
    }
  })

  await API.graphql({
    query: batchUpdateVideoByQualities,
    variables: { videoByQualites: updatedQualities }
  })
}

export const updateAudioTracks = async (oldAudios, newAudios, videoId) => {
  const audio = newAudios
    .filter((item) => item.status)
    .map(({ id, language, languageCode, isOriginal, srcAudio }) => {
      return {
        id,
        language,
        languageCode,
        isOriginal,
        srcAudio
      }
    })

  const params = {
    videoId,
    audio,
    isMovie: true
  }
  const { fileName: movieFileName } = await invokeUpdateCaptionsAudiosFunction(
    params
  )

  const transcodedMovie = await fetchDetail(movieFileName, false, {})

  /** update download url */
  await updateMovie({
    id: videoId,
    download_url: transcodedMovie.detail.mp4UrlsCloudflare.length
      ? transcodedMovie.detail.mp4UrlsCloudflare[0]
      : ''
  })

  await updateVideoByQualities(
    videoId,
    transcodedMovie.detail.hlsUrls,
    transcodedMovie.detail.hlsUrlsCloudflare
  )
  const ids = oldAudios.map((item) => item.id)
  try {
    if (ids.length > 0)
      await API.graphql({
        query: batchDeleteAudioTracks,
        variables: { ids }
      })

    const tracks = newAudios.map(({ id, language, srcAudio, isOriginal }) => {
      return {
        id,
        language,
        url: srcAudio,
        status: true,
        is_orignal: isOriginal,
        video_id: videoId
      }
    })

    if (tracks.length > 0)
      await API.graphql({
        query: batchCreateAudioTracks,
        variables: { tracks }
      })
  } catch (err) {
    console.log('error in updating audios ', err)
    throw new Error('Something went wrong in updating audios')
  }
}

export const updateCaptions = async (oldCaptions, newCaptions, videoId) => {
  const caption = newCaptions
    .filter((item) => item.status)
    .map((item) => {
      return {
        language: item.language,
        srcCaption: item.url,
        // type: item.url.includes('.srt') ? 'SRT' : 'WEBVTT',
        type: 'WEBVTT',
        languageCode: LanguagesCode[item.language].toUpperCase(),
        timeDelta: 0
      }
    })

  const params = {
    videoId,
    caption,
    isMovie: true
  }
  const { fileName: movieFileName } = await invokeUpdateCaptionsAudiosFunction(
    params
  )

  const transcodedMovie = await fetchDetail(movieFileName, false, {})

  /** update download url */
  await updateMovie({
    id: videoId,
    download_url: transcodedMovie.detail.mp4UrlsCloudflare.length
      ? transcodedMovie.detail.mp4UrlsCloudflare[0]
      : ''
  })

  await updateVideoByQualities(
    videoId,
    transcodedMovie.detail.hlsUrls,
    transcodedMovie.detail.hlsUrlsCloudflare
  )
  const ids = oldCaptions.map((item) => item.id)
  try {
    if (ids.length > 0)
      await API.graphql({
        query: batchDeleteCaptions,
        variables: { ids }
      })

    if (newCaptions.length > 0)
      await API.graphql({
        query: batchCreateCaptions,
        variables: { captions: newCaptions }
      })
  } catch (err) {
    console.log('error in updating captions ', err)
    throw new Error('Something went wrong in updating captions')
  }
}

export const updateTrailersAndCaptions = async (
  trailers,
  movieThumbnailLg,
  movieThumbnailMd,
  movieThumbnailSm
) => {
  try {
    const filteredUpdatedTrailers = trailers.filter(
      (item) => item.updated && !item.new
    )

    /** update meta file for updated trailers */
    await Promise.all(
      filteredUpdatedTrailers.map(async (trailer, index) => {
        const { supportedCaptions, audio_tracks: addAudioTracks, id } = trailer
        const caption = supportedCaptions
          .filter((item) => item.status)
          .map((item) => {
            return {
              language: item.language,
              srcCaption: item.url,
              // type: item.url.includes('.srt') ? 'SRT' : 'WEBVTT',
              type: 'WEBVTT',
              languageCode: LanguagesCode[item.language].toUpperCase(),
              timeDelta: 0
            }
          })

        const audio = addAudioTracks
          .filter((item) => item.status)
          .map(({ id, language, languageCode, isOriginal, srcAudio }) => {
            return {
              id,
              language,
              languageCode,
              isOriginal,
              srcAudio
            }
          })

        const params = {
          videoId: id,
          audio,
          caption,
          isMovie: false
        }
        const { fileName } = await invokeUpdateCaptionsAudiosFunction(params)

        const result = await fetchDetail(fileName, true, trailer)
        const hlsUrls = {}
        await Promise.all(
          result.detail.hlsUrlsCloudflare.map((url) => {
            const resolution = parseVideoQuality(url)
            hlsUrls[parseInt(resolution)] = url
            return
          })
        )
        const hlsUrl = hlsUrls[Math.max(...Object.keys(hlsUrls))]
        trailer.hls_url = hlsUrl
      })
    )

    let newFilteredTrailers = trailers.filter((item) => item.new)

    newFilteredTrailers = await Promise.all(
      newFilteredTrailers.map(async (trailer) => {
        const trailerCaptions = trailer.supportedCaptions.map((item) => {
          return {
            language: item.language,
            srcCaption: item.url,
            // type: item.url.includes('.srt') ? 'SRT' : 'WEBVTT',
            type: 'WEBVTT',
            languageCode: LanguagesCode[item.language].toUpperCase(),
            timeDelta: 0
          }
        })
        const trailerPayload = {
          data: {
            srcVideo: trailer.key,
            audio: trailer.audio_tracks,
            caption: trailerCaptions
          },
          videoId: trailer.id,
          isMovie: false
        }

        const { fileName: trailerFileName } = await invokeUploadMetaFunction(
          trailerPayload
        )
        return {
          ...trailer,
          key: trailerFileName
        }
      })
    )

    const transcodedTrailers = await Promise.all(
      newFilteredTrailers.map(async (trailer) => {
        const result = await fetchDetail(trailer.key, true, trailer)
        const hlsUrls = {}
        await Promise.all(
          result.detail.hlsUrlsCloudflare.map((url) => {
            const resolution = parseVideoQuality(url)
            hlsUrls[parseInt(resolution)] = url
            return
          })
        )
        const hlsUrl = hlsUrls[Math.max(...Object.keys(hlsUrls))]
        return {
          ...result,
          hlsCloudflareUrl: hlsUrl
        }
      })
    )

    const newTrailers = transcodedTrailers.map((trailer) => {
      const durationObj = trailer.duration || {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      return {
        id: trailer.id,
        hls_url: trailer.hlsCloudflareUrl,
        status: true,
        thumbnail_sm: trailer.thumbnail_sm || movieThumbnailSm,
        thumbnail_md: trailer.thumbnail_md || movieThumbnailMd,
        thumbnail_lg: trailer.thumbnail_lg || movieThumbnailLg,
        title: trailer.title,
        is_main: trailer.isMain,
        video_id: trailer.video_id,
        type: trailer.type || 'TRAILER',
        additional_attributes: JSON.stringify({ duration: durationObj })
      }
    })

    const updatedTrailers = filteredUpdatedTrailers.map((item) => {
      const durationObj = item.duration || {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      return {
        id: item.id,
        status: item.status,
        thumbnail_sm: item.thumbnail_sm || movieThumbnailSm,
        thumbnail_md: item.thumbnail_md || movieThumbnailMd,
        thumbnail_lg: item.thumbnail_lg || movieThumbnailLg,
        title: item.title,
        is_main: item.is_main,
        type: item.type || 'TRAILER',
        hls_url: item.hls_url,
        additional_attributes: JSON.stringify({ duration: durationObj })
      }
    })
    const filteredTrailers = trailers.filter((item) => item.captionsUpdated)
    const filteredAudiosTrailers = trailers.filter((item) => item.audiosUpdated)

    /** audios */
    let allAudios = []
    filteredAudiosTrailers.forEach((item) => {
      allAudios = [...allAudios, ...item.audio_tracks]
    })
    let newAudios = allAudios.filter((item) => item.new)
    let updatedAudios = allAudios.filter((item) => item.updated && !item.new)
    let deletedAudios = updatedAudios.map((item) => item.id)

    /** captions */
    let allCaptions = []
    filteredTrailers.forEach((item) => {
      allCaptions = [...allCaptions, ...item.supportedCaptions]
    })
    let newCaptions = allCaptions.filter((item) => item.new)
    let updatedCaptions = allCaptions.filter(
      (item) => item.updated && !item.new
    )
    let deletedCaptions = updatedCaptions.map((item) => item.id)

    if (updatedTrailers.length > 0) {
      console.log('update existing trailers')
      await Promise.all(
        updatedTrailers.map(async (item) => {
          await API.graphql({
            query: updateTrailer,
            variables: { input: item }
          })
        })
      )

      // not working exactly
      // behaviour: delete items
      // expected behaviour: update items
      // await API.graphql({
      //   query: batchUpdateTrailers,
      //   variables: { trailers: updatedTrailers }
      // })
    }

    // add new trailers
    if (newTrailers.length > 0) {
      await addTrailer(newTrailers)
    }

    // delete Audios
    if (deletedAudios.length > 0) {
      await API.graphql({
        query: batchDeleteAudioTracks,
        variables: { ids: deletedAudios }
      })
    }

    /** create new audios */
    if (newAudios.length > 0 || updatedAudios.length > 0) {
      const tracks = [...newAudios, ...updatedAudios].map((item) => {
        return {
          id: item.id,
          language: item.language,
          url: item.srcAudio,
          status: item.status || true,
          is_orignal: item.isOriginal,
          video_id: item.video_id
        }
      })
      await API.graphql({
        query: batchCreateAudioTracks,
        variables: { tracks }
      })
    }

    // delete captions
    if (deletedCaptions.length > 0) {
      await API.graphql({
        query: batchDeleteCaptions,
        variables: { ids: deletedCaptions }
      })
    }
    // create new captions
    if (newCaptions.length > 0 || updatedCaptions.length > 0) {
      const payload = [...newCaptions, ...updatedCaptions].map((item) => {
        return {
          id: item.id,
          language: item.language,
          url: item.url,
          status: item.status,
          video_id: item.video_id,
          is_orignal: item.is_orignal
        }
      })
      await API.graphql({
        query: batchCreateCaptions,
        variables: { captions: payload }
      })
    }

    // const newTrailers = []
    // const deletedCaptions = []
  } catch (err) {
    console.log('error in updating trailers and captions ', err)
    throw new Error('Something went wrong in updating captions')
  }
}
