import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { useSelector } from 'react-redux'

import {
  ButtonComponent,
  CaptionsValidation,
  Header
} from '../../../components'
import { PrimaryColor } from '../../../styles/colors'
import { CaptionForm, AddCaptionModal } from './_index'

export const StepFour = () => {
  const { captions } = useSelector((state) => state.newMovie)

  const [isVisible, setIsVisible] = useState(false)
  const [validationModal, setValidationModal] = useState(false)

  const closeModal = () => setIsVisible(false)
  const closeValidationModal = () => setValidationModal(false)

  return (
    <div>
      <Header title="Upload Caption" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonComponent
          borderColor={PrimaryColor}
          backgroundColor={PrimaryColor}
          titleColor={'#ffffff'}
          title="Validate Captions"
          width={200}
          height={35}
          onClickHandler={() => setValidationModal(true)}
        />
      </div>
      <div className="create-movie-step">
        <div className="create-caption-button">
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="ADD"
            width={90}
            height={25}
            onClickHandler={() => setIsVisible(true)}
          />
        </div>
        {captions.length === 0 && (
          <div className={'empty-captions-container'}>
            Please click on the <span className={'highlighted-text'}>ADD</span>{' '}
            button above to add captions or press{' '}
            <span className={'highlighted-text'}>NEXT</span> below to skip it
          </div>
        )}
        {captions.map((item) => {
          return <CaptionForm item={item} />
        })}
      </div>

      <Modal
        open={isVisible}
        closeModal={closeModal}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="file-upload-modal">
        <AddCaptionModal closeModal={closeModal} />
      </Modal>

      <CaptionsValidation
        isOpen={validationModal}
        close={closeValidationModal}
      />
    </div>
  )
}
