import React from 'react'
import { Header } from '../../../components'
import { VideoUploadForm } from './_index'

export const StepFive = () => {
  return (
    <div>
      <Header title="Upload Files" />
      <div className="create-movie-step">
        <div className="create-caption-button">
          <div className="file-type">Upload Movie</div>
        </div>

        <VideoUploadForm />
      </div>
    </div>
  )
}
