import React, { useState, useEffect } from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { useStyles } from './styles'
import { LandingPagePreview } from './components'
import { Close, DesktopWindows, PhoneAndroid, Tablet } from '@material-ui/icons'

export const PreviewDrawer = ({ open, close }) => {
  const classes = useStyles()
  const [selectedDevice, setSelectedDevice] = useState('desktop')
  const [device, setDevice] = useState(selectedDevice)

  // re-render the landing page area
  // to close the player if any
  useEffect(() => {
    setDevice(null)
    setTimeout(() => {
      setDevice(selectedDevice)
    }, 300)
  }, [selectedDevice, setDevice])

  return (
    <div>
      <Drawer
        classes={{
          paper: classes.drawerPaper
        }}
        anchor={'right'}
        open={open}>
        <div className={classes.landingPagePreviewMainContainer}>
          <div className={classes[selectedDevice]}>
            {device && <LandingPagePreview selectedDevice={device} />}
          </div>
        </div>
        <div className={classes.platformsContainer}>
          <IconButton
            onClick={() => setSelectedDevice('desktop')}
            className={
              selectedDevice === 'desktop'
                ? classes.selectedDeviceButton
                : classes.deviceButton
            }>
            <DesktopWindows
              className={
                selectedDevice === 'desktop'
                  ? classes.selectedDeviceIcon
                  : classes.deviceIcon
              }
            />
          </IconButton>
          <IconButton
            onClick={() => setSelectedDevice('tablet')}
            className={
              selectedDevice === 'tablet'
                ? classes.selectedDeviceButton
                : classes.deviceButton
            }>
            <Tablet
              className={
                selectedDevice === 'tablet'
                  ? classes.selectedDeviceIcon
                  : classes.deviceIcon
              }
            />
          </IconButton>
          <IconButton
            onClick={() => setSelectedDevice('mobile')}
            className={
              selectedDevice === 'mobile'
                ? classes.selectedDeviceButton
                : classes.deviceButton
            }>
            <PhoneAndroid
              className={
                selectedDevice === 'mobile'
                  ? classes.selectedDeviceIcon
                  : classes.deviceIcon
              }
            />
          </IconButton>
        </div>
        <IconButton
          size="small"
          onClick={close}
          className={classes.closeButton}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </Drawer>
    </div>
  )
}
