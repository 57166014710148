import React, { useEffect, useState, useReducer } from 'react'
import {
  Chip,
  IconButton,
  makeStyles,
  Switch,
  LinearProgress,
  Typography
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Storage } from 'aws-amplify'
import ReactDropzone from 'react-dropzone'
import { v4 as uuid4 } from 'uuid'
import { Close } from '@material-ui/icons'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'

import config from '../../../config'
import { ButtonComponent, SelectInput } from '../../../components'
import { PrimaryColor, SecondaryButton } from '../../../styles/colors'
import { ALERT_TYPES, LanguageList } from '../../../lib/constants'
import { showAlert } from '../../../redux/modules/alertHandler'
import { updateCaptions } from '../../../backend/movies'

export const Captions = ({ supported_captions, id, updateLocally }) => {
  const classes = useStyles()
  const [captions, setCaptions] = useState([])
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setCaptions([])
    if (supported_captions && supported_captions.items) {
      const data = supported_captions.items.map((item) => {
        if (!item.additional_attributes) delete item['additional_attributes']
        delete item['createdAt']
        delete item['updatedAt']
        return { ...item }
      })
      setCaptions(data)
    }
  }, [supported_captions, setCaptions])

  useEffect(() => {
    const filteredCaptions = captions.filter((item) => !item.url)
    setValidated(filteredCaptions.length > 0 ? false : true)
  }, [captions, setValidated])

  const addCaption = () => {
    const newCaption = {
      id: uuid4(),
      is_orignal: false,
      language: 'English',
      status: true,
      url: null,
      video_id: id
    }

    setCaptions([...captions, { ...newCaption }])
    setUpdated(true)
  }
  const removeCaption = (id) => {
    const updatedCaptions = captions.filter((item) => item.id !== id)
    setCaptions(updatedCaptions)
    setUpdated(true)
  }

  const updateCaption = (caption) => {
    const updatedCaptions = captions.map((item) => {
      if (item.id === caption.id) return caption
      return item
    })
    setCaptions(updatedCaptions)
    setUpdated(true)
  }

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const handleUpdate = async () => {
    if (loading) return

    setLoading(true)
    try {
      await updateCaptions(supported_captions.items, captions, id)
      handleAlert('Data updated successfuly', ALERT_TYPES.SUCCESS)
      updateLocally()
      setLoading(false)
      setUpdated(false)
    } catch (err) {
      console.log('Error is: ', err)
      handleAlert('Something went wrong. Please try again.', ALERT_TYPES.ERROR)
      setLoading(false)
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <div style={{ margin: '10px 0px' }}>
          <ButtonComponent
            title="Add Caption"
            backgroundColor={SecondaryButton}
            borderColor={SecondaryButton}
            onClickHandler={addCaption}
            width={150}
            height={30}
          />
        </div>
        <div className={classes.captionsCardContainer}>
          {captions.length === 0 && (
            <div style={{ marginTop: 20 }}>
              <Typography>
                Click on add Caption button to add captions
              </Typography>
            </div>
          )}
          {captions.map((caption) => (
            <CaptionCard
              videoId={id}
              key={caption.id}
              updateCaption={updateCaption}
              caption={caption}
              removeCaption={removeCaption}
            />
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <ButtonComponent
          title="UPDATE"
          disabled={!updated || !validated}
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          loading={loading}
          onClickHandler={handleUpdate}
          width={150}
          height={40}
        />
      </div>
      {loading && <div>This will take few minutes.</div>}
    </div>
  )
}

export const CaptionCard = ({
  videoId,
  caption,
  removeCaption,
  updateCaption,
  showCloseIcon = true
}) => {
  const classes = useStyles()
  const [{ uploading, uploadProgress }, setUploadData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      uploading: false,
      uploadProgress: 0
    }
  )
  const handleLanguage = ({ target: { value } }) =>
    updateCaption({ ...caption, language: value })

  const handleOrignal = ({ target: { checked } }) =>
    updateCaption({ ...caption, is_orignal: checked })

  const handleStatus = ({ target: { checked } }) =>
    updateCaption({ ...caption, status: checked })

  const handleUpload = async (acceptedFiles) => {
    if (uploading) return

    setUploadData({ uploading: true })

    const file = acceptedFiles[0]
    let fileName = uuid4()
    let fileType = file.name.split('.').pop()

    try {
      const { key } = await Storage.put(
        `${videoId}/${config.captionsFolder}/${fileName}.${fileType}`,
        file,
        {
          progressCallback(progress) {
            const { loaded, total } = progress

            setUploadData({
              uploadProgress: Math.floor((loaded * 100) / total)
            })
          }
        }
      )

      updateCaption({ ...caption, url: key })

      setUploadData({ uploading: false, uploadProgress: 0 })
    } catch (error) {
      setUploadData({ uploading: false, uploadProgress: 0 })
    }
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <div className={classes.languageSelectorContainer}>
          <SelectInput
            data={LanguageList}
            multiple={false}
            value={caption.language}
            changeHandler={handleLanguage}
          />
        </div>
        {caption.url && (
          <div className={classes.chipContainer}>
            <Chip label="Uploaded" />
          </div>
        )}
        <div className={classes.progressBarContainer}>
          {uploading && (
            <div className="caption-upload-progress">
              <LinearProgress variant="determinate" value={uploadProgress} />
            </div>
          )}
        </div>
        <div className={classes.dropzoneContainer}>
          <ReactDropzone onDrop={handleUpload} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} accept=".vtt" />
                  <ButtonComponent
                    borderColor={PrimaryColor}
                    titleColor={PrimaryColor}
                    title="ADD"
                    onClickHandler={() => {}}
                    width={90}
                    height={30}
                    icon={<BackupOutlinedIcon className={classes.dropIcon} />}
                  />
                </div>
              </section>
            )}
          </ReactDropzone>
        </div>
      </div>
      <div className={classes.toggleButtonsContainer}>
        <div className={classes.toggleButton}>
          <span className={classes.toggleText}>Is Orignal</span>
          <Switch
            value={caption.is_orignal}
            checked={caption.is_orignal}
            onChange={handleOrignal}
            size="small"
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>

        <div className={classes.toggleButton}>
          <span className={classes.toggleText}>Is Active</span>
          <Switch
            checked={caption.status}
            onChange={handleStatus}
            size="small"
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      {showCloseIcon && (
        <IconButton
          onClick={() => removeCaption(caption.id)}
          size="small"
          className={classes.closeIconButton}>
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1
  },
  card: {
    width: '80%',
    border: '1px solid lightgray',
    borderRadius: 5,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 10
  },
  cardContent: {
    display: 'flex'
  },
  closeIconButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 2
  },
  closeIcon: {
    color: 'red',
    fontSize: 18
  },
  languageSelectorContainer: {
    width: 200
  },
  toggleButtonsContainer: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  toggleButton: {
    display: 'flex',
    paddingLeft: 10
  },
  toggleText: {
    paddingRight: 5
  },
  chipContainer: {
    margin: '0px 10px'
  },
  progressBarContainer: {
    flex: 1,
    margin: '0px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  dropzoneContainer: {
    marginRight: 30
  },
  dropIcon: {
    fontSize: 24,
    paddingRight: 10
  },
  captionsCardContainer: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))
