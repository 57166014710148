import React from 'react'
import { Grid } from '@material-ui/core'

export const GridContainer = ({
  children,
  navigation,
  gutter,
  selectedDevice,
  ...otherProps
}) => {
  return (
    <>
      <Grid item container {...otherProps}>
        {selectedDevice !== 'mobile' && (
          <Grid item xs={gutter ? 1 : false} sm={1} />
        )}
        <Grid
          item
          xs={gutter ? 10 : 12}
          sm={selectedDevice !== 'mobile' ? 10 : 12}>
          {children}
        </Grid>
        {selectedDevice !== 'mobile' && (
          <Grid item xs={gutter ? 1 : false} sm={1} />
        )}
      </Grid>
    </>
  )
}
