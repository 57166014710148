import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { FormInput, Header, SelectInput } from '../../components'
import { CountriesFilters } from '../../lib/constants'
import './pricing.css'
import { PricingByMovieCard } from './_pricing'
import { fetchPricingsData } from '../../redux/modules/pricings'

export const Pricing = () => {
  const allPricings = useSelector((state) => state.pricingsData)
  const [filter, setFilter] = useState('All')

  const [pricingsData, setPricingsData] = useState({})
  const [searchMovie, setSearchMovie] = useState("")

  const history = useHistory()

  const dispatch = useDispatch()

  const navigateToPricingsByMovie = (videoId) =>
    history.push(`/pricing/${videoId}`)

  useEffect(() => {
    ;(async () => {
      try {
      fetchData();
      } catch (err) {
        console.log('Error in fetching movies is: ', err)
      }
    })()
  }, [])

  useEffect(() => {
    const pricingsByMovie = {}

    allPricings.map((item) => {
      const {
        video: { title, status }
      } = item;

      if (pricingsByMovie[title]){
        pricingsByMovie[title] = [...pricingsByMovie[title], item]
      }
      else {
        pricingsByMovie[title] = [item]
        pricingsByMovie[title].status = status
      }
    })

    setPricingsData(pricingsByMovie)
  }, [allPricings])

  const fetchData = async () => {
    try {
      await dispatch(fetchPricingsData())
    } catch (e) {
      console.log('error is: ', e)
    }
  }

  const handleFilter = (event) => {
    let _filter = event.target.value
    setFilter(_filter)
    getMoviesByFilter(_filter, allPricings)
  }

  const getMoviesByFilter = (_filter, _moviesList) => {
    if (_filter === 'All') allPricingObjectFunction(_moviesList)
    else if (_filter === 'enabled') {
      let _movies = _moviesList.filter((item) => item.video.statusValue === "ENABLED")
      allPricingObjectFunction(_movies)
    } else {
      let _movies = _moviesList.filter((item) => item.video.statusValue !== "ENABLED")
      allPricingObjectFunction(_movies)
    }
  }

  const allPricingObjectFunction = (allPricingData) => {
    let pricingsByMovieTemp = {}
    allPricingData.map((item) => {
      const {
        video: { title, status }
      } = item;

      if (pricingsByMovieTemp[title]){
        pricingsByMovieTemp[title] = [...pricingsByMovieTemp[title], item]
      }
      else {
        pricingsByMovieTemp[title] = [item]
        pricingsByMovieTemp[title].status = status
      }
    })
    setPricingsData(pricingsByMovieTemp)
  }


  return (
    <div className="dashboard-page">
      <Header title="Pricing" />
      <div className="plan-content">
        <div className="pricing-filter-container">
          <div className="search-container">
            <FormInput placeholder="Search Movie" id="plan-search" name="search" 
              value={searchMovie}
              onChangeHandler={(e) => { setSearchMovie(e.target.value.toLowerCase()); }} 
            />
          </div>
          <div className="dropdown-filter">
            <SelectInput
              data={CountriesFilters}
              value={filter}
              changeHandler={handleFilter}
            />
          </div>
        </div>

        <div className="plans-list-container">
          {Object.keys(pricingsData).filter((title) => {
            if (searchMovie === "") {
              return title;
            }
            const regex = new RegExp(`${searchMovie}`, 'gi');
            return title.match(regex);
          }).map((title) => (
            <PricingByMovieCard
              title={title}
              item={pricingsData[title]}
              navigateToPricingsByMovie={navigateToPricingsByMovie}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
