import { Auth } from 'aws-amplify'
// constants
const LOGIN = 'LOGIN'

// actions
export const login = (payload) => ({
  payload,
  type: LOGIN
})

// thunks
export const loginUser = (email, password) => {
  return async (dispatch) => {
    try {
      const user = await Auth.signIn(email, password)
      const { attributes, signInUserSession } = user
      const { accessToken } = signInUserSession
      const { payload } = accessToken
      if (
        payload['cognito:groups'] &&
        (payload['cognito:groups'].indexOf('admin') > -1 ||
          payload['cognito:groups'].indexOf('support') > -1)
      ) {
        dispatch({
          type: LOGIN,
          payload: {
            attributes,
            accessToken: accessToken,
            login: true
          }
        })
      } else {
        Auth.signOut()
        return Promise.reject({ code: 'InvalidUser' })
      }

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut()
      dispatch({
        type: LOGIN,
        payload: {
          attributes: null,
          accessToken: null,
          login: false
        }
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

// reducer
const initialState = {
  login: false
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOGIN:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

export default reducer
