import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: ({ titleFontSize }) => `${titleFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: 400,
    textAlign: 'center',
    color: ({ titleColor }) => titleColor,
    backgroundColor: ({ titleBackgroundColor }) => titleBackgroundColor,
    padding: '0px 10px',
    borderRadius: 5,
    backdropFilter: ({ titleBackgroundBlurriness }) =>
      `blur(${titleBackgroundBlurriness}px)`
  },
  h2: {
    fontSize: ({ subTitleFontSize }) => `${subTitleFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    color: ({ subTitleColor }) => subTitleColor,
    backgroundColor: ({ subTitleBackgroundColor }) => subTitleBackgroundColor,
    padding: '0px 10px',
    paddingTop: '0px !important',
    marginTop: 20,
    borderRadius: 5,
    backdropFilter: ({ subTitleBackgroundBlurriness }) =>
      `blur(${subTitleBackgroundBlurriness}px)`
  },
  contentLibraryText: {
    fontSize: ({ bannerFontSize }) => `${bannerFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: 300,
    marginTop: '20px',
    color: '#c2c2c2',
    color: ({ bannerColor }) => bannerColor,
    backgroundColor: ({ bannerBackgroundColor }) => bannerBackgroundColor,
    padding: '0px 10px',
    borderRadius: 5,
    backdropFilter: ({ bannerBackgroundBlurriness }) =>
      `blur(${bannerBackgroundBlurriness}px)`
  },
  container: {
    display: 'flex'
  },
  downArrowContainer: {
    cursor: 'pointer',
    bottom: 0,
    position: 'absolute',
    color: 'white',
    left: '50%',
    '-webkit-transform': 'translateX(-50%)',
    transform: 'translateX(-50%)'
  },
  videoContainer: {
    height: '100%',
    width: '100%'
  },
  videoOverContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  withImageContainer: {
    backgroundImage: ({ url }) => `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
