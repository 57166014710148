import React, { useState, useEffect, useReducer } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { CircularProgress, Chip, Switch } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  ButtonComponent,
  FormInput,
  Header,
  SelectInput
} from '../../components'
import { SecondaryButton } from '../../styles/colors'
import { getPlan as getPlanMutation } from '../../graphql/queries'
import { validatePlan } from './_createUpdatePlan'
import { handlePlanCreation, handleUpdatePlan } from '../../backend/plans'
import { fetchVideoQualities } from '../../redux/modules/videoQualities'
import { fetchDeviceTypes } from '../../redux/modules/deviceTypes'
import './createUpdatePlan.css'

export const CreateUpdatePlan = () => {
  // new plan data
  const [
    {
      selectedQuality,
      selectedDevices,
      title,
      expireAfterPurchase,
      expireAfterStart,
      isAdSupported,
      isDownloadble,
      nWatches,
      nDaysDownloadToExpire
    },
    setPlan
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    selectedQuality: 'Select Quality',
    selectedDevices: ['Select Device'],
    title: '',
    expireAfterPurchase: 43200,
    expireAfterStart: 2880,
    isAdSupported: false,
    isDownloadble: false,
    nWatches: 1,
    nDaysDownloadToExpire: 30
  })

  const [deviceTypesObject, setDeviceTypesObject] = useState({})

  const videoQualities = useSelector((state) => state.videoQualities)
  const deviceTypes = useSelector((state) => state.deviceTypes)

  const dispatch = useDispatch()

  //selected plan for editing
  const [{ selectedPlan, status }, setSelectedPlan] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { selectedPlan: null, status: false }
  )

  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const plans = useSelector((state) => state.plans)

  const { id } = useParams()

  const history = useHistory()

  useEffect(() => {
    const devices = {}
    deviceTypes.forEach(({ id, name }) => {
      devices[id] = name
    })
    setDeviceTypesObject(devices)
  }, [deviceTypes])

  useEffect(() => {
    if (videoQualities.length === 0) dispatch(fetchVideoQualities())
    if (id) fetchPlan()
    if (deviceTypes.length === 0) dispatch(fetchDeviceTypes())
  }, [id])

  const setData = (plan) => {
    const {
      title,
      video_quality: { id },
      status,
      expire_max_mins_after_purchase,
      expire_max_min_after_start,
      is_ad_supported,
      is_downloadable,
      n_watches,
      n_days_download_to_expire
    } = plan

    setSelectedPlan({ selectedPlan: plan, status })
    setFetching(false)
    setPlan({
      title,
      selectedQuality: id,
      expireAfterPurchase: expire_max_mins_after_purchase,
      expireAfterStart: expire_max_min_after_start,
      isAdSupported: is_ad_supported,
      isDownloadble: is_downloadable,
      nWatches: n_watches,
      nDaysDownloadToExpire: n_days_download_to_expire
    })
  }

  useEffect(() => {
    if (selectedPlan && selectedPlan.device_types.length > 0) {
      const deviceIds = selectedPlan.device_types.map(({ deviceId }) => {
        return deviceId
      })
      setPlan({ selectedDevices: deviceIds })
    }
  }, [selectedPlan, setSelectedPlan])

  const fetchPlan = async () => {
    setFetching(true)

    //fetch plan from redux
    const filteredPlans = plans.filter((plan) => plan.id === id)

    //check if it already exists
    if (filteredPlans.length > 0) setData({ ...filteredPlans[0] })
    //if not exist fetch it from backend
    else {
      try {
        const {
          data: { getPlan }
        } = await API.graphql({
          query: getPlanMutation,
          variables: { id }
        })

        const {
          device_types: { items }
        } = getPlan
        if (items.length > 0) {
          const devices = items.map((device) => {
            return {
              id: device.id,
              deviceId: device.device_types.id,
              title: device.device_types.title
            }
          })

          setData({ ...getPlan, device_types: devices })
        }
      } catch (err) {
        console.log('Error in getting plan is: ', err)
        alert('Something went wrong please try again')
      }
    }
  }

  const createPlan = async () => {
    const validation = validatePlan(title, selectedQuality, selectedDevices)

    if (validation) {
      setError(validation)
      return
    }

    setLoading(true)

    let payload = {
      title,
      status: true,
      video_quality_id: selectedQuality,
      is_ad_supported: isAdSupported,
      expire_max_mins_after_purchase: expireAfterPurchase,
      expire_max_min_after_start: expireAfterStart,
      is_downloadable: isDownloadble,
      n_watches: nWatches,
      n_days_download_to_expire: nDaysDownloadToExpire
    }

    try {
      await handlePlanCreation(payload, selectedDevices)

      history.replace('/plan-list')

      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError('Something went wrong please try again.')
    }
  }

  const updatePlan = async () => {
    const validation = validatePlan(title, selectedQuality)
    if (validation) {
      setError(validation)
      return
    }

    setLoading(true)

    let payload = {
      title,
      status,
      video_quality_id: selectedQuality,
      id,
      is_ad_supported: isAdSupported,
      expire_max_mins_after_purchase: expireAfterPurchase,
      expire_max_min_after_start: expireAfterStart,
      is_downloadable: isDownloadble,
      n_watches: nWatches,
      n_days_download_to_expire: nDaysDownloadToExpire
    }

    try {
      await handleUpdatePlan(
        payload,
        selectedDevices,
        selectedPlan.device_types
      )

      history.replace('/plan-list')

      setLoading(false)
    } catch (err) {
      console.log('err', err)
      setLoading(false)
      setError('Something went wrong please try again.')
    }
  }

  return (
    <div className="dashboard-page">
      {fetching ? (
        <div className="centered-container">
          <CircularProgress color="black" />
        </div>
      ) : (
        <div className="create-plan-main-container">
          <Header
            title={selectedPlan ? 'Update your Plan' : `Create your Plan`}
          />

          <div className="create-plan-container">
            <div className="create-plan-input-container">
              <div className="create-plan-input">
                <FormInput
                  placeholder="Plan Title"
                  value={title}
                  onChangeHandler={(event) =>
                    setPlan({ title: event.target.value })
                  }
                  id="plan-title"
                  name="planTitle"
                />
              </div>
              <div className="create-plan-input">
                <SelectInput
                  data={[
                    {
                      id: '-1',
                      name: 'Select Quality',
                      value: 'Select Quality'
                    },
                    ...videoQualities
                  ]}
                  value={selectedQuality}
                  changeHandler={(event) =>
                    setPlan({ selectedQuality: event.target.value })
                  }
                />
              </div>

              <div className="create-plan-input">
                <SelectInput
                  data={[
                    {
                      id: '-1',
                      name: 'Select Device',
                      value: 'Select Device'
                    },
                    ...deviceTypes
                  ]}
                  value={selectedDevices}
                  multiple={true}
                  changeHandler={(event) =>
                    setPlan({ selectedDevices: event.target.value })
                  }
                />
              </div>

              <div className="update-plan-devices">
                {selectedDevices.map((deviceId) => {
                  if (deviceTypesObject[deviceId])
                    return (
                      <div key={deviceId} className="device-tag">
                        <Chip
                          style={{ height: 20 }}
                          label={deviceTypesObject[deviceId]}
                        />
                      </div>
                    )
                })}
              </div>

              <div className="plan-input">
                <div className="status-container">Number of Watches</div>
                <div className="plan-expiry-input">
                  <FormInput
                    value={nWatches}
                    onChangeHandler={({ target: { value } }) =>
                      setPlan({ nWatches: value <= 0 ? 1 : value })
                    }
                    type="number"
                    id="nWatches"
                    name="nWatches"
                  />
                </div>
                <p className="plan-expiry-unit"></p>
              </div>

              <div className="plan-input">
                <div className="status-container">Expire After Purchased</div>
                <div className="plan-expiry-input">
                  <FormInput
                    value={expireAfterPurchase / 1440}
                    onChangeHandler={({ target: { value } }) =>
                      setPlan({ expireAfterPurchase: value * 1440 })
                    }
                    type="number"
                    id="expireAfterPurchase"
                    name="expireAfterPurchase"
                  />
                </div>
                <p className="plan-expiry-unit">Days</p>
              </div>

              <div className="plan-input">
                <div className="status-container">
                  Expire After Movie Started
                </div>
                <div className="plan-expiry-input">
                  <FormInput
                    value={expireAfterStart / 60}
                    onChangeHandler={({ target: { value } }) =>
                      setPlan({ expireAfterStart: value * 60 })
                    }
                    type="number"
                    id="expireAfterStart"
                    name="expireAfterStart"
                  />
                </div>
                <p className="plan-expiry-unit">Hours</p>
              </div>

              <div className="plan-input">
                <div className="status-container">Is Ad Supported?</div>
                <div>
                  <Switch
                    checked={isAdSupported}
                    onChange={(_, checked) =>
                      setPlan({ isAdSupported: checked })
                    }
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </div>
              <div className="plan-input">
                <div className="status-container">Is Downloadable?</div>
                <div>
                  <Switch
                    checked={isDownloadble}
                    onChange={(_, checked) =>
                      setPlan({ isDownloadble: checked })
                    }
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              </div>

              {isDownloadble && (
                <div className="plan-input">
                  <div className="status-container">Download Expiration</div>
                  <div className="plan-expiry-input">
                    <FormInput
                      value={nDaysDownloadToExpire}
                      onChangeHandler={({ target: { value } }) =>
                        setPlan({
                          nDaysDownloadToExpire: value <= 0 ? 1 : value
                        })
                      }
                      type="number"
                      id="nDaysDownloadToExpire"
                      name="nDaysDownloadToExpire"
                    />
                  </div>
                  <p className="plan-expiry-unit">Days</p>
                </div>
              )}

              {selectedPlan && (
                <div className="update-plan-devices">
                  <div className="status-container">
                    {status ? 'Enabled' : 'Disabled'}
                  </div>
                  <div>
                    <Switch
                      checked={status}
                      onChange={(event, checked) =>
                        setSelectedPlan({ status: checked })
                      }
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                </div>
              )}

              {error && <p className="errorText">{error}</p>}
            </div>
          </div>
          <div className="create-plan-footer">
            <div className="create-plan-footer-button">
              <ButtonComponent
                title="CANCEL"
                backgroundColor={SecondaryButton}
                borderColor={SecondaryButton}
                onClickHandler={() => history.replace('/plan-list')}
                width={130}
              />
            </div>
            <div className="create-plan-footer-button">
              <ButtonComponent
                loading={loading}
                title={selectedPlan ? 'UPDATE' : 'SAVE'}
                onClickHandler={selectedPlan ? updatePlan : createPlan}
                width={130}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
