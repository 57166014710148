import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { PrimaryColor, DrawerBackground } from '../styles/colors'

const drawerWidth = 240

export function ApppBar({ classes }) {
  return (
    <AppBar position="absolute" className={classes.appBarShift}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}>
          Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    backgroundColor: PrimaryColor,
    paddingRight: 24
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: DrawerBackground,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: 'flex'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  drawerMenu: {
    '&:hover': {
      backgroundColor: PrimaryColor,
      '& $rightIcon': {
        color: 'white'
      },
      '& $drawerMenuText': {
        color: 'white'
      }
    }
  },
  rightIcon: {
    color: 'black',
    fontSize: 22
  },
  drawerMenuText: {
    color: 'black',
    textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: 600
  },
  selectedDrawerMenu: {
    backgroundColor: PrimaryColor,
    '&:hover': {
      backgroundColor: PrimaryColor
    }
  },
  selectedRightIcon: {
    color: 'white',
    fontSize: 22
  },
  selectedDrawerMenuText: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: 600
  }
}))
