import React, { useState } from 'react'
import {
  TablePagination,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { makeStyles } from '@material-ui/core/styles'
import { UserActivityHeader } from '../../lib/constants'

import moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

function RowHead(_attributes) {
  var head_arr = []
  var i = 0
  const handleHeadFormatting = (key, attrObject) => {
    var _attrObject = attrObject[key]

    if (typeof _attrObject === 'object' && _attrObject !== null) {
      Object.keys(_attrObject).map((key1, index) => {
        if (key1 != 'additional_attributes') {
          handleHeadFormatting(key1, _attrObject)
        }
      })
    } else {
      head_arr[i] = key
      i++
    }
    return head_arr
  }
  var headArray = handleHeadFormatting(
    _attributes.index,
    _attributes.additional_attributes_object
  )
  return (
    <React.Fragment>
      {headArray.map((key) => (
        <TableCell>{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
      ))}
    </React.Fragment>
  )
}

function RowTableBody(_attributes) {
  var body_arr = []
  var i = 0

  const handleBodyFormatting = (key, attrObject) => {
    var _attrObject = attrObject[key]

    if (typeof _attrObject === 'object' && _attrObject !== null) {
      Object.keys(_attrObject).map((key1, index) => {
        if (key1 != 'additional_attributes') {
          handleBodyFormatting(key1, _attrObject)
        }
      })
    } else {
      if (key == 'createdAt' || key == 'updatedAt' || key == 'time') {
        body_arr[i] = moment(_attrObject).format('MM-DD-YY hh:mm:ss A')
      } else body_arr[i] = _attrObject

      i++
    }
    return body_arr
  }
  var bodyArray = handleBodyFormatting(
    _attributes.index,
    _attributes.additional_attributes_object
  )
  return (
    <React.Fragment>
      {bodyArray.map((key) => (
        <TableCell>{key}</TableCell>
      ))}
    </React.Fragment>
  )
}

function Row(userActivityList) {
  const [open, setOpen] = useState(false)

  const additional_attributes_object = JSON.parse(
    userActivityList.row.additional_attributes
  )

  return (
    <React.Fragment>
      <TableRow key={userActivityList.row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {userActivityList.row.action.description}
        </TableCell>

        <TableCell align="left">
          {moment(userActivityList.row.createdAt).format('MM-DD-YY hh:mm:ss A')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {Object.keys(additional_attributes_object).map((key) => (
                      <RowHead
                        index={key}
                        additional_attributes_object={
                          additional_attributes_object
                        }
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(additional_attributes_object).map((key) => (
                    <RowTableBody
                      index={key}
                      additional_attributes_object={
                        additional_attributes_object
                      }
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const UsersActivityList = ({ usersActivityList }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, usersActivityList.length - page * rowsPerPage)

  const classes = useStyles()
  const handleChangePage = (_, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              {UserActivityHeader.map((item) => (
                <TableCell
                  key={item}
                  align="left"
                  className="countries-table-header">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? usersActivityList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : usersActivityList
            ).map((userActivityList) => (
              <Row key={userActivityList.id} row={userActivityList} />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="countries-table-pagination">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={usersActivityList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </div>
  )
}

export { UsersActivityList }
