import React, { useState, useEffect } from 'react'
import {
  IconButton,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from '@material-ui/core'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuid4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'

import {
  ButtonComponent,
  FormInput,
  ImageDropZone,
  OrSeparator,
  VideoAutoSelector,
  Audioform
} from '../../../../../components'
import {
  addTrailerTitle,
  removeTrailer,
  setTrailerThumbNail,
  setTrailer,
  setMainTrailer,
  addTrailerCaptions,
  addTrailerVideoFromBucket,
  setTrailerType,
  addTrailerAudio,
  removeTrailerAudio,
  updateTrailerAudio,
  setTrailerDuration
} from '../../../../../redux/modules/newMovie'
import { PrimaryColor } from '../../../../../styles/colors'
import { CaptionForm } from './captionForm'
import { SourceBucket } from '../../../../../classes'

const dropZoneStyle = {
  dropzone: {
    minHeight: 56,
    borderWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed gray'
  },
  inputLabel: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    color: 'black'
  }
}

export const TrailerUploadForm = ({
  trailer,
  index,
  contentPreview = false
}) => {
  const [name, setName] = useState(null)
  const { trailers, videoId } = useSelector((state) => state.newMovie)
  const count = trailers.length
  const {
    title,
    thumbnail_sm,
    thumbnail_md,
    thumbnail_lg,
    key,
    id,
    isMain,
    captions,
    audios,
    videoFromBucket,
    type,
    duration
  } = trailer
  const dispatch = useDispatch()
  const sourceBucket = new SourceBucket()

  useEffect(() => {
    if (count === 1) dispatch(setMainTrailer(id))
  }, [count])

  const handleIsMain = () => dispatch(setMainTrailer(id))

  const handleTrailerType = ({ target: { value } }) =>
    dispatch(setTrailerType(id, value))

  const handleTitle = ({ target: { value } }) =>
    dispatch(addTrailerTitle(value, id))

  const thumbnails = [
    {
      id: 'thumbnail_lg',
      title: 'Upload Thumbnail (lg)',
      imageUrl: thumbnail_lg
    },
    {
      id: 'thumbnail_md',
      title: 'Upload Thumbnail (md)',
      imageUrl: thumbnail_md
    },
    {
      id: 'thumbnail_sm',
      title: 'Upload Thumbnail (sm)',
      imageUrl: thumbnail_sm
    }
  ]

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done' && name) {
      console.log(status, meta, file)
      dispatch(setTrailer(name, id))
      setName(null)
    }

    if (status === 'headers_received') {
      remove()
    } else if (status === 'aborted') {
      alert('Something went wrong please try again')
      setName(null)
    }
  }

  // public video
  const getUploadParams = async ({ file, meta: { duration } }) => {
    const fileParts = file.name.split('.')
    const fileType = fileParts[1]
    const fileName = `public/${id}/${uuid4()}.${fileType}`

    setName(fileName)

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName,
      Expires: 60,
      ContentType: fileType
    }

    const _url = await sourceBucket.getUploadURL(params)

    return {
      body: file,
      method: 'PUT',
      meta: {
        fileUrl: `${process.env.REACT_APP_BUCKET_URL}/${fileName}`
      },
      url: _url
    }
  }

  const handleTrailerRemovel = () => {
    dispatch(removeTrailer(id))
  }

  const addCaptions = () => {
    dispatch(addTrailerCaptions('English', id))
  }

  const addAudio = () => {
    dispatch(addTrailerAudio(id))
  }

  const handleBucketVideoChange = (value) =>
    dispatch(addTrailerVideoFromBucket(value, id))

  const remove = (audioId) => dispatch(removeTrailerAudio(id, audioId))
  const update = (audioId, payload) =>
    dispatch(updateTrailerAudio(id, audioId, payload))

  const handleDuration = (event, key) => {
    const { value } = event.target
    dispatch(setTrailerDuration(key, value, id))
  }

  return (
    <div className="trailer-form-container">
      <FormInput
        value={title}
        placeholder="Title"
        id="title"
        name="title"
        onChangeHandler={handleTitle}
      />

      <div style={{ display: 'flex', paddingTop: 15 }}>
        <div style={{ flex: 1 }}>
          <Dropzone
            autoUpload={true}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            inputContent={
              <div>
                <p style={{ margin: 0 }}>{'UPLOAD VIDEO'}</p>
                {key && <p className="trailer-name">({key})</p>}
              </div>
            }
            styles={dropZoneStyle}
            accept="video/*"
          />
        </div>
        <OrSeparator />
        <div style={{ flex: 1 }}>
          <VideoAutoSelector
            value={videoFromBucket}
            handleChange={handleBucketVideoChange}
          />
        </div>
      </div>

      {contentPreview && duration && (
        <div className="create-movie-form-item">
          <div className="create-movie-form-label">
            <FormLabel>Duration</FormLabel>
          </div>
          <div className="flex-container">
            <div>
              <FormInput
                onChangeHandler={(event) => handleDuration(event, 'hours')}
                placeholder="hh"
                id="durationHours"
                name="durationHours"
                type={'number'}
                value={duration.hours}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={(event) => handleDuration(event, 'minutes')}
                placeholder="mm"
                id="durationMinutes"
                name="durationMinutes"
                type={'number'}
                value={duration.minutes}
              />
            </div>
            <div className="time-sapartor-container">:</div>
            <div>
              <FormInput
                onChangeHandler={(event) => handleDuration(event, 'seconds')}
                placeholder="ss"
                id="durationSeconds"
                name="durationSeconds"
                type={'number'}
                value={duration.seconds}
              />
            </div>
          </div>
        </div>
      )}

      <div className="create-movie-thumbnail-item">
        {thumbnails.map(({ id: thumbnailId, title, imageUrl }) => {
          return (
            <ImageDropZone
              videoId={videoId}
              key={thumbnailId}
              imageUrl={imageUrl}
              title={title}
              onChange={(value) =>
                dispatch(setTrailerThumbNail(value, thumbnailId, id))
              }
            />
          )
        })}
      </div>

      <div className="trailer-container-close-button">
        <IconButton size="small" onClick={handleTrailerRemovel}>
          <CloseIcon style={{ height: 15, width: 15, color: 'red' }} />
        </IconButton>
      </div>

      <>
        {captions.map((item) => {
          return <CaptionForm trailerId={id} caption={item} />
        })}

        <div className="centered">
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add Captions"
            height={35}
            onClickHandler={addCaptions}
            width={150}
          />
        </div>
      </>
      <div
        style={{
          borderTop: '1px solid gray',
          margin: '10px 0px',
          paddingTop: 10
        }}>
        {audios.map((audio) => {
          return (
            <Audioform
              videoId={id}
              audio={audio}
              isTrailer={true}
              remove={remove}
              update={update}
            />
          )
        })}

        <div className="centered">
          <ButtonComponent
            backgroundColor={PrimaryColor}
            titleColor={'white'}
            borderColor={PrimaryColor}
            title="Add Audio"
            height={35}
            onClickHandler={addAudio}
            width={150}
          />
        </div>
      </div>

      <div className="flex-container">
        {type === 'TRAILER' && (
          <div className="one-flex flex-container">
            <p>Is Main</p>
            <Checkbox
              checked={isMain}
              onClick={handleIsMain}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        )}
        {!contentPreview && (
          <div className="flex-end">
            <RadioGroup
              row
              value={type}
              onChange={handleTrailerType}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group">
              <FormControlLabel
                value="TRAILER"
                control={<Radio color="primary" />}
                label="Trailer"
              />
              <FormControlLabel
                value="SONG"
                control={<Radio color="primary" />}
                label="Song"
              />
              <FormControlLabel
                value="CLIP"
                control={<Radio color="primary" />}
                label="Clip"
              />
            </RadioGroup>
          </div>
        )}
      </div>
    </div>
  )
}
