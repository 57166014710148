import React, { useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { ButtonComponent, ImageDropZone } from '../../../components'
import { SecondaryButton } from '../../../styles/colors'
import { showAlert } from '../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../lib/constants'
import { updateMovie } from '../../../backend/movies'

export const Thumbnails = ({
  thumbnail_lg,
  thumbnail_md,
  thumbnail_sm,
  id: videoId,
  updateLocally
}) => {
  const classes = useStyles()

  const [
    { thumbnailLg, thumbnailMd, thumbnailSm, updated, validated, loading },
    setThumbnails
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    thumbnailLg: thumbnail_lg,
    thumbnailMd: thumbnail_md,
    thumbnailSm: thumbnail_sm,
    updated: false,
    validated: false,
    loading: false
  })
  const dispatch = useDispatch()
  const thumbnails = [
    {
      id: 'thumbnailLg',
      title: 'Upload Thumbnail (lg)',
      imageUrl: thumbnailLg
    },
    {
      id: 'thumbnailMd',
      title: 'Upload Thumbnail (md)',
      imageUrl: thumbnailMd
    },
    {
      id: 'thumbnailSm',
      title: 'Upload Thumbnail (sm)',
      imageUrl: thumbnailSm
    }
  ]

  useEffect(() => {
    if (thumbnailLg && thumbnailMd && thumbnailSm)
      setThumbnails({ validated: true })
    else setThumbnails({ validated: false })
  }, [thumbnailLg, thumbnailMd, thumbnailSm, setThumbnails])

  const handleAlert = (message, type) => {
    dispatch(
      showAlert({
        type,
        message,
        isVisible: true
      })
    )
  }

  const updateThumbnails = async () => {
    setThumbnails({ loading: true })

    const payload = {
      thumbnail_lg: thumbnailLg,
      thumbnail_md: thumbnailMd,
      thumbnail_sm: thumbnailSm,
      id: videoId
    }
    try {
      await updateMovie(payload)
      setThumbnails({ loading: false, updated: false })
      handleAlert('Data updated successfuly', ALERT_TYPES.SUCCESS)
      updateLocally()
    } catch (err) {
      console.log('Error in updating thumbnails is: ', err)
      handleAlert('Something went wrong. Please try again', ALERT_TYPES.ERROR)
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.thumbnails}>
        {thumbnails.map(({ id, title, imageUrl }) => {
          return (
            <ImageDropZone
              videoId={videoId}
              key={id}
              imageUrl={imageUrl}
              title={title}
              onChange={(key) => setThumbnails({ [id]: key, updated: true })}
            />
          )
        })}
      </div>
      <div className={classes.footer}>
        <ButtonComponent
          title="UPDATE"
          disabled={!updated || !validated}
          backgroundColor={SecondaryButton}
          borderColor={SecondaryButton}
          loading={loading}
          onClickHandler={updateThumbnails}
          width={150}
          height={40}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  thumbnails: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1
  }
}))
