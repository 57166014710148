import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import { FormInput, Header } from '../../components'
import { CreateCastButton, CastCard } from './_castList'
import { fetchCast } from '../../redux/modules/cast'
import './castList.css'

export const CastList = () => {
  const [castList, setCastList] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchCast, setSearchCast] = useState("")

  const cast = useSelector((state) => state.cast)
  const castCount = cast.length

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchCasts = async () => {
      setLoading(true)

      try {
        await dispatch(fetchCast())

        setLoading(false)
      } catch (err) {
        alert('Something went wrong. Please try again')

        setLoading(false)
      }
    }

    if (castCount === 0) fetchCasts()
    setCastList(cast)
  }, [castCount])

  return (
    <div className="dashboard-page">
      <Header title="Cast List" />
      <div className="plan-filter-container centered">
        <div className="search-container">
          <FormInput
            placeholder="Search"
            id="plan-search"
            name="search"
            value={searchCast}
            onChangeHandler={(e) => { setSearchCast(e.target.value.toLowerCase()); }}
          />
        </div>
      </div>

      <div className="cast-list-content">
        <CreateCastButton onClickHandler={() => history.push('/create-cast')} />
        {castList.filter((cast) => {
          if (searchCast === "") {
            return cast;
          }
          const regex = new RegExp(`${searchCast}`, 'gi');
          return cast.nick_name.match(regex) || cast.name.match(regex);
        }).map((castItem) => {
          return <CastCard key={castItem.id} castItem={castItem} />
        })}

        {loading && (
          <div className="progress-container">
            <CircularProgress size={20} style={{ color: 'black' }} />
            <p>Searching cast data</p>
          </div>
        )}
      </div>
    </div>
  )
}
