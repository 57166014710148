import { API } from 'aws-amplify'

import { listPlans } from '../../graphql/queries'
import { deletePlan, batchDeletePlanDeviceTypes } from '../../graphql/mutations'
// constants
const SET_PLANS = 'SET_PLANS'

// actions

const cleanData = (items, dispatch) => {
  let processedPlans = items.map((planItem) => {
    let processedPlan = { ...planItem }
    const { device_types } = processedPlan

    if (device_types.items.length > 0) {
      const devuces = device_types.items.map(
        ({ id, device_types: { title, id: deviceId } }) => {
          return {
            id,
            title,
            deviceId
          }
        }
      )

      processedPlan = { ...processedPlan, device_types: devuces }
    }

    return processedPlan
  })

  dispatch({
    type: SET_PLANS,
    payload: processedPlans
  })
  return Promise.resolve()
}
// thunks
export const fetchPlans = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listPlans: { items }
        }
      } = await API.graphql({ query: listPlans })

      cleanData(items, dispatch)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

const deleteAssociatedPlanDevices = async (planId, plans) => {
  const payload = plans.filter(({ id }) => id === planId)
  const planDevices = payload[0].device_types.map(({ id: deviceId }) => {
    return deviceId
  })

  await API.graphql({
    query: batchDeletePlanDeviceTypes,
    variables: { planDevices }
  })
}

export const handleDelete = (planId) => {
  return async (dispatch, getState) => {
    const plans = getState().plans
    const payload = plans.filter(({ id }) => id !== planId)

    try {
      const response = await API.graphql({
        query: deletePlan,
        variables: { input: { id: planId } }
      })
      await deleteAssociatedPlanDevices(planId, plans)
      if (!response.error) {
        dispatch({
          type: SET_PLANS,
          payload
        })
      }
    } catch (err) {
      throw err
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PLANS:
      return payload

    default:
      return state
  }
}

export default reducer
