import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from '@material-ui/core'
import { API } from 'aws-amplify'
import { nanoid } from 'nanoid'

import { FormInput, SelectInput } from '../../../../components'
import { TextareaAutosize } from '@mui/material'
import {
  handleFormChanges,
  setPromoCode
} from '../../../../redux/modules/promoCode'
import { fetchPlans } from '../../../../redux/modules/plans'
import { listVideos } from '../../../../graphql/queries'

export const PromoFormDetail = ({ countries }) => {
  const {
    newPromoCode: {
      country_code,
      start_date,
      end_date,
      percentage_off,
      max_use_limit,
      max_use_limit_by_user,
      unlimited,
      video_id,
      plan_id,
      code,
      user_id,
      justification
    }
  } = useSelector((state) => state.promoCode)
  const plans = useSelector((state) => state.plans)
  const plansCount = plans.length

  const [plansList, setPlansList] = useState([])
  const [videosList, setVideosList] = useState([])

  const dispatch = useDispatch()

  const handleChange = (key, value) =>
    dispatch(handleFormChanges({ [key]: value }))

  const handleUnlimited = () =>
    dispatch(handleFormChanges({ unlimited: !unlimited, max_use_limit: null }))

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchPlans())
        const {
          data: {
            listVideos: { items }
          }
        } = await API.graphql({
          query: listVideos,
          variables: { filter: { statusValue: { eq: 'ENABLED' } }, limit: 500 }
        })

        const processVideos = items.map(({ id, title }) => {
          return {
            id,
            value: id,
            name: title
          }
        })
        setVideosList(processVideos)
      } catch (err) {
        console.log('Error is', err)
        alert(err.message || 'Something went wrong please try again')
      }
    })()
  }, [setVideosList, dispatch])

  useEffect(() => {
    if (plansCount > 0) {
      const processedPlans = plans.map(({ id, title }) => {
        return { id: id, name: title, value: id }
      })
      setPlansList(processedPlans)
    }
  }, [plansCount, setPlansList])

  useEffect(() => {
    dispatch(setPromoCode(nanoid(8)))
  }, [dispatch])

  return (
    <div className="promo-code-form-detail">
      <div className="promo-detail-form-container">
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Promo Code *</div>
          <FormInput
            placeholder="Promo Code"
            id="promo_code"
            name="promo_code"
            onChangeHandler={({ target: { value } }) =>
              handleChange('code', value)
            }
            value={code}
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">User ID</div>
          <FormInput
            placeholder="User ID"
            type="number"
            id="user_id"
            name="user_id"
            onChangeHandler={({ target: { value } }) =>
              handleChange('user_id', value)
            }
            value={user_id}
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Select Country *</div>
          <SelectInput
            data={[
              { id: '-1', name: 'Select Country', value: '-1' },
              ...countries
            ]}
            value={country_code}
            changeHandler={({ target: { value } }) =>
              handleChange('country_code', value)
            }
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Select Video</div>
          <SelectInput
            data={[
              { id: '-1', value: '-1', name: 'Select Video' },
              ...videosList
            ]}
            value={video_id}
            changeHandler={({ target: { value } }) =>
              handleChange('video_id', value)
            }
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Select Plan</div>
          <SelectInput
            data={[
              { id: '-1', value: '-1', name: 'Select Plan' },
              ...plansList
            ]}
            value={plan_id}
            changeHandler={({ target: { value } }) =>
              handleChange('plan_id', value)
            }
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Start Date</div>
          <FormInput
            placeholder="Start Date"
            id="start_date"
            name="start_date"
            type="date"
            onChangeHandler={({ target: { value } }) =>
              handleChange('start_date', value)
            }
            value={start_date}
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">End Date *</div>
          <FormInput
            placeholder="End Date"
            id="end_date"
            name="end_date"
            type="date"
            onChangeHandler={({ target: { value } }) =>
              handleChange('end_date', value)
            }
            value={end_date}
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Percentage Off *</div>
          <FormInput
            placeholder="Percentage Off"
            id="percentage_off"
            name="percentage_off"
            type="number"
            onChangeHandler={({ target: { value } }) =>
              handleChange('percentage_off', value)
            }
            value={percentage_off}
          />
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Max. Use Limit</div>
          <FormInput
            disabled={unlimited}
            placeholder="Max. Use Limit"
            id="max_use_limit"
            name="max_use_limit"
            type="number"
            onChangeHandler={({ target: { value } }) =>
              handleChange('max_use_limit', value)
            }
            value={max_use_limit}
          />
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <Checkbox
              checked={unlimited}
              style={{ padding: 0, margin: 0 }}
              onClick={handleUnlimited}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <p style={{ paddingLeft: 10 }}>Unlimited use</p>
          </div>
        </div>
        <div className="promo-code-form-input">
          <div className="promo-code-form-label">Max. Use Limit By a User</div>
          <FormInput
            placeholder="Max. Use Limit By a User"
            id="max_use_limit_by_user"
            name="max_use_limit_by_user"
            type="number"
            onChangeHandler={({ target: { value } }) =>
              parseInt(value) > 0 &&
              handleChange('max_use_limit_by_user', parseInt(value))
            }
            value={max_use_limit_by_user}
          />
        </div>
        <div className="promo-code-form-input" style={{ float: 'unset' }}>
          <div className="promo-code-form-label">Justification</div>
          <TextareaAutosize
            rowsMax={4}
            aria-label="maximum height"
            placeholder="Please Fill This"
            id="justification"
            name="justification"
            value={justification}
            onChange={(e) => {
              handleChange('justification', e.target.value)
            }}
          />
        </div>
      </div>
    </div>
  )
}
