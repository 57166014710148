import { API } from 'aws-amplify'

import { listContentRatings } from '../../graphql/queries'

// constants
const SET_CONTENT_RATINGS = 'SET_CONTENT_RATINGS'

const filter = {
  status: {
    eq: true
  }
}

// thunks
export const fetContentRatings = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listContentRatings: { items }
        }
      } = await API.graphql({
        query: listContentRatings,
        variables: { filter: filter }
      })
      const ratings = items.map((item) => {
        return { ...item, name: item.title }
      })
      dispatch({
        type: SET_CONTENT_RATINGS,
        payload: ratings
      })
    } catch (err) {
      throw err
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CONTENT_RATINGS:
      return payload

    default:
      return state
  }
}

export default reducer
