import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addMovieAndTrailerToAWS } from '../../backend/movies'
import './createMovie.css'
import { Uploader, Steps, Loader } from './_createMovie'
import { fetchCast } from '../../redux/modules/cast'
import {
  clearData,
  fetchCastCrewTypes,
  setVideoID
} from '../../redux/modules/newMovie'
import { MovieFooter } from './movieFooter'
import { useHistory } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

export const CreateMovie = () => {
  const [
    { saving, savingProgress, progressDetail },
    setSavingData
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    saving: false,
    savingProgress: 0,
    progressDetail: ''
  })

  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(true)

  const cast = useSelector((state) => state.cast)

  const newMovie = useSelector((state) => state.newMovie)

  const { castCrew, videoId } = newMovie

  const dispatch = useDispatch()

  const history = useHistory()

  const castCount = cast.length

  const castCrewKeys = castCrew ? Object.keys(castCrew).length : 0

  useEffect(() => {
    if (!castCrew) {
      const fetchCastCrewTypesList = async () => {
        try {
          await dispatch(fetchCastCrewTypes())
        } catch (err) {
          console.log('Error in fetching cast Crew Types', err)
          alert('Something went wrong in fetching cast crew types')
        }
      }

      fetchCastCrewTypesList()
    }
  }, [castCrewKeys, dispatch])

  useEffect(() => {
    if (videoId === '' || !videoId) dispatch(setVideoID(uuidv4()))
  }, [videoId, dispatch])

  const handleClose = () =>
    setSavingData({ saving: false, savingProgress: 0, progressDetail: '' })

  const openLoader = async () => {
    setSavingData({
      saving: true,
      savingProgress: 5,
      progressDetail: 'Transcoding Movie'
    })

    const saveMovie = async () => {
      try {
        await addMovieAndTrailerToAWS(newMovie, handleProgress)

        dispatch(clearData())

        handleClose()
      } catch (err) {
        alert(err)

        handleClose()

        return
      }

      history.push('/')
    }

    setTimeout(() => {
      saveMovie()
    }, 3000)
  }

  const handleSteps = (value) => {
    if (value > 0 && value < 9) setStep(value)
  }

  useEffect(() => {
    if (step === 3 && castCount === 0) {
      const fetchCastCrews = async () => {
        try {
          setLoading(true)
          await dispatch(fetchCast())
          setLoading(false)
        } catch (err) {
          alert('Something went wrong please try again')
        }
      }

      fetchCastCrews()
    } else setLoading(false)
  }, [step, castCount, dispatch])

  const handleProgress = (value, text) =>
    setSavingData({ saving: true, savingProgress: value, progressDetail: text })

  return (
    <div className="dashboard-page">
      {loading ? <Loader /> : <Steps step={step} />}
      <MovieFooter
        handleSteps={handleSteps}
        step={step}
        openLoader={openLoader}
      />

      <Uploader
        saving={saving}
        savingProgress={savingProgress}
        progressDetail={progressDetail}
      />
    </div>
  )
}
