import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonComponent, Header, Audioform } from '../../../../components'
import { PrimaryColor } from '../../../../styles/colors'
import {
  addAudio,
  removeAudio,
  updateAudio
} from '../../../../redux/modules/newMovie'

export const AddAudio = () => {
  const { audios, videoId } = useSelector((state) => state.newMovie)
  const dispatch = useDispatch()

  const handleAdd = () => {
    dispatch(addAudio())
  }

  const remove = (id) => dispatch(removeAudio(id))
  const update = (id, payload) => dispatch(updateAudio(id, payload))

  return (
    <div>
      <Header title="Add Audio" />
      <div className="create-movie-step">
        <div className="create-caption-button">
          <ButtonComponent
            borderColor={PrimaryColor}
            backgroundColor={PrimaryColor}
            titleColor={'#ffffff'}
            title="ADD"
            width={90}
            height={25}
            onClickHandler={handleAdd}
          />
        </div>
        {audios.length === 0 && (
          <div className={'empty-captions-container'}>
            Please click on the <span className={'highlighted-text'}>ADD</span>{' '}
            button above to add audio or press{' '}
            <span className={'highlighted-text'}>NEXT</span> below to skip it
          </div>
        )}
        {audios.map((audio) => {
          return (
            <Audioform
              key={audio.id}
              audio={audio}
              videoId={videoId}
              remove={remove}
              update={update}
            />
          )
        })}
      </div>
    </div>
  )
}
