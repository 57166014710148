import AWS from 'aws-sdk'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
})

export class SourceBucket {
  #bucket
  constructor() {
    this.#bucket = new AWS.S3({
      region: process.env.REACT_APP_REGION,
      signatureVersion: 'v4'
    })
  }

  listProtectedContent() {
    return this.listContent('protected/')
  }

  listPublicContent() {
    return this.listContent('public/')
  }

  async listContent(filteringValue) {
    const params = { Bucket: `${process.env.REACT_APP_BUCKET_NAME}` }
    return new Promise((resolve, reject) => {
      this.#bucket.listObjects(params, (err, data) => {
        if (err) return reject(err)

        const list = data.Contents.filter(
          (item) =>
            item.StorageClass === 'STANDARD' &&
            item.Key.includes(filteringValue)
        ).map((item) => ({
          ...item,
          label: item.Key.replace(filteringValue, '')
        }))
        return resolve(list)
      })
    })
  }

  async getUploadURL(params) {
    return new Promise((resolve, reject) => {
      this.#bucket.getSignedUrl('putObject', params, (err, url) => {
        if (err) return reject(err)
        return resolve(url)
      })
    })
  }
}
