import { API, Auth } from 'aws-amplify'

import { listVideos } from '../graphql/queries'

export const fetchEnabledVideos = async () => {
  try {
    const {
      data: {
        listVideos: { items }
      }
    } = await API.graphql({
      query: listVideos,
      variables: { filter: { statusValue: { eq: 'ENABLED' } } }
    })

    const videos = items.map((_video) => {
      return {
        id: _video.id,
        name: _video.title,
        value: _video.id,
        status: _video.statusValue
      }
    })

    return videos
  } catch (err) {
    throw err
  }
}

export const getSignedUrl = async (userId, videoUrl) => {
  const authToken = await (await Auth.currentSession())
    .getIdToken()
    .getJwtToken()

  const params = {
    body: {
      userId,
      videoUrl
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('getSignedUrl', '/admin-signed-url', params)
}
