import { API } from 'aws-amplify'

import { listVideoQualities } from '../../graphql/queries'

// constants
const SET_VIDEO_QUALITIES = 'SET_VIDEO_QUALITIES'

const filter = {
  status: {
    eq: true // filter priority = 1
  }
}

// thunks
export const fetchVideoQualities = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listVideoQualities: { items }
        }
      } = await API.graphql({
        query: listVideoQualities,
        variables: { filter: filter }
      })

      const qualities = items.map(
        ({ id, vertical_resolution: { title, resolution } }) => {
          return {
            id: id,
            name: title,
            value: id,
            resolution: parseInt(resolution)
          }
        }
      )
      const sortedByResolutionQualities = qualities.sort(
        (a, b) => a.resolution - b.resolution
      )

      dispatch({
        type: SET_VIDEO_QUALITIES,
        payload: sortedByResolutionQualities
      })
    } catch (err) {
      throw err
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_VIDEO_QUALITIES:
      return payload

    default:
      return state
  }
}

export default reducer
