import { API } from 'aws-amplify'

import {
  batchDeletePricings,
  batchCreatePricings,
  batchCreateVideoPlans
} from '../graphql/mutations'
import {
  listPricings,
  listVideoByQualities,
  planByQuality
} from '../graphql/queries'

export const deletePricings = async (items) => {
  const pricingIds = items.map((item) => item.id)

  try {
    await API.graphql({
      query: batchDeletePricings,
      variables: { pricingIds }
    })

    return
  } catch (err) {
    throw err
  }
}

export const fetchPricings = async () => {
  try {
    const {
      data: {
        listPricings: { items }
      }
    } = await API.graphql({
      query: listPricings
    })

    return items
  } catch (err) {
    throw err
  }
}

export const pricingsByCountryAndVideo = async (videoId, countryCode) => {
  try {
    const {
      data: {
        listPricings: { items }
      }
    } = await API.graphql({
      query: listPricings,
      variables: {
        filter: {
          and: {
            video_id: { eq: videoId },
            country_code: { eq: countryCode }
          }
        }
      }
    })

    return items
  } catch (err) {
    throw err
  }
}

// update pricings
export const updatePricing = async (
  video_id,
  country_code,
  selectedPlans,
  selectedPricings
) => {
  // Steps:
  // 1- Delete all old pricings
  // 2- Add all new videoplans user added prcings for
  // 3- Add pricings for all existed and newly added plans

  try {
    //  delete all old pricings
    const toDeletePricingIds = selectedPricings.map((item) => item.id)
    if (toDeletePricingIds.length > 0)
      await API.graphql({
        query: batchDeletePricings,
        variables: { pricingIds: toDeletePricingIds }
      })

    // new videoPlans to create for which new pricings are added
    const existingPlanIds = selectedPricings.map(
      ({ video_plan: { plan_id } }) => plan_id
    )
    const newPlans = selectedPlans.filter(
      ({ id }) => !existingPlanIds.includes(id)
    )
    const newVideoPlans = newPlans.map((item) => {
      const { id, status, videoByQualityId } = item

      return {
        plan_id: id,
        status: status,
        video_by_quality_id: videoByQualityId
      }
    })
    const planIds = selectedPlans.map(({ id }) => id)
    const existingVideoPlans = selectedPricings
      .filter(({ video_plan: { plan_id } }) => planIds.includes(plan_id))
      .map(({ video_plan }) => video_plan)
    let newlyAddedVideoPlans = [...existingVideoPlans]
    if (newVideoPlans.length > 0) {
      const { data } = await API.graphql({
        query: batchCreateVideoPlans,
        variables: { videoPlans: newVideoPlans }
      })
      newlyAddedVideoPlans = [
        ...newlyAddedVideoPlans,
        ...data.batchCreateVideoPlans
      ]
    }

    // create new pricings for the plans that are exists already
    // and for newly added plans

    const pricings = newlyAddedVideoPlans.map((item) => {
      const plan = selectedPlans.filter((i) => i.id === item.plan.id)

      return {
        country_code,
        price: plan.length > 0 ? parseFloat(plan[0].price) : 0.0,
        status: true,
        video_plan_id: item.id,
        video_id
      }
    })

    await API.graphql({
      query: batchCreatePricings,
      variables: { pricings }
    })

    return
  } catch (err) {
    throw err
  }
}

export const createPricing = async (movie, country, selectedPlans) => {
  try {
    const videoPlans = selectedPlans.map((item) => {
      return {
        plan_id: item.id,
        status: true,
        video_by_quality_id: item.videoByQualityId
      }
    })

    const response = await API.graphql({
      query: batchCreateVideoPlans,
      variables: { videoPlans }
    })

    const { data } = response

    const pricings = data.batchCreateVideoPlans.map((item) => {
      const plan = selectedPlans.filter((i) => i.id === item.plan.id)
      return {
        country_code: country,
        price: plan.length > 0 ? parseFloat(plan[0].price) : 0.0,
        status: true,
        video_plan_id: item.id,
        video_id: movie
      }
    })

    await API.graphql({
      query: batchCreatePricings,
      variables: { pricings }
    })

    return
  } catch (err) {
    console.log('Error in create pricing is: ', err)
    throw err
  }
}

// The flow is not finialized yet
// dummy flow of fetching relevant plans
export const fetchPlans = async (videoId) => {
  try {
    const {
      data: {
        listVideoByQualities: { items }
      }
    } = await API.graphql({
      query: listVideoByQualities,
      variables: { limit: 1000, filter: { video_id: { eq: videoId } } }
    })

    let promises = []

    items.forEach((item) => {
      const { id, video_quality_id } = item

      promises.push(
        new Promise((resolve) => {
          API.graphql({
            query: planByQuality,
            variables: { video_quality_id: video_quality_id }
          }).then((result) => {
            const {
              data: { planByQuality }
            } = result

            resolve({
              plansByQuality: planByQuality.items,
              videoByQualityId: id
            })
          })
        })
      )
    })

    return Promise.all(promises)

    //will do it will a single query later
    // const response = await API.graphql({
    //     query: getPlansByQualities,
    //     variables: { qualityIds:  videoQualites}
    //   })
    // const plans = response.data.getPlansByQualities.items
  } catch (err) {
    throw err
  }
}
