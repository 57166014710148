import { API } from 'aws-amplify'
import { listGenres } from '../../graphql/queries'

// constants
const SET_GENRES = 'SET_GENRES'

// actions

// thunks
export const fetchGenres = () => {
  return async (dispatch) => {
    try {
      const {
        data: {
          listGenres: { items }
        }
      } = await API.graphql({
        query: listGenres,
        variables: {
          filter: {
            status: {
              eq: true
            }
          }
        }
      })

      const genres = items
        .map((item) => {
          return { id: item.id, name: item.title, value: item.id }
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      dispatch({
        type: SET_GENRES,
        payload: genres
      })
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

// reducer
const initialState = []

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_GENRES:
      return payload

    default:
      return state
  }
}

export default reducer
